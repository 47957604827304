
module.exports = {
        ENV :{
        // url: 'http://localhost:8080/' ,
        // url : 'http://18.130.245.236:8080/'
        // url: 'http://3.8.116.192:8080/'
        url: 'https://www.esignrepack.co.uk/'
        // Staging server for site testing
        // url: 'http://18.169.131.65/'
        //url: 'http://35.177.55.225/'
        // url: 'http://35.178.176.156:8080/' // faisal
        // url: 'http://35.178.176.156:8080/' 
        }
    }
