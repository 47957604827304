import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import AllUserService from './../../services/alluser.service';
import OutBoxService from '../../services/outBox.services';
import LeadsListingService from '../../services/leadslisting.service';
import DateRangePicker from 'react-bootstrap-daterangepicker'

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class OutBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            twillioService: [],
            twillioWhatsAppService: [],
            smslocalService: [],
            mailgunService: [],
            mailchimpService: [],
            seven67Service: [],

            firstname: "",
            lastname: "",
            phone: '',
            email: '',
            dateTo: '',
            dateFrom: '',
            leadId: '',
            existingCSV: [],
            smstemplate: [],
            emailtemplate: [],
            perPage: 50,
            selectedEmailID: '',
            selectedSMSID: '',
            selectedUserID: '',
            selectedCSV: '',
            createdAt: '',
            pandaLoader: true,
			allusersArray: [],

            hasMoreTwilio: false,
            hasMoreSeven67: true,
            hasMoreTwilioWhatsapp: false,
            hasMoreTwilioMailgun: false,
            hasMoreTwilioMailchimp: false,
            hasMoreTwilioLocal: false,

            userStatus: '',
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),


        };
        this.outBoxService = new OutBoxService();
        this.leadlistingService = new LeadsListingService();
        this.alluserService = new AllUserService();
    }

    componentDidMount() {

       
      


    window.scrollTo(0, 0);

    let loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser.role === 'user') {
        let userId = loginUser.id;
        this.allSubUsersAPI(userId);
    }

        let role = JSON.parse(localStorage.getItem('user'));
        if (role) {
            let roleStatus = role.id;
            this.alluserService.editUser(roleStatus).then(data => {
                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        userStatus: result,
                    })
                    if (this.state.userStatus.status === 'inactive') {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
        let rolename = role.role;
        if (rolename === 'subUser') {
            this.getPermissions();
        } else {
            this.setState({ pandaLoader: false })
        }
        // this.getSMSlocal();
        this.getTwilioOutBox();
        this.getTwilioWhatsApp();
        this.getTwilioSmslocal();
        this.getTwilioMailgun();
        this.getTwilioMailchimp();
        this.getSeven67();

        this.getAllCSV();
        this.smsTemplate();
        this.emailTemplate();
    }
     
    allSubUsersAPI(userId) {
        this.setState({
            isloading: true
        })

        this.alluserService.allSubUsers(userId).then(data => {

            if (data.code === 200) {
                this.setState({
                    allusersArray: data.data,
                    isloading: false
                })
            }
        }).catch(err => {
            console.log(err)
        })
	}


    getPermissions() {

        let user = JSON.parse(localStorage.getItem('user'));
        let id = user.id;
        let role = user.role;
        if (role === 'subUser') {
            this.alluserService.userPermission(id).then(data => {

                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        permission: result,
                        pandaLoader: false
                    })
                    if (this.state.permission.outbox === false) {
                        this.props.history.push('*');
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getTwilioOutBox() {
        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString();
            // this.state.creationDateStart ? new Date(this.state.creationDateStart) : "";
            // startDate = startDate ? startDate.toISOString() : "";


            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();


            let body = {
                phone: phone,
                firstname: firstname,
                smsTemplate: selectedSMSID,
                email: email,
                lastname: this.state.lastname,
                emailTemplate: selectedEmailID,
                csvId: selectedCSV,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID

            }
            this.setState({
                hasMoreTwilio: true
            })
            this.outBoxService.getTwilioOutBox(body).then(data => {
                   debugger
                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreTwilio: false
                        })
                    }
                    this.setState({
                        twillioService: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getTwilioWhatsApp() {
        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString();

            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

            let body = {
                phone: this.state.phone,
                firstname: this.state.firstname,
                smsTemplate: this.state.selectedSMSID,
                email: this.state.email,
                lastname: this.state.lastname,
                emailTemplate: this.state.selectedEmailID,
                csvId: selectedCSV,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID
            }
            this.setState({
                hasMoreTwilioWhatsapp: true
            })
            this.outBoxService.getTwilioWhatsAppOutBox(body).then(data => {

                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreTwilioWhatsapp: false
                        })
                    }
                    this.setState({
                        twillioWhatsAppService: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getTwilioSmslocal() {
        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString(dateStart);

            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString(dateEnd);

            let body = {
                phone: this.state.phone,
                firstname: this.state.firstname,
                smsTemplate: this.state.selectedSMSID,
                email: this.state.email,
                csvId: selectedCSV,
                lastname: this.state.lastname,
                emailTemplate: this.state.selectedEmailID,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID
            }
            this.setState({
                hasMoreTwilioLocal: true
            });
            this.outBoxService.getSmsLocalOutBox(body).then(data => {

                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreTwilioLocal: false
                        })
                    }

                    this.setState({
                        smslocalService: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getTwilioMailgun() {
        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString();

            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();
            let body = {
                phone: this.state.phone,
                firstname: this.state.firstname,
                smsTemplate: this.state.selectedSMSID,
                email: this.state.email,
                csvId: selectedCSV,
                lastname: this.state.lastname,
                emailTemplate: this.state.selectedEmailID,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID
            }
            this.setState({
                hasMoreTwilioMailgun: true
            })
            this.outBoxService.getmailgunOutBox(body).then(data => {

                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreTwilioMailgun: false
                        })
                    }
                    this.setState({
                        mailgunService: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getTwilioMailchimp() {
        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString();

            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();
            let body = {
                phone: this.state.phone,
                firstname: this.state.firstname,
                smsTemplate: this.state.selectedSMSID,
                email: this.state.email,
                csvId: selectedCSV,
                lastname: this.state.lastname,
                emailTemplate: this.state.selectedEmailID,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID
            }
            this.setState({
                hasMoreTwilioMailchimp: true
            })
            this.outBoxService.getmailchimpOutBox(body).then(data => {

                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreTwilioMailchimp: false
                        })
                    }
                    this.setState({
                        mailchimpService: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    getSeven67() {

        if (true) {
            const { firstname, phone, email, selectedEmailID, selectedSMSID, selectedCSV } = this.state;
            var dateStart = this.state.startDate == "" ? "" : new Date(this.state.startDate);
            dateStart = dateStart == "" ? "" : dateStart.toISOString();

            var dateEnd = this.state.endDate == "" ? "" : new Date(this.state.endDate);
            dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();
            let body = {
                phone: this.state.phone,
                firstname: this.state.firstname,
                smsTemplate: this.state.selectedSMSID,
                email: this.state.email,
                csvId: selectedCSV,
                lastname: this.state.lastname,
                emailTemplate: this.state.selectedEmailID,
                startDate: dateStart,
                endDate: dateEnd,
                perPage: this.state.perPage,
                selectedUserID: this.state.selectedUserID
            }
            this.setState({
                hasMoreSeven67: true
            })
            this.outBoxService.getSeven67OutBox(body).then(data => {

                console.log("data", data);
                if (data.code == 404) {
                    // notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    if (data.theCount === data.data.length) {
                        this.setState({
                            hasMoreSeven67: false
                        })
                    }
                    this.setState({
                        seven67Service: data.data,
                        errors: ""
                    });
                    // notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    search() {
        this.setState({
            isloading: true
        })
        this.getTwilioOutBox();
        this.getTwilioWhatsApp();
        this.getTwilioSmslocal();
        this.getTwilioMailgun();
        this.getTwilioMailchimp();
        this.getSeven67();
    }

    clear() {
   
        this.setState({
            firstname: "",
            lastname: "",
            phone: '',
            email: '',
            dateTo: '',
            dateFrom: '',
            leadId: '',
            selectedEmailID: '',
            selectedSMSID: '',
            selectedCSV: '',
            perPage :50,
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
        }, () => {
            this.getTwilioOutBox();
            this.getTwilioWhatsApp();
            this.getTwilioSmslocal();
            this.getTwilioMailgun();
            this.getTwilioMailchimp();
            this.getSeven67();

        })


    }

    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onChangedate = date => this.setState({ date: date })




    getAllCSV() {
        this.leadlistingService.getAllCSV().then(data => {
            console.log(data)
            if (data.code === 404) {
                this.setState({
                    existingCSV: []
                })
            }
            if (data.code === 200) {
                this.setState({
                    existingCSV: data.data
                })
            }
        }).catch(err => {
            console.log(err)
        });
    }

    smsTemplate() {
        this.leadlistingService.allSmSTemplateOutbox().then(data => {
            console.log(data, "sms");
            if (data.code === 200) {
                this.setState({
                    smstemplate: data.data
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }


    emailTemplate() {
        this.leadlistingService.allEmailTemplateOutbox().then(data => {
            console.log(data, "email");
            if (data.code === 200) {
                this.setState({
                    emailtemplate: data.data
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    selectEmailSMS(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleEvent = (e, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate
        })
    }

    fetchMoreDataTwilio = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

        //setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreTwilio: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                that.outBoxService.getTwilioOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {

                        if (data.theCount === data.data.length) {
                            that.setState({
                                hasMoreTwilio: false
                            })
                        }
                        that.setState({
                            twillioService: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        //}, 1000);

    }

    fetchMoreDataTwilioWhatsapp = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();




        setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreTwilioWhatsapp: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                that.outBoxService.getTwilioWhatsAppOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        if (data.theCount === data.data.length) {
                            that.setState({
                                hasMoreTwilioWhatsapp: false
                            })
                        }
                        that.setState({
                            twillioWhatsAppService: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        }, 1000);

    }

    fetchMoreDataLocal = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

        setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreTwilioLocal: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                that.setState({
                    hasMoreTwilioLocal: true
                });
                that.outBoxService.getSmsLocalOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        if (data.theCount === data.data.length) {
                            that.setState({
                                hasMoreTwilioLocal: false
                            })
                        }

                        that.setState({
                            smslocalService: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        }, 1000);

    }

    fetchMoreDataMailgun = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

        setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreTwilio: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                that.setState({
                    hasMoreTwilioMailgun: true
                })
                that.outBoxService.getmailgunOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        if (data.theCount === data.data.length) {
                            that.setState({
                                hasMoreTwilioMailgun: false
                            })
                        }
                        that.setState({
                            mailgunService: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        }, 1000);

    }


    fetchMoreDataMailchimp = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

        setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreTwilio: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                this.setState({
                    hasMoreTwilioMailchimp: true
                })
                this.outBoxService.getmailchimpOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        if (data.theCount === data.data.length) {
                            this.setState({
                                hasMoreTwilioMailchimp: false
                            })
                        }
                        this.setState({
                            mailchimpService: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        }, 1000);

    }

    fetchMoreDataSeven67 = () => {
        var that = this;

        var dateStart = that.state.startDate == "" ? "" : new Date(that.state.startDate);
        dateStart = dateStart == "" ? "" : dateStart.toISOString();

        var dateEnd = that.state.endDate == "" ? "" : new Date(that.state.endDate);
        dateEnd = dateEnd == "" ? "" : dateEnd.toISOString();

        setTimeout(() => {
            that.setState({
                perPage: that.state.perPage + 50,
            }, () => {

                that.setState({
                    hasMoreSeven67: true
                })

                let body = {
                    phone: that.state.phone,
                    firstname: that.state.firstname,
                    smsTemplate: that.state.selectedSMSID,
                    email: that.state.email,
                    lastname: that.state.lastname,
                    emailTemplate: that.state.selectedEmailID,
                    csvId: that.state.selectedCSV,
                    startDate: dateStart,
                    endDate: dateEnd,
                    perPage: that.state.perPage
                }
                this.setState({
                    hasMoreSeven67: true
                })
                this.outBoxService.getmailchimpOutBox(body).then(data => {

                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        // notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        if (data.theCount === data.data.length) {
                            this.setState({
                                hasMoreSeven67: false
                            })
                        }else{
                            
                        }
                        this.setState({
                            seven67Service: data.data,
                            errors: ""
                        });
                        // notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            });
        }, 1000);

    }

    render() {
        console.log(this.state)
        return (
            <div className="content-page Setting-tab tab-structure" id="setting03">
                {this.state.pandaLoader === true ?
                    <div className="loading">Loading&#8230;</div>
                    : ""}
                <Notifications options={{ zIndex: 200, top: '20px' }} />

                <div className="setting-fields field-set">

                    <div className="outbox-filter">
                        <div className="page-heading">
                            <h3>Outbox</h3>
                        </div>
                       
                        <ul className="adv-filter-outbox">
                        <li className="form-group select-filed">
                                <div className="custom-select">
                                    <div className="dropdown lead-sentdrop custom-select">
                                        <select className="btn-dropdown dropdown-toggle btn-dropsent form-control" name="selectedUserID" value={this.state.selectedUserID} onChange={(e) => this.onchange(e)} data-toggle="dropdown">
                                            <option value="">Select User</option>
                                            {this.state.smstemplate.length > 0 ?
                                                
                                                this.state.allusersArray.map((val, key) => {
                                                    return (<option value={val._id} key={key}>{val.firstName.length < 15 ? val.firstName : val.firstName.slice(0, 15) + " ..."}</option>)
                                                }) : <option value="">No Record Found</option>}
                                        </select>
                                    </div>
                                </div>
                            </li>
                            </ul>
                        <ul className="adv-filter-outbox">
                            <li className="form-group text-filed">
                                <input type="text" className="form-control" name="firstname" value={this.state.firstname || ''} placeholder="First Name" onChange={(e) => this.onchange(e)} />
                            </li>
                            <li className="form-group text-filed">
                                <input type="text" className="form-control" name="lastname" value={this.state.lastname || ''} placeholder="Last Name" onChange={(e) => this.onchange(e)} />
                            </li>
                            {/* <li className="form-group text-filed">
                                <input type="text" className="form-control"  name="leadId" value={this.state.leadId || ''} placeholder=" search by Lead ID"  onChange={(e) => this.onchange(e)}/>
                            </li> */}
                            <li className="form-group text-filed">
                                <input type="text" className="form-control" name="email" value={this.state.email || ''} placeholder="Email" onChange={(e) => this.onchange(e)} />
                            </li>
                            <li className="form-group text-filed">
                                <input type="text" className="form-control" name="phone" value={this.state.phone || ''} placeholder="Phone no" onChange={(e) => this.onchange(e)} />
                            </li>
                        </ul>

                        <ul className="adv-filter-outbox cust">
                            <li className="form-group date">
                                {/* <input type="daterange" name="daterange" className="date-input" /> */}
                                <div className="custom-datepicker">
                                    {/* <DatePicker className="form-control"  name="date" selected={this.state.date} onChange={this.onChangedate} className="date-input"/> */}
                                    <DateRangePicker id="selectDate" autoApply={false} locale={{ format: "DD/MM/YYYY" }} startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} >
                                        <input type="button" name="daterange" value={this.state.startDate.format("MM/DD/YYYY") + " - " + this.state.endDate.format("MM/DD/YYYY")} className="date-input" />
                                    </DateRangePicker>
                                </div>
                            </li>
                            <li className="form-group select-filed">
                                <div className="custom-select">
                                    <select className="btn-dropdown dropdown-toggle btn-dropsent form-control" name="selectedEmailID" value={this.state.selectedEmailID} onChange={(e) => this.onchange(e)} data-toggle="dropdown">
                                        <option value="">Select Email Template</option>
                                        {this.state.emailtemplate.length > 0 ?
                                            this.state.emailtemplate.map((val, key) => {
                                                return (<option value={val._id} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>)
                                            }) : <option value="">No Record Found</option>}
                                    </select>
                                </div>
                            </li>
                            <li className="form-group select-filed">
                                <div className="custom-select">
                                    <div className="dropdown lead-sentdrop custom-select">
                                        <select className="btn-dropdown dropdown-toggle btn-dropsent form-control" name="selectedSMSID" value={this.state.selectedSMSID} onChange={(e) => this.onchange(e)} data-toggle="dropdown">
                                            <option value="">Select SMS Template</option>
                                            {this.state.smstemplate.length > 0 ?
                                                this.state.smstemplate.map((val, key) => {
                                                    return (<option value={val._id} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>)
                                                }) : <option value="">No Record Found</option>}
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li className="form-group select-filed">
                            <div className="form-group select-filed btn-al-r">
                                <a className="btn btn-search btn-style-md" style={{padding:"6px 15px", marginRight:"10px"}} onClick={() => this.search()}>Search</a>
                                <a className="btn btn-search btn-style-md"  style={{padding:"6px 15px"}}  onClick={() => this.clear()}>Reset</a>
                            </div>
                             </li>                       
            
                            {/* <li className="form-group select-filed">
                                <div className="custom-select">
                                    <select name="selectedCSV" className="btn-dropdown dropdown-toggle btn-dropsent form-control" value={this.state.selectedCSV} onChange={(e) => this.onchange(e)}>
                                        {this.state.existingCSV.length === 0 ? <option>No Record Found</option> :
                                            <React.Fragment>
                                                <option value="">Select CSV </option>
                                                {this.state.existingCSV.map((val, key) => {
                                                    return (
                                                        <option value={val._id} key={key} key={key}>{val.name.length <15 ? val.name : val.name.slice(0,15)+" ..."}</option>
                                                    )
                                                })}
                                            </React.Fragment>
                                        }
                                    </select>
                                </div>
                            </li> */}
                            {/* <li className="form-group date">
                                <input type="daterange" name="daterange" className="date-input" />
                                <div className="custom-datepicker">
                                    <DatePicker className="form-control" name="date" selected={this.state.date} onChange={this.onChangedate} className="date-input" placeholder="Select Date"/>
                                </div>
                            </li> */}
                        </ul>
                        {/* <ul className="adv-filter-outbox">
                            
                        </ul> */}
                        {/* <div className="form-group select-filed">
                                <a className="btn btn-search btn-style-md" style={{padding:"6px 15px", marginRight:"10px"}} onClick={() => this.search()}>Search</a>
                                <a className="btn btn-search btn-style-md"  style={{padding:"6px 15px"}}  onClick={() => this.clear()}>Reset</a>
                    </div>*/}
                    </div>

                    {/* <div className="content-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="page-heading">
                                    <h3>Outbox</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12"> */}
                    {/* <div className="add-btn text-right">
                                    <button type="button" className="btn-refresh btn-purple btn-round">
                                        <i className="fa fa-refresh fa-spin"></i>
                                    </button>
                                </div> */}
                    {/* </div>
                        </div>
                    </div> */}
                    {/* <!-- Nav tabs --> */}
                    <div className="tab leads-tabs" role="tabpanel">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className="active">
                                <a href="#smtp01"  aria-controls="home" role="tab" data-toggle="tab" >Twillio</a>
                            </li>
                            <li role="presentation">
                                <a href="#smtp02" aria-controls="profile" role="tab" data-toggle="tab">Twillio Whatsapp</a>

                            </li>
                            <li role="presentation">
                                <a href="#smtp03"  aria-controls="profile" role="tab" data-toggle="tab">SMS Local</a>

                            </li>
                            <li role="presentation">
                                <a href="#smtp04"  aria-controls="profile" role="tab" data-toggle="tab">Mail Gun</a>
                            </li>
                            <li role="presentation">
                            <a href="#smtp05"  aria-controls="profile" role="tab" data-toggle="tab">Mail Chimp</a>
                            </li>
                            <li role="presentation">
                                <a href="#smtp06"  aria-controls="profile" role="tab" data-toggle="tab">Kev SMS</a>
                            </li>
                        </ul>
                        {/* <!-- Tab panes --> */}
                        <div className="tab-content tabs">

                            <div role="tabpanel" className="tab-pane fade in active" id="smtp01">
                                <div className="table-wrap">
                                    <div className="table-responsive" style={{ textAlign: "center" }}>
                                        <InfiniteScroll
                                            dataLength={this.state.twillioService.length}
                                            next={this.fetchMoreDataTwilio}
                                            hasMore={this.state.hasMoreTwilio}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreTwilio}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>SMS Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.twillioService.length > 0 ?
                                                        this.state.twillioService.map((val, key) => {
                                                            if (val.user !== undefined) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.phone}>{val.phone}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.smsTemplateName}>{val.smsTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                        <tr >
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    {this.state.hasMoreTwilio ? "" : "No Record Found"}
                                                                </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade" id="smtp02">
                                <div className="table-wrap">
                                    <div className="table-responsive" style={{ textAlign: "center" }}>
                                        <InfiniteScroll
                                            dataLength={this.state.twillioWhatsAppService.length}
                                            next={this.fetchMoreDataTwilioWhatsapp}
                                            hasMore={this.state.hasMoreTwilioWhatsapp}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreTwilioWhatsapp}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                <th>#</th>
                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>SMS Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.twillioWhatsAppService.length > 0 ?
                                                        this.state.twillioWhatsAppService.map((val, key) => {
                                                            if (val.user !== undefined) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.phone}>{val.phone}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.smsTemplateName}>{val.smsTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                        <tr >
                                                           
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    {this.state.hasMoreTwilioWhatsapp ? "" : "No Record Found"}
                                                                </td>
                                                        </tr>
                                                }
                                            </tbody>

                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade " id="smtp03">
                                <div className="table-wrap">
                                    <div className="table-responsive" style={{ textAlign: "center" }}>
                                        <InfiniteScroll
                                            dataLength={this.state.smslocalService.length}
                                            next={this.fetchMoreDataLocal}
                                            hasMore={this.state.hasMoreTwilioLocal}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreTwilioLocal}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                <th>#</th>
                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>SMS Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.smslocalService.length > 0 ?
                                                        this.state.smslocalService.map((val, key) => {
                                                            if (val.user) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.phone}>{val.phone}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.smsTemplateName}>{val.smsTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                            <tr >
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    {this.state.hasMoreTwilioLocal ? "" : "No Record Found"}
                                                                </td>
                                                        </tr>
                                                       
                                                }
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade " id="smtp04">
                                <div className="table-wrap">
                                    <div className="table-responsive" style={{ textAlign: "center" }}>
                                        <InfiniteScroll
                                            dataLength={this.state.mailgunService.length}
                                            next={this.fetchMoreDataMailgun}
                                            hasMore={this.state.hasMoreTwilioMailgun}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreTwilioMailgun}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                <th>#</th>                                                    
                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>Email Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.mailgunService.length > 0 ?
                                                        this.state.mailgunService.map((val, key) => {
                                                            if (val.user) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.email}>{val.email}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.emailTemplateName}>{val.emailTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                            <tr >
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    {this.state.hasMoreTwilioMailgun ? "" : "No Record Found"}
                                                                </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade " id="smtp05">
                                <div className="table-wrap">
                                    <div className="table-responsive"  style={{ textAlign: "center" }}>
                                    <InfiniteScroll
                                            dataLength={this.state.mailchimpService.length}
                                            next={this.fetchMoreDataMailchimp}
                                            hasMore={this.state.hasMoreTwilioMailchimp}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreTwilioMailchimp}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                <th>#</th>                                                    

                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Email</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>Email Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.mailchimpService.length > 0 ?
                                                        this.state.mailchimpService.map((val, key) => {
                                                            if (val.user) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.email}>{val.email}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.emailTemplateName}>{val.emailTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                        <tr >
                                                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                                                        {this.state.hasMoreTwilioMailchimp ? "" : "No Record Found"}
                                                                    </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane fade " id="smtp06">
                                <div className="table-wrap">
                                    <div className="table-responsive"  style={{ textAlign: "center" }}>
                                    <InfiniteScroll
                                            dataLength={this.state.seven67Service.length}
                                            next={this.fetchMoreDataSeven67}
                                            hasMore={this.state.hasMoreSeven67}
                                            loader={
                                                <ClipLoader
                                                    css={override}
                                                    sizeUnit={"px"}
                                                    size={50}
                                                    color={'#123abc'}
                                                    loading={this.state.hasMoreSeven67}
                                                />}
                                        >
                                        <table className="table table-hover table-striped table-bordered table-condensed">
                                            <thead className="thead-light">
                                                <tr>
                                                <th>#</th>                                                    

                                                    <th>Title</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Phone</th>
                                                    {/* <th>LeadId</th> */}
                                                    <th>Sms Template</th>
                                                    <th>Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.seven67Service.length > 0 ?
                                                        this.state.seven67Service.map((val, key) => {
                                                            if (val.user) {
                                                                return (
                                                                    <tr key={key}>
                                                                        <th title={key+1}>{key+1}</th>
                                                                        <td title={val.title}>{val.title}</td>
                                                                        <td title={val.firstname}>{val.firstname}</td>
                                                                        <td title={val.lastname}>{val.lastname}</td>
                                                                        <td title={val.phone}>{val.phone}</td>
                                                                        {/* <td>{val.leadId}</td> */}
                                                                        <td title={val.smsTemplateName}>{val.smsTemplateName} </td>
                                                                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                        })

                                                        :
                                                        <tr >
                                                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                                                        {this.state.hasMoreTwilioMailchimp ? "" : "No Record Found"}
                                                                    </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OutBox);