import React, { Component } from 'react'
import './editemailtemplate.css';
import Switch from "react-switch";
import CKEditor from "react-ckeditor-component";
import Notifications, { notify } from 'react-notify-toast';
import AllUserService from './../../services/alluser.service';


import $ from 'jquery';
import EmailTemplateService from '../../services/emailtemplate.service';

class EditEmailTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			subject: '',
			description: 'd',
			action: false,
			errors: '',
			status: 'inactive',
			userStatus:''
		}
		this.alluserService = new AllUserService();
		this.emailTemplateService = new EmailTemplateService();
	}

	//lifecycle
	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if(role){
			let roleStatus = role.id;
		this.alluserService.editUser(roleStatus).then(data => {
			if (data.code === 200) {
				let result = data.data;
				this.setState({
					userStatus: result,
				})
				if (this.state.userStatus.status === 'inactive') {
					localStorage.clear();
					this.props.history.push('/');
				}
			}
		}).catch(err => {
			console.log(err)
		})
		}
		//console.log(this.props.location.state.editEmail, this.props.location.state.title, this.props.location.state.editEmail.status, 'this.props.location.state.editEmail.status')
		if (this.props.location.state != undefined) {
			this.setState({
				title: this.props.location.state.editEmail.title,
				subject: this.props.location.state.editEmail.subject,
				description: this.props.location.state.editEmail.description,
				status: this.props.location.state.editEmail.status,
				action: this.props.location.state.editEmail.status,
			})
		} else {
			this.props.history.push('*')
		}


	}

	onchangeEmailActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	// copyFunction() {
	// 	var copyText = document.getElementById("copy-text");
	// 	// copyText.select();
	// 	document.execCommand("copy");
	// 	alert("Copied the text: " + copyText.value);
	//   }

	myFunction(a) {
		var x = document.getElementById("snackbar");
		x.className = "show";
		x.innerHTML = a;
		setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onChange = (evt) => {
		var newContent = evt.editor.getData();
		this.setState({
			description: newContent
		})
	}

	radioChange(e) {
		this.setState({
			action: !this.state.action
		})
	}

	submit(e) {
		const { title, subject, description } = this.state;
		e.preventDefault();

		if (title === '') {
			this.setState({ errors: 'Title is Required' });
			return;
		}

		else if (subject === '') {
			this.setState({ errors: 'Subject is Required' });
			return;
		}
		else if (description === '') {
			this.setState({ errors: 'Description is Required' });
			return;
		}
		else {
			this.setState({
				errors: ''
			})

			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				title: this.state.title,
				description: this.state.description,
				subject: this.state.subject,
				status: this.state.status,
				updatedBy: updater.id,
			}
			this.emailTemplateService.updateEmailTemplate(this.props.location.state.editEmail._id, body).then(data => {
				var current = this;
				if (data.code === 200) {

					setTimeout(() => {
						current.setState({
							title: '',
							subject: '',
							description: '',
							status: 'inactive',
							action: false,
							errors: ''
						}, () => {
							$('#addnewtemplate').hide();

							current.props.history.push('emailtemplate')
						})
					}, 1000);
					notify.show(data.message, "success", 2000);

				}
			}).catch(err => {
				console.log(err)
			})

		}
	}

	cancel(e) {
		e.preventDefault();
		this.props.history.push('/emailtemplate');
	}
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />

				<div className="content-page" id="">
					<div className="content-header">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>Edit Email Template</h3>
								</div>
							</div>
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="add-btn text-right">
									<button type="button" className="btn-style-md btn-purple btn-round mr-5" onClick={(e) => this.submit(e)}>Save Template</button>
									<button type="button" className="btn-purple btn-style-md btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>

								</div>
							</div>
						</div>
					</div>
					{Object.keys(this.state.errors).length > 0 && this.state.errors ?
						<div className="alert alert-danger">
							{this.state.errors}
						</div> : ''}
					<div className="template-editor row">
						<div className="add-template-modal col-lg-9 col-md-10 col-sm-12 col-xs-12">
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Title</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" value={this.state.title} name="title" onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Subject</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" value={this.state.subject} name="subject" onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Dynamic Fields</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<div class="tag-wrap">
										<span class="tag tag-label">[[title]]</span>
										<span class="tag tag-label">[[firstname]]</span>
										<span class="tag tag-label">[[lastname]]</span>
										<span class="tag tag-label">[[email]]</span>
										<span class="tag tag-label">[[phone1]]</span>
										<span class="tag tag-label">[[lookupId]]</span>
										<span className="tag tag-label">[[customerName]]</span>
										<span class="tag tag-label">[[LINK0]]</span>
									</div>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Description</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<CKEditor
										activeClass="p10"
										content={this.state.description}
										events={{
											"blur": this.onBlur,
											"afterPaste": this.afterPaste,
											"change": this.onChange
										}}
									/>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Status</label>
								<div className="col-md-9 col-sm-8 col-xs-12">
									<Switch name="status"
										onChange={(e) => this.onchangeEmailActive(e)} checked={this.state.status == 'active' ? true : false} />
								</div>
							</div>
							{/* <div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Action Button</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<div className="need-buttons">
										<label className="custom-radio">Yes
											<input type="radio" name="action" onChange={(e) => this.radioChange(e)} checked={this.state.action} value={this.state.action} />
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio">No
											<input type="radio" name="action" onChange={(e) => this.radioChange(e)} checked={!this.state.action} value={this.state.action} />
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="btn-fields" id="btnfields">
										<input type="text" className="form-control mb-10"  placeholder="Button Text" />
										<input type="text" className="form-control"  placeholder="Button Link" />
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>





				{/* <!-- Leads listing Modal --> */}
				<div className="modal modal-style fade" id="savemodal" role="dialog">
					<div className="modal-dialog">
						{/* <!-- Modal content--> */}
						<div className="modal-content">
							{/* <!-- <div className="modal-header">
				<button type="button" className="close" data-dismiss="modal">&times;</button>
				<h4 className="modal-title">Save your Template</h4>
			</div> --> */}
							<div className="modal-body text-center save-template">
								<img src="images/done.png" className="img-resposive center-block mb-10" alt="" />
								<h3 className="text-purple mb-5">Successfully!</h3>
								<p>Template has been saved.</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-purple btn-style-md btn-round" data-dismiss="modal">Done</button>
							</div>
						</div>

					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default EditEmailTemplate;