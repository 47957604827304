import React, { Component } from 'react'
import userPhoto from './../../assets/images/user-photo.jpg';
import { withRouter } from 'react-router-dom';
import Switch from "react-switch";
import AllUserService from './../../services/alluser.service';
import LeadsListingService from '../../services/leadslisting.service';
import AllCampaignService from '../../services/campaign.service';
import Modal from 'react-responsive-modal';

import { Link } from 'react-router-dom';
import './profile.css';
import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import 'react-circular-progressbar/dist/styles.css';
import DatePicker from "react-datepicker";
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';
import TimePicker from 'react-time-picker';

class EditCampaigns extends Component {

	// __ __ Initial State __ __ //
	initialStates = {
		name: "",
		description: "",
		campaignType: 'sms',
		emailTemplate: "",
		smsTemplate: "",
		leadsFrom: 0,
		leadsTo: 0,
		csv: "",
		date: "",
		time: "10:00",
		sentPerMinute: 10,
		emailtemplates: [],
		smstemplates: [],
		existingCSV: [],
		sms_sender_id: "",
		unsubscriber: false,
		partial: false,
		all_data: false,
		selectedSMSID: "",
		selectedEmailID: "",
		errors: '',
		path: ENV.url,
		user: '',
		role: '',
		open: false,
	};

	constructor(props) {
		super(props);
		this.state = this.initialStates;

		// __ __ services __ __ //
		this.leadlistingService = new LeadsListingService();
		this.alluserService = new AllUserService();
		this.allcampaignService = new AllCampaignService();

	}
	//life Cycle
	componentDidMount() {
		var compainId = this.props.match.params.id;

		//  console.log(compainId);
		let user = JSON.parse(localStorage.getItem('user'));

		if (user) {

			let roleStatus = user.id;

			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;

					this.setState({
						user: result,
						role: user.role
					})

					this.singleCompain(compainId);

					if (this.state.user.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		this.emailTemplate();
		this.smsTemplate();
		this.getAllCSV();
	}


	singleCompain(compainId) {
		this.allcampaignService.editSingleCompain(compainId).then(data => {
			if (data.code === 200) {
				let campaigndata = data.data[0];
				debugger
				this.setState({
					compainId: campaigndata._id,
					name: campaigndata.name,
					description: campaigndata.description,
					campaignType: campaigndata.campaignType,
					selectedEmailID: campaigndata.emailTemplate,
					selectedSMSID: campaigndata.smsTemplate,
					leadsFrom: campaigndata.leadsFrom,
					leadsTo: campaigndata.leadsTo,
					csv: campaigndata.csv,
					date: campaigndata.date,
					time: campaigndata._time,
					sentPerMinute: campaigndata.sentPerMinute,
					sms_sender_id: campaigndata.sms_sender_id,
					unsubscriber: campaigndata.unsubscriber,
					partial: campaigndata.partial,
					all_data: campaigndata.all_data,

				})

			}
			console.log(this.state.name);
		}).catch(err => {
			console.log(err)
		})
	}



	userProfile() {
		let user = JSON.parse(localStorage.getItem('user'));

		console.log(user);
		if (user) {
			this.profileService.UserProfile(user.id).then(data => {
				console.log("data", data);
				this.setState({
					firstName: data.data.firstName,
					lastName: data.data.lastName,
					email: data.data.email,
					phone: data.data.phone,
					gender: data.data.gender,
					errors: '',
					userProfile: data.data.profileImage,
					externalLink: data.data.externalLink
				});

			}).catch(err => {
				console.log(err);

			})
		} else {
			this.props.history.push('/');
			localStorage.clear();

		}

	}

	getAllCSV() {
		this.leadlistingService.getAllCSV().then(data => {
			console.log(data)
			if (data.code === 404) {
				this.setState({
					existingCSV: []
				});

			}
			if (data.code === 200) {
				this.setState({
					existingCSV: data.data
				}, () => {
					var that = this;
					this.state.existingCSV.forEach(element => {
						if (element._id === that.state.csvID) {
							that.setState({
								linktoCopy: element.link.length > 0 ? element.link[0] : ''
							})
						}
						else if (element._id === that.state.selectedCSV) {
							that.setState({
								linktoCopy: element.link.length > 0 ? element.link[0] : ''
							})
						}
					});
				})
			}

		}).catch(err => {
			console.log(err)
		});
	}

	emailTemplate() {
		this.leadlistingService.allEmailTemplate().then(data => {
			console.log(data, "email");
			if (data.code === 200) {
				this.setState({
					emailtemplates: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	smsTemplate() {
		this.leadlistingService.allSmSTemplate().then(data => {
			console.log(data, "sms");
			if (data.code === 200) {
				this.setState({
					smstemplates: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	onchange(e) {
		console.log(e.target.value);
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	switchHandler = async (name, e) => {

        await this.setState({
            [name]: e
        })

	}
	addCampaign(e) {

		e.preventDefault();
		const { name, description, campaignType, compainId, emailTemplate, smsTemplate, leadsFrom, leadsTo, csv, date, time, sentPerMinute, selectedEmailID, selectedSMSID } = this.state;
		if (name === '') {
			this.setState({ errors: 'Name is required ' });
			return;
		}
		else if (description === '') {
			this.setState({ errors: 'Desctiption is required ' });
			return;
		}
		else if (campaignType === '') {
			this.setState({ errors: 'Please select Compaign Type' });
			return;
		}
		else if (selectedEmailID === "") {
			this.setState({ errors: 'Please select Email Template' });
			return;
		} else if (selectedSMSID === '') {
			this.setState({ errors: 'Please select SMS Template' });
			return;
		}
		else if (csv === "") {
			this.setState({ errors: 'Please select CSV File' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let loginUser = JSON.parse(localStorage.getItem('user'));
			let userId = loginUser.id;

			debugger
			let body = {
				name: this.state.name,
				description: this.state.description,
				campaignType: this.state.campaignType,
				emailTemplate: this.state.selectedEmailID,
				smsTemplate: this.state.selectedSMSID,
				leadsFrom: this.state.leadsFrom,
				leadsTo: this.state.leadsTo,
				csv: this.state.csv,
				date: this.state.date,
				time: this.state.time,
				sentPerMinute: this.state.sentPerMinute,
				user: userId,
				sms_sender_id: this.state.sms_sender_id,
				unsubscriber: this.state.unsubscriber,
				partial:this.state.partial,
				all_data: this.state.all_data,
			}


			var user = JSON.parse(localStorage.getItem('user'));

			this.allcampaignService.editCampaign(body, compainId).then(data => {
				this.setState({
					isloading: true
				});
				if (data.code === 403) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 406) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 201) {

					this.props.history.push('/campaigns');
				}
			}).catch(err => {
				console.log(err)
			})

		}
	}


	selectEmail(e) {

		this.setState({
			selectedEmailID: e.target.value
		});
	}

	selectSMS(e) {
		this.setState({
			selectedSMSID: e.target.value,
		});
	}


	onChangedate = date => {
		this.setState({ date: date })

	}

	onChangetime = time => {
		this.setState({ time: time })
	}

	cancel(e) {
		e.preventDefault();
		this.props.history.push('/dashboard');
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};


	render() {
		console.log(this.state.selectedEmailID);
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div className="content-page Setting-tab tab-structure" id="setting01">
					<div className="setting-fields field-set">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>Edit Campaign</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
								</div>
							</div>
						</div>
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''}
						<div className="row column-reverse">
							<div className="col-md-8 col-xs-12">
								<div className="field-form-width my-profile">
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Campaign Name</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Campaign name" name="name" value={this.state.name || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Campaign Description</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Campaign Description" name="description" value={this.state.description || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Select CSV</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">

											<select name="csv" className="form-control" value={this.state.csv} onChange={(e) => this.onchange(e)}>

												{this.state.existingCSV.length === 0 ?
													<option value="">No Record Found</option>
													:
													<React.Fragment>

														{this.state.existingCSV.map((val, key) => {
															return (
																<option value={val._id} key={key} key={key}>{val.name}</option>
															)
														})}
													</React.Fragment>
												}
											</select>
										</div>
									</div>

									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Campaign Type</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<select type="select" className="form-control" name="campaignType" onChange={(e) => this.onchange(e)} value={this.state.campaignType || ''} >
												<option>sms</option>
												<option>email</option>
												<option>sms/email</option>
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">SMS Template</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<select className="form-control" name="selectedSMSID" value={this.state.selectedSMSID} onChange={(e) =>
												this.selectSMS(e)} data-toggle="dropdown">

												{
													this.state.smstemplates.length > 0 ?
														this.state.smstemplates.map((val, key) => {
															return (
																<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
															)
														}) :
														<option value="">No Record Found</option>
												}

											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Email Template</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">

											<select className="form-control" name="selectedEmailID" value={this.state.selectedEmailID} onChange={(e) =>
												this.selectEmail(e)} data-toggle="dropdown">

												{
													this.state.emailtemplates.length > 0 ?
														this.state.emailtemplates.map((val, key) => {
															return (
																<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
															)
														}) :
														<option value="">No Record Found</option>
												}

											</select>

										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Sms Sender Id </label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="0" name="sms_sender_id" value={this.state.sms_sender_id || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Leads Limit</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="0" name="leadsFrom" value={this.state.leadsFrom || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Leads Skip</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="0" name="leadsTo" value={this.state.leadsTo || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>

									<div className="form-group row">
										<label className="col-md-3">Campaign Date</label>
										<div className="col-md-8">

											<DatePicker className="form-control"
												selected={this.state.date}
												onChange={this.onChangedate}
											/>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-md-3">Campaign Time</label>
										<div className="col-md-8">
											<TimePicker
												onChange={(e) => this.onChangetime(e)}
												value={this.state.time}
											/>
										</div>
									</div>
									<div className="form-group row">
										<h4 className="col-md-3">Exclude data</h4>
									</div>

									<div className="form-group row">
										<div className="form-box">
											<div className="roleSetting"><h3>Unsubscribers</h3></div>
											<div className="custom-switch ml-15 pull-right"><Switch name="unsubscriber" onChange={this.switchHandler.bind(this, 'unsubscriber')} checked={this.state.unsubscriber} /></div>
										</div>
									</div>
									<div className="form-group row">
										<div className="form-box">
											<div className="roleSetting"><h3>Partial</h3></div>
											<div className="custom-switch ml-15 pull-right"><Switch name="partial" onChange={this.switchHandler.bind(this, 'partial')} checked={this.state.partial} /></div>
										</div>
									</div>
									<div className="form-group row">
										<div className="form-box">
											<div className="roleSetting"><h3>All Data</h3></div>
											<div className="custom-switch ml-15 pull-right"><Switch name="all_data" onChange={this.switchHandler.bind(this, 'all_data')} checked={this.state.all_data} /></div>
										</div>
									</div>





									<div className="form-group-btn top-10 text-right">
										<button onClick={(e) => this.addCampaign(e)} className="btn-style-md btn-purple btn-round  mr-10">Save</button>
										<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>

									</div>
									

									

								</div>
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(EditCampaigns)
