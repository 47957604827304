import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import settingImage from './../../assets/images/settings.svg';
import email_template from './../../assets/images/email-template.svg';
import site from './../../assets/images/site.svg';
import dashboard from './../../assets/images/dashboard.svg';
import list from './../../assets/images/list.svg';
import { withRouter } from 'react-router-dom';
import AllUserService from './../../services/alluser.service';
import $ from 'jquery';
$(document).on("click", "#sidebar li", function () {
	$("#sidebar li").removeClass("active");
	$(this).addClass("active");
	if ($(this).parent("ul").prev("li.have-drop").length) {
		$(this).parent("ul").prev("li.have-drop").addClass("active");
	}
});

$(window).scroll(function () {
	if ($(this).scrollTop() > 73) {
		$('.sidenav').addClass('fixed-nav');
	} else {
		$('.sidenav').removeClass('fixed-nav');
	}
});

class Sidebar extends Component {

	constructor(props) {
		super(props);

		this.state = {
			role: '',
			permissions: '',
		}
		this.alluserService = new AllUserService();
	}
	componentDidMount() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
			let id = user.id;
			if (user.role == 'subUser') {
				this.subUserPermission(id);
			} else {
				this.userPermission();
			}
		} else {
			localStorage.clear();
			this.props.history.push('/')
		}

	}

	componentWillMount() {
		if (localStorage.getItem('token') && localStorage.getItem('user')) {
			let user = JSON.parse(localStorage.getItem('user'));
			let id = user.id;
			if (user.role == 'subUser') {
				this.subUserPermission(id);
			} else {
				this.userPermission();
			}
			//this.allUsersAPI();
		} else {
			localStorage.clear();
			this.props.history.push('/');
		}
	}
	subUserPermission = (id) => {
		this.alluserService.userPermission(id).then(data => {
			debugger
			this.setState({
				permissions: data.data
			})
		}).catch(err => { console.log(err); })
	}
	userPermission = () => {
		debugger
		let permissions = {
			smtpSetting: true,
			smtpSetting_Status: 'RW',
			bitlaySetting: true,
			bitlaySetting_Status: "RW",
			smsSetting: true,
			smsSetting_Status: 'RW',
			Template: true,
			Template_Status: 'RW',
			emailTemplate: true,
			emailTemplate_Status: 'RW',
			smsTemplate: true,
			smsTemplate_Status: 'RW',
			Sites: true,
			Sites_Status: 'RW',
			leadsListing: true,
			leadsListing_Status: 'RW',
			csv: true,
			csv_Status: "RW",
			outbox: true,
			outbox_Status: "RW",
			logs: true,
			logs_status: "RW",
			getPackage: true,
			dashboardperm: true,
			formperm: true,
			banksperm: true,
			templateperm: true,
			signatureperm: true,
			paymenhistoryperm: true,
			billingperm: true,
			agentleadsperm: true,
			unsubscribersperm: true,
			userperm: true,
			agentReprotperm: true,
			lead_sms_credit: true,
			email_status: true,
			sms_status: true,
			campaignsperm: true
		}
		this.setState({
			permissions
		})
	}

	showEmail() {
		$('#addnewtemplate').hide();
		$('#emailtemplatetab').show();
	}
	showSMS() {
		$('#smstemplatetab').show();
		$('#addnewtemplate02').hide();
	}
	render() {
		return (
			<nav id="sidebar">
				<ul className="list-unstyled components sidenav">
					{this.state.permissions.dashboardperm ?
						<li className="active list-item">
							<Link to="/dashboard">
								<img src={dashboard} className="img-resposive" alt="" />
								<span>Dashboard</span>
							</Link>
						</li>
						: ''
					}	
					{this.state.permissions.email_status ?
						<li className=" list-item">
							<Link to="/user/emailStatus">
								<img src={dashboard} className="img-resposive" alt="" />
								<span>Email Status</span>
							</Link>
						</li>
						: ''
					}

					{this.state.permissions.sms_status ?
						<li className=" list-item">
							<Link to="/user/smsStatus">
								<img src={dashboard} className="img-resposive" alt="" />
								<span>SmS Status</span>
							</Link>
						</li>
						: ''
					}


					{this.state.permissions.lead_sms_credit ? <li className="list-item">
						<Link to="/lead_count">
							<img src={site} className="img-resposive" alt="" />
							<span>Lead/SMS Credit</span>
						</Link>
					</li> : ''}
					<li className="list-item have-drop" data-toggle="collapse" data-target="#service">
						<a >
							<img src={settingImage} className="img-resposive" alt="" />
							<span>Settings</span>
						</a>
					</li>
					<ul className="sub-menu collapse" id="service">
						<li className="list-item"><Link to="/profile">My Profile</Link></li>
						{this.state.permissions.smtpSetting ? <li className="list-item"><Link to="/smtp">SMTP Settings</Link></li> : ''}
						{this.state.permissions.smsSetting ? <li className="list-item"><Link to="/sms-setting">SMS Settings</Link></li> : ''}
						{this.state.permissions.bitlaySetting ? <li className="list-item"><Link to="/shortener-setting">Bitly Settings</Link></li> : ''}

					</ul>
					{this.state.permissions.Template ? <li className="list-item have-drop" data-toggle="collapse" data-target="#templateul" >
						<a>
							<img src={email_template} className="img-resposive" alt="" />
							<span>Templates</span>
						</a>
					</li> : ''}
					<ul className="sub-menu collapse" id="templateul">
						{this.state.permissions.emailTemplate ? <li className="list-item"><Link to="/emailtemplate" onClick={() => this.showEmail()}>Email Template</Link></li> : ''}
						{this.state.permissions.smsTemplate ?
							<li className="list-item"><Link to="/smstemplate" onClick={() => this.showSMS()} >SMS Template</Link></li> : ''}
					</ul>

					{this.state.permissions.campaignsperm ? <li className="list-item">
						<Link to="/campaigns">
							<img src={site} className="img-resposive" alt="" />
							<span>Campaigns</span>
						</Link>
					</li> : ''}

					
					{this.state.permissions.Sites ? <li className="list-item">
						<Link to="/sites">
							<img src={site} className="img-resposive" alt="" />
							<span>Sites</span>
						</Link>
					</li> : ''}

					{this.state.permissions.leadsListing ? <li className="list-item">
						<Link to="/leadslisting">
							<img src={list} className="img-resposive" alt="" />
							<span>Leads</span>
						</Link>
					</li> : ''}

					{this.state.permissions.leadsListing ? <li className="list-item">
						<Link to="/form">
							<img src={require('./../../assets/images/form.png')} className="img-resposive" alt="" />
							<span>Form</span>
						</Link>
					</li> : ''}


					{this.state.permissions.agentReprotperm ?
						<li className="list-item have-drop" data-toggle="collapse" data-target="#service1">
							<a >
								<img src={settingImage} className="img-resposive" alt="" />
								<span>Agent</span>
							</a>
						</li> : ''}
					<ul className="sub-menu collapse" id="service1">
						<li className="list-item"><Link to="/agentReport/email">Agent Email</Link></li>
						<li className="list-item"><Link to="/agentReport">Agent Reports</Link></li>

					</ul>

					{this.state.permissions.userperm ? <li className="list-item">
						<Link to="/alluser">
							<img src={require('./../../assets/images/users.png')} className="img-resposive" alt="" />
							<span>Users</span>
						</Link>
					</li> : ''}

					{/* {
						(this.state.role == 'admin' || this.state.role == 'user') ? <li className="list-item">
							<Link to="/alluser">
								<img src={require('./../../assets/images/users.png')} className="img-resposive" alt="" />
								<span>Users</span>
							</Link>
						</li> : ''
					} */}


					{this.state.permissions.unsubscribersperm ? <li className="list-item">
						<Link to="/unsubscribers/all">
							<img src={require('./../../assets/images/users.png')} className="img-resposive" alt="" />
							<span>Opt outs</span>
						</Link>
					</li> : ''}

					{/* {
						(this.state.role == 'admin' || this.state.role == 'user') ? <li className="list-item">
							<Link to="/unsubscribers/all">
								<img src={require('./../../assets/images/users.png')} className="img-resposive" alt="" />
								<span>Un subscribers</span>
							</Link>
						</li> : ''
					} */}

					{this.state.permissions.agentleadsperm ? <li className="list-item">
						<Link to="/global-search-leads">
							<img src={require('./../../assets/images/users.png')} className="img-resposive" alt="" />
							<span>AgentLead Search</span>
						</Link>
					</li> : ''}




					{this.state.permissions.csv ? <li className="list-item">
						<Link to="/csvlisting">
							<img src={require('./../../assets/images/csv.png')} className="img-resposive" alt="" />
							<span>CSV Files</span>
						</Link>
					</li> : ''}

					{this.state.permissions.banksperm ?
						<li className="list-item">
							<Link to="/banklisting">
								<img src={require('./../../assets/images/csv.png')} className="img-resposive" alt="" />
								<span>Bank Listing</span>
							</Link>
						</li> : ''}

					{this.state.permissions.templateperm ?
						<li className="list-item">
							<Link to="/templatelisting">
								<img src={require('./../../assets/images/csv.png')} className="img-resposive" alt="" />
								<span>Template Listing</span>
							</Link>
						</li> : ''}

					{this.state.permissions.signatureperm ?
						<li className="list-item">
							<Link to="/stats/signature">
								<img src={require('./../../assets/images/csv.png')} className="img-resposive" alt="" />
								<span>Signature Stats</span>
							</Link>
						</li> : ""}


					{this.state.permissions.outbox ? <li className="list-item">
						<Link to="/outBox">
							<img src={email_template} className="img-resposive" alt="" />
							<span>OutBox</span>
						</Link>
					</li> : ''}

					{this.state.permissions.logs ? <li className="list-item">
						<Link to="/logs">
							<img src={require('./../../assets/images/logs.png')} className="img-resposive" alt="" />
							<span>Logs</span>
						</Link>
					</li> : ''}
					{this.state.role == 'admin' ? <li className="list-item">
						<Link to="/package">
							<img src={require('./../../assets/images/package.png')} className="img-resposive" alt="" />
							<span>Package</span>
						</Link>
					</li> : ''}
					{this.state.role === 'admin' || this.state.role === "user" || this.state.permissions.paymenhistoryperm ? <li className="list-item">
						<Link to="/payment-history">
							<img src={require('./../../assets/images/ph.png')} className="img-resposive" alt="" />
							<span>Payment History</span>
						</Link>
					</li> : ''}

					{this.state.permissions.getPackage && this.state.role !== 'admin' ? <li className="list-item">
						<Link to="/get-package">
							<img src={require('./../../assets/images/pkg.png')} className="img-resposive" alt="" />
							<span>Get Package</span>
						</Link>
					</li> : ''}
					{this.state.role === 'admin' || this.state.role === "user" ? <li className="list-item">
						<Link to="/billing">
							<img src={require('./../../assets/images/billing.png')} className="img-resposive" alt="" />
							<span>Billing</span>
						</Link>
					</li> : ''}
					{this.state.role === 'admin' ? <li className="list-item">
						<Link to="/esignstats">
							<img src={require('./../../assets/images/stats.png')} className="img-resposive" alt="" />
							<span>eSign Stats</span>
						</Link>
					</li> : ''}
					{/* {this.state.role=== 'admin' ? <li className="list-item">
						<Link to="/reports">
							<img src={require('./../../assets/images/stats.png')} className="img-resposive" alt="" />
							<span>Reports</span>
						</Link>
					</li> : ''} */}


				</ul>
			</nav>
		)
	}
}

export default withRouter(Sidebar);