import React, { Component } from 'react'
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import Switch from "react-switch";
import SmSTemplateService from '../../services/smstemplate.service';
import AllUserService from './../../services/alluser.service';
import Notifications, { notify } from 'react-notify-toast';


class EditSmsTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			description: '',
			status: 'inactive',
			errors: '',
			userStatus: '',
		}
		this.addSmsTemplateService = new SmSTemplateService();
		this.alluserService = new AllUserService();
	}
	componentDidMount() {
		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		if (this.props.location.state != undefined) {
			this.setState({
				title: this.props.location.state.editSMS.title,
				description: this.props.location.state.editSMS.description,
				status: this.props.location.state.editSMS.status,
			})
			$('#addnewtemplate02').show();
		} else {
			this.props.history.push('/*')
		}

	}

	onchangeEmailActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}


	onchange(e) {
		if (e.target.key === 'Enter') {
			e.preventDefault();
		}

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	submit(e) {
		e.preventDefault();
		const { title, description } = this.state;


		if (title === '') {
			this.setState({ errors: 'Title is Required' });
			return;
		}
		else if (description === '') {
			this.setState({ errors: 'Description is Required' });
			return;
		}
		else {
			this.setState({ errors: '' });
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				title: this.state.title,
				description: this.state.description,
				status: this.state.status,
				updatedBy: updater.id,
			}
			this.addSmsTemplateService.updateSMS(this.props.location.state.editSMS._id, body).then(data => {
				if (data.code === 200) {
					var current = this;
					setTimeout(() => {
						current.setState({
							title: '',
							description: '',
							status: 'inactive',
							errors: ''
						})
						current.props.history.push('smstemplate')
					}, 1000);

					notify.show(data.message, "success", 2000);


				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	cancel(e) {
		e.preventDefault();
		this.props.history.push('/smstemplate');
	}
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div id="snackbar"></div>
				<div className="content-page" id="addnewtemplate02">
					<div className="content-header">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>Edit SMS Template</h3>
								</div>
							</div>
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="add-btn text-right">
									<button type="button" className="btn-style-md btn-purple btn-round mr-5" onClick={(e) => this.submit(e)}>Save Template</button>
									<button type="button" className="btn-purple btn-style-md btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>

								</div>
							</div>
						</div>
					</div>
					{Object.keys(this.state.errors).length > 0 && this.state.errors ?
						<div className="alert alert-danger">{this.state.errors}</div> : ''}
					<div className="template-editor row">
						<div className="add-template-modal col-lg-9 col-md-10 col-sm-12 col-xs-12">
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Title</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" name="title" value={this.state.title} onChange={(e) => this.onchange(e)} className="form-control" />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Dynamic Fields</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<div class="tag-wrap">
										<span class="tag tag-label">[[title]]</span>
										<span class="tag tag-label">[[firstname]]</span>
										<span class="tag tag-label">[[lastname]]</span>
										<span class="tag tag-label">[[email]]</span>
										<span class="tag tag-label">[[phone1]]</span>
										<span class="tag tag-label">[[lookupId]]</span>
										<span className="tag tag-label">[[customerName]]</span>
										<span class="tag tag-label">[[LINK0]]</span>
									</div>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Description</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<textarea className="form-control" name="description" value={this.state.description} onChange={(e) => this.onchange(e)} rows="6"></textarea>
								</div>
							</div>
							<div className="form-group row">
								<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Status</label>
								<div className="col-md-9 col-sm-8 col-xs-12">
									<Switch name="status"
										onChange={(e) => this.onchangeEmailActive(e)} checked={this.state.status == 'active' ? true : false} />
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Leads listing Modal --> */}
				<div className="modal modal-style fade" id="savemodal" role="dialog">
					<div className="modal-dialog">
						{/* <!-- Modal content--> */}
						<div className="modal-content">
							{/* <!-- <div className="modal-header">
				<button type="button" className="close" data-dismiss="modal">&times;</button>
				<h4 className="modal-title">Save your Template</h4>
			</div> --> */}
							<div className="modal-body text-center save-template">
								<img src="images/done.png" className="img-resposive center-block mb-10" alt="" />
								<h3 className="text-purple mb-5">Successfully!</h3>
								<p>Template has been  saved.</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-purple btn-style-md btn-round" data-dismiss="modal">Done</button>
							</div>
						</div>

					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(EditSmsTemplate);