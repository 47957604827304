import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import AllUserService from './../../services/alluser.service';
import './payment.css';
import { ENV } from './../../env';

import PaymentService from './../../services/payment.service';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import StripeCheckout from 'react-stripe-checkout';


import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;



// __  Custom Styling __ //
// _____________________ //

const paybutton = {
    padding: '0px',
    border: 'none',
    margin: '5px 0px 0px 0px'
}


// __ Payment Component __//
// ______________________ //

class Payment extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			price: '',
			isloading :false,
			noOfPDF: '',
			description : [],
			pkgID : '',
			errors: '',
			path: ENV.url,
			userStatus: '',
			useremail : "",
			isloadingStripe:false,
			pkgStats:{
                totalPdfs : 0,
                usedPdfs:0,
				remainingPdfs: 0,
				price:0
            }
		
		}
		this.paymentService = new PaymentService();
		this.alluserService = new AllUserService();
	}

    // ___ Lifecycle hooks ___//
    // ______________________ //
    
	componentDidMount() {

		if (this.props.location.state != undefined) {
			this.setState({
				price: this.props.location.state.price,
				noOfPDF: this.props.location.state.signedPdf,
				name : this.props.location.state.packageName,
				pkgID : this.props.location.state.id
			})
		} else {
			this.props.history.push('/*');
		}

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.setState({
				useremail : role.email
			})
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		this.getPackageStats();
	}

	getPackageStats() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.paymentService.packageStats(user.id).then(data => {
            if (data.code === 200) {
                this.setState({
                    pkgStats: data.data
                },() =>{
					var eachPdfPrice = 0;
					if(this.state.pkgStats){
						let totalPdfs = this.state.pkgStats.totalPdfs;
						let packagePrice;
						if(typeof this.state.pkgStats.package !== 'undefined' && this.state.pkgStats.package.length >0)
						{
							packagePrice = this.state.pkgStats.price;
							
							eachPdfPrice = packagePrice/totalPdfs;
						}	
						else
						{
							packagePrice = 0;
							eachPdfPrice = 0;
						}
					}
					document.getElementById('totalPdfs1').innerHTML = this.state.pkgStats.totalPdfs;
					document.getElementById('usedPdfs2').innerHTML = this.state.pkgStats.usedPdfs
					document.getElementById('remainingPdfs3').innerHTML = this.state.pkgStats.remainingPdfs
					document.getElementById('statsnumer4').innerHTML = '$'+ parseFloat(eachPdfPrice * this.state.pkgStats.remainingPdfs, 2).toFixed( 2 )
				})
            }
        }).catch(err => {
            console.log(err);
        })
    }


	emptyError() {
		this.setState({
			errors: ''
		})
	}

	onchange(e){
		this.setState({
			[e.target.name] : e.target.value
		})
	}

	makePayment(e){
		let body = {
			packageId: this.state.pkgID,
		}

		this.setState({
			isloading:true
		})
		this.paymentService.makePayment(body).then(data=>{
			if(data.code === 200){
				let that = this;
				
				window.open(data.data.url,"_self")

				setTimeout(() => {
					that.setState({
						isloading:false
					})
				}, 3000);
			}
		})

	}

	onToken = (token) => {
		let user = JSON.parse(localStorage.getItem('user'));

		console.log(token, "toekne")
		let body = {
			stripeToken: token.id,
			stripeEmail: token.email,
			amount: this.state.price,
			userId: user.id,
			packageId: this.state.pkgID
		}

		this.setState({
			isloadingStripe: true
		})
		this.paymentService.createStripe(body).then(data => {
			if (data.code === 200) {
				this.setState({
					isloadingStripe: false
				})
				this.getPackageStats();
				notify.show(data.message, "success", 3000)
			}

			console.log(data, "data")
		})

	}


	render() {
		return (
			<React.Fragment>
				{this.state.isloadingStripe === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				
				<div className="setting-fields field-set">
					<div className="content-header">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>Payment Methods</h3>
								</div>
							</div>

							<div className="col-md-6 col-sm-6 col-xs-12">
								{/* <div className="add-btn text-right">
										<button type="button"  className="btn-refresh btn-purple btn-round">
												<i class="fa fa-refresh fa-spin"></i>
										</button>
								</div> */}
							</div>
						</div>
					</div>
					<div className="tab leads-tabs" role="tabpanel">
						<ul className="nav nav-tabs" role="tablist">
							<li role="presentation" className="active">
								<a href="#smtp01" aria-controls="home" onClick={(e)=>this.emptyError(e)} role="tab" data-toggle="tab" >PayPal</a>
							</li>
							<li role="presentation">
								<a href="#smtp02" aria-controls="home" onClick={(e)=>this.emptyError(e)} role="tab" data-toggle="tab">Stripe</a>

							</li>
						</ul>
						<div className="tab-content tabs">
							<div role="tabpanel" className="tab-pane fade in active " id="smtp01">
								<div className="setting-fields field-set">
									<div className="content-header">
										<div className="row">
											<div className="col-md-3 col-sm-3 col-xs-12">
											</div>
											<div className="col-md-6 col-sm-6 col-xs-12">
												<div className="page-heading">
													<h3>Upgrade your Package</h3>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-xs-12">
											</div>
										</div>
									</div>
									{Object.keys(this.state.errors).length > 0 && this.state.errors ?
										<div className="alert alert-danger">
											{this.state.errors}
										</div> : ''}
									<div className="row column-reverse">
										<div className="col-md-3"></div>
										<div className="col-md-6 col-xs-12">
											<div className="field-form-width my-profile">
												<div className="form-group">
													<label className=" col-form-label">Package Name</label>
													<div className="form-control">{this.state.name}</div>
												</div>
												<div className="form-group">
													<label className="col-form-label">Package Price</label>
													<div className="form-control"  >${this.state.price}</div>
												</div>
												<div className="form-group">
													<label className="col-form-label">No of PDFs</label>
													<div className="form-control" >{this.state.noOfPDF}</div>
												</div>
												<div className="form-group-btn top-10 text-right" style={{ textAlign: "center" }}>
													{this.state.isloading ?
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> :
														<button type="button" style={paybutton} onClick={(e) => this.makePayment(e)} ><img src={require('./../../assets/images/checkout.png')} alt="Check out with PayPal" /></button>
													}
												</div>
											</div>
										</div>
										<div className="col-md-3"></div>
									</div>
								</div>
							</div>

							<div role="tabpanel" className="tab-pane fade " id="smtp02">
						
							<div className="setting-fields field-set">
									<div className="content-header">
										<div className="row">
											<div className="col-md-3 col-sm-3 col-xs-12">
											</div>
											<div className="col-md-6 col-sm-6 col-xs-12">
												<div className="page-heading">
													<h3>Upgrade your Package</h3>
												</div>
											</div>
											<div className="col-md-3 col-sm-3 col-xs-12">
											</div>
										</div>
									</div>
									{Object.keys(this.state.errors).length > 0 && this.state.errors ?
										<div className="alert alert-danger">
											{this.state.errors}
										</div> : ''}
									<div className="row column-reverse">
										<div className="col-md-3"></div>
										<div className="col-md-6 col-xs-12">
												<div className="field-form-width my-profile">
														<div className="form-group">
															<label className=" col-form-label">Package Name</label>
															<div className="form-control">{this.state.name}</div>
														</div>

														<div className="form-group">
															<label className="col-form-label">Package Price</label>
															<div className="form-control"  >${this.state.price}</div>
														</div>

														<div className="form-group">
															<label className="col-form-label">No of PDFs</label>
															<div className="form-control" >{this.state.noOfPDF}</div>
														</div>

													<StripeCheckout
													email = {this.state.useremail}
													token={this.onToken}
													panelLabel={`Pay $${this.state.price}`}
													stripeKey="pk_test_EaPE80MRSifxUvclUfze3GTq00RT6fTNuu"
													/>
												</div>
										</div>
										<div className="col-md-3"></div>
									</div>
								</div>
							
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(Payment)
