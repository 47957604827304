import React, { Component } from 'react'
import {ENV} from '../env';

class BitlayService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }



    async create(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'templateId/create', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    
    
     
    async EmailTemplateCreUpd(body) {
        debugger
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'Bank/EmailTemplate/Cre/Upd', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }





    async BankRefCount(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'updateBanks/Data', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateBitlay(accountId,body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'bilty/'+accountId, {
            method: 'PUT',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    

     async get() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'templateId/templates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }



    async getBankCount() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'getBanks/user/Count', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    // /get/signle/Bank/:userId

    async getSingleBank(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'get/signle/Bank/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async delete(id){
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'templateId/templates/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }



    async getBankEmail() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'getBanks/user/EmailTemplate', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
     
    // getBank


    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default BitlayService;