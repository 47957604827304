import React, { Component } from 'react';
import moment, { relativeTimeThreshold } from 'moment';
import { Link } from 'react-router-dom';
import email_temp from './../../assets/images/email.svg';
import AllUserService from '../../services/alluser.service';
import ProfileService from '../../services/profile.service';
import PackageService from '../../services/package.service';
import { Stripe } from '../stripe/stripe';
import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedLeads: "",
      errors: '',
      roleIn: '',
      dashboard: [],
      loginLogs: [],
      paymentList: [],
      EData: [],
      SData: [],
      permission: '',
      pandaLoader: true,
      psites: true,
      psms: true,
      pemail: true,
      userStatus: '',

      leadCount: 0,
      leadPrice: 0,
      outBoxPrice: 0,
      smsCount: 0,
      userCredit: 0,

      leadCount_price: 0,
      smsCount_price: 0,

      smsCheck: false,
      smsCharge: 0,
      leadCheck: false,
      leadCharge: 0,
      par_charge: 0,
      balance_less_then: 0,

      isloading: false,
    }
    this.alluserService = new AllUserService();
    this.profileService = new ProfileService();
  }
  URL() {
    return ENV.url;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let role = JSON.parse(localStorage.getItem('user'));
    debugger
    if (role) {
      let roleStatus = role.id;
      this.alluserService.editUser(roleStatus).then(data => {
        if (data.code === 200) {
          this.userProfile();
          this.userPayment();
          this.userPaidCredit();
          this.getPageInnerPermision(role);
          let result = data.data;
          this.setState({
            userStatus: result,
          })
          if (this.state.userStatus.status === 'inactive') {
            localStorage.clear();
            this.props.history.push('/');
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
    debugger
    if (role) {
      debugger
      this.alluserService.LeadSmsCredit().then(data => {
        debugger
        if (data.code === 200) {
          let result = data;
          debugger
          this.setState({
            leadCount: result.leadCount,
            leadPrice: result.leadPrice,
            outBoxPrice: result.outBoxPrice,
            smsCount: result.smsCount,
          })

        }
      }).catch(err => {
        console.log(err)
      })
    }

  }

  
  userPaidCredit() {
    this.setState({

      isloading: true
    })
    this.alluserService.LeadSmsCredit_Paid().then(data => {
      if (data.code === 200) {
        let result = data;
        this.setState({
          leadCount_price: result.leadCount,
          smsCount_price: result.smsCount,
          
          isloading: false
        })

      }
    }).catch(err => {
      console.log(err)
    })
  }


  userProfile() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      
      let userId = user.id;
      if(user.role == "subUser"){
        userId = user.parentId;
      }
      this.profileService.UserProfile(userId).then(data => {
        
        console.log("data", data);
            data = data.data;
         this.setState({
          userCredit : data.userCredit
         })
      }).catch(err => {
        console.log(err);
      })
    } else {
      this.props.history.push('/');
      localStorage.clear();
        }

  }

  userPayment() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      
      this.profileService.userPayment(user.id).then(data => {
        
        console.log("data", data);
        
        this.setState({
          paymentList: data.data,
         
        });

      }).catch(err => {
        console.log(err);

      })
    } else {
      this.props.history.push('/');
      localStorage.clear();

    }

  }


  getPageInnerPermision(data) {
    
    let role = data.role;
    let userId = data.id;
    if (role == "admin") {
      userId = data.id;
    } else if (role == "user") {
      userId = data.id;
    } else if (role == "subUser") {
      userId = data.parentId;
    }
    let dataArray = [];
    this.alluserService.getInnerPermission(userId).then(data => {
      
      let paymentInfo = data.data.paymentInfo[0];
      if(paymentInfo.check){
        this.setState({
          smsCheck: paymentInfo.smsCheck,
          smsCharge: paymentInfo.smsCharge,
          leadCheck: paymentInfo.leadCheck,
          leadCharge: paymentInfo.leadCharge,
          par_charge: paymentInfo.par_charge,
          balance_less_then: paymentInfo.balance_less_then,
          isloading: false
        })
      }
     
    }).catch(err => {
      console.log(err)
    })

  }


  render() {
    return (
      <React.Fragment>
        	{/* <Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""} */}

        <div className="content-page">
          <div className="dashboard-buttons">
            <React.Fragment>
            {this.state.smsCheck ? 
              <div className="dashboard-btn-box1">
                <div className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn email-bg" >
                  <div className="ribbon1"><span>£ {this.state.outBoxPrice.toFixed(2)}</span></div>
                  <div className="box-icons"><img src={email_temp} className="img-resposive" alt="" /></div>
                  <h4>Total SMS Send  {this.state.smsCount}</h4>
                  <h4>Total SMS Paid  {this.state.smsCount_price}</h4>
                </div>
              </div>
             : "" }
             {this.state.leadCheck ? 
              <div className="dashboard-btn-box1">
                <Link className="btn btn-dashleads btn-lg col-md-12 dashboard-nav-btn lead-bg" to="/lead_count" >
                  <div className="ribbon1"><span>£ {this.state.leadPrice}</span></div>
                  <div className="box-icons"><img src={require('../../assets/images/signed.png')} className="img-resposive" alt="" /></div>
                  <h4>Total Signed Leads {this.state.leadCount}</h4>
                  <h4>Total Lead Paid {this.state.leadCount_price}</h4>
                </Link>
              </div>
                : "" }
            </React.Fragment>
          </div>


          <div>
            <h4> Total Account Credit : £  {this.state.userCredit.toFixed(2)} </h4> <br/>
            
          </div>
          <div>
      
          {this.state.smsCheck ?  <h4> Per SMS Charge : £   {this.state.smsCharge} </h4> :  ""} <br/>
            {this.state.leadCheck ?   <h4> Per Lead Charge : £    {this.state.leadCharge}</h4> : ""} <br/>
          </div>

          <div >
            <h4> Total Amount Pay : £  {(this.state.par_charge * this.state.paymentList.length).toFixed(2)} </h4>
          </div>

          <div className="dashboard-buttons card_detail_form">

            <Stripe></Stripe>
          </div>


          <div className="setting-fields field-set"  >

            <div className="row mb-20">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              </div>
            </div> 
            <div class="table-wrap">
              <div class="table-responsive">
                <table class="table table-hover table-striped table-bordered table-condensed">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Credit Top Up</th>
                      <th>price</th>
                      <th>Charge Id</th>
                      <th>Created Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.paymentList.length > 0 ?

                        this.state.paymentList.map((val, key) => {
                          return (
                            <tr key={key}>
                              <th title={key + 1}>{key + 1}</th>
                              <td title={val.creditTopUp}>{val.creditTopUp}</td>
                              <td title={val.price}>{val.price}</td>
                              <td title={val.chargeId}>{val.chargeId} </td>
                              <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
                            </tr>
                          )
                        })
                        : 
                        <tr >
													<td colSpan="8" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : " No payment Done"}

													</td>
												</tr>
                    }
                         

                  </tbody>

                </table>
              </div>
            </div>
          </div>


        </div>
      </React.Fragment>
    )
  }
}
