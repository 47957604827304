import React, { Component } from 'react';
import AllUserService from '../../services/alluser.service';
import UnsubscriberService from '../../services/unsubscribers.service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import LeadsListingService from '../../services/leadslisting.service';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ENV } from '../../env';
import moment from 'moment';
import './unscubscribe.css';
import $ from 'jquery';
import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Unsubscribers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			existingCSV: [],
			allUsers: [],
			selectedMailgunUserID: '',
			unsubscribersListing: [],
			unsubscribersListingDuplicate: [],
			path: ENV.url,
			permission: '',
			editData: '',
			pandaLoader: true,
			perPage: 0,
			startDate: moment().startOf('month'),
			endDate: moment().endOf('month'),
			link: [],
			status: 'inactive',
			isloading: false,
			isUnscubscribe: true,
			filter_fname: "",
			filter_lname: "",
			filter_email: "",
			filter_phone: "",
			zip: '',
			createUserModel: false,
			id: '',
			multiSelect: [],
			searchDropdown: "",
			agentId:"",
			startDate: moment().startOf('month'),
			endDate: moment().endOf('month')
		}

		this.alluserService = new AllUserService();
		this.leadlistingService = new LeadsListingService();
		this.UnsubscriberService = new UnsubscriberService();
	}

	reset() {
		this.setState({
			filter_email: '',
			filter_phone: '',
			filter_fname: '',
			filter_lname: '',
			zip: '',
			multiSelect: [],
			searchDropdown: ""
		})
	}

	handleEvent = (e, picker) => {
		this.setState({
			startDate: picker.startDate,
			endDate: picker.endDate
		})
	}

	allUserCSV() {
		this.leadlistingService.allCSV().then(data => {
			if (data.code === 200) {
				let arr = [];
				for (var i = 0; i < data.data.length; i++) {
					arr.push({
						label: data.data[i].name,
						//   value: false,
						value: data.data[i]._id,
					});
				}
				this.setState({
					existingCSV: arr,
				});
			}
			if (data.code === 404) {
				this.setState({
					existingCSV: [],
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));

		if (this.props.match.params.id) {
			debugger
			this.setState({
				agentId: this.props.match.params.id,
			}, ()=> {
				this.getAllUnsubscribers();
			})
		}

		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		if (localStorage.getItem('token')) {

			
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}
		this.allUserCSV();
	}

    getAllUnsubscribers = () => {
		debugger
		var th = this;
		var arrayLength = this.state.unsubscribersListing.length;
		let body = {
			filter_email: this.state.filter_email,
			filter_phone: this.state.filter_phone,
			filter_fname: this.state.filter_fname,
			filter_lname: this.state.filter_lname,
			zip: this.state.zip,
			csvArray: this.state.multiSelect,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			perPage: this.state.perPage + 50,
			agentId: this.state.agentId
		}


		th.setState({
			perPage: th.state.perPage + 50,
			// isloading: true,
			isUnscubscribe: true
		})
		th.UnsubscriberService.all(body).then(data => {
			debugger
			if (data.code === 200) {
				if(data.data.length > arrayLength){
					th.setState({
						isloading: false,
						unsubscribersListing: data.data,
						unsubscribersListingDuplicate: data.data,
						isUnscubscribe: true
					})
				}else{
					th.setState({
						isloading: false,
						isUnscubscribe: false
					})
				}
				
			}
		}).catch(err => {
			console.log(err)
			th.setState({
				isloading: false
			})
		})


	}

	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}

	logout() {
		//console.log("helo")
		localStorage.clear();
		this.props.history.push('/');
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.csv === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.unsubscribersListingDuplicate;

			let data = myArray.filter(x => {
				return x.name.replace(/\s+/g, '').toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				unsubscribersListing: data
			})
		})
	}

	delete(id, index) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this Un subscriber?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.deleteUnsubscriber(id, index); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	deleteUnsubscriber(id, index) {
		this.UnsubscriberService.delete(id).then(data => {
			if (data.code === 200) {
				var tempArray = this.state.unsubscribersListing;
				tempArray.splice((index), 1);
				this.setState(
					{
						unsubscribersListing: tempArray
					})
				notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			console.log(err)
		})
	}


	selectedOption(array) {
		console.log(array)
		let ids = [];
		if (array) {
			array.forEach(element => {
				ids.push(element.value)
			});
		}

		this.setState({
			multiSelect: ids
		})
	}

	render() {
		console.log(this.state);
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""}



				<div className="content-header">
					<div className="row">
						<div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
								<h3>Un subscribers</h3>
							</div>
						</div>
						<div className="col-md-9 col-sm-9 col-xs-12">
							<div className="head-right-wrap">
							</div>
						</div>
					</div>
				</div>

				<div className="accordian-expand">
					{/* <div className="row">
						<div className="col-xs-12 col-sm-12">
							<div className="listing-filter-search gobal-search">

								<div className="form-group text-filed">
									<input type="text" className="form-control" placeholder="First Name" name="filter_fname" value={this.state.filter_fname} onChange={(e) => this.onchange(e)} />
								</div>
								<div className="form-group text-filed">
									<input type="text" className="form-control" placeholder="Last Name" name="filter_lname" value={this.state.filter_lname} onChange={(e) => this.onchange(e)} />
								</div>
								<div className="form-group text-filed">
									<input type="text" className="form-control" placeholder="Email" name="filter_email" value={this.state.filter_email} onChange={(e) => this.onchange(e)} />
								</div>
								<div className="form-group text-filed">
									<input type="text" className="form-control" placeholder="Phone" name="filter_phone" value={this.state.filter_phone} onChange={(e) => this.onchange(e)} />
								</div>
								<div className="custom-datepicker">
							
									<DateRangePicker id="selectDate" autoApply={false} locale={{ format: "DD/MM/YYYY" }} startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} >
										<input type="button" name="daterange" value={this.state.startDate.format("MM/DD/YYYY") + " - " + this.state.endDate.format("MM/DD/YYYY")} className="date-input" />
									</DateRangePicker>
								</div>
								<div className="form-group btn-filed">
									<button type="button" className="btn-search btn-style-md mr-10" onClick={(e) => this.getAllUnsubscribers(e)}>Filter</button>
									<button type="button" className="btn-search btn-style-md" onClick={(e) => this.reset(e)}>Reset</button>
								</div>
							</div>
							<div className="gobal-search ">
								<div className="form-group multi-select-input">
									<Select
										isMulti
										name="colors"
										options={this.state.existingCSV}
										className="basic-multi-select"
										classNamePrefix="select"
										closeMenuOnSelect={true}
										onChange={value => this.selectedOption(value)}
										onInputChange={this.handleInputChange}
										inputValue={this.state.searchDropdown}
									/>
								</div>
							</div>
						</div>
					</div> */}
				</div>

				{this.state.role === 'admin' ?
					<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >

						<div className="row mb-20">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Email</th>
											<th>Phone</th>
											<th>Created Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.unsubscribersListing.length > 0 ?
												this.state.unsubscribersListing.map((val, key) => {
													if (val.user) {
														return (
															<tr key={key}>
																<th title={key + 1}>{key + 1}</th>
																<td title={val.firstName}>{val.firstName}</td>
																<td title={val.lastName}>{val.lastName}</td>
																<td title={val.email}>{val.email} </td>
																<td title={val.phone}>{val.phone} </td>
																<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
																<td width="230">
																	<div className="table-option-btns">
																		<a type="button" onClick={() => this.delete(val._id, key)} className="btn btn-table mr-5" download  >
																			<i className="fa fa-trash" ></i> Delete
																        </a>
																	</div>
																</td>
															</tr>
														)
													}
												})
												: <tr >
													<td colSpan="8" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}

													</td>
												</tr>
										}


									</tbody>
								</table>
							</div>
						</div>
					</div> : ''
				}


				{this.state.role === 'user' || this.state.role === 'subUser' ?
					<div className="setting-fields field-set" style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? 'block' : 'none' }} >

						<div class="table-wrap">
							<div class="table-responsive">
								<InfiniteScroll
									dataLength={this.state.unsubscribersListing.length}
									next={ this.getAllUnsubscribers}
									hasMore={this.state.isUnscubscribe}
									loader={
										<ClipLoader
											css={override}
											sizeUnit={"px"}
											size={50}
											color={'#123abc'}
											loading={this.state.isUnscubscribe}
										/>}
								>
									<table class="table table-hover table-striped table-bordered table-condensed">
										<thead class="thead-light">
											<tr>
												<th>#</th>
												<th>First Name</th>
												<th>Last Name</th>
												<th>Email</th>
												<th>Phone</th>
												<th>Created Date</th>
												{/* <th>Action</th> */}
											</tr>
										</thead>
										<tbody>
											{
												this.state.unsubscribersListing.length > 0 ?
													this.state.unsubscribersListing.map((val, key) => {
														return (
															<tr key={key}>
																<th title={key + 1}>{key + 1}</th>
																<td title={val.firstName}>{val.firstName}</td>
																<td title={val.lastName}>{val.lastName}</td>
																<td title={val.email}>{val.email} </td>
																<td title={val.phone}>{val.phone} </td>
																<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
																{/* <td width="230">
																	<div className="table-option-btns">
																		<a type="button" onClick={() => this.delete(val._id, key)} className="btn btn-table mr-5" download  >
																			<i className="fa fa-trash" ></i> Delete
                                                                    </a>
																	</div>
																</td> */}
															</tr>
														)

													})
													: <tr >
													<td colSpan="7" style={{ textAlign: "center" }}>
															{this.state.isloading ? <ClipLoader
																css={override}
																sizeUnit={"px"}
																size={50}
																color={'#123abc'}
																loading={this.state.isloading}
															/> : "No Record Found"}
														</td>
													</tr>
											}
										</tbody>
									</table>
								</InfiniteScroll>
							</div>
						</div>


					</div> : ''
				}


			</React.Fragment >
		);
	}
}

export default Unsubscribers;