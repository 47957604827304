import React, { Component } from 'react'
import { ENV } from './../env';


// let token = JSON.parse(localStorage.getItem("token"));

// axios.defaults.headers.common = {'Authorization': token}


class LeadsListingService extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    URL() {
        return ENV.url;
    }


    async getAllCSV() {
        let token = JSON.parse(localStorage.getItem("token"));
        if (!token) {
            return;
        } else {
            const url = ENV.url;
            const data_1 = await fetch(ENV.url + 'allActiveCsv', {
                method: 'GET',

                headers: {
                    'Authorization': token.tokenType + ' ' + token.accessToken,
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json());
            return data_1;
        }

    }
    async unSubscribe(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'unsubscribe/agentLead', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async agentPaperPack(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/agentPaperPack', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async saveNotes(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/agentNotes', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    
    async uploadCSV(body) {
let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'uploadCsv', {
            method: 'POST',
            body: body,
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                // 'Content-Type': 'application/octet-stream'
            }
        }).then(res => res.json());
        return data_1;
    }

    async  getSingleCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'csv/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,


            }
        }).then(res => res.json());
        return data_1;
    }

    async importCSV(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'importCsv', {
            method: 'POST',
            body: body,

            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'content-type': 'multipart/form-data'



            }
        }).then(res => res.json());
        return data_1;
    }

    async downloadAllSignedPdf(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'download/signed-pdfs', {
            method: 'POST',
            body: JSON.stringify(body),

            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => res.json());
        return data_1;
    }

    async downloadAllUnsignedPdf(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'download/unsigned-pdfs', {
            method: 'POST',
            body: JSON.stringify(body),

            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => res.json());
        return data_1;
    }


    async removeZipFile(body) {
        debugger;
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'remove/zipfile', {
            method: 'POST',
            body: JSON.stringify(body),

            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => { console.log(res) });
        return data_1;
    }

    async getAllSite() {
        let token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'get-active-sites', {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    async getActivePdfTemplates() {
        let token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'templateIds/active', {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    


    async search(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'leads/listing', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    async allSmSTemplate() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'smsActiveTemplate/templates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async allSmSTemplateOutbox() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/smsTemplates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async allEmailTemplate() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'emailActiveTemplate/templates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async allEmailTemplateOutbox() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/emailTemplates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async sendEmail(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'mailgun/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async sendSMS() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'emailTemplate/templates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async postCSVid(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'csvHeaders', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async createLead(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'creteLead', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async getCsvCollection(id, from, to) {
let token = JSON.parse(localStorage.getItem("token"));
     
        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'getCsvCollection/'+id + '/' + from +'/'+to, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    // async sendEmail(body) {
    //     let token = JSON.parse(localStorage.getItem("token"));

    //     // console.log(" ULR =>", this.URL())

    //     const data_1 = await fetch(ENV.url + 'mailgun/send', {
    //         method: 'POST',
    //         body: JSON.stringify(body),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': token.tokenType + ' ' + token.accessToken,

    //         }
    //     }).then(res => res.json());
    //     return data_1;
    // }

    async sendEmailcsv(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'emailFromCsv/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async GlobalSendCSV(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'mail/GlobalSendCSV', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async currentLeadStatus(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'api/currentLeatStatus', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async UpdatePhone(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'updatePhone/lead', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async reset(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        const data_1 = await fetch(ENV.url + 'reset/lead', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }




    async GlobalSendSmsCSV(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'sms/GlobalSendCSV', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async PhpDashboardLead(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'api/phpDashBoard/call', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    
    async sendSMS(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'sms/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async generateUnSignedDocuments(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'generate/unsigned/documents', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async sendSMScsv(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'smsFromCsv/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async useExisting(id, from, to) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'getCsvCollection/'+id+'/'+from+'/'+to, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async getSingUserCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'getUsersCsv/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async allCSV() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'getAllCsv', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async allSignedCSV() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'all-csv/stats', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async deleteCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'csv/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async updateCSV(id, body) {
        let token = JSON.parse(localStorage.getItem("token"));

        const data_1 = await fetch(ENV.url + 'csv/update/'+id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    

    async downloadCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'exportCsv/'+id, {
            method: 'GET',
            body: JSON.stringify(this.state.leadwithemail),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async downloadUnsignedCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'exportUnsignedCsv/'+id, {
            method: 'GET',
            // body: JSON.stringify(this.state.leadwithemail),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async downloadSiteCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'exportSiteCsv/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async getSMStype() {

        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'smsSetting', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => 
            res.json());
        return data_1;
    }

    async openLinks(body) {

        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'openlinks', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => res.json())
        return data_1;
    }

    async getEmailtype() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'emailSetting', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async downloadAllPdfs() {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'download-all-pdfs', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async filtersearchcsv(id,body) {
        console.log(body, "body")
        let url  = ENV.url + `csvFilter/${id}?`
        if(body.filter_email !== ''){
            url= url + "email="+body.filter_email+"&"
        }
        if(body.filter_fname !== ''){
            url= url + "firstname="+body.filter_fname+"&"
        }
        if(body.filter_lname !== ''){
            url= url + "lastname="+body.filter_lname+"&"
        }
        if(body.filter_phone !== ''){
            url= url + "phone1="+body.filter_phone+"&"
        }
        if(body.zip !== ''){
            url= url + "zip="+body.zip
        }
  
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async filtersearchSite(id,body) {
        console.log(body, "body")
        let url  = ENV.url + `searchLeads/${id}?`
        if(body.filter_email !== ''){
            url= url + "email="+body.filter_email+"&"
        }
        if(body.filter_fname !== ''){
            url= url + "firstname="+body.filter_fname+"&"
        }
        if(body.filter_lname !== ''){
            url= url + "lastname="+body.filter_lname+"&"
        }
        if(body.filter_phone !== ''){
            url= url + "phone1="+body.filter_phone+"&"
        }
        if(body.zip !== ''){
            url= url + "zip="+body.zip
        }
  
        let token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    async deleteLead(csvid, leadid) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'csvCollection/'+csvid+'/'+leadid, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }






    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default LeadsListingService;