import React, { Component } from 'react';
import {ENV} from './../env';

// let token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class SchedularService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    async addEvent(body){
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'schedule', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default SchedularService;