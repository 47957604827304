import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import BitlayService from './../../services/bitlay.service';
import AllUserService from './../../services/alluser.service';

import $ from 'jquery';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import Switch from "react-switch";
import { debug } from 'util';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
// import responsiveTabs from './../../assets/js/responsiveTabs.min.js';
class BitlaySetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bitlaysetting: '',
			flosetting:'',
			flouserId:'',
			bitlayID: "",
			floID:"",
			errors: '',
			role: '',
			bitly: "",
			flo: "",
			selectedBitlay: '',
			isloading: false,
			listing: [],
			listingDuplicate: [],
			users: [],
			permission: '',
			pandaLoader: true,
			usersearch: "",
			disableButton: false,
			userStatus: '',

		}
		this.bitlayservice = new BitlayService();
		this.alluserService = new AllUserService();
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		this.getBitlay();
		if (localStorage.getItem('token')) {
			this.allUsersAPI();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					let perm = this.state.permission;
					if (this.state.permission.bitlaySetting === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			console.log("all users", data)
			var count = 0;

			if (data.code === 200) {
				this.setState({
					users: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getBitlay() {
		this.setState({
			isloading: true
		})

		this.bitlayservice.getBitlay().then(data => {
			console.log(data)
			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						bitlayID: data.data._id,
						bitlaysetting: data.data.apiKey,
						flosetting: data.data.floApiKey,
						flouserId: data.data.flouserId,
						bitly: data.data.isBitlayApiActive,
						flo: data.data.isFloApiActive,
						isloading: false,
						disableButton: false
					})
				} else {
					this.setState({
						listing: data.data,
						listingDuplicate: data.data,
						isloading: false
					})
				}
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			console.log(err);
			this.setState({
				isloading: false
			})

		})
	}

	onchange(e) {
		
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	updateBitlay(e){
		e.preventDefault();
		const { bitlaysetting } = this.state;

		if (bitlaysetting === '') {
			this.setState({ errors: 'Bitlay API key is required.' });
			return;
		}
		else {
			this.setState({
				errors: ''
			});
			var check = this.state.bitlayID;
			var id = '';
			if (check == null || check == undefined || check == "") {
				id = "123";
				this.setState({
					bitlayID: id,
					disableButton: true
				});
			}
			else {
				id = this.state.bitlayID;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				apiKey: this.state.bitlaysetting,
				floApiKey: this.state.flosetting,
				flouserId: this.state.flouserId,
				isBitlayApiActive:  this.state.bitly,
				isFloApiActive: this.state.flo,
				updatedBy: updater.id
			}
			this.bitlayservice.updateBitlay(id, body).then(data => {
				console.log(data);
				if (data.code == 200) {
					this.getBitlay();
					notify.show(data.message, "success", 2000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	updateFlo(e) {
		e.preventDefault();
		// flouserId: data.data.flouserId,
		const { flosetting , flouserId } = this.state;
		if (flosetting === '') {
			this.setState({ errors: 'Flo API key is required.' });
			return;
		}else  if(flouserId === ''){
			this.setState({ errors: 'User ID is required.' });
			return;	
		}
		else {
			this.setState({
				errors: ''
			});
			var check = this.state.bitlayID;
			var id = '';
			if (check == null || check == undefined || check == "") {
				id = "123";
				this.setState({
					bitlayID: id,
					disableButton: true
				});
			}
			else {
				id = this.state.bitlayID;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				apiKey: this.state.bitlaysetting,
				floApiKey: this.state.flosetting,
				flouserId: this.state.flouserId,
				isBitlayApiActive:  this.state.bitly,
				isFloApiActive: this.state.flo,
				updatedBy: updater.id
			}
			this.bitlayservice.updateBitlay(id, body).then(data => {
				console.log(data);
				if (data.code == 200) {
					this.getBitlay();
					notify.show(data.message, "success", 2000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	submit(e) { // Bitly

		e.preventDefault();
		const { bitlaysetting } = this.state;
		const { flosetting , flouserId } = this.state;

		if (bitlaysetting === '' && flosetting === '') {
			this.setState({ errors: 'API key is required.' });
			return;
		}else  if(flouserId === ''){
			this.setState({ errors: 'User ID is required.' });
			return;	
		}
		else {
			this.setState({
				errors: ''
			});
			var check = this.state.bitlayID;
			var id = '';
			if (check == null || check == undefined || check == "") {
				id = "123";
				this.setState({
					bitlayID: id,
					disableButton: true
				});
			}
			else {
				id = this.state.bitlayID;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				apiKey: this.state.bitlaysetting,
				floApiKey: this.state.flosetting,
				flouserId: this.state.flouserId,
				isBitlayApiActive:  this.state.bitly,
				isFloApiActive: this.state.flo,
				updatedBy: updater.id
			}
			this.bitlayservice.updateBitlay(id, body).then(data => {
				console.log(data);
				if (data.code == 200) {
					this.getBitlay();
					notify.show(data.message, "success", 2000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}


	cancel(e) {

		e.preventDefault();
		this.props.history.push('/dashboard');
	}



	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.listingDuplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.apiKey.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				listing: data
			})
		})
	}

	onchangeBitly(check) {
		console.log(check);
		this.setState({
			bitly: check,
			flo: false
		})
	}

	onchangeFlo(check) {
		this.setState({
			bitly: false,
			flo: check
		})
	}

	render() {
		return (
			<div className="content-page Setting-tab tab-structure" id="setting02">
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />

				{/* For Super Admin */}
				<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >
					<div className="content-header">

						<div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
								<div className="page-heading">
									<h3>URL Shortener Settings</h3>
								</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
                                <div className="head-search">
								   <input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
								</div>
                                </div>
                            </div>
                        </div>
					</div>

				

					<div className="table-wrap">
						<div className="table-responsive">
							<table className="table table-hover table-striped table-bordered table-condensed">
								<thead className="thead-light">
									<tr>
										<th>User</th>
										<th>API Key</th>

									</tr>
								</thead>
								
								<tbody>
									{
										this.state.listing.length > 0 ?
											this.state.listing.map((val, key) => {
												if (val.user) {
													return (
														<tr key={key}>
															<td title={val.user ? val.user.firstName + val.user.lastName : ""}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
															<td title={val.apiKey}>{val.isFloApiActive ? val.floApiKey : val.apiKey} </td>
														</tr>
													)
												}

											})
											: <tr >
												<td colSpan="3" style={{ textAlign: "center" }}>
													{this.state.isloading ? <ClipLoader
														css={override}
														sizeUnit={"px"}
														size={50}
														color={'#123abc'}
														loading={this.state.isloading}
													/> : "No Record Found"}

												</td>
											</tr>
									}

								</tbody>
							</table>
						</div>
					</div>
				</div>

				{/* For User */}
				<div className="setting-fields field-set" style={{ display: this.state.role !== 'admin' ? 'block' : 'none' }}>
					<div className="content-header">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>URL Shortener Settings</h3>
								</div>
							</div>
							<div className="col-md-6 col-sm-6 col-xs-12">
						
							</div>
						</div>
					</div>
					{/* <!-- Nav tabs --> */}
					<div className="tab leads-tabs" role="tabpanel">
						<ul className="nav nav-tabs" role="tablist">
							<li role="presentation" className="active" >
								<a href="#smtp02" aria-controls="home" role="tab" data-toggle="tab">Flo.ink Setting</a>
							</li>
							<li role="presentation" >
								<a href="#smtp01" aria-controls="home" role="tab" data-toggle="tab">Bitly Setting</a>
							</li>
						</ul>
						{/* <!-- Tab panes --> */}
						<div className="tab-content tabs">
							<div role="tabpanel" className="tab-pane fade in " id="smtp01">
								<div className="row r-tab-form">
									{Object.keys(this.state.errors).length > 0 && this.state.errors ?
										<div className="alert alert-danger">
											{this.state.errors}
										</div> : ''}
									<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
										<div className="form-group row">
											<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Api key</label>
											<div className="col-md-9 col-sm-8 col-xs-12">
												<input type="text" className="form-control"
													disabled={(this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW') ? "disabled" : ""}
													placeholder="Bitlay" name="bitlaysetting" value={this.state.bitlaysetting} onChange={(e) => this.onchange(e)} />
											</div>
										</div>

										<div className="form-group  row">
											<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
											<div className="col-md-9 col-sm-8 col-xs-12">
												<Switch name="bitly"
														disabled={(this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW') ? "disabled" : ""}
														onChange={(e) => this.onchangeBitly(e)} checked={this.state.bitly} />
											</div>
										</div>

										{this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW' ? "" :
											<div className="form-group-btn top-10 text-right">
												<button onClick={(e) => this.updateBitlay(e)} disabled={this.state.disableButton} className="mr-10 btn-style-md btn-purple btn-round">Save</button>
												<button className="btn-style-md btn-purple btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>											
											</div>
										}
									</div>
								</div>
							</div>
							<div role="tabpanel" className="tab-pane fade in active" id="smtp02">
								<div className="row r-tab-form">
									{Object.keys(this.state.errors).length > 0 && this.state.errors ?
										<div className="alert alert-danger">
											{this.state.errors}
										</div> : ''}
									<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
										<div className="form-group row">
											<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Api key</label>
											<div className="col-md-9 col-sm-8 col-xs-12">
												<input type="text" className="form-control"
													disabled={(this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW') ? "disabled" : ""}
													placeholder="Api Key" name="flosetting" value={this.state.flosetting} onChange={(e) => this.onchange(e)} />
											</div>
										</div>

										<div className="form-group row">
											<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">userId</label>
											<div className="col-md-9 col-sm-8 col-xs-12">
												<input type="text" className="form-control"
													disabled={(this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW') ? "disabled" : ""}
													placeholder="UserId" name="flouserId" value={this.state.flouserId} onChange={(e) => this.onchange(e)} />
											</div>
										</div>

										<div className="form-group  row">
											<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
											<div className="col-md-9 col-sm-8 col-xs-12">
												<Switch name="flo"
														disabled={(this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW') ? "disabled" : ""}
														onChange={(e) => this.onchangeFlo(e)} checked={this.state.flo} />
											</div>
										</div>

										{this.state.role === 'subUser' && this.state.permission.bitlaySetting_Status !== 'RW' ? "" :
											<div className="form-group-btn top-10 text-right">
												<button onClick={(e) => this.updateFlo(e)} disabled={this.state.disableButton} className="mr-10 btn-style-md btn-purple btn-round">Save</button>
												<button className="btn-style-md btn-purple btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>											
											</div>
										}
									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BitlaySetting);



