import React, { Component } from 'react';
import {ENV} from './../env';

// var token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class PaymentService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;

    }

    async makePayment(body) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'payment/makePayment', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async paypalResponse(body) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'payment/paypalResponse', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async createStripe(body) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch('http://35.177.55.225/payment/stipe', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async paymentHistory(id) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'payment/paymentHistory/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async packageStats(id) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'package/stats/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async creditAdminToUser(body) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'smsCredit/transfer', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async smsCreditHistory() {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'smsCredit/history', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }

    async smsCreditUserHistoryForAdmin(id) {
        var token = JSON.parse(localStorage.getItem("token"));
        const data_1 = await fetch(ENV.url + 'smsCredit/userHistory/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
                
            }
        }).then(res => res.json());
        return data_1;
    }


    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default PaymentService;