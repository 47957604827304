import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import Routes from './Routes';
import Dashboard from './container/dashboard/dashboard';
import Header from './container/header/header';
import Sidebar from './container/sidebar/sidebar';
import { withRouter } from 'react-router-dom';
import Login from './container/login/login';
import Home from  './staticPages/home/home'
import ResetPassword from './container/resetPassword/resetPassword';
import $ from 'jquery';
class App extends Component {

  constructor(props){
    super(props);

  }
    

  

  sideBarShowHideScript(){
			$('#sidebar').toggleClass('active');
	}

  render() {
    
    if (this.props.location.pathname === '/login') {
      return (
        <Login />
      )
    }
   else if (this.props.location.pathname === '/') {
      return (
        <Home />
      )
    }
    else if(this.props.location.pathname === "/resetPassword"){
      return(
        <ResetPassword/>
      )
    }
    else if (this.props.location.pathname === '/leadslisting') {
      return (
        <div className="App">
          <section className="lead-header header-bg">
            <Header></Header>
          </section>
          <section className="below-header">
            <div className="wrapper leadnav">
              <Sidebar></Sidebar>
              <div id="content" className="lead-content">
                <Routes></Routes>
              </div>
            </div>
          </section>
        </div>
      );
    }
    else {
      return (
        <div className="App">
          <section className="lead-header header-bg">
            <Header></Header>
          </section>
          <section className="below-header">
            <div className="wrapper leadnav">
              <Sidebar></Sidebar>
              <div id="content" className="lead-content">
                <Routes></Routes>
              </div>
            </div>
          </section>
        </div>
      );
    }



  }
}

export default withRouter(App);
