import React, { Component } from 'react'
import {ENV} from './../env';

// var token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class MailGunService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    async getMailgun() {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        var url = ENV.url;
        const data_1 = await fetch(url + 'getMailgun', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }



    async postMailgun(body) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'mailgun/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async updateMailGun(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'mailgun/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async getSingleMailGun(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getUserMailgun/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }


    async updateMailChimp(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'updateMailchimp/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }


    async getMailChimp() {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getMailchimp', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    
    async getUserMailchimp(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getUserMailchimp/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }




    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default MailGunService;