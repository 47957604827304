import React, { Component } from 'react'
import {ENV} from '../env';

class BitlayService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }
     


    
    async updateBitlay(accountId,body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'bilty/'+accountId, {
            method: 'PUT',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    

     async getBitlay() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'getBilty', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async getUserBitlay(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'getUserBitly/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateFlo(accountId,body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'bilty/'+accountId, {
            method: 'PUT',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default BitlayService;