import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import './createLead.css';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import CreateLeadService from '../../services/createlead.service';
import CSVService from '../../services/cvs.service';
import AllUserService from './../../services/alluser.service';
import BankService from '../../services/bank.services';


class CreateLead extends Component {
    constructor(props) {

        super(props);
        this.state = {
            isInputDisabled: true,
            previousData: [],
            previousAddresss: false,
            originalAddress: false,
            zipPerivous: '',
            zipPerivousAddress: '',
            zipPerviousCode: '',
            FindAddressArray: [],
            newData: [],
            dataAddress: null,
            resultsHTML: '',
            gl: [],
            elementID: null,
            title: '',
            firstname: '',
            lastname: '',
            DateOfBirth: new Date(),
            previous_postcode1: '',
            address1: '',
            phone1: '+44',
            email: '',
            zip: '',
            link: [],
            permission: '',
            //loader
            pandaLoader: true,

            leads: '',
            LeadsId: [],
            domainname: '',
            host: '',
            username: '',
            password: '',
            dbname: '',
            tablename: '',
            addressnew: [],
            errors: '',
            id: '',

            leadModelOpen: false,
            userModelOpen: false,
            postalCodeAddress: false,

            csvid: '',
            siteid: '',
            leadid: "",
            previousName: "",
            leadip: "",
            xAuthentication: "",
            title: "",
            firstname: "",
            lastname: "",
            BankName: "",
            LenderIDs: "",
            address: "",
            address1: "",
            address2: "",
            address3: "",
            previous_name: "",
            previous_address1: "",
            previous_address2: "",
            previous_address3: "",
            previous_address4: "",
            previous_address5: "",
            previous_postcode1: "",
            previous_postcode2: "",
            previous_postcode3: "",
            previous_postcode4: "",
            previous_postcode5: "",
            previous_address1_line1: "",
            previous_address2_line1: "",
            previous_address3_line1: "",
            previous_address4_line1: "",
            previous_address5_line1: "",
            previous_address1_line2: "",
            previous_address2_line2document: "",
            previous_address3_line2: "",
            previous_address4_line2: "",
            previous_address5_line2: "",
            previous_address1_line3: "",
            previous_address2_line3: "",
            previous_address3_line3: "",
            previous_address4_line3: "",
            previous_address5_line3: "",
            previous_address1_city: "",
            previous_address2_city: "",
            previous_address3_city: "",
            previous_address4_city: "",
            previous_address5_city: "",
            previous_address1_province: "",
            previous_address2_province: "",
            previous_address3_province: "",
            previous_address4_province: "",
            previous_address5_province: "",
            previous_address1_company: "",
            previous_address2_company: "",
            previous_address3_company: "",
            previous_address4_company: "",
            previous_address5_company: "",
            city: "",
            province: "",
            country: "",
            company: "",
            phone1: "+44",
            email: "",
            zip: "",
            dob_day: "",
            dob_month: "",
            dob_year: "",
            dob: "",
            document_id: "",
            pdf_url: "",
            lender_id_doc_order: "",
            signature_src: "",
            PostURL: "",
            is_waypoint: "",
            is_submit: "",
            is_signed: "",
            other: [],
            userStatus: '',
            banks: [],
            CBN: ""

        }
        this.csvService = new CSVService();
        this.alluserService = new AllUserService();
        this.createleadService = new CreateLeadService();
        this.bankService = new BankService();

    }


    componentDidMount() {
        window.scrollTo(0, 0);

        let role = JSON.parse(localStorage.getItem('user'));
        if (role) {
            let roleStatus = role.id;
            this.allUserBank();
            this.alluserService.editUser(roleStatus).then(data => {
                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        userStatus: result,
                    })
                    if (this.state.userStatus.status === 'inactive') {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
        let rolename = role.role;
        if (rolename === 'subUser') {
            this.getPermissions();
        } else {
            this.setState({ pandaLoader: false })
        }

        if (this.props.location.state != undefined) {
            this.setState({
                csvid: this.props.location.state.id,
            })
        } else {
            this.props.history.push('/*');
        }
    }


    allUserBank() {
        this.setState({
            isloading: true
        })
        // this.bankService.createBank(body).then(data => {
        this.bankService.getBank().then(data => {
            debugger
            if (data.code === 200) {
                console.log(data.data);
                this.setState({
                    banks: data.data
                });
                // notify.show(data.message, "success", 3000)
                this.setState({
                    isloading: false
                })
            }
            if (data.code === 404) {
                debugger
                this.setState({
                    csvListing: [],
                    csvListingDuplicate: [],
                    isloading: false
                })
                // notify.show(data.message, "success", 3000)

            }
        }).catch(err => {
            console.log(err)
            this.setState({
                isloading: false
            })
        })

    }

    getPermissions() {
        let user = JSON.parse(localStorage.getItem('user'));
        let id = user.id;
        let role = user.role;
        if (role === 'subUser') {
            this.alluserService.userPermission(id).then(data => {

                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        permission: result,
                        pandaLoader: false
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }


    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onCheckBox(e) {

        if (e.target.checked == true) {
            var name = e.target.value;
            var leads = this.state.leads === "" || this.state.leads == undefined ? name : this.state.leads + ' | ' + name;
            this.setState({
                leads: leads
            })
            var tempArray = [];
            tempArray = leads.split('|');
            var trimArray = []
            for (var i = 0; tempArray.length > i; i++) {
                var trimData = tempArray[i].trim();
                if (trimData != '') {
                    trimArray.push(trimData.toLowerCase());
                }
            }
            // console.log("leads", trimArray)
            this.setState({
                LeadsId: trimArray
            })
        } else {
            var name = e.target.value;
            var tempArray = [];
            var checkBox = [];
            tempArray = this.state.LeadsId;
            // splice("Kiwi", 1);

            for (var y = 0; tempArray.length > y; y++) {
                if (name != tempArray[y] && name != '') {
                    checkBox.push(tempArray[y]);
                }
            }
            // console.log(checkBox);
            this.setState({
                LeadsId: checkBox
            })
            var dataString = '';
            for (var i = 0; checkBox.length > i; i++) {

                dataString = dataString + checkBox[i] + "|";
            }
            // console.log(dataString);
            this.setState({
                leads: dataString
            })
        }
    }
    getAddress(data) {
        if (data != '') {
            this.setState({ originalAddress: true });
            var x = data;
            fetch(`https://controlfunnel.co.uk/address/${x}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {

                    var tempArray = [];
                    tempArray = result.data

                    this.setState({
                        gl: tempArray
                    })
                },
                (error) => {
                    console.log(error);
                });
        } else {

        }

    }

    onzipChange(e) {
        this.setState({
            zipPerivous: e.target.value
        })
    }

    onzipPreviousChange(e) {
        this.setState({
            zipPerviousCode: e.target.value,
            zip: e.target.value
        })
    }

    getPreivous(e, i) {
        // console.log(i, "--------------------------------------------------")

        this.setState({
            previousAddresss: true
        })
        var x = this.state.zipPerivous;
        fetch(`https://controlfunnel.co.uk/address/${x}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {

                var tempArray = [];
                tempArray = result.data

                this.setState({
                    previousData: tempArray
                })
                // if(result.status === "success"){  
                // }else{ 
                // }
            },
            (error) => {
                console.log(error);
            });
    }

    removeTextField(indexValue) {

        var temp = [];
        temp = this.state.FindAddressArray;
        var length = temp.length;
        var newArray = [];
        for (var i = 0; temp.length > i; i++) {
            if (i != indexValue) {
                newArray.push(temp[i]);
            }
        }

        for (var z = 0; 6 > z; z++) {
            this.setState({
                [`previous_address` + z]: '',
                [`previous_postcode` + z]: '',
                [`previous_address1_line` + z]: '',
                [`previous_address` + z + `_city`]: '',
            })
        }


        for (var y = 0; newArray.length > y; y++) {

            var splitAddress = [];
            splitAddress = newArray[y].address.split(',');
            var length = temp.length;
            this.setState({
                [`previous_address` + (parseInt(y) + 1)]: newArray[y].address,
                [`previous_postcode` + (parseInt(y) + 1)]: splitAddress[1],
                [`previous_address1_line` + (parseInt(y) + 1)]: splitAddress[2],
                [`previous_address` + (parseInt(y) + 1) + `_city`]: splitAddress[3],
            })
        }
        this.setState({
            FindAddressArray: newArray.slice()
            // address: array123.slice()
        });
    }

    onEditLeadsModel = (e) => {

        if (this.state.leadModelOpen === false) {
            this.setState({ leadModelOpen: true });
        } else {
            this.setState({ leadModelOpen: false });
        }
    };



    postalCodeAddressOpen = (e) => {

        if (this.state.postalCodeAddressOpen === false) {
            this.setState({ postalCodeAddressOpen: true });
        } else {
            this.setState({ postalCodeAddressOpen: false });
        }
    }


    addressSelection(data) {
        this.setState({ originalAddress: false })
        var address = data;
        var temp = [];
        this.setState({
            gl: temp
        })

        var AddressArray = address.split(',');
        // console.log(AddressArray);
        this.setState({
            address: address,
            address1: AddressArray[1],
            address2: AddressArray[2],
            city: AddressArray[3],
            country: AddressArray[4],
        })
    }

    addressSelectionPrevious(data) {

        this.setState({
            previousAddresss: false
        })

        this.setState({
            zipPerivousAddress: data
        })


        var zipCode = this.state.zipPerivous;
        var addressCode = data;
        var checkLength = this.state.FindAddressArray.length;
        if (checkLength < 5) {
            if (zipCode != '', addressCode != '') {
                // var inputs = this.state.addressnew;
                var temp = [];
                temp = this.state.FindAddressArray;
                var data = {
                    zip: zipCode,
                    address: addressCode
                }
                temp.push(data);
                var splitAddress = [];
                splitAddress = addressCode.split(',');
                var length = temp.length;
                this.setState({
                    // address: address,
                    // address1: AddressArray[1],
                    // address2: AddressArray[2],
                    // city: AddressArray[3],
                    // country: AddressArray[4],
                    [`previous_address` + length]: addressCode,
                    [`previous_postcode` + length]: splitAddress[1],
                    [`previous_address1_line` + length]: splitAddress[2],
                    [`previous_address` + length + `_city`]: splitAddress[3],
                })
                this.setState({
                    FindAddressArray: temp,
                    zipPerivousAddress: ''
                })
            } else {
                notify.show("Zip code and Address Must be Selected!", "success", 3000);
            }
        } else {
            notify.show("Only 5 Previous Code allow to add!", "success", 3000);
        }

    }

    submit() {
        var regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (isValidPhoneNumber(this.state.phone1) === false) {
            this.setState({
                errors: "Invalid Phone No"
            });
            return
        }
        else if (this.state.email === '') {
            this.setState({ errors: 'Email is required' });
            return;
        }
        else if (!this.state.email.match(regexp)) {
            this.setState({ errors: 'Email is invalid' });
            return;
        }

        let body = {
            csvId: this.state.csvid,
            leadObj: {
                previous_surename: this.state.previous_surename ? this.state.previous_surename : "",
                leadip: this.state.leadip,
                xAuthentication: this.state.xAuthentication,
                title: this.state.title,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                BankName: this.state.leads,
                LenderIDs: this.state.LenderIDs,
                address: this.state.address,
                previous_name: this.state.previous_name,
                address1: this.state.address1,
                address2: this.state.address2,
                address3: this.state.address3,
                previous_address1: this.state.previous_address1,
                previous_address2: this.state.previous_address2,
                previous_address3: this.state.previous_address3,
                previous_address4: this.state.previous_address4,
                previous_address5: this.state.previous_address5,
                previous_postcode1: this.state.previous_postcode1,
                previous_postcode2: this.state.previous_postcode2,
                previous_postcode3: this.state.previous_postcode3,
                previous_postcode4: this.state.previous_postcode4,
                previous_postcode5: this.state.previous_postcode5,
                previous_address1_line1: this.state.previous_address1_line1,
                previous_address2_line1: this.state.previous_address2_line1,
                previous_address3_line1: this.state.previous_address3_line1,
                previous_address4_line1: this.state.previous_address4_line1,
                previous_address5_line1: this.state.previous_address5_line1,
                previous_address1_line2: this.state.previous_address1_line2,
                previous_address2_line2: this.state.previous_address2_line2,
                previous_address3_line2: this.state.previous_address3_line2,
                previous_address4_line2: this.state.previous_address4_line2,
                previous_address5_line2: this.state.previous_address5_line2,
                previous_address1_line3: this.state.previous_address1_line3,
                previous_address2_line3: this.state.previous_address2_line3,
                previous_address3_line3: this.state.previous_address3_line3,
                previous_address4_line3: this.state.previous_address4_line3,
                previous_address5_line3: this.state.previous_address5_line3,
                previous_address1_city: this.state.previous_address1_city,
                previous_address2_city: this.state.previous_address2_city,
                previous_address3_city: this.state.previous_address3_city,
                previous_address4_city: this.state.previous_address4_city,
                previous_address5_city: this.state.previous_address5_city,
                previous_address1_province: this.state.previous_address1_province,
                previous_address2_province: this.state.previous_address1_province,
                previous_address3_province: this.state.previous_address1_province,
                previous_address4_province: this.state.previous_address1_province,
                previous_address5_province: this.state.previous_address1_province,
                previous_address1_company: this.state.previous_address1_company,
                previous_address2_company: this.state.previous_address2_company,
                previous_address3_company: this.state.previous_address3_company,
                previous_address4_company: this.state.previous_address4_company,
                previous_address5_company: this.state.previous_address5_company,
                city: this.state.city,
                province: this.state.province,
                country: this.state.country,
                company: this.state.company,
                phone1: this.state.phone1,
                email: this.state.email,
                zip: this.state.zip,
                dob_day: this.state.dob_day,
                dob_month: this.state.dob_month,
                dob_year: this.state.dob_year,
                dob: this.state.dob,
                DateOfBirth: moment(this.state.DateOfBirth).format('YYYY/MM/DD'),
                document_id: this.state.document_id,
                pdf_url: this.state.pdf_url,
                lender_id_doc_order: this.state.lender_id_doc_order,
                signature_src: this.state.signature_src,
                PostURL: this.state.PostURL,
                is_waypoint: this.state.is_waypoint,
                is_submit: this.state.is_submit,
                is_signed: this.state.is_signed,
                other: [],
                CBN: this.state.CBN,
                myname:"jkdgasfhdamhdjsa"
            }

        }

        this.createleadService.createSingleLead(body).then(data => {
            // console.log("----------------------data csv-----------------", data)
            if (data.code === 200) {
                $('#diableCreateLeadButton').prop("disabled", true);
                var current = this;
                current.setState({
                    errors: ""
                })
                notify.show(data.message, "success", 3000);
                setTimeout(() => {
                    current.props.history.push('/leadslisting')
                }, 3000);

            } else if (data.code === 404) {
                this.setState({
                    errors: data.message
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    onEditLeadsModel = (e) => {
        if (this.state.leadModelOpen === false) {
            this.setState({ leadModelOpen: true });
        } else {
            this.setState({ leadModelOpen: false });
        }
    };

    postalCodeAddressOpen = (e) => {

        if (this.state.postalCodeAddressOpen === false) {
            this.setState({ postalCodeAddressOpen: true });
        } else {
            this.setState({ postalCodeAddressOpen: false });
        }
    }

    findAddress(e) {
        e.preventDefault();
        this.csvService.findAddress().then(data => {
            // console.log(data)
        }).catch(err => {
            console.log(err)
        })
    }

    onChangedate = date => {

        this.setState({ DateOfBirth: date })
    }

    render() {
        var that = this;
        if (this.state.gl) {
            var data = this.state.gl.map(function (dat, key) {
                return (
                    <div>
                        <p onClick={() => that.addressSelection(dat.address)} > {dat.address} </p>
                    </div>
                )
            });

        }
        if (this.state.previousData) {
            var dataprevious = this.state.previousData.map(function (dat, key) {
                return (
                    <div>
                        <p onClick={() => that.addressSelectionPrevious(dat.address)} > {dat.address} </p>
                    </div>
                )
            });
        }
        var currentThis = this;
        // console.log(this.state);
        // console.log(this.state.FindAddressArray);
        return (
            <React.Fragment>
                {this.state.pandaLoader === true ?
                    <div className="loading">Loading&#8230;</div>
                    : ""}
                <div>
                    {/* {data} */}
                    <Notifications options={{ zIndex: 200, top: '20px' }} />

                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="page-heading">
                                    <h3>Create CSV Lead</h3>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                    <div className="row">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">{this.state.errors}</div> : ''}
                        <div className="col-md-12 col-xs-12">
                            <div className="form-group row">
                                <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Lenders</label>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="tag-wrap edit-csv-tags">
                                        {this.state.LeadsId.map((item, key) =>
                                            <span key={key} className="tag tag-label">{item}</span>
                                        )}
                                    </div>
                                    <div className="edit-tag-btn">
                                        <button className="btn-style-md btn-round btn-purple" onClick={this.onEditLeadsModel} >Select Lender</button>
                                    </div>
                                </div>
                                {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <button className="btn-style-md btn-round btn-purple" onClick={this.onEditLeadsModel} >Edit</button>
                                </div> */}
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Title</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="title" value={this.state.title} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">First Name</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Last Name</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Date of Birth</label>
                                        <div className="custom-datepicker">
                                            <DatePicker className="form-control"
                                                selected={this.state.DateOfBirth}
                                                onChange={this.onChangedate}
                                                showYearDropdown
                                                showMonthDropdown
                                            />
                                            {/* <input type="text" className="form-control" name="DateOfBirth" value={this.state.DateOfBirth} onChange={(e) => this.onchange(e)} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Phone</label>
                                        <div className="">
                                            <ReactPhoneInput
                                                // defaultCountry={'gb'}

                                                inputExtraProps={{
                                                    name: 'phone1',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                value={this.state.phone1}
                                                onChange={phone1 => this.setState({ phone1 })} />
                                            {/* <input type="text" className="form-control" name="phone1" value={this.state.phone1} onChange={(e) => this.onchange(e)} /> */}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Email</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="email" value={this.state.email} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">CBN/REF</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="CBN" value={this.state.CBN} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Your Post Code and Address</label>
                                        <div className="">
                                            <div> <span>Zip:</span>{this.state.zip}</div>
                                            <div><span>Address:</span>{this.state.address}</div>
                                        </div>
                                        {/* <div className="addressShow">
                                            {data}
                                        </div> */}

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-xs-12">
                                    <div className="form-group row">
                                        <label className="col-md-12 col-xs-12">Please provide your current Postcode</label>
                                        <div className="col-md-5 col-xs-12">
                                            <div className="pos-rel">
                                                <input type="text" className="form-control" name="zip" value={this.state.zip || ''} onChange={(e) => this.onzipPreviousChange(e)} />
                                                <div style={{ "display": this.state.originalAddress ? "block" : "none" }} className="addressShow">
                                                    {data != undefined > 0 ? data : "No Record Found"}
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-xs-12">
                                            <button className="btn btn-style-md btn-purple" onClick={(e) => this.getAddress(this.state.zipPerviousCode)}>Find Address</button>
                                        </div>
                                        <div className="col-md-5 col-xs-12">
                                            <input type="text" className="form-control" name="zip" value={this.state.address || ''} disabled={this.state.isInputDisabled} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="address-box">
                                <h4>Please make sure you give us your previous names and any addresses linked to your lenders, in the fields below.</h4>
                                <div className="form-group row">
                                    <label className="col-md-12 col-sm-12 col-xs-12">Previous Surname</label>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                        <input type="text" className="form-control" value={this.state.previous_name || ''} onChange={(e) => this.onchange(e)} name="previous_name" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <h4>Add Address</h4>
                                </div>



                                <div className="form-group" id="dynamic">
                                    {/* <label className="">Previous Postcode</label> */}
                                    {this.state.FindAddressArray.map((val, i) => {
                                        return (
                                            <div className="form-group">
                                                <div className="add-address-row">
                                                    <div className="add-address-col">
                                                        <div className="input-field">
                                                            <label>Zip code:</label>
                                                            <input type="text" className="form-control" name="zip" disabled={this.state.isInputDisabled} value={val.zip || ''} />
                                                        </div>
                                                        <div className="input-field-btn">
                                                            {/* <button className="btn btn-style-md btn-purple" onClick={(e) => this.getPreivous(e , i)}>Find Address</button> */}
                                                        </div>
                                                        <div className="input-field">
                                                            <label>Address:</label>
                                                            <input type="text" className="form-control" name={this.state.previous_address + i} value={val.address || ''} disabled={this.state.isInputDisabled} />
                                                        </div>
                                                    </div>
                                                    <div className="add-address-col add-remove">
                                                        <label></label>
                                                        <div className="field-add-btns" id="minusbutton">
                                                            <button className="btn-rem ml-5" onClick={(e) => this.removeTextField(i)}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                    <div className="form-group">
                                        <label className="">Previous Postcode</label>
                                        <div className="add-address-row">
                                            <div className="add-address-col">
                                                <div className="input-field pos-rel">
                                                    <input type="text" className="form-control" name="zip" onChange={(e) => this.onzipChange(e)} />
                                                    <div style={{ "display": this.state.previousAddresss ? "block" : "none" }} className="addressShow bottom">
                                                        {dataprevious != undefined > 0 ? dataprevious : "No Record Found"}
                                                    </div>
                                                </div>
                                                <div className="input-field-btn">
                                                    <button className="btn btn-style-md btn-purple" onClick={(e) => this.getPreivous(e)}>Find Address</button>
                                                </div>
                                                <div className="input-field">
                                                    {/* <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className="add-address-col add-remove">
                                                {/* <div className="field-add-btns" id="minusbutton">
                                                    <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="add-btn text-right list-item mt-20">
                                <button type="button" id="diableCreateLeadButton" className="btn-purple btn-style-md btn-round mr-5" onClick={(e) => this.submit(e)}>Save</button>
                                <Link to="/leadslisting" type="button" className="btn-purple btn-style-md btn-round ">Cancel</Link>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="extra_fields" style={{ display: "none" }}>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">leadip</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="leadip" value={this.state.leadip || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">xAuthentication</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="xAuthentication" value={this.state.xAuthentication || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">BankName</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="BankName" value={this.state.BankName || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1" value={this.state.previous_address1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2" value={this.state.previous_address2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3" value={this.state.previous_address3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4" value={this.state.previous_address4 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5" value={this.state.previous_address5 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode1" value={this.state.previous_postcode1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode2" value={this.state.previous_postcode2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode3" value={this.state.previous_postcode3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode4</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode4" value={this.state.previous_postcode4 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode5</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode5" value={this.state.previous_postcode5 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line1" value={this.state.previous_address1_line1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line1" value={this.state.previous_address2_line1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>


                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line1" value={this.state.previous_address3_line1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line1" value={this.state.previous_address4_line1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line1" value={this.state.previous_address5_line1 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line2" value={this.state.previous_address1_line2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line2" value={this.state.previous_address2_line2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line2" value={this.state.previous_address3_line2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line2" value={this.state.previous_address4_line2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line2" value={this.state.previous_address5_line2 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line3" value={this.state.previous_address1_line3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line3" value={this.state.previous_address2_line3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line3" value={this.state.previous_address3_line3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line3" value={this.state.previous_address4_line3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line3" value={this.state.previous_address5_line3 || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_city" value={this.state.previous_address1_city || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_city" value={this.state.previous_address2_city || ''} onChange={(e) => this.onchange(e)} />
                        </div> ||''
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_city" value={this.state.previous_address3_city || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_city" value={this.state.previous_address4_city || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_city" value={this.state.previous_address5_city || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_province" value={this.state.previous_address1_province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_province" value={this.state.previous_address2_province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_province" value={this.state.previous_address3_province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_province" value={this.state.previous_address4_province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_province" value={this.state.previous_address5_province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_company" value={this.state.previous_address1_company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_company" value={this.state.previous_address2_company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_company" value={this.state.previous_address3_company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_company" value={this.state.previous_address4_company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_company" value={this.state.previous_address5_company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="city" value={this.state.city || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="province" value={this.state.province || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">country</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="country" value={this.state.country || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="company" value={this.state.company || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_day</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_day" value={this.state.dob_day || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_month</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_month" value={this.state.dob_month || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_year</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_year" value={this.state.dob_year || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob" value={this.state.dob || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">document_id</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="document_id" value={this.state.document_id || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">pdf_url</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="pdf_url" value={this.state.pdf_url || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">lender_id_doc_order</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="lender_id_doc_order" value={this.state.lender_id_doc_order || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">signature_src</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="signature_src" value={this.state.signature_src || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">PostURL</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="PostURL" value={this.state.PostURL || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_waypoint</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_waypoint" value={this.state.is_waypoint || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_submit</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_submit" value={this.state.is_submit || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_signed</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_signed" value={this.state.is_signed || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">other</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="other" value={this.state.other || ''} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>
                </div>


                <Modal className="modal modal-style" open={this.state.leadModelOpen} onClose={this.onEditLeadsModel}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.onEditLeadsModel()} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Find out if any of these may owe you compensation. </h4>
                    </div>
                    <div className="modal-body">
                        <lable>Please select ALL the banks you had a credit card, loan, or mortgage with</lable>
                        <div className="row">

                            {
                                this.state.banks.length > 0 ?
                                    this.state.banks.map((val, key) => {
                                            return (
                                                <div className="col-md-4">
                                                      {/* {val} */}
                                                    <div className="modal-checkbox">
                                                        <label className="custom-checkbox"> {val.bankName}
                                                <input type="checkbox" name={val.bankName} value={val.bankName.toLowerCase()} checked={this.state.LeadsId.indexOf(val.bankName.toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                    }) : ""
                            }
                            
                        </div>
                    </div>
                </Modal>


            </React.Fragment>
        );
    }
}

export default withRouter(CreateLead);