import React, { Component } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import AllUserService from './../../services/alluser.service';
import './form.css';
import $ from 'jquery';
import FormService from '../../services/form.service';
import BitlayService from './../../services/bitlay.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { ENV } from './../../env';
import { css } from '@emotion/core';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// pagination
			hasMorephone: true,
			hasMoreemail: true,
			hasMorewithout: true,
			hasMorewith: true,
			// pagination

			smstype: "",
			emailtype: "",
			smstypeList: [],
			emailtypeList: [],
			smssentTo: '',
			emailsentTo: '',
			signedleadsTo: '',
			openedLinks: '',
			Conversion: '',
			csvError: '',
			isLoading: false,
			csvLoaderButton: false,
			file: File,
			existingCSV: [],
			selectedCSV: '', // in exisiting csv dropdown value
			csvName: '',
			csvID: '', // set csvID during upload of CSVas
			errors: '',
			errorsModal1: '',
			errorsLink: '',
			linkExpiryDate: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
			// filterError: "",
			sendTheMessages: true,
			start: 0,
			limit: 50,

			to: '',
			from: '',
			selectedCsvName: '',
			dataCounts: [],
			tabs: 'phone',
			leadwithphone: [],
			// leadwithphone_search: [],
			leadwithemail: [],
			leadwithformSubmit: [],
			leadwithoutformSubmit: [],
			leadwithphonecheck: [],
			leadwithformSubmitcheck: [],
			leadwithoutformSubmitcheck: [],
			leadwithemailcheck: [],
			smstemplate: [],
			emailtemplate: [],
			selectedEmailID: '',
			selectedSMSID: '',
			openModal: false,
			isHeaderModal: false,
			ImportCSVModal: false,
			csvCreatedModal: false,
			sendSMSModal: false,
			permission: '',

			uploadedFileHeader: [],
			jsonCreatedDropdown: [],
			isHeader: false,
			link: [''],
			// path: "http://localhost:8080/",
			path: ENV.url,
			reminder: "",
			csvLoader: false,

		

			//filter serach
			filter_fname: "",
			filter_lname: "",
			filter_email: "",
			filter_phone: "",
			customerName: "",
			zip: "",

			//loader
			pandaLoader: true,
			userStatus: '',

		}
		this.formService = new FormService();
		this.bitlayservice = new BitlayService();
		this.alluserService = new AllUserService();


		this.csvData = [

			"phone1",
			"email",
			"customerName",
			"bankName",
			"dateOfBirth",
			"policyStartDate"
		]
		this.alluserService = new AllUserService();
	}

	downloadAllSignedPdf(e) {

		var that = this;
		e.preventDefault();
		let body = {
			//leadwithformSubmitcheck: this.state.leadwithphone
			leadwithformSubmit: this.state.leadwithformSubmit
		}
		if (this.state.leadwithemail.length < 1) {
			this.setState({
				errors: "There is no signed pdf to download"
			});
			return false;
		}
		this.setState({
			csvLoader: true
		})
		this.formService.downloadAllSignedPdf(body).then(data => {

			if (data.code === 200) {
				if (data) {
					this.setState({
						csvLoader: false
					})
					var isOpen = window.open(this.state.path + data.data, "_self");
					if (isOpen) {
						setTimeout(function () {
							that.formService.removeZipFile({
								zipfile: data.data
							}).then(data => {
								notify.show("all csv has been downloaded successfully", "success", 2000);
							})
						}, 1500)
					}
				}
				else {
					notify.show(data.message, "error", 2000);
				}
			}
		}).catch(err => {
			console.log(err)
		})


	}

	renderDownloadButtons(){

		if(this.state.csvID || this.state.selectedCSV){
			//if(true){
			if(this.state.leadwithformSubmit.length > 0){
				return(
					<tr>
						<td colSpan="15">
							{/* <button className="download-signed-leads" onClick={(e) => this.downloadAllSignedPdf(e)}>Click here to download all pdfs  <span> </span>
								<i className=" fa fa-download"></i>
							</button> */}
							<button className="download-signed-leads" onClick={() => this.onDownloadSignedLeads()}>Download Submitted Forms <span> </span>
								<i className=" fa fa-download"></i>
							</button>
						</td>
					</tr>
				)
			}
		}


	}

	renderDownloadUnsigned(){

		if(this.state.csvID || this.state.selectedCSV){
			//if(true){
			if(this.state.leadwithoutformSubmit.length > 0){
				return(
					<tr>
						<td colSpan="14">
							<button className="download-signed-leads" onClick={() => this.onDownloadUnsignedLeads()}>Download Unsubmitted Forms <span> </span>
								<i className=" fa fa-download"></i>
							</button>
						</td>
					</tr>
				)
			}
		}
	}
	
	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}
	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		}
		else if (rolename === 'admin') {

			this.setState({
				sendTheMessages: false,
				pandaLoader: false
			});
		}
		else {
			this.setState({ pandaLoader: false })
		}
		this.getAllCSV();
		this.smsTemplate();
		this.emailTemplate();
		this.sideBarShowHideScript();
		this.showLeadsListOnRefereshPage();
		this.getSMStype();
		this.getEmailtype();

	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					let perm = this.state.permission;
					if (this.state.permission.leadsListing === false) {
						this.props.history.push('*');
					} else if (this.state.permission.sendMessage === false) {
						this.setState({
							sendTheMessages: false
						})
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	getSMStype() {
		this.formService.getSMStype().then(data => {
			if (data.code === 200) {
				this.setState({
					smstypeList: data.data
				})
			}
		})
	}

	getEmailtype() {
		this.formService.getEmailtype().then(data => {
			console.log("-------------------------------------------------------", data)
			if (data.code === 200) {
				this.setState({
					emailtypeList: data.data
				})
			}
		})
	}

	getOpenLinks(selectedCSV) {

		if (selectedCSV) {
			let body = { _id: selectedCSV };
			this.formService.openLinks(body).then(data => {
				if (data.code === 200) {

					console.log(data);
					this.setState({
						openedLinks: data.viewPercentage,
						conversion: data.pdfCount,
					})
				}
			}).catch(err => {
				console.log(err)
			})
		}

	}

	showLeadsListOnRefereshPage = async() => {
		await this.setState({
			filter_email: "",
			filter_phone: "",
			filter_fname: "",
			filter_lname: "",
			zip: "",
			customerName:"",
			hasMoreemail: true,
			hasMorephone: true,
			hasMorewith: true,
			hasMorewithout: true,
			limit :50
		});
		let FormbodyselectedCSV = JSON.parse(localStorage.getItem('FormbodyselectedCSV'));
		let FormbodyCSVID = JSON.parse(localStorage.getItem('FormbodyCSVID'));

	 if (FormbodyselectedCSV) {

			this.getOpenLinks(FormbodyselectedCSV);
			this.setState({
				selectedCSV: FormbodyselectedCSV
			})
			this.formService.useExisting(FormbodyselectedCSV, this.state.start, this.state.limit).then(data => {
				if (data.code === 200) {
					 this.setState({
						selectedCsvName: data.csvName,
						leadwithemail: data.leadsWithEmail,
						leadwithphone: data.leadsWithPhone,
						// leadwithphone_search: data.leadsWithPhone,
						leadwithformSubmit: data.leadsWithFormSubmit,
						leadwithoutformSubmit: data.leadsWithoutFormSubmit,
						dataCounts: data.dataCounts,
						csvID: '',
						errors: "",
						smssentTo: data.dataCounts[0].smsSentTo,
						emailsentTo: data.dataCounts[0].emailSentTo,
						signedleadsTo: data.dataCounts[0].formSubmittedCounts
					}, ()=>{
						if(this.state.leadwithphone.length ==0 || data.dataCounts[0].with_phone === data.leadsWithPhone.length){
							this.setState({
								hasMorephone :false
							})
						}
						
						if(this.state.leadwithemail.length ==0|| data.dataCounts[0].with_email === data.leadsWithEmail.length){
							this.setState({
								hasMoreemail :false
							})
						}
						
						if(this.state.leadwithformSubmit.length ==0|| data.dataCounts[0].with_form_submit === data.leadsWithFormSubmit.length){
							this.setState({
								hasMorewith :false
							})
						}
						
						if(this.state.leadwithoutformSubmit.length ==0|| data.dataCounts[0].without_form_submit === data.leadsWithoutFormSubmit.length){
							this.setState({
								hasMorewithout :false
							})
						}
					})

					
					localStorage.removeItem('FormbodyselectedCSV');
					localStorage.removeItem('FormbodyCSVID');
					localStorage.setItem('FormbodyselectedCSV', JSON.stringify(FormbodyselectedCSV));
				}
				if (data.code === 404) {
					this.setState({
						isLoading: false
					});
					localStorage.removeItem('FormbodyselectedCSV');
					localStorage.removeItem('FormbodyCSVID');
				}
			}).catch(err => {
				this.setState({
					hasMorephone :false,
					hasMoreemail :false,
					hasMorewith :false,
					hasMorewithout :false


				})
				console.log(err)
			})
		}else if (FormbodyCSVID) {
			this.setState({
				csvID: FormbodyCSVID
			})

			this.formService.getFormCsvCollection(FormbodyCSVID, this.state.start, this.state.limit).then(data => {
				if (data.code === 200) {

					this.setState({
						csvCreatedModal: false,
						leadwithemail: data.leadsWithEmail,
						leadwithphone: data.leadsWithPhone,
						leadwithformSubmit: data.leadsWithFormSubmit,
						leadwithoutformSubmit: data.leadsWithoutFormSubmit,
						dataCounts: data.dataCounts,
						link: [],
					},() =>{
						if(this.state.leadwithphone.length ==0 || data.dataCounts[0].with_phone === data.leadsWithPhone.length){
							this.setState({
								hasMorephone :false
							})
						}
						
						if(this.state.leadwithemail.length ==0|| data.dataCounts[0].with_email === data.leadsWithEmail.length){
							this.setState({
								hasMoreemail :false
							})
						}
						
						if(this.state.leadwithformSubmit.length ==0|| data.dataCounts[0].with_form_submit === data.leadsWithFormSubmit.length){
							this.setState({
								hasMorewith :false
							})
						}
						
						if(this.state.leadwithoutformSubmit.length ==0|| data.dataCounts[0].without_form_submit === data.leadsWithoutFormSubmit.length){
							this.setState({
								hasMorewithout :false
							})
						}
					})
					localStorage.removeItem('FormbodyselectedCSV');
					localStorage.removeItem('FormbodyCSVID');
					localStorage.setItem('FormbodyCSVID', JSON.stringify(FormbodyCSVID));
				}
				if (data.code === 404) {
					this.setState({
						isLoading: false
					});
					localStorage.removeItem('FormbodyselectedCSV');
					localStorage.removeItem('FormbodyCSVID');
				}
			}).catch(err => {
				this.setState({
					hasMorephone :false,
					hasMoreemail :false,
					hasMorewith :false,
					hasMorewithout :false

				})
				console.log(err)
			})
		}else{
			this.setState({
				hasMoreemail: false,
				hasMorephone: false,
				hasMorewith: false,
				hasMorewithout: false
			})
		}
	}

	sideBarShowHideScript() {
		$('#sidebarCollapseLead').on('click', function () {
			$('#sidebar').toggleClass('active');
		});
		$('#sidebarCollapseLead').on('click', function () {
			$('#sidebarCollapse').toggleClass('active');
		});
	}



	onOpenModal = () => {
		this.setState({ openModal: true });
	};

	onDownloadSignedLeads = () => {
		var csvID = this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID ;
		this.formService.downloadSubmittedFormCSV(csvID).then(data => {
			if (data.code === 200) {
				if (data.count > 0) {
					window.open(this.state.path + data.data, "_self");
				}
				else {
					notify.show(data.message, "error", 2000);
				}
			}
		});
	};

	onDownloadUnsignedLeads = () => {
		var csvID = this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID ;
		this.formService.downloadNotSubmitedCSV(csvID).then(data => {
			if (data.code === 200) {
				if (data.count > 0) {
					window.open(this.state.path + data.data, "_self");
				}
				else {
					notify.show(data.message, "error", 2000);
				}
			}
		});
	};



	onCloseModal = () => {
		this.resetOnCloseModal();
		// this.getAllCSV();
		this.setState({ openModal: false, csvError: "" });
	};

	isHeaderOnOpenModal = () => {
		this.setState({ isHeaderModal: true });
	};

	isHeaderOnCloseModal = () => {
		this.setState({ isHeaderModal: false });
	};

	onOpenImportCSV = (e) => {
		this.setState({ ImportCSVModal: true });
	};

	onCloseImportCSV = () => {
		this.setState({ ImportCSVModal: false, csvName: "", isHeader: false, link: [], errorsModal1: "", file: File });
	};

	onCSVcreated = (e) => {
		this.setState({ csvCreatedModal: true });
	};

	OnClosecsvCreatedModal = () => {
		this.setState({ csvCreatedModal: false });
	};



	openSMSModal = (e) => {
		this.setState({ sendSMSModal: true });
	};

	closeSMSModal = () => {
		this.setState({ sendSMSModal: false });
	};


	smsTemplate() {
		this.formService.allSmSTemplate().then(data => {
			console.log(data, "sms");
			if (data.code === 200) {
				this.setState({
					smstemplate: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	emailTemplate() {
		this.formService.allEmailTemplate().then(data => {
			console.log(data, "email");
			if (data.code === 200) {
				this.setState({
					emailtemplate: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getAllCSV() {
		this.formService.getAllCSV().then(data => {
			console.log(data)
			if (data.code === 404) {
				this.setState({
					existingCSV: []
				});

			}
			if (data.code === 200) {
				this.setState({
					existingCSV: data.data
				})
			}

		}).catch(err => {
			console.log(err)
		});
	}

	showInput(e) {
		e.preventDefault();
		$('#showInput').trigger('click')
	}

	handleChange(e) {
		console.log("handlechange empty site id")
		var current = this;
		this.setState({ [e.target.name]: e.target.value }, () => {

			this.formService.getSingleCSV(this.state.selectedCSV).then(data => {
				console.log(data)
				this.setState({
					file: current.state.path + data.data.file,
					csvName: data.data.name,
					csvID: '',
					errorsModal1: '',
				});

			}).catch(err => {
				console.log(err)
			})
		});

	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value,
		})
	}



	selectEmailSMS(e) {

		this.setState({
			[e.target.name]: e.target.value
		});

	}



	sendEmail(e) {
		var checkboxes = [];
		checkboxes = [...this.state.leadwithemailcheck, ...this.state.leadwithformSubmitcheck,
		...this.state.leadwithoutformSubmitcheck, ...this.state.leadwithphonecheck];


		// -------------------------------------------send by CSV checkboxes  sms and emai ------------------------------
	 if ((this.state.from === "" && this.state.to === "") && this.state.selectedEmailID !== "" && (this.state.csvID != "" || this.state.selectedCSV != "")) {
			console.log("------------------------------- CSV ------------------------------------")
			var current = this;
			if (checkboxes.length > 0) {
				if (this.state.emailtype !== "") {
					let body = {
						leads: checkboxes,
						templateId: current.state.selectedEmailID,
						csvId: current.state.selectedCSV ? current.state.selectedCSV : current.state.csvID,
						type: current.state.emailtype
						// links: this.state.link

					}
					console.log(body, "body")
					current.formService.sendEmailcsv(body).then(data => {
						if (data.code == 404) {
							notify.show(data.message, "error", 2000);
						}
						else if (data.code == 200) {
							current.setState({
								leadwithphonecheck: [],
								leadwithemailcheck: [],
								leadwithformSubmitcheck: [],
								leadwithoutformSubmitcheck: [],
								selectedEmailID: '',
								// selectedCSV:'',
								emailtype: "",
								errors: ''
							});

							notify.show(data.message, "success", 2000);
							$("#emailtype").val($("#target option:first").val());

							$(".leadphone").prop("checked", false);
							$(".leademail").prop("checked", false);
							$(".leadwith").prop("checked", false);
							$(".leadwithout").prop("checked", false);

							$(".leadphoneall").prop("checked", false);
							$(".leademailall").prop("checked", false);
							$(".leadwithall").prop("checked", false);
							$(".leadwithoutall").prop("checked", false);

							this.showLeadsListOnRefereshPage();
						}
					}).catch(err => {
						this.setState({
							selectedEmailID: ''
						})
						console.log(err)
					})
				} else {
					this.setState({
						errors: "Please select Email type."
					})
				}

			}
			else {
				// notify.show("Select LeadID", "error", 2000);
				this.setState({
					errors: "Please select LeadID"
				})
			}
		}



		// -----------------------------------------to from done sms and email template by CSV ------------------------------
		else if ((this.state.to != "" && this.state.from != "") && this.state.selectedEmailID && (this.state.csvID != "" || this.state.selectedCSV != "")) {
			console.log('----------------------------Remider Email CSV------------------------')
			if (this.state.reminder && this.state.to !== '' && this.state.from !== "" && this.state.selectedEmailID !== "") {
				if (this.state.emailtype !== "") {
					let bodyReminder = {
						to: this.state.to,
						from: this.state.from,
						option: this.state.reminder,
						csvId: this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID,
						templateId: this.state.selectedEmailID,
						type: this.state.emailtype
						// links: this.state.link
					}
					console.log(bodyReminder, "body")
					this.formService.sendEmailcsv(bodyReminder).then(data => {
						if (data.code === 404) {
							notify.show(data.message, "error", 3000);
						}
						else if (data.code === 200) {
							this.setState({
								to: '',
								from: '',
								reminder: '',
								// csvId : '',
								selectedEmailID: '',
								templateId: '',
								link: [],
								emailtype: "",
								errors: ''
							});
							$("#emailtype").val($("#target option:first").val());
							$("#reminderj").val($("#target option:first").val());
							notify.show(data.message, "success", 3000);
							this.showLeadsListOnRefereshPage();
						}

					}).catch(err => {
						console.log(err)
					})
				} else {
					this.setState({
						errors: "Please select Email type."
					})
				}

			} else {
				this.setState({
					errors: "Please select Reminder Status."
				})
			}

		}

		else if (this.state.selectedEmailID === "") {
			this.setState({
				errors: "Please select Email template."
			})
		}
		else if (this.state.tabs === "phone" && this.state.leadwithphone.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withemail" && this.state.leadwithemail.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withsignature" && this.state.leadwithformSubmit.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withoutsignature" && this.state.leadwithoutformSubmit.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		}

		else if (this.state.to === "" || this.state.from === "") {

			this.setState({
				errors: "Please select To/From."
			})
		}
	}



	confirmSendSMS() {
		this.bitlayservice.getBitlay().then(data => {

			if (data.code === 200) {
				if (data.data) {
					this.sendSMS();
				} else {
					var checkboxes = [];
					checkboxes = [...this.state.leadwithemailcheck, ...this.state.leadwithformSubmitcheck,
					...this.state.leadwithoutformSubmitcheck, ...this.state.leadwithphonecheck];

				 if ((this.state.from == "" && this.state.to === "") && this.state.selectedSMSID !== "" && (this.state.csvID != "" || this.state.selectedCSV != "")) {
						console.log("------------------------------- CSV ------------------------------------")
						var current = this;
						if (checkboxes.length > 0) {
							if (this.state.smstype !== "") {
							} else {
								this.setState({
									errors: "Please select SMS type"
								})
								return;
							}
						} else {
							this.setState({
								errors: "Please select leadid"
							})
							return
							// notify.show("Select LeadID", "error", 2000)
						}
					}


					else if (this.state.to && this.state.from && this.state.selectedSMSID && (this.state.csvID || this.state.selectedCSV)) {
						console.log('----------------------------Remider sms CSV------------------------')
						if (this.state.reminder && this.state.to !== '' && this.state.from !== "" && this.state.selectedSMSID !== "") {
							if (this.state.smstype !== "") {
							} else {
								this.setState({
									errors: "Please select SMS type"
								})
								return;
							}
						} else {
							this.setState({
								errors: "Please select Reminder Status"
							});
							return;
						}
					}

					else if (this.state.selectedSMSID === "") {
						this.setState({
							errors: "Please select SMS template"
						})
						return;
					}
					else if (this.state.tabs === "phone" && this.state.leadwithphone.length === 0) {
						this.setState({
							errors: "Please select leadID"
						})
						return;

					} else if (this.state.tabs === "withemail" && this.state.leadwithemail.length === 0) {
						this.setState({
							errors: "Please select leadID"
						})
						return;

					} else if (this.state.tabs === "withsignature" && this.state.leadwithformSubmit.length === 0) {
						this.setState({
							errors: "Please select leadID"
						})
						return;

					} else if (this.state.tabs === "withoutsignature" && this.state.leadwithoutformSubmit.length === 0) {
						this.setState({
							errors: "Please select leadID"
						})
						return;

					}
					else if (this.state.to === "" || this.state.from === "") {

						this.setState({
							errors: "Please select To/From"
						})
						return;
					}

					this.setState({
						errors: ""
					})

					this.openSMSModal();

				}
			}
			else if (data.code === 404) {
				var checkboxes = [];
				checkboxes = [...this.state.leadwithemailcheck, ...this.state.leadwithformSubmitcheck,
				...this.state.leadwithoutformSubmitcheck, ...this.state.leadwithphonecheck];

			 if ((this.state.from == "" && this.state.to === "") && this.state.selectedSMSID !== "" && (this.state.csvID != "" || this.state.selectedCSV != "")) {
					console.log("------------------------------- CSV ------------------------------------")
					var current = this;
					if (checkboxes.length > 0) {
						if (this.state.smstype !== "") {
						} else {
							this.setState({
								errors: "Please select SMS type"
							})
							return;
						}
					} else {
						this.setState({
							errors: "Please select leadid"
						})
						return
						// notify.show("Select LeadID", "error", 2000)
					}
				}
				else if (this.state.to && this.state.from && this.state.selectedSMSID && (this.state.csvID || this.state.selectedCSV)) {
					console.log('----------------------------Remider sms CSV------------------------')
					if (this.state.reminder && this.state.to !== '' && this.state.from !== "" && this.state.selectedSMSID !== "") {
						if (this.state.smstype !== "") {
						} else {
							this.setState({
								errors: "Please select SMS type"
							})
							return;
						}
					} else {
						this.setState({
							errors: "Please select Reminder Status"
						});
						return;
					}
				}
				else if (this.state.selectedSMSID === "") {
					this.setState({
						errors: "Please select SMS template."
					})
					return;
				}
				else if (this.state.tabs === "phone" && this.state.leadwithphone.length === 0) {
					this.setState({
						errors: "Please select leadID"
					})
					return;

				} else if (this.state.tabs === "withemail" && this.state.leadwithemail.length === 0) {
					this.setState({
						errors: "Please select leadID"
					})
					return;

				} else if (this.state.tabs === "withsignature" && this.state.leadwithformSubmit.length === 0) {
					this.setState({
						errors: "Please select leadID"
					})
					return;

				} else if (this.state.tabs === "withoutsignature" && this.state.leadwithoutformSubmit.length === 0) {
					this.setState({
						errors: "Please select leadID"
					})
					return;

				}
				else if (this.state.to === "" || this.state.from === "") {
					this.setState({
						errors: "Please select To/From."
					})
					return;
				}
				this.setState({
					errors: ""
				})
				this.openSMSModal();
			}
		}).catch(err => {
			console.log(err);
			this.setState({
			})
		})
	}

	sendSMS(e) {


		this.closeSMSModal();
		var checkboxes = [];
		checkboxes = [...this.state.leadwithemailcheck, ...this.state.leadwithformSubmitcheck,
		...this.state.leadwithoutformSubmitcheck, ...this.state.leadwithphonecheck];

		// -------------------------------------------send by CSV checkboxes  sms and emai ------------------------------

		 if ((this.state.from == "" && this.state.to === "") && this.state.selectedSMSID !== "" && (this.state.csvID != "" || this.state.selectedCSV != "")) {
			console.log("------------------------------- CSV ------------------------------------")
			var current = this;
			if (checkboxes.length > 0) {
				if (this.state.smstype !== "") {
					let body = {
						leads: checkboxes,
						templateId: current.state.selectedSMSID,
						csvId: current.state.selectedCSV ? current.state.selectedCSV : current.state.csvID,
						smsType: current.state.smstype
						// links: this.state.link
					}
					console.log(body, "body")
					current.formService.sendSMScsv(body).then(data => {
						if (data.code == 404) {
							notify.show(data.message, "error", 2000);
						}
						else if (data.code === 200) {
							current.setState({
								leadwithphonecheck: [],
								leadwithemailcheck: [],
								leadwithformSubmitcheck: [],
								leadwithoutformSubmitcheck: [],
								selectedSMSID: '',
								// selectedCSV:'',
								link: [],
								errors: '',
								smstype: ''
							});
							$("#smstype").val($("#target option:first").val());

							notify.show(data.message, "success", 2000);

							$(".leadphone").prop("checked", false);
							$(".leademail").prop("checked", false);
							$(".leadwith").prop("checked", false);
							$(".leadwithout").prop("checked", false);

							$(".leadphoneall").prop("checked", false);
							$(".leademailall").prop("checked", false);
							$(".leadwithall").prop("checked", false);
							$(".leadwithoutall").prop("checked", false);
							this.showLeadsListOnRefereshPage();
						}
					}).catch(err => {
						console.log(err)
					})


				} else {
					this.setState({
						errors: "Please select SMS type"
					})
				}
			} else {
				this.setState({
					errors: "Please select leadid"
				})
				// notify.show("Select LeadID", "error", 2000)
			}
		}



		// -----------------------------------------to from done sms and email template by CSV ------------------------------

		else if (this.state.to && this.state.from && this.state.selectedSMSID && (this.state.csvID || this.state.selectedCSV)) {
			console.log('----------------------------Remider sms CSV------------------------')
			if (this.state.reminder && this.state.to !== '' && this.state.from !== "" && this.state.selectedSMSID !== "") {
				if (this.state.smstype !== "") {
					let bodyReminder = {
						to: this.state.to,
						from: this.state.from,
						option: this.state.reminder,
						csvId: this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID,
						templateId: this.state.selectedSMSID,
						links: this.state.link,
						smsType: this.state.smstype
					}
					this.formService.sendSMScsv(bodyReminder).then(data => {
						if (data.code == 404) {
							notify.show(data.message, "error", 2000);
						}
						else if (data.code === 200) {
							$("#smstype").val($("#target option:first").val());
							this.setState({
								smstype: '',
								to: '',
								from: '',
								reminder: '',
								selectedSMSID: '',
								link: [],
								// selectedCSV: ''
							})
							$("#reminderj").val($("#target option:first").val());

							notify.show(data.message, "success", 3000);
							this.showLeadsListOnRefereshPage();
						}

					}).catch(err => {
						console.log(err)
					})
				} else {
					this.setState({
						errors: "Please select SMS type"
					})
				}
			} else {
				this.setState({
					errors: "Please select Reminder Status"
				})
			}
		}


		else if (this.state.selectedSMSID === "") {
			this.setState({
				errors: "Please select SMS template"
			})
		}

		else if (this.state.tabs === "phone" && this.state.leadwithphone.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withemail" && this.state.leadwithemail.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withsignature" && this.state.leadwithformSubmit.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		} else if (this.state.tabs === "withoutsignature" && this.state.leadwithoutformSubmit.length === 0) {
			this.setState({
				errors: "Please select leadID"
			})
			return;

		}


		else if (this.state.to === "" || this.state.from === "") {

			this.setState({
				errors: "Please select To/From"
			})
		}
	}

	bulkPdfDawnload(e) {

		e.preventDefault();
		var leads = this.state.leadwithphone;
		console.log(leads);
		for (var i = 0; leads.length > i; i++) {
			console.log(leads[i].pdf_url);
			if (leads[i].pdf_url != null && leads[i].pdf_url != '' || leads[i].pdf_url != undefined) {
			
				var a = document.createElement('a');
				a.href = leads[i].pdf_url;
				a.download = 'very_important_report.pdf';
				a.click();
			}
		}
	}

	download_file(fileURL, fileName) {
		// for non-IE
		if (!window.ActiveXObject) {
			var save = document.createElement('a');
			save.href = fileURL;
			save.target = '_blank';
			var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
			save.download = fileName || filename;
			if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href;
				// window event not working here
			} else {
				var evt = new MouseEvent('click', {
					'view': window,
					'bubbles': true,
					'cancelable': false
				});
				save.dispatchEvent(evt);
				(window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
		}

		// for IE < 11
		else if (!!window.ActiveXObject && document.execCommand) {
			var _window = window.open(fileURL, '_blank');
			_window.document.close();
			_window.document.execCommand('SaveAs', true, fileName || fileURL)
			_window.close();
		}
	}


	handleImageChange = e => {
		e.preventDefault();
		let fileSize = e.target.files[0] ? e.target.files[0].size : "";
		if (fileSize !== "") {
			var _size = Math.floor(fileSize / 1000000);
			if (_size > 70) {
				this.setState({
					errorsModal1: e.target.files[0].name + " is too large, maximum file size is 70MB.",
					file: File
				})
				return
			}
		}



		var current = this;
		current.setState({
			errorsModal1: "",
			file: e.target.files[0]
		});
	}

	submit() {
		var current = this;
		var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;

		const { file, csvName } = this.state;

		if (csvName === '') {
			this.setState({ errorsModal1: 'Name field is required.' });
			return;
		}else if(format.test(csvName)){
			this.setState({ errorsModal1: 'Only Alphabets and Integer value allow for CSV Name' });
			return;
		}
		else if (file.name === 'File') {
			this.setState({ errorsModal1: 'Please select CSV file to upload.' });
			return;
		}
		else if (this.state.link.length === 0) {
			this.setState({ errorsModal1: 'Please add at least one link.' });
			return;
		}
		else if (this.state.link[0] === "") {
			this.setState({ errorsModal1: 'Please add at least one link.' });
			return;
		}
		else {

			if (this.state.link.length > 0) {
				for (var i = 0; this.state.link.length > i; i++) {

					if (this.state.link[i] == '') {
						this.setState({ errorsModal1: 'Remove empty link fields' });
						return
					}
				}
			}
			this.setState({
				errorsModal1: ""
			});
			console.log(this.state.link, '-------------------------------------Link')

			console.log(this.state.linkExpiryDate);

			let body = new FormData();
			body.append('name', this.state.csvName);
			body.append('isHeader', this.state.isHeader);
			body.append('file', this.state.file);
			body.append('link[]', this.state.link);
			body.append('linkExpiryDate', this.state.linkExpiryDate);

			if (this.state.isHeader === true) {
				this.setState({
					csvLoaderButton: true
				})
				this.formService.uploadFormCSV(body).then(data => {
					console.log("data", data)
					if (data.code === 200) {
						this.setState({
							file: File,
							// existingCSV: [],
							selectedCSV: '',
							csvName: '',
							errorsModal1: '',
							csvID: data.data._id
						}, () => {
							let body = {
								csvId: this.state.csvID
							}
							current.formService.postFormCSVid(body).then(data1 => {

								console.log(data1, "data1");
								if (data1.code === 200) {
									current.setState({
										uploadedFileHeader: data1.data[0],
										csvLoaderButton: false
									}, () => {
										current.onOpenModal();
										current.onCloseImportCSV();
									})
								}
							}).catch(err => {

								this.setState({
									csvLoaderButton: false
								}
								)

								console.log(err)
							})

						})
					}
					if (data.code === 404) {
						this.setState({
							csvLoaderButton: false,
							errorsModal1: data.message
						})
					}
				}).catch(err => {
					this.setState({
						csvLoaderButton: false
					}
					)
					console.log(err)
				})
			} else {
				this.setState({
					csvLoaderButton: true
				})
				this.formService.uploadFormCSV(body).then(data => {
					if (data.code === 200) {

						current.setState({
							isHeaderModal: true,
							ImportCSVModal: false,
							csvLoaderButton: false
						})
					} else if (data.code === 404) {
						this.setState({
							csvLoaderButton: false,
							errorsModal1: data.message
						})
					}
				}).catch(err => {
					console.log(err);
					this.setState({
						csvLoaderButton: false
					})
				});


			}
		}
	}

	useExistingCSV = async () => {
		var current = this;
		this.getOpenLinks(this.state.selectedCSV);
		await this.setState({
			start:0,
			limit: 50,
			csvLoaderButton: true
		})
		if (this.state.selectedCSV === "") {
			this.setState({
				errorsModal1: "Please select existing CSV file to populate data.",				
				csvLoaderButton: false
			})
		}
		else {

			this.formService.useExisting(current.state.selectedCSV, current.state.start, current.state.limit).then(data => {

				if (data.code === 200) {
					debugger
					this.setState({
						selectedCsvName: data.csvName,
						leadwithemail: data.leadsWithEmail,
						leadwithphone: data.leadsWithPhone,
						leadwithformSubmit: data.leadsWithFormSubmit,
						leadwithoutformSubmit: data.leadsWithoutFormSubmit,
						dataCounts: data.dataCounts,
						csvID: '',
						csvName: "",
						errorsModal1: '',
						errors: "",
						smssentTo: data.dataCounts[0].smsSentTo,
						emailsentTo: data.dataCounts[0].emailSentTo,
						signedleadsTo: data.dataCounts[0].formSubmittedCounts,
						csvLoaderButton: false,

						// on response true , so that fetchmore function call 
						hasMorephone:true,
						hasMoreemail:true,
						hasMorewith:true,
						hasMorewithout:true,

					} ,() =>{
						if(this.state.leadwithphone.length ==0 || data.dataCounts[0].with_phone === data.leadsWithPhone.length){
							this.setState({
								hasMorephone :false
							})
						}
						
						if(this.state.leadwithemail.length ==0|| data.dataCounts[0].with_email === data.leadsWithEmail.length){
							this.setState({
								hasMoreemail :false
							})
						}
						
						if(this.state.leadwithformSubmit.length ==0|| data.dataCounts[0].with_form_submit === data.leadsWithFormSubmit.length){
							this.setState({
								hasMorewith :false
							})
						}
						
						if(this.state.leadwithoutformSubmit.length ==0|| data.dataCounts[0].without_form_submit === data.leadsWithoutFormSubmit.length){
							this.setState({
								hasMorewithout :false
							})
						}
					});
					notify.show('Leads collection has been loaded successfully.', "success", 2000);
					current.onCloseImportCSV();
					localStorage.removeItem('FormbodyselectedCSV');
					localStorage.removeItem('FormbodyCSVID');
					
					localStorage.setItem('FormbodyselectedCSV', JSON.stringify(current.state.selectedCSV));
				}
				if (data.code === 404) {
					this.setState({
						csvLoaderButton: false,

					})
				}
			}).catch(err => {
				this.setState({
					csvLoaderButton: false
				})
				console.log(err)
			})
		}



	}

	useExisting() {
		var current = this;
		if (current.state.selectedCSV !== '') {
			this.setState({ errorsModal1: 'Please select existing CSV' });
			return;
		}
		else {
			console.log("donw")
			let body = {
				csvId: current.state.selectedCSV
			}
			current.formService.importCSV(body).then(data => {
				console.log(data, "data");
				this.setState({
					csvName: data.data.name,
					file: ''

				})
			}).catch(err => {
				console.log(err)
			})
		}
	}

	search(changeTab = false) {

		var current = this;
		let reg = /^\d{4}\/\d{1,2}\/\d{1,2}$/;
		if (this.state.errors === "Input Only +ve Number") {

			return;
		}
		else if (this.state.start === '') {
			this.setState({
				errors: "Please select Start Number"
			});
			return;
		}

		else if (this.state.limit === '') {
			this.setState({
				errors: "Please select Limit Number"
			});
			return;
		} else if (this.state.start > this.state.limit) {
			this.setState({
				errors: "Start should be less then the limit"
			});
			return;
		}

		else if (this.state.csvID !== "" || this.state.selectedCSV !== "") {
			this.setState({
				csvLoader: true,
			});
			this.formService.useExisting(current.state.selectedCSV ? current.state.selectedCSV : current.state.csvID, current.state.start, current.state.limit).then(data => {

				if (data.code === 200) {

					this.setState({
						leadwithemail: data.leadsWithEmail,
						leadwithphone: data.leadsWithPhone,
						leadwithformSubmit: data.leadsWithFormSubmit,
						leadwithoutformSubmit: data.leadsWithoutFormSubmit,
						dataCounts: data.dataCounts,
						csvLoader: false,
						// csvID: '',
						errorsModal1: '',
						errors: "",

						//to close loader on search of fetchMoreData
						hasMoreemail: false,
						hasMorephone: false,
						hasMorewith: false,
						hasMorewithout: false


					})
					current.onCloseImportCSV();
					if (this.state.csvID) {
						localStorage.removeItem('FormbodyCSVID');
						localStorage.setItem('FormbodyCSVID', JSON.stringify(current.state.csvID));
					} else if (this.state.selectedCSV) {
						localStorage.removeItem('FormbodyselectedCSV');
						localStorage.setItem('FormbodyselectedCSV', JSON.stringify(current.state.selectedCSV));
					}
				}
			}).catch(err => {
				console.log(err)
				this.setState({ csvLoader: false });
			})

		}
	}

	tabsClick(val) {
		if (val === 'phone') {

			this.setState({
				tabs: "phone"
			})
		}
		if (val === 'withemail') {
			this.setState({
				tabs: "withemail"
			})

		}
		if (val === 'withsignature') {
			this.setState({
				tabs: "withsignature"
			})

		}
		if (val === 'withoutsignature') {
			this.setState({
				tabs: "withoutsignature"
			})
		}
	}

	changePhoneCheckBox(e) {
		var options = [];
		options = this.state.leadwithphonecheck
		let index;
		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		this.setState({ leadwithphonecheck: options })
	}

	selectAllwithPhone(e) {
		if (e.target.checked) {
			let array = []
			for (let index = 0; index < this.state.leadwithphone.length; index++) {
				let key = this.state.leadwithphone[index].leadid;
				array.push(key);
			}
			this.setState({
				leadwithphonecheck: array
			});
			$(".leadphone").prop("checked", true)
		} else {
			this.setState({
				leadwithphonecheck: []
			})
			$(".leadphone").prop("checked", false)
		}
	}

	changeEmailCheckBox(e) {
		const options = this.state.leadwithemailcheck
		let index;
		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		this.setState({ leadwithemailcheck: options })
	}

	selectAllwithEmail(e) {
		if (e.target.checked) {
			let array = []
			for (let index = 0; index < this.state.leadwithemail.length; index++) {
				let key = this.state.leadwithemail[index].leadid;
				array.push(key);
			}
			this.setState({
				leadwithemailcheck: array
			});
			$(".leademail").prop("checked", true)

		} else {
			this.setState({
				leadwithemailcheck: []
			});
			$(".leademail").prop("checked", false)
		}
	}

	changeSignatureCheckBox(e) {
		const options = this.state.leadwithformSubmitcheck
		let index;
		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		this.setState({ leadwithformSubmitcheck: options })
	}

	selectAllwithSignature(e) {
		if (e.target.checked) {
			let array = []
			for (let index = 0; index < this.state.leadwithformSubmit.length; index++) {
				let key = this.state.leadwithformSubmit[index].leadid;
				array.push(key);
			}
			this.setState({
				leadwithformSubmit: array
			});
			$(".leadwith").prop("checked", true)

		} else {
			this.setState({
				leadwithformSubmitcheck: []
			});
			$(".leadwith").prop("checked", false)

		}
	}

	changeWihtoutSignatureCheckBox(e) {
		const options = this.state.leadwithoutformSubmitcheck
		let index;
		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		this.setState({ leadwithoutformSubmitcheck: options })
	}

	selectAllwithoutSignature(e) {
		if (e.target.checked) {
			let array = []
			for (let index = 0; index < this.state.leadwithoutformSubmit.length; index++) {
				let key = this.state.leadwithoutformSubmit[index].leadid;
				array.push(key);
			}
			this.setState({
				leadwithoutformSubmitcheck: array
			});
			$(".leadwithout").prop("checked", true)

		} else {
			this.setState({
				leadwithoutformSubmitcheck: []
			});
			$(".leadwithout").prop("checked", false)

		}
	}

	createJson(event) {
		const selectedIndex = event.target.options.selectedIndex;
		let key = event.target.options[selectedIndex].getAttribute('id');
		let value = event.target.value;
		let body = {
			"key": key,
			"value": value
		}
		let array = this.state.jsonCreatedDropdown;
		let objIndex = array.findIndex((obj => obj.key == key));
		if (objIndex !== -1) {
			array[objIndex].key = key;
			array[objIndex].value = value
		} else {
			array.push(body);

		}
		this.setState({
			array
		});
	}

	checkIsPositive(e) {
		let amount = parseFloat(e.target.value);
		// if (isNaN(amount) || amount < 0 || e.target.value[0] === '0') {

		if (isNaN(amount) || amount < 0) {
			amount = '';

		}
		if (amount === '') {
			this.setState({
				errors: "Please enter only positive value.",
				[e.target.name]: amount,

			})
		} else {
			this.setState({
				[e.target.name]: amount,
				errors: ""
			})

		}

	}

	_headerRadio(e) {
		this.setState({
			isHeader: !this.state.isHeader
		})
	}

	withoutHeaderMap() {
		var current = this;
		let body = new FormData();

		body.append('name', this.state.csvName);
		body.append('isHeader', this.state.isHeader);
		body.append('file', this.state.file);
		body.append('link[]', this.state.link);
		body.append('linkExpiryDate', this.state.linkExpiryDate);

		this.setState({
			csvLoaderButton: true
		})
		this.formService.uploadFormCSV(body).then(data => {
			if (data.code === 200) {
				this.setState({
					file: File,
					existingCSV: [],
					selectedCSV: '',
					csvName: '',
					errorsModal1: '',
					csvID: data.data._id,

				}, () => {
					let body = {
						csvId: current.state.csvID
					}

					current.formService.postFormCSVid(body).then(data1 => {
						console.log(data1, "data1");
						if (data1.code === 200) {

							current.setState({
								uploadedFileHeader: data1.data[0],
								csvLoaderButton: false
							}, () => {
								current.isHeaderOnCloseModal();
								current.onCloseImportCSV();
								current.onOpenModal();
								}
							)
						}
					}).catch(err => {
						this.setState({
							csvLoaderButton: false
						})
						console.log(err)
					})

				})
			}
		}).catch(err => {
			this.setState({
				csvLoaderButton: false
			})
			console.log(err)
		})
	}

	withHeaderMap() {
		console.log(this.state.jsonCreatedDropdown, "json created")

		let body = {
			headers: this.state.jsonCreatedDropdown,
			csvId: this.state.csvID
		}
		console.log(body, "map header")
		// this.onCloseModal();
		// this.OnClosecsvCreatedModal();
		this.setState({
			csvLoader: true,
			openModal: false,
			// jsonCreatedDropdown: 
			leadwithphone: [],
			leadwithemail: [],
			leadwithformSubmit: [],
			leadwithoutformSubmit: []
		})
		this.formService.createFormLead(body).then(data => {
			console.log(data, "---------------------------------------------------------")
			if (data.code === 404) {
				// notify.show(data.message, "error", 2000);
				$('.modal-body').animate({ 'scrollTop': 0 }, 'slow')
				this.setState({
					csvError: data.message,
					csvLoader: false,
					openModal: true

				})
				return
			}
			if (data.code === 200) {
				this.onCloseModal();
				this.OnClosecsvCreatedModal();
				this.onCSVcreated();
				this.setState({
					csvLoader: false,
					csvError: "",
					jsonCreatedDropdown: [],
				})
			}
		}).catch(err => {
			this.setState({
				csvLoader: false
			})
			console.log(err)
		})
	}

	finish(e) {

		e.preventDefault();
		this.setState({
			csvLoaderButton: true
		})
		console.log(this.state.csvID, "csv id")
		var current = this;
		this.formService.getFormCsvCollection(this.state.csvID, this.state.start, this.state.limit).then(data => {

			console.log(data, '=================================================================')
			if (data.code === 200) {
				localStorage.removeItem('FormbodyselectedCSV');
				localStorage.removeItem('FormbodyCSVID');
				localStorage.setItem('FormbodyCSVID', JSON.stringify(current.state.csvID));
				this.setState({
					csvCreatedModal: false,
					csvLoaderButton: false,
					leadwithemail: data.leadsWithEmail,
					leadwithphone: data.leadsWithPhone,
					leadwithformSubmit: data.leadsWithFormSubmit,
					leadwithoutformSubmit: data.leadsWithoutFormSubmit,
					dataCounts: data.dataCounts,

					link: [],
//so that fetchmore function can call 
					hasMorephone :true,
					hasMoreemail :true,
					hasMorewith :true,
					hasMorewithout :true,
				}, () =>{
					if(this.state.leadwithphone.length ==0 || data.dataCounts[0].with_phone === data.leadsWithPhone.length){
						this.setState({
							hasMorephone :false
						})
					}
					
					if(this.state.leadwithemail.length ==0|| data.dataCounts[0].with_email === data.leadsWithEmail.length){
						this.setState({
							hasMoreemail :false
						})
					}
					
					if(this.state.leadwithformSubmit.length ==0|| data.dataCounts[0].with_form_submit === data.leadsWithFormSubmit.length){
						this.setState({
							hasMorewith :false
						})
					}
					
					if(this.state.leadwithoutformSubmit.length ==0|| data.dataCounts[0].without_form_submit === data.leadsWithoutFormSubmit.length){
						this.setState({
							hasMorewithout :false
						})
					}
				})
				this.getAllCSV(); // its listing in dropdown in useExisting
			}
		}).catch(err => {
			this.setState({
				csvLoaderButton: false
			})
			console.log(err)
		})
	}


	emptyError() {
		this.setState({
			errorsModal1: ''
		})
	}

	// --------------------------------------------------------Links --------------------------------
	changeSchema1(e, index) {
		var arrayOfObjs = this.state.link;
		arrayOfObjs[index] = e.currentTarget.value;
		this.setState({ link: arrayOfObjs });
	}

	addTextfield2(e) {
		e.preventDefault();
		var inputs = this.state.link;

		inputs.push('');

		this.setState({
			inputs
		})
	}

	removeTextField(e, indexValue) {
		e.preventDefault();
		var array123 = [];
		array123 = this.state.link;

		array123.splice((indexValue), 1);
		if (array123.length > 0) {
			$("#afterHide").hide();

		} else {
			$("#afterHide").show();
		}

		this.setState({
			link: array123.slice()
		});
	}

	removeTextField2(e, indexValue) {
		e.preventDefault();
		var array123 = [];
		array123 = this.state.link;

		array123.splice((indexValue), 1);
		this.setState({
			link: array123.slice(),

		});
	}


	//------------------------------------------- Remainder Status -----------------------------
	// reminder(e) {
	// 	const selectedIndex = e.target.options.selectedIndex;
	// 	//let key = e.target.options[selectedIndex].getAttribute('name');
	// 	let val = Number(e.target.value);
	// 	this.setState({
	// 		reminder: val
	// 	})
	// }

	reminder(e) {
		//console.log("value", e.target.value);
		let selectedIndex = e.target.options.selectedIndex;
		if(e.target.value == '1' || e.target.value == '2')
		{
			$('.form-group.email-template').show();
			$('.form-group.sms-template').hide();
		}
		else if(e.target.value == '3' || e.target.value == '4')
		{
			$('.form-group.sms-template').show();
			$('.form-group.email-template').hide();
		}
		else{
			// $('.form-group.sms-template').hide();
			// $('.form-group.email-template').hide();
			$('.form-group.sms-template').show();
			$('.form-group.email-template').show();
		}
		//let key = e.target.options[selectedIndex].getAttribute('name');
		let val = Number(e.target.value);
		this.setState({
			reminder: val
		})
	}


	reset() {
		this.setState({
			csvID: "",
			selectedCSV: "",
			leadwithformSubmit: [],
			leadwithoutformSubmit: [],
			leadwithphone: [],
			leadwithemail: [],
			leadwithformSubmitcheck: [],
			leadwithoutformSubmitcheck: [],
			leadwithphonecheck: [],
			leadwithemailcheck: [],
			from: "",
			to: "",
			reminder: "",
			selectedEmailID: "",
			selectedSMSID: "",
			errors: "",
			dataCounts: [],
			isLoading: false,
			emailsentTo: '',
			smssentTo: '',
			signedleadsTo: '',
			//to set loader in table of listing 
			start : 0,
			limit : 50,
			isLoading :false,
			hasMoreemail: false,
			hasMorephone: false,
			hasMorewith: false,
			hasMorewithout: false
		})
		localStorage.removeItem('FormbodyselectedCSV');
		localStorage.removeItem('FormbodyCSVID');
	}

	resetOnCloseModal() {
		this.setState({
			// csvID: "",
			selectedCSV: "",
			uploadedFileHeader: [],
			from: "",
			to: "",
			reminder: "",
			selectedEmailID: "",
			selectedSMSID: "",
			errors: "",
			dataCounts: [],
			isLoading: false,
			emailsentTo: '',
			smssentTo: '',
			signedleadsTo: ''
		})
	}



	editCSVrecord(e, leadid) {

		console.log(leadid, this.state.csvID, "csvid -----------------------------------------------")
		console.log("----------------------Edit  CSV-------------------------")

		if (this.state.selectedCSV) {
			this.props.history.push({
				pathname: '/editFormCsv/' + leadid + '/' + this.state.selectedCSV,
				// state: { editEmail: data.data[0]}
			})
		} else if (this.state.csvID) {
			this.props.history.push({
				pathname: '/editFormCsv/' + leadid + '/' + this.state.csvID,
				// state: { editEmail: data.data[0]}
			})
		}
	}

	filterData(e) {
		e.preventDefault();

		if (this.state.filter_email === "" && this.state.zip === "" && this.state.filter_phone === "" && this.state.customerName === "") {
			// this.setState({
			// 	filterError: "Search Field is Empty"
			// })
		} else {
			this.setState({
				csvLoaderButton: true,
			})
			let body = {
				filter_email: this.state.filter_email,
				filter_phone: this.state.filter_phone,
				customerName: this.state.customerName,
				zip: this.state.zip
			}
			if (this.state.selectedCSV !== "" || this.state.csvID !== "") {
				this.formService.filtersearchform(this.state.csvID ? this.state.csvID : this.state.selectedCSV, body).then(data => {
					console.log(data, "data");
					debugger
					if (data.code === 200) {
						if (data.dataCounts[0].totalCount === data.leadsWithEmail.length) {
							this.setState({
								hasMoreemail: false
							})
						}
						if (data.dataCounts[0].totalCount === data.leadsWithPhone.length) {
							this.setState({
								hasMorephone: false
							})
						}
						if (data.dataCounts[0].totalCount === data.leadsWithFormSubmit.length) {
							this.setState({
								hasMorewith: false
							})
						}
						if (data.dataCounts[0].totalCount === data.leadsWithoutFormSubmit.length) {
							this.setState({
								hasMorewithout: false
							})
						}
							
							
						this.setState({
							//empty state
							csvLoaderButton: false,
							leadwithemail: data.leadsWithEmail,
							leadwithphone: data.leadsWithPhone,
							leadwithformSubmit: data.leadsWithFormSubmit,
							leadwithoutformSubmit: data.leadsWithoutFormSubmit,
							dataCounts: data.dataCounts
						})
					}
				}).catch(err => {
					this.setState({
						csvLoaderButton: false
					})
					console.log(err)
				})
			}
			

		}
	}

	fetchMoreDataWithPhone = () => {
			var that = this;	
			console.log(this.state.limit)
			this.setState({
				hasMorephone: true,
				limit : that.state.limit + 50,
			})
		
			// setTimeout(() => {
				that.formService.getFormCsvCollection(that.state.csvID ? that.state.csvID : that.state.selectedCSV, that.state.start, that.state.limit).then(data => {
					if (data.code === 200) {
						if(data.dataCounts[0].with_phone ===data.leadsWithPhone.length){
							console.log("yes")
							that.setState({
								hasMorephone : false
							})
						}
						that.setState({
							csvCreatedModal: false,
							leadwithemail: data.leadsWithEmail,
							leadwithphone: data.leadsWithPhone,
							leadwithformSubmit: data.leadsWithFormSubmit,
							leadwithoutformSubmit: data.leadsWithoutFormSubmit,
							dataCounts: data.dataCounts,
							link: []
						})
					}
				}).catch(err => {
					that.setState({
						isLoading: false,
					})
					console.log(err)
				})
			// }, 1000);
		
		

	}

	fetchMoreDataWithEmail = () => {
				var that = this;
				this.setState({
					hasMoreemail: true,
					limit: that.state.limit + 50,
				})
				// setTimeout(() => {
				that.formService.getFormCsvCollection(that.state.csvID ? that.state.csvID : that.state.selectedCSV, that.state.start, that.state.limit).then(data => {
					if (data.code === 200) {
						if (data.dataCounts[0].with_email === data.leadsWithEmail.length) {
							that.setState({
								hasMoreemail: false
							})
						}
						that.setState({
							csvCreatedModal: false,
							leadwithemail: data.leadsWithEmail,
							leadwithphone: data.leadsWithPhone,
							leadwithformSubmit: data.leadsWithFormSubmit,
							leadwithoutformSubmit: data.leadsWithoutFormSubmit,
							dataCounts: data.dataCounts,
							link: []
						})
					}
				}).catch(err => {
					that.setState({
						isLoading: false,
					})
					console.log(err)
				})
				// }, 1000);
		
	}

	fetchMoreDataWithSignature = () => {
				var that = this;
				this.setState({
					hasMorewith: true,
					limit: that.state.limit + 50,
				})
				// setTimeout(() => {
				that.formService.getFormCsvCollection(that.state.csvID ? that.state.csvID : that.state.selectedCSV, that.state.start, that.state.limit).then(data => {
					if (data.code === 200) {
						if (data.dataCounts[0].with_form_submit === data.leadsWithFormSubmit.length) {
							that.setState({
								hasMorewith: false
							})
						}
						that.setState({
							csvCreatedModal: false,
							leadwithemail: data.leadsWithEmail,
							leadwithphone: data.leadsWithPhone,
							leadwithformSubmit: data.leadsWithFormSubmit,
							leadwithoutformSubmit: data.leadsWithoutFormSubmit,
							dataCounts: data.dataCounts,
							link: []
						})
					}
				}).catch(err => {
					that.setState({
						isLoading: false,
					})
					console.log(err)
				})
				// }, 1000);

	}

	fetchMoreDataWithoutSignature = () =>{
			var that = this;	
			this.setState({
				hasMorewithout: true,
				limit : that.state.limit + 50
			})
			// setTimeout(() => {
				that.formService.getFormCsvCollection(that.state.csvID ? that.state.csvID : that.state.selectedCSV, that.state.start, that.state.limit).then(data => {
					if (data.code === 200) {
						if(data.dataCounts[0].without_form_submit ===data.leadsWithoutFormSubmit.length){
							that.setState({
								hasMorewithout : false
							})
							
						}
						that.setState({
							csvCreatedModal: false,
							leadwithemail: data.leadsWithEmail,
							leadwithphone: data.leadsWithPhone,
							leadwithformSubmit: data.leadsWithFormSubmit,
							leadwithoutformSubmit: data.leadsWithoutFormSubmit,
							dataCounts: data.dataCounts,
							link: []
						})
					}
				}).catch(err => {
					that.setState({
						isLoading: false,
					})
					console.log(err)
				})
			// }, 1000);

		
		
		
	}


	showHide(e, id) {
		$(e.target).toggleClass('active');
		$("#" + id).slideToggle('slow');
		if(id == 'filter01'){
			$("#filter02").slideUp('slow');
			$(e.target).next().removeClass('active');
		}
		else
		{
			$("#filter01").slideUp('slow');
			$(e.target).prev().removeClass('active');
		}
	}


	deleteLead(e, leadid){
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this file?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(leadid); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	conformDelete(id){
		let csid = this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID;
		console.log(csid)
		this.formService.deleteLead(csid, id).then(data =>{
			if(data.code===200){
				this.showLeadsListOnRefereshPage()
			}
		})
	}
	render() {
		var currentThis = this;
console.log("czxczxcczxccxczxc",this.state.leadwithphone);
console.log(this.state.csvID);
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}

				<Notifications options={{ zIndex: 200, top: '20px' }} />

				{this.state.csvLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<nav className="navbar navbar-default">
					{Object.keys(this.state.errors).length > 0 && this.state.errors ?
						<div className="alert alert-danger">
							{this.state.errors}
						</div>
						: ''}
					<div className="container-fluid">
						<div className="row">
							
							<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
								<ul className="adv-filter-nav">
									<li className="form-group mr-10">
										<label>Start</label>
										<input type="text" className="form-control" placeholder="0" value={this.state.start} name="start" onChange={(e) => this.checkIsPositive(e)} />
									</li>
									<li className="form-group">
										<label>Limit</label>
										<input type="text" className="form-control" placeholder="500" value={this.state.limit} name="limit" onChange={(e) => this.checkIsPositive(e)} />
									</li>
								</ul>
								<ul className="nav navbar-right filter-list">
									<li className="download-csv"  >
										<button onClick={(e) => this.onOpenImportCSV(e)} className="btn btn-green btn-style-md">
											<i className="fa fa-download"></i>&nbsp; Import CSV
								</button>
									</li>
									<input type="file" id="inputFile" className="hidenInput" onChange={(e) => this.handleImageChange(e)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
									{/* <li>
										<label>Selected CSV</label>
										<div className="custom-select">
											
											<select name="selectedCSV"  disabled={true} className="form-control" value={this.state.selectedCSV ? this.state.selectedCSV : this.state.csvID} onChange={(e) =>
														this.handleChange(e)}>
														{this.state.existingCSV.length === 0 ?
															<option value="">No Record Found</option>
															:
															<React.Fragment>
																<option value="">Not Selected</option>
																{this.state.existingCSV.map((val, key) => {
																	return (
																		<option value={val._id} key={key} key={key}>{val.name}</option>
																	)
																})}
															</React.Fragment>
														}
													</select>
										</div>
									</li> */}
									<li><a className="btn-search btn-style-md" onClick={() => this.search()}>Search</a></li>
									<li><a className="btn-search btn-style-md btn " style={{ cursor: "pointer" }} onClick={() => this.reset()}>Reset</a></li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
				<div className="content-page template-tab" id="leadstable">
					<div className="content-header">
						<div className="row">
							
							<div className="col-xs-12">
								{this.state.selectedCsvName? 
										<div className="page-heading mb-10">
											<h4 className="fw400">
												Selected CSV: <b>{this.state.selectedCsvName || "Not Selected"}</b>
											</h4>
										</div>
								:""}
								<div className="accordian-block">
									{
										this.state.sendTheMessages == true ?

											<a  className="btn btn-link" onClick={(e) => this.showHide(e, "filter01")} >
												<i class="fa fa-check-square-o" aria-hidden="true"></i>Launch Campaign <i className="fa fa-angle-down"></i>
											</a>
											:
											''
									}

									{this.state.csvID !== "" || this.state.selectedCSV !== ""  ? 
										<a className="btn btn-link" onClick={(e) => this.showHide(e, "filter02")}>
											<i className="fa fa-filter" aria-hidden="true"></i>Search Records <i className="fa fa-angle-down"></i>
										</a>
									: "" }

									<div className="collapse accordian-expand" id="filter01">
										<div className="row">
											<div className="col-md-12 col-sm-12 col-xs-12">
												<div className="adv-filter">
													<ul className="outer-adv-filter">
														<li className="form-group">
															<label>From</label>
															<input type="text" placeholder="lead Id" className="form-control" value={this.state.from} name="from" onChange={(e) => this.checkIsPositive(e)} />
														</li>
														<li className="form-group">
															<label>To</label>
															<input type="text" placeholder="lead Id" className="form-control" value={this.state.to} name="to" onChange={(e) => this.checkIsPositive(e)} />
														</li>
														<li className="form-group lead-drop">
															<label>Reminder Status</label>
															<div className="dropdown lead-status custom-select">
																<select  id="reminderj" value={this.state.selectedRemainder} onChange={(e) =>
																	this.reminder(e)} >
																	<option value="" name="">Select</option>
																	<option value="1" name="reminderEmail">Sent Emai</option>
																	<option value="2" name="reminderEmail">No Email Sent</option>
																	<option value="3" name="reminderSMS">Sent SMS</option>
																	<option value="4" name="reminderSMS">No SMS Sent</option>
																	{/* <option value="5" name="reminderSMS">Unsigned Leads</option> */}
																</select>
															</div>
														</li>
													</ul>
												</div>
												<div className="clearfix mb-20"></div>
												<div className="add-btn sent-btns">
													<div className="form-group email-template" style={{display:'none'}}>
														<label>Select Email Template</label>
														<div className="dropdown lead-sentdrop custom-select mr-10">
															<select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedEmailID" value={this.state.selectedEmailID} onChange={(e) =>
																this.selectEmailSMS(e)} data-toggle="dropdown">
																<option value="">Select Email Template</option>
																{
																	this.state.emailtemplate.length > 0 ?
																		this.state.emailtemplate.map((val, key) => {
																			return (
																				<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
																			)
																		}) :
																		<option value="">No Record Found</option>
																}
															</select>
														</div>
													</div>
													<div className="form-group email-template mr-20" style={{display:'none'}}>
														<label>Select Email Type</label>
														<div className="dropdown lead-sentdrop custom-select mr-10">
															<select className="btn-dropdown dropdown-toggle btn-dropsent" name="emailtype" value={this.state.emailtype} onChange={(e) =>
																this.selectEmailSMS(e)} data-toggle="dropdown">
																<option value="">Select Email Type</option>
																{
																	this.state.emailtypeList.length > 0 ?
																		this.state.emailtypeList.map((val, key) => {
																			return (
																				<option value={val.type} title={val.name} key={key}>{val.name}</option>
																			)
																		}) :
																		<option value="">No Record Found</option>
																}
															</select>
														</div>

														<div className="sendEmail">
															<a className="btn btn-purple btn-style-md btn-send" onClick={() => this.sendEmail()}><i className="fa fa-paper-plane" aria-hidden="true"></i></a>
														</div>
													</div>
													<div className="form-group sms-template" style={{display:'none'}}>
														<label>Select SMS Template</label>
														<div className="dropdown lead-sentdrop custom-select mr-10">
															<select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedSMSID" value={this.state.selectedSMSID} onChange={(e) =>
																this.selectEmailSMS(e)} data-toggle="dropdown">
																<option value="">Select SMS Template</option>
																{
																	this.state.smstemplate.length > 0 ?
																		this.state.smstemplate.map((val, key) => {
																			return (
																				<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
																			)
																		}) :
																		<option value="">No Record Found</option>
																}
															</select>
														</div>
													</div>
													<div className="form-group sms-template" style={{display:'none'}}>
														<label>Select SMS Type</label>
														<div className="dropdown lead-sentdrop custom-select mr-10">
															<select className="btn-dropdown dropdown-toggle btn-dropsent" id="smstype" name="smstype" value={this.state.smstype} onChange={(e) =>
																this.selectEmailSMS(e)} data-toggle="dropdown">
																<option value="">Select SMS Type</option>
																{
																	this.state.smstypeList.length > 0 ?
																		this.state.smstypeList.map((val, key) => {
																			return (
																				<option value={val.type} title={val.name} key={key}>{val.name.length < 15 ? val.name : val.name.slice(0, 15) + " ..."}</option>
																			)
																		}) :
																		<option value="">No Record Found</option>
																}
															</select>
														</div>
														<div className="sendEmail">
															<a className="btn btn-purple btn-style-md btn-send" onClick={() => this.confirmSendSMS()}><i className="fa fa-paper-plane" aria-hidden="true"></i></a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{this.state.csvID !== "" || this.state.selectedCSV !== ""?
										<div className="collapse accordian-expand" id="filter02">
												<div className="row">
													<div className="col-xs-12">
														<div className="listing-filter-search leadspage">
															<div className="form-group field-list">
																<input type="text" className="form-control" placeholder="First Name" name="customerName" value={this.state.customerName} onChange={(e) => this.onchange(e)} />
															</div>
															<div className="form-group field-list">
																<input type="text" className="form-control" placeholder="Email" name="filter_email" value={this.state.filter_email} onChange={(e) => this.onchange(e)} />
															</div>
															<div className="form-group field-list">
																<input type="text" className="form-control" placeholder="Phone" name="filter_phone" value={this.state.filter_phone} onChange={(e) => this.onchange(e)} />
															</div>
															<div className="form-group field-list">
																<input type="text" className="form-control" placeholder="Zip code" name="zip" value={this.state.zip} onChange={(e) => this.onchange(e)} />
															</div>
															<div className="form-group field-list">
																<button type="button" className="btn-search btn-style-md mr-10" onClick={(e) => this.filterData(e)}>Filter</button>
																<button type="button" className="btn-search btn-style-md" onClick={(e) => this.showLeadsListOnRefereshPage(e)}>Reset</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										: ""
									}
								</div>
							</div>
							<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							
							</div>
							<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
								<div className="add-btn pull-right">
								
								</div>
							</div>
							
						</div>
					</div>
					<div className="tab leads-tabs" role="tabpanel">
						<div className="head-right-wrap mb-20">
							<div className="send-count">
								<span>SMS Sent To:  <b>{this.state.smssentTo || "0"}</b></span>
								<span>Email Sent To:  <b>{this.state.emailsentTo || "0"}</b></span>
								<span>Submit Forms:  <b>{this.state.signedleadsTo || "0"}</b></span>
								<span>Opened Links:  <b>{this.state.openedLinks || "0"}{"%"}</b></span>
								<span>Conversion:  <b>{this.state.conversion || "0"}{"%"}</b></span>
							</div>
						</div>
						{/* <!-- Nav tabs --> */}

						<ul className="nav nav-tabs" role="tablist">
							<li role="presentation" className="active" onClick={this.tabsClick.bind(this, "phone")} >
								<a href="#tab-1" aria-controls="home" role="tab" data-toggle="tab" >
									Leads With Phone
						<div className="count-badge btn-danger">{this.state.dataCounts[0] != null && this.state.dataCounts[0] != undefined ? this.state.dataCounts[0].with_phone == null ? 0 : this.state.dataCounts[0].with_phone : '0'}</div>
								</a>
							</li>
							<li role="presentation" onClick={this.tabsClick.bind(this, "withemail")} >
								<a href="#tab-2" aria-controls="profile" role="tab" data-toggle="tab">
									Leads With Email
						<div className="count-badge btn-danger">{this.state.dataCounts[0] ? this.state.dataCounts[0].with_email == null ? 0 : this.state.dataCounts[0].with_email : '0'}</div>
								</a>
							</li>
							<li role="presentation" onClick={this.tabsClick.bind(this, "withsignature")} >
								<a href="#tab-3" aria-controls="messages" role="tab" data-toggle="tab">
									Leads With Form Submit
						<div className="count-badge btn-danger">{this.state.dataCounts[0] ? this.state.dataCounts[0].with_form_submit == null ? 0 : this.state.dataCounts[0].with_form_submit : '0'}</div>
								</a>
							</li>
							<li role="presentation" onClick={this.tabsClick.bind(this, "withoutsignature")}>
								<a href="#tab-4" aria-controls="messages" role="tab" data-toggle="tab">
									Leads Without Without Submit
						<div className="count-badge btn-danger">{this.state.dataCounts[0] ? this.state.dataCounts[0].without_form_submit == null ? 0 : this.state.dataCounts[0].without_form_submit : '0'}</div>
								</a>
							</li>
						</ul>
						{/* <!-- Tab panes --> */}
						<div className="tab-content tabs">


							{
								this.state.tabs === "phone" ?

									<div role="tabpanel" className="tab-pane fade in active" id="tab-1">



										<div className="table-wrap leads-table">
											<div className="table-responsive" style={{ textAlign: "center" }}>
												<InfiniteScroll
													dataLength={this.state.leadwithphone.length}
													next={this.fetchMoreDataWithPhone}
													hasMore={this.state.hasMorephone}
													loader={
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.hasMorephone}
														/>}
												>
													<table className="table table-hover table-striped table-bordered table-condensed" style={{ }}>
														<thead className="thead-light">
															<tr>
																<th>
																	<div className="table-check">
																		<label className="custom-checkbox">
																			<input type="checkbox" className="leadphoneall" onClick={(e) => this.selectAllwithPhone(e)} />
																			<span className="checkmark"></span>
																		</label>
																	</div>
																</th>

																<th>Lead ID</th>
																<th>Email</th>
																<th>Customer Name</th>
																<th>Bank Name</th>
																<th>Date</th>
																<th>Phone</th>
																<th>SMS Counts</th>
																<th>Email Counts</th>
																<th>Views</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.leadwithphone.length > 0 ?
																	this.state.leadwithphone.map((val, key) => {
																		return (
																			<tr key={key} style={{
																				background: val.sms_send_count >
																					0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
																			}}>
																				<td><div className="table-check">
																					<label className="custom-checkbox">
																						<input type="checkbox" className="leadphone" value={val.leadid} onChange={(e) => this.changePhoneCheckBox(e)} />
																						<span className="checkmark"></span>
																					</label>
																				</div>
																				</td>

																				<td title={val.leadid}>{val.leadid}</td>
																				<td title={val.email}>{val.email}</td>
																				<td title={val.customerName} >{val.customerName}</td>
																				<td title={val.bankName} >{val.bankName} </td>
																				<td title={moment(val.createdAt).format('MM/DD/YYYY')} >{moment(val.createdAt).format('MM/DD/YYYY')}</td>
																				<td title={val.phone1} >{val.phone1}</td>
																				<td title={val.sms_send_count}>{val.sms_send_count || '0'}</td>
																				<td title={val.email_send_count}>{val.email_send_count || '0'}</td>
																				<td>{val.page_visited}</td>
																				<td>
																					<div className="table-option-btns">
																						{
																							this.state.selectedCSV !== "" || this.state.csvID !== "" ?
																								<React.Fragment>
																									<button type="button" onClick={(e) => this.editCSVrecord(e, val.leadid)} className="btn-round btn-edit-icon">
																										<i className="fa fa-edit"></i>
																									</button>
																									<button type="button" onClick={(e) => this.deleteLead(e, val.leadid)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
																										<i className="fa fa-trash"></i>
																									</button>
																									<a 
																									 href={`https://ppisaviours.co.uk/form/view.php?csvId=${this.state.selectedCSV}&leadId=${val._id}`}
																									// href={val.pbaPdf_url} 
																									style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																										<i className="fa fa-link"></i>
																									</a>
																								</React.Fragment>
																							
																								:
																								<button type="button" onClick={(e) => this.editSiteCSVrecord(e, val.leadid)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																						}
																						
																					</div>
																				</td>
																			</tr>
																		)
																	}) :

																	<tr >
																		<td colSpan="13" style={{ textAlign: "center" }}>
																			{this.state.hasMorephone ? "" : this.state.leadwithphone.length === 0? "No Record Found": "No Record Found"}
																		</td>
																	</tr>
															}

														</tbody>
													</table>
												</InfiniteScroll>
											</div>
										</div>
									</div>
									: 
								this.state.tabs === "withemail" ?
									<div role="tabpanel" className="tab-pane fade in active" id="tab-2">
										<div className="table-wrap leads-table">
											<div className="table-responsive" style={{ textAlign: "center" }}>
												<InfiniteScroll
													dataLength={this.state.leadwithemail.length}
													next={this.fetchMoreDataWithEmail}
													hasMore={this.state.hasMoreemail}
													loader={
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.hasMoreemail}
														/>}
												>
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>
																	<div className="table-check">
																		<label className="custom-checkbox">
																			<input type="checkbox" className="leademailall" onClick={(e) => this.selectAllwithEmail(e)} />
																			<span className="checkmark"></span>
																		</label>
																	</div>
																</th>
																<th>Lead ID</th>
																<th>Email</th>
																<th>Customer Name</th>
																<th>Bank Name</th>
																<th>Date</th>
																<th>Phone</th>
																<th>SMS Counts</th>
																<th>Email Counts</th>
																<th>Views</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.leadwithemail.length > 0 ?
																	this.state.leadwithemail.map((val, key) => {
																		return (
																			<tr key={key} style={{
																				background: val.sms_send_count >
																					0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
																			}} >
																				<td>
																					<div className="table-check">
																						<label className="custom-checkbox">
																							<input type="checkbox" className="leademail" value={val.leadid} onChange={(e) => this.changeEmailCheckBox(e)} />
																							<span className="checkmark"></span>
																						</label>
																					</div>
																				</td>
																				<td title={val.leadid} >{val.leadid}</td>
																				<td title={val.email}  >{val.email}</td>
																				<td title={val.customerName} >{val.customerName}</td>
																				<td title={val.bankName} >{val.bankName} </td>
																				<td title={moment(val.createdAt).format('MM/DD/YYYY')} >{moment(val.createdAt).format('MM/DD/YYYY')}</td>																				
																				<td title={val.phone1} >{val.phone1}</td>
																				<td title={val.sms_send_count} >{val.sms_send_count || '0'}</td>
																				<td title={val.email_send_count} >{val.email_send_count || '0'}</td>
																				<td>{val.page_visited}</td>
																				<td>
																					<div className="table-option-btns">
																						{
																							this.state.selectedCSV !== "" || this.state.csvID !== "" ?
																							<React.Fragment>
																								<button type="button" onClick={(e) => this.editCSVrecord(e, val.leadid)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																								<button type="button" onClick={(e) => this.deleteLead(e, val.leadid)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
																									<i className="fa fa-trash"></i>
																								</button>
																								<a 
																									 href={`https://ppisaviours.co.uk/form/view.php?csvId=${this.state.selectedCSV}&leadId=${val._id}`}
																									// href={val.pbaPdf_url} 
																									style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																										<i className="fa fa-link"></i>
																									</a>
																							</React.Fragment>
																								
																								:
																								<button type="button" onClick={(e) => this.editSiteCSVrecord(e, val.leadid, val._id)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																						}
																					</div>
																				</td>
																			</tr>
																		)
																	}) :
																	<tr >
																		<td colSpan="12" style={{ textAlign: "center" }}>
																			{this.state.hasMoreemail ? ""
																				: "No Record Found"}
																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</InfiniteScroll>
											</div>
										</div>
									</div>
									: 
								this.state.tabs === "withsignature" ?
									<div role="tabpanel" className="tab-pane fade in active" id="tab-3">
										<div className="table-wrap leads-table">
											<div className="table-responsive" style={{ textAlign: "center" }}>
												<InfiniteScroll
													dataLength={this.state.leadwithformSubmit.length}
													next={this.fetchMoreDataWithSignature}
													hasMore={this.state.hasMorewith}
													loader={
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.hasMorewith}
														/>}
												>
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">


															{this.renderDownloadButtons()}

															<tr>
																<th>
																	<div className="table-check">
																		<label className="custom-checkbox">
																			<input type="checkbox" className="leadwithall" onClick={(e) => this.selectAllwithSignature(e)} />
																			<span className="checkmark"></span>
																		</label>
																	</div>
																</th>
																<th>#</th>
																<th>Lead ID</th>
																<th>Email</th>
																<th>Customer Name</th>
																<th>Bank Name</th>
																<th>Date</th>
																<th>Phone</th>
																<th>SMS Counts</th>
																<th>Email Counts</th>
																<th>Views</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.leadwithformSubmit.length > 0 ?
																	this.state.leadwithformSubmit.map((val, key) => {
																		return (
																			<tr key={key} style={{
																				background: val.sms_send_count >
																					0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
																			}}>
																				<td>
																					<div className="table-check">
																						<label className="custom-checkbox">
																							<input type="checkbox" className="leadwith" value={val.leadid} onChange={(e) => this.changeSignatureCheckBox(e)} />
																							<span className="checkmark"></span>
																						</label>
																					</div>
																				</td>
																				<td title={key + 1}>{key + 1}</td>
																				<td title={val.leadid}>{val.leadid}</td>
																				<td title={val.email}>{val.email}</td>
																				<td title={val.customerName} >{val.customerName}</td>
																				<td title={val.bankName} >{val.bankName} </td>
																				<td title={moment(val.createdAt).format('MM/DD/YYYY')} >{moment(val.createdAt).format('MM/DD/YYYY')}</td>																				
																				<td title={val.phone1} >{val.phone1}</td>
																				<td title={val.sms_send_count}>{val.sms_send_count || '0'}</td>
																				<td title={val.email_send_count}>{val.email_send_count || '0'}</td>
																				<td>{val.page_visited}</td>
																				<td>
																					<div className="table-option-btns">
																						{
																							this.state.selectedCSV !== "" || this.state.csvID !== "" ?
																							<React.Fragment>
																								<button type="button" onClick={(e) => this.editCSVrecord(e, val.leadid, val._id)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																								<button type="button" onClick={(e) => this.deleteLead(e, val.leadid)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
																									<i className="fa fa-trash"></i>
																								</button>
																								<a 
																									 href={`https://ppisaviours.co.uk/form/view.php?csvId=${this.state.selectedCSV}&leadId=${val._id}`}
																									// href={val.pbaPdf_url} 
																									style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																										<i className="fa fa-link"></i>
																									</a>
																							</React.Fragment>
																								
																								:
																								<button type="button" onClick={(e) => this.editSiteCSVrecord(e, val.leadid, val._id)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																						}
																					</div>
																				</td>
																			</tr>
																		)
																	}) :
																	<tr >
																		<td colSpan="12" style={{ textAlign: "center" }}>
																			{this.state.hasMorewith ? ""
																				: "No Record Found"}
																		</td>
																	</tr>
															}

														</tbody>
													</table>
												</InfiniteScroll>
											</div>
										</div>
									</div>

									: 
								this.state.tabs === "withoutsignature" ?
									<div role="tabpanel" className="tab-pane fade in active" id="tab-4">
										<div className="table-wrap leads-table">
											<div className="table-responsive" style={{ textAlign: "center" }}>
												<InfiniteScroll
													dataLength={this.state.leadwithoutformSubmit.length}
													next={this.fetchMoreDataWithoutSignature}
													hasMore={this.state.hasMorewithout}
													loader={
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.hasMorewithout}
														/>}
												>
													<table className="table table-hover table-striped table-bordered table-condensed">
														

														<thead className="thead-light">

														{this.renderDownloadUnsigned()}

															<tr>
																<th>
																	<div className="table-check">
																		<label className="custom-checkbox">
																			<input type="checkbox" className="leadwithoutall" onClick={(e) => this.selectAllwithoutSignature(e)} />
																			<span className="checkmark"></span>
																		</label>
																	</div>
																</th>
																<th>Lead ID</th>
																<th>Email</th>
																<th>Customer Name</th>
																<th>Bank Name</th>
																<th>Date</th>
																<th>Phone</th>
																<th>SMS Counts</th>
																<th>Email Counts</th>
																<th>Views</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.leadwithoutformSubmit.length > 0 ?
																	this.state.leadwithoutformSubmit.map((val, key) => {
																		return (
																			<tr key={key} style={{
																				background: val.sms_send_count >
																					0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
																			}}>
																				<td>
																					<div className="table-check">
																						<label className="custom-checkbox">
																							<input type="checkbox" className="leadwithout" value={val.leadid} onChange={(e) => this.changeWihtoutSignatureCheckBox(e)} />
																							<span className="checkmark"></span>
																						</label>
																					</div>
																				</td>
																				<td title={val.leadid}>{val.leadid}</td>
																				<td title={val.email}>{val.email}</td>
																				<td title={val.customerName} >{val.customerName}</td>
																				<td title={val.bankName} >{val.bankName} </td>
																				<td title={moment(val.createdAt).format('MM/DD/YYYY')} >{moment(val.createdAt).format('MM/DD/YYYY')}</td>																				
																				<td title={val.phone1} >{val.phone1}</td>
																				<td title={val.sms_send_count}>{val.sms_send_count || '0'}</td>
																				<td title={val.email_send_count}>{val.email_send_count || '0'}</td>
																				<td>{val.page_visited}</td>
																				<td>
																					<div className="table-option-btns">
																						{
																							this.state.selectedCSV !== "" || this.state.csvID !== "" ?
																							<React.Fragment>
																							<button type="button" onClick={(e) => this.editCSVrecord(e, val.leadid, val._id)} className="btn-round btn-edit-icon">
																								<i className="fa fa-edit"></i>
																							</button>
																							<button type="button" onClick={(e) => this.deleteLead(e, val.leadid)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
																								<i className="fa fa-trash"></i>
																							</button>
																							<a 
																									 href={`https://ppisaviours.co.uk/form/view.php?csvId=${this.state.selectedCSV}&leadId=${val._id}`}
																									// href={val.pbaPdf_url} 
																									style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																										<i className="fa fa-link"></i>
																									</a>
																							</React.Fragment>
																								
																								:
																								<button type="button" onClick={(e) => this.editSiteCSVrecord(e, val.leadid, val._id)} className="btn-round btn-edit-icon">
																									<i className="fa fa-edit"></i>
																								</button>
																						}
																					</div>
																				</td>
																			</tr>
																		)
																	}) :
																	<tr >
																		<td colSpan="12" style={{ textAlign: "center" }}>
																			{this.state.hasMorewithout ? ""
																				: "No Record Found"}
																		</td>
																	</tr>
															}


														</tbody>
													</table>
												</InfiniteScroll>
											</div>
										</div>
									</div>

									: ""
							}
							
							</div>
					</div>
				</div>
				<Modal open={this.state.ImportCSVModal} onClose={this.onCloseImportCSV}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.onCloseImportCSV()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Import CSV file</h4>
					</div>
					<div className="modal-body">
						<div className="tab leads-tabs" role="tabpanel">
							<ul className="nav nav-tabs" role="tablist">
								<li role="presentation" className="active">
									<a href="#smtp01" onClick={() => this.emptyError()} aria-controls="home" role="tab" data-toggle="tab">Add CSV</a>
								</li>
								<li role="presentation">
									<a href="#smtp02" onClick={() => this.emptyError()} aria-controls="profile" role="tab" data-toggle="tab">Use existing CSV</a>
								</li>
							</ul>
							<div className="tab-content tabs">
								<div role="tabpanel" className="tab-pane fade in active" id="smtp01">
									<div className="add-csv-modal">
										{Object.keys(this.state.errorsModal1).length > 0 && this.state.errorsModal1 ?
											<div className="alert alert-danger">
												{this.state.errorsModal1}
											</div>
										: ''}
										<div className="form-group row">
											<label className="col-md-4"></label>
											<div className="col-md-8">
											<a type="button" href={this.state.path + "/Sample.csv"}  download> <i class="fa fa-download" aria-hidden="true"> </i> Click here to Download Sample file</a>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-4">Name</label>
											<div className="col-md-8">
												<input type="text" className="form-control" value={this.state.csvName} name="csvName" onChange={(e) => this.onchange(e)} />
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-4">File</label>
											<div className="col-md-8">
												<div className="upload-csv">
													<p>{this.state.file ? this.state.file.name !== 'File' ? this.state.file.name : '' : ""}</p>
													<button href="" onClick={(e) => this.showInput(e)} className="btn btn-green btn-style-md">
														<i className="fa fa-download"></i>&nbsp; Import CSV</button>
													<input type="file" accept=".csv" id="showInput" className="hidenInput" onChange={(e) => this.handleImageChange(e)} />
												</div>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-4">Include CSV Headers</label>
											<div className="col-md-8">
												<div className="need-buttons">
													<label className="custom-radio">Yes
														<input type="radio" name="isHeader" value={this.state.isHeader} checked={this.state.isHeader} onChange={(e) => this._headerRadio(e)} />
														<span className="checkmark"></span>
													</label>
													<label className="custom-radio">No
														<input type="radio" name="isHeader" value={this.state.isHeader} checked={!this.state.isHeader} onChange={(e) => this._headerRadio(e)} />
														<span className="checkmark"></span>
													</label>
												</div>
											</div>
										</div>
										<div className="form-group row" id="dynamic">
											<label className="col-md-4">Add Link</label>
											<div className="col-md-8">
												{this.state.link.length != 0 ? this.state.link.map((val, i) => {
													return (
														<div className="field-add" key={i}>
															<div className="field-add-input mr-10" id="dynamic">
																<input type="text" className="form-control" id={i} name="link" value={this.state.link[i]} onChange={(e) => this.changeSchema1(e, i)} />
															</div>
															<div className="field-add-btns" id="minusbutton">
														
																<button className="btn-rem ml-5" onClick={(e) => currentThis.removeTextField2(e, i)}>
																	<i className="fa fa-minus" aria-hidden="true"></i>
																</button>
															</div>
														</div>
													)
												}) : ''}
												<label className="errorsLinks">{this.state.isAdd ? this.state.errorsLink : ''}</label>
												<div className="field-add-btns" id="afterHide1">
													<button className={this.state.isAdd ? 'btn-add link-disable' : 'btn-add'} disabled={this.state.isAdd} onClick={(e) => this.addTextfield2(e)}>
														<i className="fa fa-plus" aria-hidden="true"></i>
													</button>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-4">Link expiry date</label>
											<div className="col-md-8">

												<DatePicker className="form-control"
													selected={this.state.linkExpiryDate}
													onChange={this.onChangedate}
												/>
											</div>
										</div>

									</div>
									<div className="modal-footer">
										{
											this.state.csvLoaderButton === true ?
											<React.Fragment>
											<div className="btn-process">
												<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
												<p>Processing...</p>
											</div>
										</React.Fragment>
												:
												<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.submit()} >Next</button>
										}
									</div>
								</div>
								<div role="tabpanel" className="tab-pane fade" id="smtp02">
									<form className="add-csv-modal">
										{Object.keys(this.state.errorsModal1).length > 0 && this.state.errorsModal1 ?
											<div className="alert alert-danger">
												{this.state.errorsModal1}
											</div>
										: ''}
										<div className="form-group row">
											<label className="col-md-4">Use existing CSV</label>
											<div className="col-md-8">
												<div className="">
													<select name="selectedCSV" className="form-control" value={this.state.selectedCSV} onChange={(e) =>
														this.handleChange(e)}>
														{this.state.existingCSV.length === 0 ?
															<option value="">No Record Found</option>
															:
															<React.Fragment>
																<option value="">Select</option>
																{this.state.existingCSV.map((val, key) => {
																	return (
																		<option value={val._id} key={key} key={key}>{val.name}</option>
																	)
																})}
															</React.Fragment>
														}
													</select>
												</div>
											</div>
										</div>
										
									</form>
									<div className="modal-footer">
										{
											this.state.csvLoaderButton === true ?
											<React.Fragment>
											<div className="btn-process">
												<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
												<p>Processing...</p>
											</div>
										</React.Fragment>
												:
												<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.useExistingCSV()} >
													Next
								</button>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			
				<Modal className="modal modal-style fade scrlTop" open={this.state.openModal} onClose={this.onCloseModal}  closeOnOverlayClick = {false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.onCloseModal()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Headers Mapping </h4>
					</div>
					<div className="modal-body mapping-ul-list">
				
						{Object.keys(this.state.csvError).length > 0 && this.state.csvError ?
							<div className="alert alert-danger">
								{this.state.csvError}
							</div>
							: ''}
						{this.csvData.length > 0 ?
							this.csvData.map((val, key) => {
								return (
									<div className="form-group row" key={key}>
										<label className="col-md-6 col-sm-6 col-xs-12">{val}</label>
										<div className="col-md-6 col-sm-6 col-xs-12"  >
											<select className="form-control" onChange={(e) =>
												this.createJson(e)}>
												<option id={val} value="">Select</option>
												{
													this.state.uploadedFileHeader.map((val2, key) => {
														return (
															<option key={key} id={val} value={val2}>{val2}</option>
														)
													})
												}
											</select>
										</div>
									</div>
								)
							}) : ""
						}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.onCloseModal()}>Close</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.withHeaderMap()}>Save</button>
					</div>
				</Modal>
			
				<Modal className="modal modal-style fade" open={this.state.isHeaderModal} onClose={this.isHeaderOnCloseModal}  closeOnOverlayClick = {false} >
					<div className="modal-header">
						<h4 className="modal-title">CSV Imported </h4>
					</div>
					<div className="modal-body">
						<h2 className="text-center fw600">Headers are created automatically</h2>
					</div>
					<div className="modal-footer">
						{/* <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.isHeaderOnCloseModal()}>Close</button> */}
						{
							this.state.csvLoaderButton === true ?
							<React.Fragment>
							<div className="btn-process">
								<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
								<p>Processing...</p>
							</div>
						</React.Fragment>
								:
								<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.withoutHeaderMap()}>Next</button>
						}
					</div>
				</Modal>
			
				<Modal className="modal modal-style fade" open={this.state.csvCreatedModal} onClose={this.OnClosecsvCreatedModal}  closeOnOverlayClick = {false} >
				
					<div className="modal-body">
						<h1 className="text-center fw600 text-success">Successfully!</h1>
						<h4 className="text-center fw600">CSV has been saved successfully.</h4>
					</div>
					<div className="modal-footer">
						{
							this.state.csvLoaderButton === true ?
							<React.Fragment>
							<div className="btn-process">
								<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
								<p>Processing...</p>
							</div>
						</React.Fragment>
								:
								<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={(e) => this.finish(e)}>Finish</button>
						}
					</div>
				</Modal>
			
				
			
				<Modal className="modal modal-style fade" open={this.state.sendSMSModal} onClose={this.closeSMSModal}>
					<div className="modal-header">
						<h4 className="modal-title">Warning!</h4>
					</div>
					<div className="modal-body">
						<div className="modal-termsandconditions">
							<div className="form-group">
								<p>You have not configured bitly account, Are you sure you want to send SMS without bitlly ?</p>
							</div>

						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.sendSMS(e)} >Send </button>
						<button className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.closeSMSModal(e)} >Close</button>
					</div>
				</Modal>
			</React.Fragment>

		);
	}
}

export default Form;