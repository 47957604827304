import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class OutBoxService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }

 

    async getTwilioOutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/twilio/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }



    async getTwilioWhatsAppOutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/twiliowhatsApp/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async getSmsLocalOutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/smsLocal/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async getmailgunOutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/mailgun/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async getmailchimpOutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/mailchimp/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async getSeven67OutBox(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'outbox/seven67/servicetype', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
  

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default OutBoxService;