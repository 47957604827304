import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import './esignstats.css';
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import $ from 'jquery';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import AllPackageService from '../../services/package.service';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class eSignStats extends Component {
     constructor(props) {
          super(props);
          this.packageService = new AllPackageService();
          this.state ={
            role : "",
            errors: '',
            alluserPackageStats: [],
            alluserPackageStatsDuplicate : [],
            isloading : false,
            usersearch : '',
           
          }

        
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);

        let loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.role === 'subUser') {
            this.props.history.push('*');
        } 
        this.setState({
            role: loginUser.role
        })
        this.userStats();

    }
    
    userStats(){
        this.setState({
            isloading: true
        })
        this.packageService.getuserEsignStats().then(data => {
            if (data.code === 200) {
                this.setState({
                    alluserPackageStats: data.data,
                    alluserPackageStatsDuplicate: data.data,
                    isloading: false
                })
            }
            else if (data.code === 404) {
                this.setState({
                    alluserPackageStats: [],
                    alluserPackageStatsDuplicate: [],
                    isloading: false
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                isloading: false
            })
        })
    }

    onchangeSearch(e) {
        var current = this;
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let myArray = this.state.alluserPackageStatsDuplicate;
            let data = myArray.filter(x => {
                return (x.user.email.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
                    (x.user.firstName.replace(/\s+/g, '')  + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
                    (x.user.business.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) 
                    // (x.totalPdfs.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
                    // (x.remainingPdfs.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) 
            })
            current.setState({
                alluserPackageStats: data
            })
        })
    }

    
     render() { 
         var that =this;
         return (
            <React.Fragment>
                <Notifications options={{ zIndex: 200, top: '20px' }} />
                
                    <div className="content-page template-tab" id="sitetable">
                    <div className="content-header">
                        
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>eSign Stats</h3>
									</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
								<div className="head-search">
                                <input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
                            </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-bordered table-condensed">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Business Name</th>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Total Quota</th>
                                    <th>Remaining eSign</th>
                                    <th>Used eSign</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.alluserPackageStats.length > 0 ?
                                this.state.alluserPackageStats.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                            <th title={key+1}>{key+1}</th>
                                            <td title={val.user.business}>{val.user.business}</td>
                                            <td title={val.user.firstName + val.user.lastName}>{val.user.firstName} {val.user.lastName}</td>
                                            <td title={val.user.email}>{val.user.email}</td>
                                            <td title={val.totalPdfs}>{val.totalPdfs}</td>
                                            <td title={val.remainingPdfs}>{val.remainingPdfs}</td>
                                            <td title={val.usedPdfs}>{val.usedPdfs}</td>
                                           </tr>
                                        )
                                }) : 
                                <tr >
                                    <td colSpan="9" style={{ textAlign: "center" }}>
                                    {this.state.isloading ? 
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.isloading}
                                        />
                                    : "No Record"}
                                    </td>
                                </tr>
                                }
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
             
               


            </React.Fragment>
            
         );
     }
}
 
export default withRouter(eSignStats) ;