import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SignatureService from '../../services/signatureStats.service';
import LeadsListingService from '../../services/leadslisting.service';
import AllUserService from './../../services/alluser.service';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import "react-datepicker/dist/react-datepicker.css";
import { css } from '@emotion/core';
import { ENV } from './../../env';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;


class AllSignedCsv extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			allUsers: [],
			selectedMailgunUserID: '',
			csvListing: [],
			csvListingDuplicate: [],
			path: ENV.url,
			permission: '',
			usersearch: '',
			editData: '',
			siteModal: false,
			pandaLoader: true,
			linkExpiryDate: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
			link : [],
			status: 'inactive',
			id: '',
			userStatus: '',
			start: 0,
			limit: 100,
			hasMore:true,
			isloading:false,


        }
        this.signatureService = new SignatureService();
		this.leadlistingService = new LeadsListingService();
		this.alluserService = new AllUserService();
	}

	componentDidMount() {
		debugger
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));
		if(role){
			let roleStatus = role.id;
		this.alluserService.editUser(roleStatus).then(data => {
			if (data.code === 200) {
				let result = data.data;
				this.setState({
					userStatus: result,
				})
				if (this.state.userStatus.status === 'inactive') {
					localStorage.clear();
					this.props.history.push('/');
				}
			}
		}).catch(err => {
			console.log(err)
		})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		if (localStorage.getItem('token')) {
			this.allUserCSV();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	fetchMore = () => {
		var that = this;
		this.setState({
			hasMore: true,
			limit: that.state.limit + 100,
		})

		console.log("hello world");

		this.signatureService.getSignatureStats(this.state.start, this.state.limit).then(data => {

			console.log(data, "-------")
			if (data.code === 200) {
				if(data.count < this.state.limit){
					this.setState({
						hasMore: false
					});
				}
				this.setState({
					csvListing: data.data,
					csvListingDuplicate: data.data
				});
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})


}


	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}


	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.csv === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}



	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	allUserCSV() {
		this.setState({
			isloading: true
		})

		this.signatureService.getSignatureStats(this.state.start, this.state.limit).then(data => {

			console.log(data, "-------")
			if (data.code === 200) {
				console.log(data.data);
				this.setState({
					csvListing: data.data,
					csvListingDuplicate: data.data
				});
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})

	}

	search(e) {
		e.preventDefault();

		if (this.state.selectedMailgunUserID !== "") {
			this.setState({
				isloading: true
			})
			this.leadlistingService.getSingUserCSV(this.state.selectedMailgunUserID).then(data => {

				console.log(data, "-------")
				if (data.code === 200) {
					this.setState({
						csvListing: data.data,
						isloading: false
					})
				}
				else if (data.code === 404) {
					this.setState({
						csvListing: [],
						isloading: false
					})
				}
			}).catch(err => {
				console.log(err)
			})
		} else {
			this.allUserCSV();
		}

	}



	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.csvListingDuplicate;

			let data = myArray.filter(x => {
				debugger
				x.firstname = x.firstname ? x.firstname : "";
				x.lastname  = x.lastname  ? x.lastname  : "";
				return x.firstname.replace(/\s+/g, '').toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
				      (x.firstname.replace(/\s+/g, '') + x.lastname.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				csvListing: data
			})
		})
	}

	render() {
        console.log(this.state.csvListing)
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""}
           
				{this.state.role === 'admin' ?	
				<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >
					<div className="content-header">
						<div className="row">
							<div className="col-md-3 col-sm-3 col-xs-12">
								<div className="page-heading">
									<h3>Signature Statistics</h3>
								</div>
							</div>
							<div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
								<div className="head-search">
									<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
								</div>
                                </div>
                            </div>
						</div>
					</div>
					<div class="table-wrap">
						<div class="table-responsive">
						<InfiniteScroll
							dataLength={this.state.csvListing.length}
							next={this.fetchMore}
							hasMore={this.state.hasMore}
							loader={
								<ClipLoader
									css={override}
									sizeUnit={"px"}
									size={50}
									color={'#123abc'}
									loading={this.state.hasMore}
								/>}
							
						>
							<table class="table table-hover table-striped table-bordered table-condensed">
								<thead class="thead-light">
									<tr>
									<th>#</th>
										<th>CSV Name</th>
										<th>Signer Name</th>
										<th>Signer Email</th>
										<th>Signer Phone</th>
										<th>Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.csvListing.length > 0 ?
											this.state.csvListing.map((val, key) => {
												if (val.user) {
													return (
														<tr key={key}>
															<th title={key+1}>{key+1}</th>
														<td> {val.csvName}  </td>
														<td> {val.title} {val.firstname} </td>
														<td> {val.email} </td>
														<td> {val.phone1}  </td>
														<td title={moment(val.signatureDate).format("YYYY/MM/DD") || ""}>{moment(val.signatureDate).format("YYYY/MM/DD") || ""} </td>
														<td title="view detail" style={{ cursor: "pointer" }}> 
														<a href={val.pdf_url} style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																														<i className="fa fa-link"></i> </a>
														</td>
													</tr>
													)
												}
											})
											: <tr >
												<td colSpan="7" style={{ textAlign: "center" }}>
													{this.state.isloading ? <ClipLoader
														css={override}
														sizeUnit={"px"}
														size={50}
														color={'#123abc'}
														loading={this.state.isloading}
													/> : "No Record Found"}

												</td>
											</tr>
									}


								</tbody>
							</table>
						</InfiniteScroll>
						</div>
					</div>
				</div>:''
				}

     
        {this.state.role === 'user' ?	  
				<div className="setting-fields field-set" style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? 'block' : 'none' }} >
					<div className="content-header">
						<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
										<div className="page-heading">
												<h3>Signature Statistics</h3>
										</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
										<div className="head-right-wrap">
											<div className="head-search">
												<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
											</div>
										</div>
								</div>
          	</div>
					</div>

					<div class="table-wrap">
					<InfiniteScroll
							dataLength={this.state.csvListing.length}
							next={this.fetchMore}
							hasMore={this.state.hasMore}
							loader={
								<ClipLoader
									css={override}
									sizeUnit={"px"}
									size={50}
									color={'#123abc'}
									loading={this.state.hasMore}
								/>}
							
						>
						<div class="table-responsive">
							<table class="table table-hover table-striped table-bordered table-condensed">
								<thead class="thead-light">
									<tr>
								     	<th>#</th>
										<th>CSV Name</th>
										<th>Signer Name</th>
										<th>Signer Email</th>
										<th>Signer Phone</th>
										<th>Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.csvListing.length > 0 ?
											this.state.csvListing.map((val, key) => {
												return (
													<tr key={key}>
															<th title={key+1}>{key+1}</th>
														<td> {val.csvName}  </td>
														<td> {val.title} {val.firstname} </td>
														<td> {val.email} </td>
														<td> {val.phone1}  </td>
														<td title={moment(val.signatureDate).format("YYYY/MM/DD") || ""}>{moment(val.signatureDate).format("YYYY/MM/DD") || ""} </td>
														<td title="view detail" style={{ cursor: "pointer" }}> 
														<a href={val.pdf_url} style={{'marginLeft': '5px'}} target="_blank" className="btn-round btn-edit-icon">
																														<i className="fa fa-link"></i> </a>
														</td>
													</tr>
												)

											})
											: <tr >

												<td colSpan="7" style={{ textAlign: "center" }}>
													{this.state.isloading ? <ClipLoader
														css={override}
														sizeUnit={"px"}
														size={50}
														color={'#123abc'}
														loading={this.state.isloading}
													/> : "No Record Found"}
												</td>

											</tr>
									}
								</tbody>
							</table>
						</div>
						</InfiniteScroll>
					</div>
					
				</div>:''
				}


			</React.Fragment >
		);
	}
}

export default AllSignedCsv;