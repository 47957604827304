import React from 'react';
import {Redirect, Route} from "react-router-dom";


const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true
    setTimeout(cb, 100)
  },
  signout(cb) {
    this.isAuthenticated = false
    setTimeout(cb, 100)
  }
}


export const PrivateRoute = ({ component : Component, ...rest}) =>(
  <Route
    {...rest}
    render = {props =>
      localStorage.getItem("token") ? 
      (<Component {...props} /> ): 
      (<Redirect to={{pathname: "/" , state: {from :props.location}}} />
    )} />
);