import React, { Component } from 'react'
import userPhoto from './../../assets/images/user-photo.jpg';
import { withRouter } from 'react-router-dom';
import ProfileService from './../../services/profile.service';
import AllUserService from './../../services/alluser.service';
import { Link } from 'react-router-dom';

import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import 'react-circular-progressbar/dist/styles.css';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			errors: '',
			path: ENV.url,
			role:'',
            externalLink:'',
            emailList: [],
		}
		this.profileService = new ProfileService();
		this.alluserService = new AllUserService();
	}
	//life Cycle
	componentDidMount() {
		let user = JSON.parse(localStorage.getItem('user'));

		if (user) {
			let roleStatus = user.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						user: result,
						role: user.role
					})
				}
			}).catch(err => {
				console.log(err)
			})
		}
		this.userProfile();
	}

	userProfile() {
        var th = this;
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.profileService.ProfileAgentGet().then(data => {
                console.log("data", data);
                debugger
				th.setState({
					emailList: data.agentEmail.emailList
				});

			}).catch(err => {
				console.log(err);

			})
		} else {
			this.props.history.push('/');
			localStorage.clear();

		}

	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}


	updateProfile(e) {
		const { emailList} = this.state;
		e.preventDefault();
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailList.length == 0) {
			this.setState({ errors: 'Email List Required!' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let body = {
				emailList: this.state.emailList,
			}
			let user = JSON.parse(localStorage.getItem('user'));
			if (user) {
				this.profileService.AgentReportEmail(user.id, body).then(data => {
					this.setState({
						
					})
					notify.show(data.message, "success", 2000);
				}).catch(error => {
					console.log(error)
				})
			} else {
				this.props.history.push('/');
				localStorage.clear();
			}
		}

	}
    

    AddEmail(e) {
		var { email , emailList } = this.state;
		e.preventDefault();
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


		if (email === '') {
			this.setState({ errors: 'Email is required' });
			return;
		}
		else if (!email.match(regexp)) {
			this.setState({ errors: 'Email is invalid' });
			return;
		} 
		else {
            this.setState({ errors: '' });
            emailList.push(email);
            this.setState({
                emailList: emailList,
				email: '',
                });
		}

	}

    
    removeTextField2(e, indexValue) {
		e.preventDefault();
		var array123 = [];
		array123 = this.state.emailList;

		array123.splice((indexValue), 1);
		this.setState({
			emailList: array123.slice(),

		});
	}
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div className="content-page Setting-tab tab-structure" id="setting01">
					<div className="setting-fields field-set">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>Agent Report Email</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
								</div>
							</div>
						</div>
                        
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''}
                         
                            <div className="form-group row" id="dynamic">
											<label className="col-md-2">Added Email</label>
											<div className="col-md-5">
												{this.state.emailList.length != 0 ? this.state.emailList.map((val, i) => {
													return (
														<div className="field-add" key={i}>
															<div className="field-add-input mr-10" id="dynamic">
																<input type="text" className="form-control" id={i} name="link" value={this.state.emailList[i]} />
															</div>
															<div className="field-add-btns" id="minusbutton">
																<button className="btn-rem ml-5" onClick={(e) => this.removeTextField2(e, i)}>
																	<i className="fa fa-minus" aria-hidden="true"></i>
																</button>
															</div>
														</div>
													)
												}) : ''}
												<label className="errorsLinks">{this.state.isAdd ? this.state.errorsLink : ''}</label>
											</div>
										</div>
                    
						<div className="row column-reverse">
							<div className="col-md-8 col-xs-12">
								<div className="field-form-width my-profile">
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Email</label>
										<div className="col-lg-6 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Your Email" name="email" value={this.state.email || ''} onChange={(e) => this.onchange(e)} />
										</div>
                                        <div className="col-lg-3 col-md-8 col-sm-9 col-xs-12">
                                        <button onClick={(e) => this.AddEmail(e)} className="btn-style-md btn-purple btn-round  mr-10">Add</button>
										</div>
									</div>
									<div className="form-group-btn top-10 ">
										<button onClick={(e) => this.updateProfile(e)} className="btn-style-md btn-purple btn-round  mr-10">Save</button>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(Profile)
