import React, { Component } from 'react';
import {ENV} from './../env';

// var token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class ProfileService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;

    }




    async ProfileAgentGet() {
        var token = JSON.parse(localStorage.getItem("token"));
        var url = ENV.url;
        const data_1 = await fetch(url + 'agent/ReportEmail', {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }


    async UserProfile(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
    
        if(token)
        {
            console.log("token is set in service");
        }   
        else
        {
            console.log("token is not set in service");
            token = JSON.parse(localStorage.getItem("token"));
        }
        const data_1 = await fetch(url + 'users/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }




    async userPayment() {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
    
        if(token)
        {
            console.log("token is set in service");
        }   
        else
        {
            console.log("token is not set in service");
            token = JSON.parse(localStorage.getItem("token"));
        }
        const data_1 = await fetch(url + 'api/stripePaymentList', {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    async updateProfile(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        console.log(token)
        const data_1 = await fetch(this.URL() + 'users/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),

            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    async AgentReportEmail(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));
        // console.log(" ULR =>", this.URL())
        console.log(token)
        const data_1 = await fetch(this.URL() + 'agentReport/email', {
            method: 'POST',
            body: JSON.stringify(body),

            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    async updateProfileImage(body) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        console.log(token)
        const data_1 = await fetch(this.URL() + 'user-profile-img', {
            method: 'POST',
            body: body,

            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }
        }).then(res => res.json());
        return data_1;
    }







    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default ProfileService;