import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import './billing.css';
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import AllUserService from '../../services/alluser.service';
import PaymentService from '../../services/payment.service';
import { object } from 'twilio/lib/base/serialize';
import { CircularProgressbar,CircularProgressbarWithChildren ,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import $ from 'jquery';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Billing extends Component {
     constructor(props) {
          super(props);
          this.alluserService = new AllUserService();
          this.paymentService = new PaymentService();
          this.state ={
            role : "",
            errors: '',
            isModelOpenAddCreditUser:false,
            isModalOpenInvoice : false,
            isModalOpenAdminInvoice :false,
            allusersArray: [],
            allusersArrayDuplicate : [],
            isloading : false,
            usersearch : '',
            userIdForUpdate:'',
            costPerSms: '',
            vatonsms: '',
            credit: '',
            userId: "",

            userCreditHistory : [],
            printInvoice4SingleUser:[],
            adminCreditHistory : [],
            //showing value in circlular profressbar
            totalSMS:0,
            usedSMS:0,
            remainingSMS:0,
          }

        
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);

        let loginUser = JSON.parse(localStorage.getItem('user'));
        if (loginUser.role === 'subUser') {
            this.props.history.push('*');
        } 
        this.setState({
            role: loginUser.role
        })
        this.allUsersAPI();
        this.smsHistoryForUser();
    }
    
    allUsersAPI() {
        this.setState({
            isloading: true
        })
        this.alluserService.allUsers().then(data => {

            if (data.code === 200) {
                this.setState({
                    allusersArray: data.data,
                    allusersArrayDuplicate: data.data,
                    isloading: false
                })
            }
            else if (data.code === 404) {
                this.setState({
                    allusersArray: [],
                    allusersArrayDuplicate: [],
                    isloading: false
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                isloading: false
            })
        })
    }

    onOpenModelAddCreditUser = () =>{
        this.setState({ 
            isModelOpenAddCreditUser: true,
            errors: "" 
        });
    }

    onCloseModelAddCreditUser = () => {
        this.setState({ 
            isModelOpenAddCreditUser: false,
            errors: "",
            costPerSms : "",
            credit :"" ,
            vatonsms:""
        });
    };

    onOpenModelInvoice = () =>{
        this.setState({ 
            isModalOpenInvoice: true,
        });
    }
    onCloseModelInvoice = () =>{
        this.setState({ 
            isModalOpenInvoice: false,
        });
    }

    onOpenModelAdminInvoice = () =>{
        this.setState({ 
            isModalOpenAdminInvoice: true,
        });
    }
    onCloseModelAdminInvoice = () =>{
        this.setState({ 
            isModalOpenAdminInvoice: false,
        });
    }

    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    smsHistoryForUser(){
    this.setState({
        isloading : true
    })
        this.paymentService.smsCreditHistory().then(data =>{
            console.log(data)
            if(data.code === 200){
                this.setState({
                    userCreditHistory : data.data,
                    isloading:false
                },()=>{
                    this.smsCounts();
                })  
            }
        }).catch(err =>{
            this.setState({
                isloading :false
            })
            console.log(err)
        })
    }

    smsCounts(){
        if(this.state.userCreditHistory.length>=0){
           let totalSMS =this.state.userCreditHistory.length > 0 ?  this.state.userCreditHistory[0].user.totalSmsCredit : 0;
           let  remainingSMS = this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.smsCredit : 0;
           let usedSMS = this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.usedSmsCredit : 0;
           this.setState({
               totalSMS : parseInt(totalSMS),
               usedSMS : parseInt(usedSMS),
               remainingSMS : parseInt(remainingSMS),

           })

        }
    }


    smsHistoryForAdmin(e){
        e.preventDefault();
      var th =this;
        this.setState({
            isloading : true
        })
        // smsCreditUserHistoryForAdmin this service function for single user invoice
            this.paymentService.smsCreditHistory().then(data =>{
                if(data.code ===200){
                    this.setState({
                        adminCreditHistory : data.data,
                        isloading:false
                    });
                    this.onOpenModelAdminInvoice();
                }
                
            }).catch(err =>{
                this.setState({
                    isloading :false
                })
                console.log(err)
            })
        }

    // Edit Credit is basically //
    //   in get user details    //
    editCredit(ID){
        this.setState({
            userId : ID
        })
        this.onOpenModelAddCreditUser();
    }

    updateCredit(e) {
        e.preventDefault();
        if(this.state.credit === ""){
            this.setState({
                errors : "Please Enter Credit"
            })
            return;
        }else if(this.state.costPerSms === ""){
            this.setState({
                errors : "Please Enter Price Per SMS"
            })
            return;
        }
        else if(this.state.vatonsms === ""){
            this.setState({
                errors : "Please Enter VAT"
            })
            return;
        }
     
            let body = {
                pensePerSms: this.state.costPerSms,
                amount: this.state.credit,
                userId: this.state.userId,
                vat:this.state.vatonsms
            }
            this.paymentService.creditAdminToUser(body).then(data => {

                if (data.code === 404) {
                    this.setState({
                        errors: ""
                    })
                    this.onCloseModelAddCreditUser();
                    this.allUsersAPI();
                    notify.show(data.message, "success", 3000)

                }
                else if (data.code === 200) {
                    this.setState({
                        errors: ""
                    })
                    this.onCloseModelAddCreditUser();
                    this.allUsersAPI();
                    notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        
    }


    onchangeSearch(e) {
        var current = this;
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let myArray = this.state.allusersArrayDuplicate;
            let data = myArray.filter(x => {
                return (x.email.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
                    (x.business ? x.business.replace(/\s+/g, '') : "").toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
                    (x.firstName.replace(/\s+/g, '')  + x.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

            })
            current.setState({
                allusersArray: data
            })
        })
    }

    onchange(e){
        this.setState({
            [e.target.name] :e.target.value.replace(/\D/,'')
        })
    }

    onchange4pointInput(e){
        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')

        if (floatRegExp.test(e.target.value) || e.target.value === "") {

            this.setState({
                [e.target.name]: e.target.value
            })
        }
       
    }

    printPDF() {
        let jspdfTextHeader = 'Invoice';
        const doc = new jsPDF();

        doc.setProperties({
            title: 'eSign ',
            subject: 'Invoice',
        });

        doc.setFontType("bold");
        doc.text(15, 20, jspdfTextHeader);
        doc.fromHTML($('#pdff').get(0), 15, 25, {'width': 180});
        doc.autoTable({
            html: '#my-table', 
            // margin: {top: 65},
            startY: doc.pageCount > 1? doc.autoTableEndPosY() + 20 : 80,
            // styles: {fillColor: [0, 0, 0]},s
            styles: {overflow: 'linebreak'},
            columnStyles: {
                0: {
                    cellWidth: 'wrap'
                }
              },
            textColor: 10
        });
        
        // doc.save('table.pdf');  
        // var printDoc = new jsPDF();
        // printDoc.fromHTML($('#gpdf').get(0), 5, 5, {'width': 180});
        doc.autoPrint();
        // printDoc.setFont("helvetica");
        // printDoc.setFontType("bold");
        // printDoc.setFontSize(2);
        doc.output("dataurlnewwindow"); // this opens a new popup,  after this the PDF opens the print window view but there are browser inconsistencies with how this is handled
        // doc.output('save', 'eSign Invoice.pdf');
    }

    printInvoiceForSingleUser=(e,i)=>{
        let index = this.state.userCreditHistory[i];
        let arr =[];
        arr.push(index);
        this.setState({
            printInvoice4SingleUser: arr
        });
        this.onOpenModelInvoice();

    }


    

     render() { 
         var that =this;
         return (
            <React.Fragment>
                <Notifications options={{ zIndex: 200, top: '20px' }} />
                {
                    this.state.role=== "admin" ? 
                    <div className="content-page template-tab" id="sitetable">
                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div className="page-heading">
                                    <h3>Billing and Invoice</h3>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
                                <div className="head-search">
                                <input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
								</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-bordered table-condensed">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    {this.state.role === 'admin' ?
                                    <th>Business Name</th>
                                    :
                                    ''
                                    }
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Total SMS</th>
                                    <th>Remaining SMS</th>
                                    <th>Used SMS</th>
                                    <th width="260">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                this.state.allusersArray.length > 0 ?
                                this.state.allusersArray.map((val, key) => {
                                    if (val.role !== "admin" && val.role === "user" && val.role !== 'subUser') {
                                        return (
                                            <tr key={key}>
                                            <th title={key+1}>{key+1}</th>
                                            <td title={val.business}>{val.business}</td>
                                            <td title={val.firstName + val.lastName}>{val.firstName} {val.lastName}</td>
                                            <td title={val.email}>{val.email}</td>
                                            <td title={val.totalSmsCredit}>{val.totalSmsCredit}</td>
                                            <td title={val.smsCredit}>{val.smsCredit}</td>
                                            <td title={val.usedSmsCredit}>{val.usedSmsCredit}</td>

                                            <td width="260">
                                                <div className="table-option-btns">
                                                
                                                <button onClick={() => this.editCredit(val._id)}  type="button" className="btn btn-table mr-5">
                                                    <i className="fa fa-dollar"></i> Add Credit
                                                </button>
                                                <button onClick={(e) => this.smsHistoryForAdmin(e)} type="button" disabled={val.smsCredit === 0 ? true:false} className="btn btn-table mr-5">
                                                    <i className="fa fa-dollar"></i> Invoice
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }
                                }) : 
                                <tr >
                                    <td colSpan="9" style={{ textAlign: "center" }}>
                                    {this.state.isloading ? 
                                    <ClipLoader
                                        css={override}
                                        sizeUnit={"px"}
                                        size={50}
                                        color={'#123abc'}
                                        loading={this.state.isloading}
                                        />
                                    : "No Record"}
                                    </td>
                                </tr>
                                }
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                : 
                this.state.role==="user" ? 
                <div className="content-page template-tab" id="sitetable">
                <div className="content-header">
                    <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-12">
                        <div className="page-heading">
                            <h3>Billing and Invoice</h3>
                        </div>
                        </div>
                    </div>
                </div>
                <div className=" row">
                    <div className="graphHeading col-sm-4 col-xs-12">
                        <div className="cards cus total">
                            <CircularProgressbarWithChildren value={(that.state.totalSMS / that.state.totalSMS) * 100}>
                                <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon total"><i className="fa fa-envelope"></i></span>

                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="labels cus">
                            <div className="heading">
                                {this.state.totalSMS}
                            </div>
                            <label>Total SMS</label>
                        </div>
                    </div>

                    <div className="graphHeading col-sm-4 col-xs-12">
                        <div className="cards cus remain">
                            <CircularProgressbarWithChildren value={(that.state.remainingSMS / that.state.totalSMS) * 100}>
                                <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon remain"><i className="fa fa-envelope"></i></span>

                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="labels cus">
                            <div className="heading">
                                {this.state.remainingSMS}
                            </div>
                            <label>Remaining SMS</label>
                        </div>
                    </div>
                    <div className="graphHeading card col-sm-4 col-xs-12">
                        <div className="cards cus used ">
                            <CircularProgressbarWithChildren value={(that.state.usedSMS / that.state.totalSMS) * 100}>
                                <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon used"><i className="fa fa-envelope"></i></span>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                        <div className="labels cus">
                            <div className="heading">
                                {this.state.usedSMS}
                            </div>
                            <label>Used SMS</label>
                        </div>
                    </div>
                </div>


                <div className="user-balance">
                    <div className="row padding-10px">&nbsp;</div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-bordered table-condensed">
                                <thead className="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Cost / SMS (pence)</th>
                                        <th>VAT (pence)</th>
                                        <th>Amount (pounds)</th>
                                        <th>Date</th>
                                        <th>Print Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        this.state.userCreditHistory.length > 0 ?
                                            this.state.userCreditHistory.map(function (value, i) {
                                                if (value.user) {
                                                    return (
                                                        <tr key={i}>
                                                            <th title={i + 1}>{i + 1}</th>
                                                            <td title={value.costPerSms}>{value.costPerSms}</td>
                                                            <td title={value.vat}>{value.vat}</td>
                                                            <td title={value.amount}>{value.amount}</td>
                                                            <td>{moment(value.createdAt).format("MMMM D, Y")}</td>
                                                            <td>
                                                                <button type="button" className="btn-purple btn-round"  onClick={(e) => that.printInvoiceForSingleUser(e,i)}>
                                                                    <i clasn="fa fa-file-invoice"></i> Invoice
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    )
                                                }

                                            }) :
                                            <tr >
                                                <td colSpan="9" style={{ textAlign: "center" }}>
                                                    {this.state.isloading ? 
                                                    <ClipLoader
                                                        css={override}

                                                        sizeUnit={"px"}
                                                        size={50}
                                                        color={'#123abc'}
                                                        loading={true}
                                                    /> 
                                                     : "No Record Found"}

                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
               </div>
            : ""
                }
               




            <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.isModelOpenAddCreditUser} onClose={this.onCloseModelAddCreditUser} closeOnOverlayClick = {false}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.onCloseModelAddCreditUser} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add Credit for user</h4>
                    </div>
                    <div className="modal-body">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div> : ''}
                        <div className="form-group row">
                            <label className="col-md-4">Credit</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="Enter amount in pounds e.g 100" name="credit" value={this.state.credit || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Cost per SMS(pence)</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="e.g 2.1"   name="costPerSms" value={this.state.costPerSms || ''} onChange={(e) => this.onchange4pointInput(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">VAT (%)</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" placeholder="e.g 20"   name="vatonsms" value={this.state.vatonsms || ''} onChange={(e) => this.onchange4pointInput(e)} />
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.updateCredit(e)}>Save</button>
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.onCloseModelAddCreditUser}>Close</button>
                    </div>
                </Modal>

            <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.isModalOpenAdminInvoice} onClose={this.onCloseModelAdminInvoice} closeOnOverlayClick = {true}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.onCloseModelAdminInvoice} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Invoice</h4>
                    </div>
                    <div className="modal-body">
                         <div id="gpdf">

                             <div id="pdff" className="row">
                                 <div className="col-xs-12">
                                     {/* <div className="invoice-title">
                                         <h2 className="invoice">Invoice</h2>
                                     </div> */}

                                     <div className="row" style={{ marginBottom: "5px" }}>
                                     <div className="col-md-5 col-sm-3">
                                             <strong>Order Date:  </strong>
                                             {moment(new Date()).format("MMMM D, Y")}
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                             <strong>Busniness Name:  </strong>
                                             <span data-toggle="tooltip" data-placement="top" 
                                             title={this.state.adminCreditHistory.length>0 ? 
                                             this.state.adminCreditHistory[0].user.business: ""}>
                                             {this.state.adminCreditHistory.length > 0 ? 
                                                this.state.adminCreditHistory[0].user.business.length <15 ? 
                                                this.state.adminCreditHistory[0].user.business : 
                                                this.state.adminCreditHistory[0].user.business.slice(0,15)+'...' : "N/A"}</span>
                                           
                                         </div>
                                        
                                         
                                        
                                     </div>
                                     <div className="row" style={{ marginBottom: "5px" }}>
                                     <div className="col-md-5 col-sm-3">
                                            
                                                 <strong>Name:  </strong>
                                                 {this.state.adminCreditHistory.length > 0 ? this.state.adminCreditHistory[0].user.firstName + " "+this.state.adminCreditHistory[0].user.lastName : "N/A"}
                                           
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                             <strong>Phon No:  </strong>
                                             {this.state.adminCreditHistory.length > 0 ? this.state.adminCreditHistory[0].user.phone : "N/A"}
                                         </div>
                                        
                                     </div>
                                   
                                     <div className="row">
                                     <div className="col-md-5 col-sm-3">
                                         <address>
                                         <strong>Email:  </strong>
                                             {this.state.adminCreditHistory.length > 0 ? this.state.adminCreditHistory[0].user.email : "N/A"}

                                         </address>
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                             <address>
                                                 <strong>Remaining SMS:  </strong>
                                                 {this.state.adminCreditHistory.length > 0 ? this.state.adminCreditHistory[0].user.smsCredit : "0"}
                                             </address>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                             <div className="row">
                                 <div className="col-md-12">
                                     <div className="panel panel-default">
                                         <div className="panel-heading">
                                             <h3 className="panel-title"><strong>Order summary</strong></h3>
                                         </div>
                                         <div className="table-wrap">
                                             <div className="table-responsive tb-heigh-450">
                                                 <table id="my-table" className="table table-hover table-striped  table-condensed">
                                                     <thead>
                                                         <tr>
                                                             <td align="center"><strong>#</strong></td>
                                                             <td align="center"><strong>Total SMS</strong></td>
                                                             <td align="center"><strong>Remaining SMS</strong></td>
                                                             <td align="center"><strong>Used SMS</strong></td>
                                                             <td align="center"><strong>Cost / SMS (pence)</strong></td>
                                                             <td align="center"><strong>VAT (pence)</strong></td>
                                                             <td align="center"><strong>Amount (pounds)</strong></td>
                                                             <td align="center"><strong>Date</strong></td>
                                                         </tr>
                                                     </thead>
                                                     <tbody>
                                                         {this.state.adminCreditHistory.length > 0 ?
                                                             this.state.adminCreditHistory.map((val, key) => {
                                                                 return (
                                                                     <tr key={key}>
                                                                         <td align="center">{key+1}</td>
                                                                         <td align="center">{val.user? val.user.totalSmsCredit : "0"}</td>
                                                                         <td align="center">{val.user? val.user.smsCredit : "0"}</td>                                                                         
                                                                         <td align="center">{val.user? val.user.usedSmsCredit : "0"}</td>
                                                                         <td align="center" title={val.costPerSms}>{val.costPerSms}</td>
                                                                         <td align="center" title={val.vat}>{val.vat}</td>
                                                                         <td align="center" className="text-center">{val.amount}</td>
                                                                         <td align="center">{moment(val.createdAt).format("MMMM D, Y")}</td>
                                                                     </tr>
                                                                 )
                                                             }) :
                                                             <tr >
                                                                 <td colSpan="8" style={{ textAlign: "center" }}>
                                                                     {this.state.isloading ? <ClipLoader
                                                                         css={override}
                                                                         sizeUnit={"px"}
                                                                         size={50}
                                                                         color={'#123abc'}
                                                                         loading={this.state.isloading}
                                                                     /> : "No Record Found"}

                                                                 </td>
                                                             </tr>
                                                         }


                                                     </tbody>
                                                 </table>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                         </div>
                     </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.printPDF(e)}>Print Invoice</button>
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.onCloseModelAdminInvoice}>Close</button>
                    </div>
                </Modal>
            



                 <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.isModalOpenInvoice} onClose={this.onCloseModelInvoice} closeOnOverlayClick={true}>
                     <div className="modal-header">
                         <button type="button" className="close" onClick={this.onCloseModelInvoice} data-dismiss="modal">&times;</button>
                         <h4 className="modal-title">Invoice</h4>
                     </div>
                     <div className="modal-body">
                         <div id="gpdf">

                             <div id="pdff" className="row">
                                 <div className="col-xs-12">
                                     {/* <div className="invoice-title">
                                         <h2 className="invoice">Invoice</h2>
                                     </div> */}

                                     <div className="row" style={{ marginBottom: "5px" }}>
                                        
                                         <div className="col-md-5 col-sm-3">
                                             <strong>Order Date:  </strong>
                                             {moment(new Date()).format("MMMM D, Y")}
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                         <strong>Busniness Name:  </strong>
                                         <span data-toggle="tooltip" data-placement="top" title={this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.business : 'N/A'}>   {this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.business.length<15 ?this.state.userCreditHistory[0].user.business:this.state.userCreditHistory[0].user.business.slice(0, 15)+"..."  : "N/A"}</span>
                                          
                                          
                                         </div>
                                     </div>
                                     <div className="row" style={{ marginBottom: "5px" }}>
                                         <div className="col-md-5 col-sm-3">
                                         <strong>Name:  </strong>
                                                 {this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.firstName +" "+ this.state.userCreditHistory[0].user.lastName : "N/A"}
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                         <strong>Phon No:  </strong>
                                             {this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.phone : "N/A"}
                                         </div>
                                     </div>
                                     <div className="row">
                                         <div className="col-md-5 col-sm-3">
                                             <address>
                                            
                                         <strong>Email:  </strong>
                                             {this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.email : "N/A"}
                                         
                                             </address>
                                         </div>
                                         <div className="col-md-7 col-sm-3">
                                             <address>
                                                 <strong>Remaining SMS:  </strong>
                                                 {this.state.userCreditHistory.length > 0 ? this.state.userCreditHistory[0].user.smsCredit : "0"}
                                             </address>
                                         </div>
                                     </div>
                                   </div>
                             </div>
                             <div className="row">
                                 <div className="col-md-12">
                                     <div className="panel panel-default">
                                         <div className="panel-heading">
                                             <h3 className="panel-title"><strong>Order summary</strong></h3>
                                         </div>
                                         <div className="table-wrap">
                                             <div className="table-responsive tb-heigh-450">
                                                 <table id="my-table" className="table table-hover table-striped  table-condensed">
                                                     <thead>
                                                         <tr>
                                                             <td><strong>#</strong></td>
                                                             <td><strong>SMS</strong></td>
                                                             {/* <td><strong>Total SMS</strong></td> */}
                                                             <td><strong>Used SMS</strong></td>
                                                             <td><strong>Cost / SMS (pence)</strong></td>
                                                             <td><strong>VAT (pence)</strong></td>
                                                             <td><strong>Amount (pounds)</strong></td>
                                                             <td><strong>Date</strong></td>
                                                         </tr>
                                                     </thead>
                                                     <tbody>
                                                         {this.state.printInvoice4SingleUser.length > 0 ?
                                                            this.state.printInvoice4SingleUser.map((val, key)=>{
                                                                return(
                                                                    <tr key={key}>
                                                                         <td>{key+1}</td>
                                                                         <td>{val.balance? val.balance :'0'}</td>         
                                                                         {/* <td>{val.user? val.user.totalSmsCredit :'0'}</td> */}
                                                                         <td>{val.user? val.user.usedSmsCredit :'0'}</td>
                                                                         <td title={val.costPerSms}>{val.costPerSms}</td>
                                                                         <td title={val.vat}>{val.vat}</td>
                                                                         <td>{val.amount}</td>
                                                                         <td>{moment(val.createdAt).format("MMMM D, Y")}</td>
                                                                     </tr>
                                                                )
                                                            })
                                                                     
                                                              :
                                                             <tr >
                                                                 <td colSpan="8" style={{ textAlign: "center" }}>
                                                                     {this.state.isloading ? <ClipLoader
                                                                         css={override}
                                                                         sizeUnit={"px"}
                                                                         size={50}
                                                                         color={'#123abc'}
                                                                         loading={this.state.isloading}
                                                                     /> : "No Record Found"}

                                                                 </td>
                                                             </tr>
                                                         }


                                                     </tbody>
                                                 </table>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                         </div>
                     </div>

                     <div className="modal-footer">
                         <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.printPDF(e)}>Print Invoice</button>
                         <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.onCloseModelInvoice}>Close</button>

                     </div>
                 </Modal>


            </React.Fragment>
            
         );
     }
}
 
export default withRouter(Billing) ;