import React, { Component } from 'react';

import './logs.css';
import { withRouter } from 'react-router-dom';
import DashboardService from '../../services/dashboard.service';

import InfiniteScroll from 'react-infinite-scroll-component';

import { ENV } from './../../env';
import moment from "moment";
import ClipLoader from 'react-spinners/ClipLoader';
import AllUserService from './../../services/alluser.service';
import DateRangePicker from 'react-bootstrap-daterangepicker'

import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;



class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: '',
      loginLogs: [],
      to: 0,
      from: 50,
      hasMore: false,
      permission: '',
      pandaLoader: true,
      fname: "",
      lname: "",
      usersearchIP: "",
      userStatus: '',

      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }
    this.dashboardService = new DashboardService();
    this.alluserService = new AllUserService();
  }
  URL() {
    return ENV.url;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let role = JSON.parse(localStorage.getItem('user'));
    if (role) {
      let roleStatus = role.id;
      this.alluserService.editUser(roleStatus).then(data => {
        if (data.code === 200) {
          let result = data.data;
          this.setState({
            userStatus: result,
          })
          if (this.state.userStatus.status === 'inactive') {
            localStorage.clear();
            this.props.history.push('/');
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
    let rolename = role.role;
    if (rolename === 'subUser') {
      this.getPermissions();
    } else {
      this.setState({ pandaLoader: false })
    }

    if (localStorage.getItem("token")) {
      this.getLoginLogs();

    } else {
      this.props.history.push('/');
      localStorage.clear();
    }
  }

  getPermissions() {

    let user = JSON.parse(localStorage.getItem('user'));
    let id = user.id;
    let role = user.role;
    if (role === 'subUser') {
      this.alluserService.userPermission(id).then(data => {

        if (data.code === 200) {
          let result = data.data;
          this.setState({
            permission: result,
            pandaLoader: false
          })
          if (this.state.permission.logs === false) {
            this.props.history.push('*');
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  getLoginLogs() {
    debugger
    let body = {
      firstname: this.state.fname,
      lastname: this.state.lname,
      ip: this.state.usersearchIP,
      from: moment.utc(this.state.startDate).format(),
      to: moment.utc(this.state.endDate).format()
    }
    this.setState({
      hasMore: true
    })

    this.dashboardService.search(body, this.state.to, this.state.from).then(data => {
      if (data.code === 200) {
        if (data.searchCount === data.data.length) {
          this.setState({
            hasMore: false
          })
        }

        this.setState({
          loginLogs: data.data,
        })
      }
    }).catch(err => {
      console.log(err);
      this.setState({
        hasMore: false
      })
    })
  }

  fetchMoreData = () => {
    var that = this;
    //setTimeout(() => {
      that.setState({
        from: that.state.from + 50,
      }, () => {
        let body ={
          firstname : that.state.fname,
          lastname :that.state.lname,
          ip: that.state.usersearchIP,
          from :moment.utc(that.state.startDate).format(), 
          to: moment.utc(that.state.endDate).format()
        }
        that.setState({
          hasMore: true
        })

      that.dashboardService.search(body, that.state.to, that.state.from).then(data => {
        debugger
        if (data.code === 200) {
          if (data.searchCount === data.data.length) {
            that.setState({ hasMore: false });
          }
          that.setState({
            loginLogs: data.data,
          })
        }
      }).catch(err => {
        console.log(err);
        that.setState({
          hasMore: false
        })
      });
    })    
    //}, 1000);
  
  }

  handleEvent = (e, picker) => {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate
    })
  }

  onchange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  search(e) {
    e.preventDefault();

    let body = {
      firstname: this.state.fname,
      lastname: this.state.lname,
      ip: this.state.usersearchIP,
      from: moment.utc(this.state.startDate).format(),
      to: moment.utc(this.state.endDate).format()
    }
    this.setState({
      hasMore: true
    })
    this.dashboardService.search(body, this.state.to, this.state.from).then(data => {
      debugger
      if (data.code === 200) {
        if (data.searchCount === data.data.length) {
          this.setState({ hasMore: false });
        }
        if (data.data.length === 0) {
          this.setState({
            hasMore: false
          })
        }
        this.setState({
          loginLogs: data.data
        });

      }
    }).catch(err => {
      console.log(err);
      this.setState({
        hasMore: false
      })
    })
  }

  reset() {
    this.setState({
      to: 0,
      from: 50,
      fname: "",
      lname: "",
      usersearchIP: "",
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    }, () => {
      this.getLoginLogs();

    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.pandaLoader === true ?
          <div className="loading">Loading&#8230;</div>
          : ""}
        <div className="content-page">
          <div className="row">
            <div className="col-md-3 col-sm-4 col-xs-12">
              <div className="page-heading">
                <h3>Login Logs</h3>
              </div>
            </div>
            <div className="col-md-9 col-sm-8 col-xs-12">
              {/* <div className="add-btn text-right">
              <button type="button" className="btn-refresh btn-purple btn-round">
              <i className="fa fa-refresh fa-spin"></i>
              </button>
              </div> */}
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="log-head">
                <div className="form-group date mr-10">
                  <DateRangePicker id="selectDate" autoApply={false} locale={{ format: "DD/MM/YYYY" }} startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} >
                    <input type="button" name="daterange" className="form-control" value={this.state.startDate.format("MM/DD/YYYY") + " - " + this.state.endDate.format("MM/DD/YYYY")} className="date-input" />
                  </DateRangePicker>
                </div>
                <div className="form-group mr-10">
                  <input type="text" name="fname" placeholder="Search by First Name" value={this.state.fname} onChange={(e) => this.onchange(e)} className="form-control" />
                </div>
                <div className="form-group mr-10">
                  <input type="text" name="lname" placeholder="Search by Last Name" value={this.state.lname} onChange={(e) => this.onchange(e)} className="form-control" />
                </div>
                <div className="form-group mr-10">
                  <input type="text" name="usersearchIP" placeholder="Search by IP Address" value={this.state.usersearchIP} onChange={(e) => this.onchange(e)} className="form-control" />
                </div>
                <div className="form-group buttons">
                  <button type="button" onClick={(e) => this.search(e)} className="btn-search btn-style-md">Search</button>
                  <button type="button" onClick={(e) => this.reset(e)} className="btn-search btn-style-md">Reset</button>
                </div>
              </div>
            </div>
          </div>
          {/* dashboard page tables */}
          <div className="dashboard-tables">
            <div className="row">
              <div className="col-md-12">
                <div className="login-log-table">
                  <div className="table-wrap">
                    <div className="table-responsive" style={{ textAlign: "center" }}>

                      <InfiniteScroll
                        dataLength={this.state.loginLogs.length}
                        next={this.fetchMoreData}
                        hasMore={this.state.hasMore}
                        loader={
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={50}
                            color={'#123abc'}
                            loading={this.state.hasMore}
                          />}>
                        <table className="table table-hover table-striped table-bordered table-condensed" style={{ textAlign: "left" }}>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">User</th>
                              <th scope="col">Ip Address</th>
                              <th scope="col">Login Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.loginLogs.length > 0 ?
                                this.state.loginLogs.map((val, key) => {

                                  return (
                                    <tr key={key}>
                                      <th scope="row">{key + 1}</th>
                                      <td title={val.firstName ? val.firstName : "" + val.lastName ? val.lastName : ""}>{val.firstName ? val.firstName : ""} {val.lastName ? val.lastName : ""}</td>
                                      <td title={val.ip ? val.ip : ""}>{val.ip ? val.ip : ""}</td>
                                      <td title={moment(val.createdAt).format("MMM Do YYYY h:m a")}>{moment(val.createdAt).format("MMM Do YYYY h:m a")}</td>
                                    </tr>
                                  )


                                }) :
                                <tr >
                                  <td colSpan="4" style={{ textAlign: "center" }}>
                                    {this.state.hasMore ? "" : "No Record Found"}
                                  </td>
                                </tr>
                            }
                          </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default withRouter(Logs);