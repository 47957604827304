import React, { Component } from "react";
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    StripeProvider,
    Elements,
    injectStripe,
    PostalCodeElement
} from 'react-stripe-elements';
import {ENV} from '../../env';
import $ from "jquery";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

const createOptions = (padding) => {
    return {
        style: {
            base: {
                // width: "100%",
                // height: "42px",
                // borderRadius: "5px",
                // border: '1px solid #9e9e9e',
                // padding: '10px 10px',
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

class _SplitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // autoRenew: true,
            accountName: '',
            cardNumber: '',
            cardExpire:'',
            cardCsv:"",
            success: "",
            // zipCode: '',
            // countryName: '',
            errorMessage: '',
            disableSubmit: false,
            isloading: false
            // countryData: [],
            // isError: false
        }
    }
    
    handleSubmit = () => {
        var th = this;

            this.props.stripe.createToken().then((payload) => {
                
                if (payload.error) {
                    this.setState({
                        errorMessage: payload.error.message,
                        isError: true,
                        disableSubmit: false,
                        isloading: false
                    })
                } else {
                    this.setState({disableSubmit: true , isloading: true})
                    
                    var token = JSON.parse(localStorage.getItem("token"));       
                    fetch(ENV.url + 'api/stripePayment', {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token.tokenType + ' ' + token.accessToken,

                        }, body: JSON.stringify({
                            // "user":  user,
                            "stripe": payload,
                        })
                    }).then(res => res.json()).then(
                        (result) => {
                            this.setState({disableSubmit: false,   isloading: false})
                            if (result.status === "success") {
                                this.setState({ success: "Payment Done successfully !" });
                             
                                setTimeout(function(){
                                    window.location.reload()
                                  }, 3000);

                             
                            } else {
                                this.setState({ errorMessage: "Something went wrong!" });
                            }
                        },
                        (error) => {
                            console.log('');
                        });
                }
            });
        // ev.preventDefault();
    };
    handleChange = (e) => {
        let val = '';
        if (e && e.value) {
            val = e.value
        }
        this.setState({
            countryName: val
        })
    }
    handleZipCode = (e) => {
        if (e.value <= 6) {
            this.setState({
                zipCode: e.value
            })
        }
    }
    handleCardNumber = (e) => {
         
           if(e.complete == true){
            //    console.log( $("input[name=cardnumber]").value);
            this.setState({
                cardNumber: $("input[name=cardnumber]").value
            })
           }
    }
    handleCardExpire = (e) => {
            this.setState({
                cardExpire: e.value
            })
    }
    handleCardCVC = (e) => {
            this.setState({
                cardCsv: e.value
            })
    }
    
    render() {
    
        return (
            <div className="payment-form">
                <h3 className='modal-title'>Enter  Your Card Detail
                </h3>
                <form>
                    <div className="row">
                        <div className="form-group col-sm-12 col-xs-12">
                            <CardNumberElement
                            value={this.state.cardNumber}
                                  onChange={(e) => this.handleCardNumber(e)}
                                {...createOptions()} />
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                            <CardExpiryElement
                             onChange={(e) => this.handleCardExpire(e)}
                                {...createOptions()}
                            />
                        </div>
                        <div className="form-group col-sm-6 col-xs-12">
                            <CardCVCElement
                             onChange={(e) => this.handleCardCVC(e)}
                               {...createOptions()}
                        />
                     </div>
                      <div className="form-group col-sm-6 col-xs-12">
                        </div>
                        <div className="form-group payment-card-country col-sm-6 col-xs-12">
                        </div>
                    </div>
                </form>

                <p className="text-green secure-line">
                    <i className="fa fa-lock" aria-hidden="true"></i> Your payments are SSL encrypted, <span className="fw600">which means they're 100% safe with us.</span> </p>
                    
                    { this.state.success ?
                    <p className="text-green secure-line">
                    <span className="fw600">{this.state.success}</span> </p>  :    
                    <p className="text-danger">
                    <span className="fw600">{this.state.errorMessage}</span> 
                    </p> 
                    
                    }

                 {/* <p className="text-danger">{ this.state.errorMessage == '' ? this.state.errorMessage : this.state.errorMessage}</p> */}
                <div className="form-btn text-center">
                    <button type="button"
                        onClick={() => this.handleSubmit()}
                        disabled={this.state.disableSubmit}
                        className="btn btn-green btn-round btn-upgrade btn-checkout">Checkout
                     </button>
                </div>

                {this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : ""}

            </div>
        );
    }
}
const SplitForm = injectStripe(_SplitForm);

class Checkout extends React.Component {
    constructor() {
        super();
        this.state = {
            elementFontSize: window.innerWidth < 450 ? '14px' : '18px',
        };
    }

    render() {
        const { elementFontSize } = this.state;
        return (
            <div className="Checkout">

                <Elements>
                    <SplitForm 
                        package = {this.props.package}
                        type = {this.props.type}
                        closeModel ={() => this.props.closeModel()}
                        isCharge={this.props.isCharge}
                    />
                </Elements>

            </div>
        );
    }
}


export const Stripe = (props) => {
    // console.log(props.package);
    return (
        <StripeProvider apiKey='pk_live_xTZBShX6TrgWy6f1Cknm4OT6'>
            <Checkout
              package = {props.package}
              type = {props.type}
              closeModel ={() => props.closeModel()}
              isCharge={props.isCharge}
            />
        </StripeProvider>
        // <StripeProvider apiKey='pk_test_51HqeDiHhjKU2RPeiKS1klKqMCcAKjClJk4snWrAPp7Tycw7FSezk1196kGDqUyaWSOZVKOPdYtvAjsFoZOQnjmbd00nBoFK99g'>
        //     <Checkout
        //       package = {props.package}
        //       type = {props.type}
        //       closeModel ={() => props.closeModel()}
        //       isCharge={props.isCharge}
        //     />
        //     </StripeProvider>
    );
};
