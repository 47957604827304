import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class AllUserService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    

    async createUser(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'user', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateCredit(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'smsCredit/transfer', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async createSubUser(subUser) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'subusers', {
            method: 'POST',
            body: JSON.stringify(subUser),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
   

    async allUsers(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'users', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async allSubUsers(id){
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'allsubusers/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async userPermission(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'userspermission/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async editUser(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'users/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async LeadSmsCredit() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/LeadsSmsCredit', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async LeadSmsCredit_Paid() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/LeadsSmsCredit_Paid', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }


    async editPermission(id,body) {
       var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/InnerPermission/Update/'+id, {
            method: 'POST',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    

    async getInnerPermission(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'api/InnerPermission/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateUser(id,body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'users/'+id, {
            method: 'PUT',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async updatePermission(id,body) {
        console.log(id,'has content',body)
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'updatepermission/'+id, {
            method: 'PUT',
            body:JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async delUser(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'users/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async delSubUser(id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'subusers/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    

    




    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default AllUserService;