import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import AllPackageService from '../../services/package.service';
import AllUserService from './../../services/alluser.service';
import { setTimeout } from 'timers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import $ from 'jquery';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class Package extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createPackageModel: false,
            packageName: '',
            price: '',
            signedPdf: '',
            features: [],
            errors: '',
            isloading: false,
            allPackagesArray: [],
            allPackagesArrayDuplicate: [],
            pkgIDforUpdate: '',
            isEdit: false,
            pkgsearch: '',
            userStatus: '',
        }
        this.AllPackageService = new AllPackageService();
        this.alluserService = new AllUserService();
    }

    componentDidMount() {
		window.scrollTo(0, 0);

        let role = JSON.parse(localStorage.getItem('user'));
        if (role) {
            let roleStatus = role.id;
            this.alluserService.editUser(roleStatus).then(data => {
                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        userStatus: result,
                    })
                    if (this.state.userStatus.status === 'inactive') {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
        this.getallPackages();
    }
    getallPackages() {
        this.setState({
            isloading: true
        })
        this.AllPackageService.allPackages().then(data => {

            if (data.code === 200) {
                this.setState({
                    allPackagesArray: data.data,
                    allPackagesArrayDuplicate: data.data,
                    isloading: false
                })
            }
        }).catch(err => {
            this.setState({
                isloading: false
            })
            console.log(err)
        })
    }
    editPackage = (id) => {

        this.AllPackageService.editPackage(id).then(data => {
            console.log("edit", data)

            this.setState({
                packageName: data.data.packageName,
                price: data.data.price,
                signedPdf: data.data.signedPdf,
                features: data.data.features,
                gender: data.data.gender,
                pkgIDforUpdate: data.data._id,
                createPackageModel: true,
                errors: '',
                isEdit: true,
            })
        }).catch(err => {
            console.log(err)
        })

    }
    changeSchema(e, index) {
        var arrayOfObjs = this.state.features;
        arrayOfObjs[index] = e.currentTarget.value;
        this.setState({ features: arrayOfObjs });
    }
    addTextfield(e) {
        e.preventDefault();
        var inputs = this.state.features;

        inputs.push('');

        this.setState({
            inputs
        })
    }
    removeTextField(indexValue) {
        var array123 = [];
        array123 = this.state.features;

        array123.splice((indexValue), 1);

        this.setState({
            features: array123.slice()
        });
    }
    createPackageOpenModal = () => {
        this.setState({
            createPackageModel: true,
            packageName: '',
            price: '',
            signedPdf: '',
            features: [],
            isloading: false,
            errors: ""

        });
    };

    createPackageCloseModel = () => {
        this.setState({ createPackageModel: false });
    };
    checkIsPositive(e) {
        let amount = parseFloat(e.target.value);
        if (isNaN(amount) || amount < 0) {
            amount = '';

        }
        if (amount === '') {
            this.setState({
                errors: "Please enter only positive value.",
                [e.target.name]: amount,

            })
        } else {
            this.setState({
                [e.target.name]: amount,
                errors: ""
            })

        }

    }
    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    updatePackageSubmit(e) {

        e.preventDefault();

        const { signedPdf, packageName, price } = this.state;
        var count = 0;
        e.preventDefault();
        if (packageName === '') {
            this.setState({ errors: 'Package name is required ' });
            return;
        }
        else if (price === '') {
            this.setState({ errors: 'Price is required ' });
            return;
        }
        else if (signedPdf === '') {
            this.setState({ errors: 'Number of signed pdf is required' });
            return;
        } else if (this.state.features.length > 0) {
            this.state.features.map((val, index) => {
                if (val === '') {
                    this.setState({ errors: 'Remove empty features fields.' });
                    count++;

                } else {
                    this.setState({ errors: "" })
                }
            })
        } if (count === 0 && packageName !== '' && price !== '' && signedPdf !== '') {
            console.log('aad part');
            this.setState({ errors: '' });

            let body = {
                packageName: this.state.packageName,
                price: this.state.price,
                signedPdf: this.state.signedPdf,
                features: this.state.features
            }
            let id = this.state.pkgIDforUpdate
            console.log('package body', body);
            this.AllPackageService.updatePackage(id, body).then(data => {
                console.log(data)
                var inputs = [""];
                inputs.push('');
                this.setState({
                    inputs
                }, () => {
                    $("#afterHide").show();
                })
                if (data.code === 404) {
                    this.setState({ errors: data.message });
                }
                if (data.code === 200) {
                    this.createPackageCloseModel();
                    this.setState({
                        packageName: '',
                        price: '',
                        signedPdf: '',
                        features: [],
                        errors: "",
                        isEdit: false
                    })
                    this.getallPackages();
                    notify.show(data.message, "success", 1000);
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    addPackageSubmit(e) {

        e.preventDefault();

        const { signedPdf, packageName, price } = this.state;
        var count = 0;
        e.preventDefault();
        if (packageName === '') {
            this.setState({ errors: 'Package name is required ' });
            return;
        }
        else if (price === '') {
            this.setState({ errors: 'Price is required ' });
            return;
        }
        else if (signedPdf === '') {
            this.setState({ errors: 'Number of signed pdf is required' });
            return;
        } else if (this.state.features.length > 0) {
            this.state.features.map((val, index) => {
                if (val === '') {
                    this.setState({ errors: 'Remove empty features fields.' });
                    count++;

                } else {
                    this.setState({ errors: "" })
                }
            })
        } if (count === 0 && packageName !== '' && price !== '' && signedPdf !== '') {
            console.log('aad part');
            this.setState({ errors: '' });

            let body = {
                packageName: this.state.packageName,
                price: this.state.price,
                signedPdf: this.state.signedPdf,
                features: this.state.features
            }
            console.log('package body', body);
            this.AllPackageService.createPackage(body).then(data => {
                console.log(data)
                var inputs = [""];
                inputs.push('');
                this.setState({
                    inputs
                }, () => {
                    $("#afterHide").show();
                })
                if (data.code === 404) {
                    this.setState({ errors: data.message });
                }
                if (data.code === 200) {
                    this.createPackageCloseModel();
                    this.setState({
                        packageName: '',
                        price: '',
                        signedPdf: '',
                        features: [],
                        errors: ""
                    })
                    this.getallPackages();
                    setTimeout(() => {
                        notify.show(data.message, "success", 1000);
                    }, 3000);
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    deletePackage(id) {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-style modal-delete">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body modal-delete-body">
                                    <h2>Are you sure?</h2>
                                    <p>You want to delete this record?</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
                                    <button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(id); onClose(); }}>Yes, Delete it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });

    }
    conformDelete(id) {

        this.AllPackageService.delPackage(id).then(data => {
            console.log(data);
            if (data.code === 200) {
                this.getallPackages();
                notify.show(data.message, "success", 3000)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    onchangeSearch(e) {
        let text = e.target.value;
        this.setState({
            [e.target.name]: e.target.value
        })
        this.setState({
            allPackagesArray: this.state.allPackagesArrayDuplicate.filter(function (item) {
                return item.packageName.replace(/\s+/g, '').toUpperCase().includes(text.replace(/\s+/g, '').toUpperCase()) ||
                    item.price.replace(/\s+/g, '').toUpperCase().includes(text.replace(/\s+/g, '').toUpperCase())
            })
        });
    }
    render() {
        return (
            <React.Fragment>
                <Notifications options={{ zIndex: 200, top: '20px' }} />

                <div className="content-page template-tab" id="sitetable">

                    <div className="content-header">

                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>All Packages</h3>
									</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
								<div className="head-search">
                                    <input type="text" name="pkgsearch" placeholder="Search" value={this.state.pkgsearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
                                </div>

                                <div className="add-btn text-right">
                                <button type="button" onClick={() => this.createPackageOpenModal()} className="btn-style-md btn-purple btn-round">Add New Package</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-bordered table-condensed">
                                <thead className="thead-light">


                                    <tr>
                                        <th>#</th>
                                        <th>Package Name</th>
                                        <th>Price</th>
                                        <th>No. of eSign</th>
                                        <th width="230">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.allPackagesArray.length > 0 ?
                                            this.state.allPackagesArray.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th title={key+1}>{key+1}</th>
                                                        <td title ={val.packageName} >{val.packageName}</td>
                                                        <td title ={val.price} >{val.price}</td>
                                                        <td title ={val.signedPdf} >{val.signedPdf}</td>
                                                        <td width="230">
                                                            <div className="table-option-btns">
                                                                <button type="button" className="btn btn-table mr-5" onClick={() => this.editPackage(val._id)}>
                                                                    <i className="fa fa-pencil-square-o"></i> Edit
                                                                </button>
                                                                <button type="button" className="btn btn-table" onClick={(e) => this.deletePackage(val._id)}>
                                                                    <i className="glyphicon glyphicon-trash"></i> Delete
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : <tr >
                                                <td colSpan="5" style={{ textAlign: "center" }}>
                                                    {this.state.isloading ? <ClipLoader
                                                        css={override}
                                                        sizeUnit={"px"}
                                                        size={50}
                                                        color={'#123abc'}
                                                        loading={this.state.isloading}
                                                    /> : "No Record Found"}

                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.createPackageModel} onClose={this.createPackageCloseModel}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.createPackageCloseModel()} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add Package </h4>
                    </div>
                    <div className="modal-body">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div> : ''}
                        <div className="form-group row">
                            <label className="col-md-4">Package Name</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="packageName" value={this.state.packageName || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Package Price (pounds)</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="price" min="0" value={this.state.price || ''} onChange={(e) => this.checkIsPositive(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">No. of eSigne</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="signedPdf" value={this.state.signedPdf || ''} onChange={(e) => this.checkIsPositive(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Price / eSigne</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="signedPdf" value={this.state.price !== '' && this.state.signedPdf !== '' ?parseFloat(this.state.price/this.state.signedPdf).toFixed(5) : '0'} disabled={true}  />
                            </div>
                        </div>
                        <div className="form-group row" id="dynamic">
                            <label className="col-md-4">Add Feature</label>
                            <div className="col-md-8">
                                {this.state.features.map((val, i) => {
                                    return (
                                        <div className="field-add" key={i}>
                                            <div className="field-add-input mr-10" id="dynamic">
                                                <input type="text" className="form-control" id={i} name="features" value={this.state.features[i]} onChange={(e) => this.changeSchema(e, i)} />
                                            </div>
                                            <div className="field-add-btns" id="minusbutton">
                                                <button className="btn-rem ml-5" onClick={() => this.removeTextField(i)}>
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                <div className="field-add-btns" id="afterHide">
                                    <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        {this.state.isEdit ? <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.updatePackageSubmit(e)}>Update</button> : <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.addPackageSubmit(e)}>Save</button>}
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.createPackageCloseModel()}>Close</button>

                    </div>
                </Modal>




            </React.Fragment>
        );

    }
}

export default withRouter(Package);