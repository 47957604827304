import React, { Component } from 'react';
import HighChartStats from './HighChartStats';
import { Link } from 'react-router-dom';
import email_temp from './../../assets/images/email.svg';
import sms_temp from './../../assets/images/sms.svg';
import site from './../../assets/images/site.svg';
import AllUserService from '../../services/alluser.service';
import PackageService from '../../services/package.service';
import './dashboard.css';
import { ENV } from './../../env';
import moment from "moment";

const title = 'Email Usage Stats';
const titles = 'SMS Usage Stats';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signedLeads:"",
      errors: '',
      roleIn: '',
      dashboard: [],
      loginLogs: [],
      EData: [],
      SData: [],
      permission: '',
      pandaLoader: true,
      psites: true,
      psms: true,
      pemail: true,
      userStatus: '',

      emailStatus: false,
      smsStatus: false,
      calLogs : false,
      system_log : false
    }
    this.alluserService = new AllUserService();
    this.packageService = new PackageService();
  }
  URL() {
    return ENV.url;
  }

  componentDidMount() {
		window.scrollTo(0, 0);
   
    let role = JSON.parse(localStorage.getItem('user'));
    this.getPageInnerPermision(role);
    if (role) {
      let roleStatus = role.id;
      this.alluserService.editUser(roleStatus).then(data => {
        if (data.code === 200) {
          let result = data.data;
          this.setState({
            userStatus: result,
          })
          if (this.state.userStatus.status === 'inactive') {
            localStorage.clear();
            this.props.history.push('/');
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
    let rolename = role.role;
    if (rolename === 'subUser') {
      this.getPermissions();
    } else {
      this.setState({ pandaLoader: false })
    }

    var parent = JSON.parse(localStorage.getItem('user'));
    this.setState({
      roleIn: parent.role
    })
    //var roleIn = parent.role;
    // console.log('CheckkkCheckkkCheckkkCheckkkCheckkkCheckkk', roleIn)
    var token = JSON.parse(localStorage.getItem("token"));

    fetch(ENV.url + 'dashboard/counts', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token.tokenType + ' ' + token.accessToken,
      }
    }).then(res => res.json()).then(
      (result) => {
        if (result.code === 200) {
          this.setState({ dashboard: result.data });
          // console.log('resultresultresultresult',result.data)
        } else {
          console.log(result);
        }

      },
      (error) => {
        console.log(error);
      });
    fetch(ENV.url + 'getLoginLogs', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token.tokenType + ' ' + token.accessToken,
      }
    }).then(res => res.json()).then(
      (result) => {
        if (result.code === 200) {
          this.setState({ loginLogs: result.data });
        } else {
          console.log(result);
        }
      },
      (error) => {
        console.log(error);
      });
    fetch(ENV.url + 'getEmailStats', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token.tokenType + ' ' + token.accessToken,
      }
    }).then(res => res.json()).then(
      (result) => {
        if (result.code === 200) {
          var email_data = [];
          var email_dates = [];
          result.data.map((val, key) => {
            email_data.push([val.counts]);
            email_dates.push(moment(val._id).format("MMM Do YYYY"));
            return null;
          });
          console.log("email_dates", email_dates);
          var email_dates_unique = email_dates.filter(function (item, pos) {
            return email_dates.indexOf(item) === pos;
          })
          console.log("email_dates_unique", email_dates_unique);

          var Cthis = this;
          setTimeout(function () {
            var EData = [{ name: 'Emails', data: email_data, xData: email_dates_unique }];

            Cthis.setState({ EData: EData });
          });

        } else {
          console.log(result);
        }
      },
      (error) => {
        console.log(error);
      });
    fetch(ENV.url + 'getSmsStats', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token.tokenType + ' ' + token.accessToken,
      }
    }).then(res => res.json()).then(
      (result) => {
        if (result.code === 200) {
          var sms_data = [];
          var sms_dates = [];
          result.data.map((val, key) => {
            sms_data.push([val.counts]);
            sms_dates.push(moment(val._id).format("MMM Do YYYY"));
            return null;
          });


          var sms_dates_unique = sms_dates.filter(function (item, pos) {
            return sms_dates.indexOf(item) === pos;
          })

          var Cthis = this;
          setTimeout(function () {
            var SData = [{ name: 'SMS', data: sms_data, xData: sms_dates_unique }];
            Cthis.setState({ SData: SData });
          });
        } else {
          console.log(result);
        }
      },
      (error) => {
        console.log(error);
      });

      this.userCSVStats();
  }

  userCSVStats(){
    this.setState({
        isloading: true
    })
    this.packageService.getuserCSVStats().then(data => {
        console.log(data, "data")
        if (data.code === 200) {
            this.setState({
                signedLeads: data.data,
                isloading: false
            })
        }
        else if (data.code === 404) {
            this.setState({
                signedLeads: 0,
                isloading: false
            })
        }
    }).catch(err => {
        console.log(err);
        this.setState({
            isloading: false
        })
    })
}

  getPermissions() {
    let user = JSON.parse(localStorage.getItem('user'));
    let id = user.id;
    let role = user.role;
    if (role === 'subUser') {
      this.alluserService.userPermission(id).then(data => {

        if (data.code === 200) {
          let result = data.data;
          this.setState({
            permission: result,
            pandaLoader: false
          })
          if (this.state.permission.dashboardperm === false) {
            this.props.history.push('*');
          }
          if (this.state.permission.emailTemplate === false) {
            this.setState({
              pemail: false
            })
          }
          if (this.state.permission.smsTemplate === false) {
            this.setState({
              psms: false
            })
          }
          if (this.state.permission.Sites === false) {
            this.setState({
              psites: false
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  getPageInnerPermision(data) {
		debugger
		let role = data.role;
		let userId = data._id;
		if (role == "admin") {
			userId = data._id;
		} else if (role == "user") {
			userId = data._id;
		} else if (role == "subUser") {
			userId = data.parentId;
		}
		this.alluserService.getInnerPermission(userId).then(data => {
			debugger
      let dashBoardCheck = data.data.dashBoardCheck;
       dashBoardCheck = data.data.dashBoardCheck[0];
			this.setState({
        emailStatus: dashBoardCheck.emailStatus,
        smsStatus:   dashBoardCheck.smsStatus ,
        calLogs :    dashBoardCheck.calLogs,
        system_log : dashBoardCheck.system_log
			})
		}).catch(err => {
			console.log(err)
		})

	}
  // getPermissions() {
  //   let user = JSON.parse(localStorage.getItem('user'));
  //   let id = user.id;
  //   let role = user.role;
  //   if (role === 'subUser') {
  //     this.alluserService.userPermission(id).then(data => {

  //       if (data.code === 200) {
  //         let result = data.data;
  //         localStorage.setItem('permission', JSON.stringify(data.data));
  //         console.log('RESultRESultRESultRESultRESult', result);
  //       }
  //     }).catch(err => {
  //       console.log(err)
  //     })
  //   }
  // }

  render() {
    console.log(this.state);
    return (
      <React.Fragment>
        <div className="content-page">
          <div className="dashboard-buttons">
            {this.state.roleIn === 'admin' || this.state.roleIn==='subUser' ?
            <React.Fragment>
               <div className="dashboard-btn-box-admin-subuser">
             {this.state.pemail ?
               <Link className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn email-bg" role="button" to="/emailtemplate">
                 <div className="ribbon"><span>{this.state.dashboard.myEmailTemplatesCount}</span></div>
                 <div className="box-icon"><img src={email_temp} className="img-resposive" alt="" /></div>
                 <h4>Email Templates</h4>
               </Link>
               :
               <div className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn email-bg" >
                 <div className="ribbon"><span>{this.state.dashboard.myEmailTemplatesCount}</span></div>
                 <div className="box-icon"><img src={email_temp} className="img-resposive" alt="" /></div>
                 <h4>Email Templates</h4>
               </div>
             }
           </div>
              <div className="dashboard-btn-box-admin-subuser">
             {this.state.psms ?
               <Link className="btn btn-dashsms btn-lg col-md-12 dashboard-nav-btn sms-bg" role="button" to="/smstemplate">
                 <div className="ribbon"><span>{this.state.dashboard.mySMSTemplatesCount ? this.state.dashboard.mySMSTemplatesCount : 0}</span></div>
                 <div className="box-icon"><img src={sms_temp} className="img-resposive" alt="" /></div>
                 <h4>SMS Templates</h4>
               </Link>
               :
               <div className="btn btn-dashsms btn-lg col-md-12 dashboard-nav-btn sms-bg" >
                 <div className="ribbon"><span>{this.state.dashboard.mySMSTemplatesCount ? this.state.dashboard.mySMSTemplatesCount : 0}</span></div>
                 <div className="box-icon"><img src={sms_temp} className="img-resposive" alt="" /></div>
                 <h4>SMS Templates</h4>
               </div>
             }
           </div>
              <div className="dashboard-btn-box-admin-subuser">
             {this.state.psites ?
               <Link className="btn btn-dashsite btn-lg col-md-12 dashboard-nav-btn mysite-bg" role="button" to="/sites">
                 <div className="ribbon"><span>{this.state.dashboard.mySitesCount}</span></div>
                 <div className="box-icon"><img src={site} className="img-resposive" alt="" /></div>
                 <h4>My Sites</h4>
               </Link>
               :
               <div className="btn btn-dashsite btn-lg col-md-12 dashboard-nav-btn mysite-bg" >
                 <div className="ribbon"><span>{this.state.dashboard.mySitesCount}</span></div>
                 <div className="box-icon"><img src={site} className="img-resposive" alt="" /></div>
                 <h4>My Sites</h4>
               </div>
             }
           </div>         
            </React.Fragment>
            
            :
            <React.Fragment>

                <div className="dashboard-btn-box">
            {this.state.pemail ?
              <Link className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn email-bg" role="button" to="/emailtemplate">
                <div className="ribbon"><span>{this.state.dashboard.myEmailTemplatesCount}</span></div>
                <div className="box-icons"><img src={email_temp} className="img-resposive" alt="" /></div>
                <h4>Email Templates</h4>
              </Link>
              :
              <div className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn email-bg" >
                <div className="ribbon"><span>{this.state.dashboard.myEmailTemplatesCount}</span></div>
                <div className="box-icons"><img src={email_temp} className="img-resposive" alt="" /></div>
                <h4>Email Templates</h4>
              </div>
            }
          </div>
          <div className="dashboard-btn-box">
            {this.state.psms ?
              <Link className="btn btn-dashsms btn-lg col-md-12 dashboard-nav-btn sms-bg" role="button" to="/smstemplate">
                <div className="ribbon"><span>{this.state.dashboard.mySMSTemplatesCount ? this.state.dashboard.mySMSTemplatesCount : 0}</span></div>
                <div className="box-icons"><img src={sms_temp} className="img-resposive" alt="" /></div>
                <h4>SMS Templates</h4>
              </Link>
              :
              <div className="btn btn-dashsms btn-lg col-md-12 dashboard-nav-btn sms-bg" >
                <div className="ribbon"><span>{this.state.dashboard.mySMSTemplatesCount ? this.state.dashboard.mySMSTemplatesCount : 0}</span></div>
                <div className="box-icons"><img src={sms_temp} className="img-resposive" alt="" /></div>
                <h4>SMS Templates</h4>
              </div>
            }
          </div>
          
          <div className="dashboard-btn-box">
            {this.state.psites ?
              <Link className="btn btn-dashsite btn-lg col-md-12 dashboard-nav-btn mysite-bg" role="button" to="/sites">
                <div className="ribbon"><span>{this.state.dashboard.mySitesCount}</span></div>
                <div className="box-icons"><img src={site} className="img-resposive" alt="" /></div>
                <h4>My Sites</h4>
              </Link>
              :
              <div className="btn btn-dashsite btn-lg col-md-12 dashboard-nav-btn mysite-bg" >
                <div className="ribbons"><span>{this.state.dashboard.mySitesCount}</span></div>
                <div className="box-icons"><img src={site} className="img-resposive" alt="" /></div>
                <h4>My Sites</h4>
              </div>
            }
          </div>
          <div className="dashboard-btn-box">
              <Link className="btn btn-dashleads btn-lg col-md-12 dashboard-nav-btn lead-bg" to="/stats/csvlisting" >
                <div className="ribbon"><span>{this.state.signedLeads}</span></div>
                <div className="box-icons"><img src={require('../../assets/images/signed.png')} className="img-resposive" alt="" /></div>
                <h4>Signed Leads</h4>
              </Link>
          </div>
        
            </React.Fragment>
          
            }
           
          </div>
          {/* dashboard page tables */}
          <div className="dashboard-tables">
            <div className="row">
            <div className="row pad-top20">
              <div className="col-md-6">
                <div className="table-heading">
                  <h4>{title}</h4>
                </div>
                {this.state.EData.length > 0 ? <HighChartStats data={this.state.EData} /> : ''}
              </div>
              <div className="col-md-6">
                <div className="table-heading">
                  <h4>{titles}</h4>
                </div>
                {this.state.SData.length > 0 ? <HighChartStats data={this.state.SData} /> : ''}
              </div>
            </div>
              {this.state.calLogs ? 
              <div className="col-md-6">
                <div className="login-log-table">
                  <div className="table-heading">
                    <h4>Login Log</h4>
                  </div>
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered table-condensed">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">User</th>
                            <th scope="col">Ip Address</th>
                            <th scope="col">Login Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.loginLogs.length > 0 ?
                              this.state.loginLogs.map((val, key) => {
                                if (val.user !== null) {
                                  return (
                                    <tr key={key}>
                                      <th scope="row">{key + 1}</th>
                                      <td>{val.firstName} {val.lastName}</td>
                                      <td>{val.ip}</td>
                                      <td>{moment(val.createdAt).format("MMM Do YYYY h:m a")}</td>
                                    </tr>
                                  )
                                }

                              }) : <tr ><td colSpan="4" style={{ textAlign: "center" }}> No Record</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> : ""}
              {this.state.system_log ? 
              <div className="col-md-6">
                <div className="login-log-table">
                  <div className="table-heading">
                    <h4>System Log</h4>
                  </div>
                  <div className="table-wrap">
                    <div className="table-responsive">
                      <table className="table table-hover table-striped table-bordered table-condensed">
                        <tbody>
                          {this.state.roleIn === 'admin' ?
                            ''
                            :
                            <tr>
                              <th>Mailgun</th>
                              <td>{this.state.dashboard.isMailgun ? <span className="yestick">Yes</span> : <span className="noCross">No</span>}</td>
                            </tr>
                          }
                          {this.state.roleIn === 'admin' ?
                            ''
                            :
                            <tr>
                              <th>SMS Local</th>
                              <td>{this.state.dashboard.smsLocalStatus ? <span className="yestick">Yes</span> : <span className="noCross">No</span>}</td>
                            </tr>
                          }
                          {this.state.roleIn === 'admin' ?
                            ''
                            :
                            <tr>
                              <th>Twilio</th>
                              <td>{this.state.dashboard.TwilioStatus ? <span className="yestick">Yes</span> : <span className="noCross">No</span>}</td>
                            </tr>
                          }
                          <tr>
                            <th>Total CSV's</th>
                            <td>{this.state.dashboard.CSVCount}</td>
                          </tr>
                          <tr>
                            <th>Total Emails Sent</th>
                            <td>{this.state.dashboard.sumEmails ? this.state.dashboard.sumEmails[0].sum : 0}</td>
                          </tr>
                          <tr>
                            <th>Total SMS Sent</th>
                            <td>{this.state.dashboard.sumSMS ? this.state.dashboard.sumSMS[0].sum : 0}</td>
                          </tr>
                          <tr>
                            <th>Total Sites</th>
                            <td>{this.state.dashboard.mySitesCount}</td>
                          </tr>
                          <tr>
                            <th>Total Templates</th>
                            <td>{(this.state.dashboard.myEmailTemplatesCount ? this.state.dashboard.myEmailTemplatesCount : 0) + (this.state.dashboard.mySMSTemplatesCount ? this.state.dashboard.mySMSTemplatesCount : 0)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>: ""}
            </div>
          
          </div>
        </div>
      </React.Fragment>
    )
  }
}
