/*global chrome*/
import React, { Component } from 'react';
import $ from 'jquery';
import {ENV} from '../../env';
import Notifications, { notify } from 'react-notify-toast';
import { retry } from 'async';
window.jQuery = window.$ = $;
const { OAuth } = require("../../config/config");
var clientId = OAuth.clientId;
const scopes = "https://www.googleapis.com/auth/cloudprint"
var pickerApiLoaded = false;

var url = ENV.url;

export default class CreateTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rolesAdded: [],
            filePages: [],
            isModalOpen: false,
            loadGdrive: false
        }
    }

    async componentDidMount() {
        var th = this;
        if (true) //extension installed
        {
           th.setState({ isModalOpen: false, loadGdrive: true })
        }
        else //extension not installed
        {
            th.setState({ loadGdrive: false })
            console.log("Please consider installig extension");
        }
        var googleScript = document.createElement('script')
        googleScript.src = `https://apis.google.com/js/api.js?onload=onApiLoad`
        window.document.body.appendChild(googleScript)
        googleScript.onload = () => {
            window.gapi.load('auth2', onAuthApiLoad);
            window.gapi.load('picker', onPickerApiLoad);
        }
        async function onAuthApiLoad() {
            var authBtn = document.getElementById('auth');
            authBtn.disabled = false;

            if (th.state.loadGdrive === true) {
                authBtn.addEventListener('click',async function () {
                    var tokenCheck = await th.isValid();
                    if(tokenCheck){
                        th.sendToGeneratePrint();
                        return
                    }
                    window.gapi.auth2.init({ client_id: clientId }).then(function (googleAuth) {
                        googleAuth.signIn({ scope: scopes }).then(function (result) {
                            handleAuthResult(result.getAuthResponse());
                        })
                    })
                });
            }
        }
        function onPickerApiLoad() {
            pickerApiLoaded = true;
            // createPicker();
        }
        function handleAuthResult(authResult) {
            var token = JSON.parse(localStorage.getItem("token")); 
            console.log(authResult);
            if (authResult && !authResult.error) {
                let oauthToken = authResult.access_token;
                let oauthTokenDetail = authResult;
                console.log(authResult);
                fetch(url + 'print/through-url', {
                                    method: 'post',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': token.tokenType + ' ' + token.accessToken,
                                    }, body: JSON.stringify({
                                        access_token : authResult
                                    })
                                }).then(res => res.json()).then(
                                    (files) => {
                                        if(files.code == 200){
                                            notify.show(files.message, "success", 2000);
                                        }
                                    },
                                    (error) => {
                                        console.log(error);
                                    });
            }
        }
    }


    async isValid(){
        return new Promise((resolve, reject) => {
            var token = JSON.parse(localStorage.getItem("token"));  
            fetch(url + 'cloud/TokenVaild', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token.tokenType + ' ' + token.accessToken,
                }
            }).then(res => res.json()).then(
                (files) => {
                    if(files.status === false){
                        resolve(false)  
                    }else{
                        resolve(true)  
                    }
                },
                (error) => {
                    debugger
                    resolve(false)  
                });
          })  
    }


    sendToGeneratePrint(){
        var token = JSON.parse(localStorage.getItem("token")); 
        fetch(url + 'print/through-url', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,
            }, body: JSON.stringify({
               
            })
        }).then(res => res.json()).then(
            (files) => {
                if(files.code == 200){
                    notify.show(files.message, "success", 2000);
                }
            },
            (error) => {
                console.log(error);
            });
    }

    onModalClose() {
        this.setState({ isModalOpen: false })
    }

    render() {
        var th = this;
        return (
            <div >
                {th.state.loadGdrive ? <div>
                    <button type="button" id="auth" className="gdrive_btn" disabled>
                    <i class="fa fa-print" aria-hidden="true"></i>
                        {/* <img src="https://help.uis.cam.ac.uk/service/printing/DSPrintGooglePrint/copy_of_G-Suite-@Cambridge-Printing/GoogleCloudPrint/image" /> */}
                    </button>
                    <div id="result" style={{ "display": "none" }}></div>
                </div>
                    :
                    <div>
                        <button type="button" id="auth" className="gdrive_btn" disabled>
                        <i class="fa fa-print" aria-hidden="true"></i>
                            {/* <img onClick={(event) => this.setOpenChromExt()} src="https://help.uis.cam.ac.uk/service/printing/DSPrintGooglePrint/copy_of_G-Suite-@Cambridge-Printing/GoogleCloudPrint/image" /> */}
                        </button> <div id="result" style={{ "display": "none" }}></div>

                    </div>
                }
            </div>
        );
    }
}
