import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './alluser.css';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import AllUserService from '../../services/alluser.service';
import { setTimeout } from 'timers';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import Switch from "react-switch";
import { object } from 'twilio/lib/base/serialize';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';
import { throws } from 'assert';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class AllUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createUserModel: false,
            openRole: false,
            role: '',
            editUserModal: false,
            editInnerModal: false,
            firstname: '',
            lastname: '',
            business: '',
            email: '',
            phone: "+92",
            password: '',
            conpassword: '',
            gender: 'Male',
            userIDforUpdate: '',
            errors: '',
            allusersArray: [],

            agentLeadSearch: [],
            unsubscribe: [],
            claimLink: [],

            isloading: false,
            smtpSetting: false,
            smtpSetting_Status: 'R',
            smsSetting: false,
            smsSetting_Status: 'R',
            Template: false,
            Template_Status: 'R',
            emailTemplate: false,
            emailTemplate_Status: 'R',
            smsTemplate: false,
            smsTemplate_Status: 'R',
            Sites: false,
            Sites_Status: 'R',
            leadsListing: false,
            leadsListing_Status: 'R',
            sendSms: false,
            sendSms_Status: 'A',
            bitlaySetting: false,
            bitlaySetting_Status: 'R',
            csv: false,
            csv_Status: 'R',
            getPackage: false,
            sendMessage: false,
            dashboardperm: false,
            formperm: false,
            banksperm: false,
            templateperm: false,
            signatureperm: false,
            paymenhistoryperm: false,
            billingperm: false,
            lead_sms_credit: false,
            agentleadsperm: false,
            unsubscribersperm: false,
            userperm: false,

            email_status: true,
			sms_status: true,
			campaignsperm: true,

            permId: '',
            isTemplate: false,
            isSubUsers: false,
            iseditPermission: false,
            allusersArrayDuplicate: [],

            pandaLoader: true,

            usersearch123: "",
            isAgent: 'inactive',
            status: 'inactive',
            editstatus: 'inactive',
            editagent: 'inactive',
        }
        this.alluserService = new AllUserService();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let loginUser = JSON.parse(localStorage.getItem('user'));
        this.setState({
            pandaLoader: false
        })
        if (loginUser.role === 'subUser') {

            // this.props.history.push('*');
        } else {
            this.setState({ pandaLoader: false })
        }
        this.setState({
            role: loginUser.role
        })
        debugger
        if (loginUser.role === 'user' || loginUser.role === 'subUser') {
            let userId = loginUser.id;
            this.allSubUsersAPI(userId);
            this.setState({
                isSubUsers: true
            })
        } else {
            this.allUsersAPI();
        }
    }

    onchangeUserActive(check) {
        if (!check) {
            this.setState({
                status: 'inactive',
            });
        } else {
            this.setState({
                status: 'active',
            });
        }
    }

    onChangeIfAgent(check) {
        if (!check) {
            this.setState({
                isAgent: 'inactive',
            });
        } else {
            this.setState({
                isAgent: 'active',
            });
        }
    }

    onchangeUserEditActive(check) {
        if (!check) {
            this.setState({
                editstatus: 'inactive',
            });
        } else {
            this.setState({
                editstatus: 'active',
            });
        }
    }


    onchangeUserEditPermission(key, check) {
        var agentLeadSearch = this.state.agentLeadSearch;
        agentLeadSearch[key].check = check ? false : true;
        this.setState({
            agentLeadSearch
        })
        console.log(agentLeadSearch);
    }

    onchangeUserEditPermissionUnsubscribe(check, key, type) {
        var unsubscribe = this.state.unsubscribe;
        if (type == "check") {
            unsubscribe[key].check = check ? false : true;
            this.setState({
                unsubscribe
            })
        } else {
            unsubscribe[key].link = check.target.value;
            this.setState({
                unsubscribe
            })
        }
    }


    onchangeUserEditPermissionClaim(check, key, type) {
        var claimLink = this.state.claimLink;
        if (type == "check") {
            claimLink[key].check = check ? false : true;
            this.setState({
                claimLink
            })
        } else {
            claimLink[key].link = check.target.value;
            this.setState({
                claimLink
            })
        }
    }




    UpdateEditPermission() {
        let data1 = {
            agentLeadSearch: this.state.agentLeadSearch,
            unsubscribe: this.state.unsubscribe,
            claimLink: this.state.claimLink
        }
        this.alluserService.editPermission(this.state.permissionId, data1).then(data => {
            console.log("editeditediteditedit", data)
            this.setState({
                editInnerModal: false
            })
        }).catch(err => {
            console.log(err)
        })
    }

    onchangeAgentEditActive(check) {
        if (!check) {
            this.setState({
                editagent: 'inactive',
            });
        } else {
            this.setState({
                editagent: 'active',
            });
        }
    }

    switchHandler = async (name, e) => {

        await this.setState({
            [name]: e
        })
        if (this.state.emailTemplate || this.state.smsTemplate) {
            this.setState({
                Template: true,
                isTemplate: true
            })
        }
        if (!this.state.emailTemplate && !this.state.smsTemplate) {
            this.setState({
                Template: false,
                isTemplate: false
            })
        }

    }
    selectHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    onCloseRoleModal = () => {
        this.setState({
            openRole: false,
            smtpSetting: false,
            smtpSetting_Status: 'R',
            smsSetting: false,
            smsSetting_Status: 'R',
            Template: false,
            Template_Status: 'R',
            emailTemplate: false,
            emailTemplate_Status: 'R',
            smsTemplate: false,
            smsTemplate_Status: 'R',
            Sites: false,
            Sites_Status: 'R',
            leadsListing: false,
            leadsListing_Status: 'R',
            bitlaySetting: false,
            bitlaySetting_Status: 'R',
            csv: false,
            csv_Status: 'R',
            outbox: false,
            outbox_status: 'R',
            logs: false,
            logs_status: 'R',
            getPackage: false,
            sendMessage: false,
            dashboardperm: false,
            permId: '',
            isTemplate: false,
            isSubUsers: false,
            iseditPermission: false,
            createUserModel: false
        })
    }
    allUsersAPI() {
        this.setState({
            isloading: true
        })
        this.alluserService.allUsers().then(data => {

            if (data.code === 200) {
                this.setState({
                    allusersArray: data.data,
                    allusersArrayDuplicate: data.data,
                    isloading: false
                })
            }
            else if (data.code === 404) {
                this.setState({
                    allusersArray: [],
                    allusersArrayDuplicate: [],
                    isloading: false
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                isloading: false
            })
        })
    }
    allSubUsersAPI(userId) {
        var th = this;
        this.setState({
            isloading: true
        })

        this.alluserService.allSubUsers(userId).then(data => {

            debugger
            if (data.code === 200) {
                th.setState({
                    allusersArray: data.data,
                    isloading: false
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }
    getPermission = (id) => {
        this.setState({
            isloading: true
        })
        this.alluserService.userPermission(id).then(data => {

            if (data.code === 200) {
                let result = data.data;
                this.setState({
                    Sites: result.Sites,
                    Sites_Status: result.Sites_Status,
                    Template: result.Template,
                    Template_Status: result.Template_Status,
                    emailTemplate: result.emailTemplate,
                    emailTemplate_Status: result.emailTemplate_Status,
                    leadsListing: result.leadsListing,
                    leadsListing_Status: result.leadsListing_Status,
                    smsSetting: result.smsSetting,
                    smsSetting_Status: result.smsSetting_Status,
                    smsTemplate: result.smsTemplate,
                    smsTemplate_Status: result.smsTemplate_Status,
                    smtpSetting: result.smtpSetting,
                    smtpSetting_Status: result.smtpSetting_Status,
                    bitlaySetting: result.bitlaySetting,
                    bitlaySetting_Status: result.bitlaySetting_Status,
                    csv: result.csv,
                    csv_Status: result.csv_Status,
                    outbox: result.outbox,
                    outbox_Status: result.outbox_Status,
                    logs: result.logs,
                    logs_status: result.logs_status,
                    getPackage: result.getPackage,
                    sendMessage: result.sendMessage,
                    dashboardperm: result.dashboardperm,
                    formperm: result.formperm,
                    banksperm: result.banksperm,
                    templateperm: result.templateperm,
                    signatureperm: result.signatureperm,
                    paymenhistoryperm: result.paymenhistoryperm,
                    billingperm: result.billingperm,
                    lead_sms_credit: result.lead_sms_credit,

                    email_status: result.email_status,
                    sms_status: result.sms_status,
                    campaignsperm: result.campaignsperm,
          
		

                    agentleadsperm: result.agentleadsperm,
                    unsubscribersperm: result.unsubscribersperm,
                    userperm: result.userperm,
                    permId: result._id,
                    isTemplate: (result.emailTemplate || result.smsTemplate) ? true : false,
                    isloading: false,
                    iseditPermission: true,
                    openRole: true,
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }
    createUserOpenModal = () => {
        this.setState({
            createUserModel: true,
            firstname: '',
            lastname: '',
            business: '',
            email: '',
            password: '',
            gender: 'Male',
            phone: '+92',
            conpassword: '',
            isloading: false,
            status: 'inactive',
            errors: ""
        });
    };

    createUserCloseModel = () => {
        this.setState({ createUserModel: false });
    };

    editUserOpenModal = () => {
        this.setState({ editUserModal: true });
    };

    editUserCloseModel = () => {
        this.setState({ editUserModal: false, errors: "", usersearch123: "" });
    };


    editInnerModalOpenModal = (id) => {
        this.alluserService.getInnerPermission(id).then(data => {
            console.log("editeditediteditedit", data)
            this.setState({
                permissionId: data.data._id,
                agentLeadSearch: data.data.agentLeadSearch,
                unsubscribe: data.data.unsubscribe,
                claimLink: data.data.claimLink,
                editInnerModal: true
            })
        }).catch(err => {
            console.log(err)
        })
    };

    editInnerModalCloseModel = () => {
        this.setState({ editInnerModal: false });
    };


    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    subUserSubmit = () => {
        let loginUser = JSON.parse(localStorage.getItem('user'));
        let userId = loginUser.id;
        let user = {
            firstName: this.state.firstname,
            lastName: this.state.lastname,
            email: this.state.email,
            password: this.state.password,
            gender: this.state.gender,
            phone: this.state.phone,
            status: this.state.status,
            role: 'subUser',
            parentId: userId
        }
        let permission = {
            smtpSetting: this.state.smtpSetting,
            smtpSetting_Status: this.state.smtpSetting_Status,
            smsSetting: this.state.smsSetting,
            smsSetting_Status: this.state.smsSetting_Status,
            Template: this.state.Template,
            Template_Status: this.state.Template_Status,
            emailTemplate: this.state.emailTemplate,
            emailTemplate_Status: this.state.emailTemplate_Status,
            smsTemplate: this.state.smsTemplate,
            smsTemplate_Status: this.state.smsSetting_Status,
            Sites: this.state.Sites,
            Sites_Status: this.state.Sites_Status,
            leadsListing: this.state.leadsListing,
            leadsListing_Status: this.state.leadsListing_Status,
            bitlaySetting: this.state.bitlaySetting,
            bitlaySetting_Status: this.state.bitlaySetting_Status,
            csv: this.state.csv,
            csv_Status: this.state.csv_Status,
            outbox: this.state.outbox,
            outbox_Status: this.state.outbox_Status,
            logs: this.state.logs,
            logs_status: this.state.logs_status,
            getPackage: this.state.getPackage,
            sendMessage: this.state.sendMessage,
            dashboardperm: this.state.dashboardperm,
            formperm: this.state.formperm,
            banksperm: this.state.banksperm,
            templateperm: this.state.templateperm,
            signatureperm: this.state.signatureperm,
            paymenhistoryperm: this.state.paymenhistoryperm,
            billingperm: this.state.billingperm,
            lead_sms_credit:  this.state.lead_sms_credit,
            email_status: this.state.email_status,
            sms_status: this.state.sms_status,
            campaignsperm: this.state.campaignsperm,
            agentleadsperm: this.state.agentleadsperm,
            unsubscribersperm: this.state.unsubscribersperm,
            userperm: this.state.userperm,
            //sendMessage_Status: this.state.sendMessage_Status
        }
        let subUser = { user, permission };
        console.log('sub user is', subUser);
        this.alluserService.createSubUser(subUser).then(data => {

            console.log('sub user is', data);
            this.setState({
                isloading: true
            });
            if (data.code === 200) {
                this.createUserCloseModel();
                this.setState({
                    firstname: '',
                    lastname: '',
                    email: '',
                    password: '',
                    gender: '',
                    phone: '',
                    conpassword: '',
                    isloading: false,
                    smtpSetting: false,
                    smtpSetting_Status: 'R',
                    smsSetting: false,
                    smsSetting_Status: 'R',
                    Template: false,
                    Template_Status: 'R',
                    emailTemplate: false,
                    emailTemplate_Status: 'R',
                    smsTemplate: false,
                    smsTemplate_Status: 'R',
                    Sites: false,
                    Sites_Status: 'R',
                    leadsListing: false,
                    leadsListing_Status: 'R',
                    bitlaySetting: false,
                    bitlaySetting_Status: 'R',
                    csv: false,
                    csv_Status: 'R',
                    outbox: false,
                    outbox_status: 'R',
                    logs: false,
                    logs_status: 'R',
                    getPackage: false,
                    sendMessage: false,
                    dashboardperm: false,
                    formperm: false,
                    banksperm: false,
                    templateperm: false,
                    signatureperm: false,
                    paymenhistoryperm: false,
                    billingperm: false,
                    lead_sms_credit: false,
                    agentleadsperm: false,
                    unsubscribersperm: false,
                    userperm: false,
                    isTemplate: false,
                    openRole: false,
                    errors: ""
                });

                this.onCloseRoleModal();
                this.allSubUsersAPI(loginUser.id);
                setTimeout(() => {
                    // notify.show(data.message, "success", 1000);
                }, 3000);
            }

            if (data.code === 406) {
                this.setState({
                    openRole: false,
                    createUserModel: true,
                    errors: data.message
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    onchangeSearch(e) {
        var current = this;
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let myArray = this.state.allusersArrayDuplicate;
            let data = myArray.filter(x => {
                return (x.email.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch123.replace(/\s+/g, '').toLowerCase()) ||
                    (x.business ? x.business.replace(/\s+/g, '') : "").toLowerCase().includes(current.state.usersearch123.replace(/\s+/g, '').toLowerCase()) ||
                    (x.firstName.replace(/\s+/g, '') + x.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch123.replace(/\s+/g, '').toLowerCase())

            })
            current.setState({
                allusersArray: data
            })
        })
    }

    addUserSubmit(e) {
        e.preventDefault();


        const { gender, email, business, password, conpassword, phone, firstname, lastname } = this.state;

        //var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (firstname === '') {
            this.setState({ errors: 'First Name is required ' });
            return;
        }
        else if (lastname === '') {
            this.setState({ errors: 'LastName is required ' });
            return;
        } else if (business === '' && this.state.role === 'admin') {
            debugger;
            this.setState({ errors: 'Business is required ' });
            return;
        }
        else if (email === '') {
            this.setState({ errors: 'Email is required' });
            return;
        }


        else if (!email.match(regexp)) {
            this.setState({ errors: 'Please enter a valid email' });
            return;
        }
        else if (phone === "") {
            this.setState({ errors: 'Phone number is required' });
            return;
        } 
        // else if (!isValidPhoneNumber(phone)) {
        //     this.setState({ errors: 'Invaild Phone' });
        //     return;
        // } 
        else if (gender === '') {
            this.setState({ errors: 'Please Enter Gender' });
            return;
        } else if (password === "" && password.length < 6) {
            this.setState({ errors: 'Passwords must be at least 6 characters long' });
            return;
        }
        else if (password !== "" && password.length < 6) {
            this.setState({ errors: 'Passwords must be at least 6 characters long' });
            return;
        }
        else if (password !== "" && conpassword === '') {
            console.log("erject")

            this.setState({ errors: 'Confirm passwords must be at least 6 characters long' });
            return;

        }
        else if (password !== conpassword) {
            console.log("erject")

            this.setState({ errors: 'Password does not match' });
            return;

        } else {

            this.setState({ errors: '' });
            let loginUser = JSON.parse(localStorage.getItem('user'));
            let userId = loginUser.id;
            let body = {
                firstName: this.state.firstname,
                lastName: this.state.lastname,
                // business: this.state.business,
                email: this.state.email,
                password: this.state.password,
                gender: this.state.gender,
                phone: this.state.phone,
                parentId: userId,
                status: this.state.status,
                isAgent: this.state.isAgent,
            }
            if (this.state.role === 'admin') {
                body.business = this.state.business
            }
            console.log('bodybodybodybody', body);

            var user = JSON.parse(localStorage.getItem('user'));

            if (user.role === 'admin') {
                this.alluserService.createUser(body).then(data => {

                    this.setState({
                        isloading: true
                    });
                    if (data.code === 403) {
                        this.setState({
                            errors: data.message
                        })
                    }
                    if (data.code === 406) {
                        this.setState({
                            errors: data.message
                        })
                    }
                    if (data.code === 201) {
                        this.createUserCloseModel();
                        this.setState({
                            firstname: '',
                            lastname: '',
                            business: '',
                            email: '',
                            password: '',
                            gender: '',
                            phone: '',
                            status: 'inactive',
                            isAgent: 'inactive',
                            conpassword: '',
                            isloading: false,
                            errors: ""
                        })
                        this.allUsersAPI();
                        setTimeout(() => {
                            // notify.show(data.message, "success", 1000);
                        }, 3000);
                    }
                }).catch(err => {
                    console.log(err)
                })

            }
            if (user.role === 'user') {

                this.setState({
                    openRole: true
                })

            }


        }
    }

    editUser(id) {

        this.alluserService.editUser(id).then(data => {
            console.log("editeditediteditedit", data)

            this.setState({
                firstname: data.data.firstName,
                lastname: data.data.lastName,
                business: this.state.role === 'admin' ? data.data.business : '',
                email: data.data.email,
                phone: data.data.phone,
                gender: data.data.gender,
                userIDforUpdate: data.data._id,
                editstatus: data.data.status,
                editagent: data.data.isAgent,
                editUserModal: true
            })
        }).catch(err => {
            console.log(err)
        })

        this.editUserOpenModal();

    }
    editPermission = (e) => {
        let loginUser = JSON.parse(localStorage.getItem('user'));
        let parentId = loginUser.id;
        let Id = this.state.permId;
        let permission = {
            parentId: parentId,
            smtpSetting: this.state.smtpSetting,
            smtpSetting_Status: this.state.smtpSetting_Status,
            smsSetting: this.state.smsSetting,
            smsSetting_Status: this.state.smsSetting_Status,
            Template: this.state.Template,
            Template_Status: this.state.Template_Status,
            emailTemplate: this.state.emailTemplate,
            emailTemplate_Status: this.state.emailTemplate_Status,
            smsTemplate: this.state.smsTemplate,
            smsTemplate_Status: this.state.smsSetting_Status,
            Sites: this.state.Sites,
            Sites_Status: this.state.Sites_Status,
            leadsListing: this.state.leadsListing,
            leadsListing_Status: this.state.leadsListing_Status,
            bitlaySetting: this.state.bitlaySetting,
            bitlaySetting_Status: this.state.bitlaySetting_Status,
            csv: this.state.csv,
            csv_Status: this.state.csv_Status,
            outbox: this.state.outbox,
            outbox_Status: this.state.outbox_Status,
            logs: this.state.logs,
            logs_status: this.state.logs_status,
            getPackage: this.state.getPackage,
            sendMessage: this.state.sendMessage,
            dashboardperm: this.state.dashboardperm,
            formperm: this.state.formperm,
            banksperm: this.state.banksperm,
            templateperm: this.state.templateperm,
            signatureperm: this.state.signatureperm,
            paymenhistoryperm: this.state.paymenhistoryperm,
            billingperm: this.state.billingperm,
            lead_sms_credit: this.state.lead_sms_credit,

            email_status: this.state.email_status,
            sms_status: this.state.sms_status,
            campaignsperm: this.state.campaignsperm,

            agentleadsperm: this.state.agentleadsperm,
            unsubscribersperm: this.state.unsubscribersperm,
            userperm: this.state.userperm,
        }
        console.log("permission", permission);
        this.alluserService.updatePermission(Id, permission).then(data => {
            console.log(data, "edit done");
            if (data.code === 200) {
                this.setState({
                    smtpSetting: false,
                    smtpSetting_Status: 'R',
                    smsSetting: false,
                    smsSetting_Status: 'R',
                    Template: false,
                    Template_Status: 'R',
                    emailTemplate: false,
                    emailTemplate_Status: 'R',
                    smsTemplate: false,
                    smsTemplate_Status: 'R',
                    Sites: false,
                    Sites_Status: 'R',
                    leadsListing: false,
                    leadsListing_Status: 'R',
                    bitlaySetting: false,
                    bitlaySetting_Status: 'R',
                    csv: false,
                    csv_Status: 'R',
                    outbox: false,
                    outbox_Status: 'R',
                    logs: false,
                    logs_status: 'R',
                    getPackage: false,
                    sendMessage: false,
                    dashboardperm: false,
                    formperm: false,
                    banksperm: false,
                    templateperm: false,
                    signatureperm: false,
                    paymenhistoryperm: false,
                    billingperm: false,
                    agentleadsperm: false,
                    unsubscribersperm: false,
                    userperm: false,
                    permId: '',
                    iseditPermission: false,
                    openRole: false,
                });
                this.allSubUsersAPI(parentId);
                // notify.show(data.message, "success", 3000)
            }

        }).catch(err => {
            console.log(err)
        })

    }

    editUserSubmit(e) {
        e.preventDefault();
        debugger
        const { gender, email, password, conpassword, phone, firstname, lastname, business } = this.state;
        e.preventDefault();
        //var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (firstname === '') {
            this.setState({ errors: 'Firstname is required ' });
            return;
        }
        else if (lastname === '') {
            this.setState({ errors: 'Lastname is required ' });
            return;
        }
        else if (business === '' && this.state.role === 'admin') {
            this.setState({ errors: 'Business is required ' });
            return;
        }
        else if (email === '') {
            this.setState({ errors: 'Email is required' });
            return;
        }


        else if (!email.match(regexp)) {
            this.setState({ errors: 'Email is invalid' });
            return;
        } else if (phone === '') {
            this.setState({ errors: 'Phone number is required!' });
            return;
        }
        // else if (!isValidPhoneNumber(phone)) {
        //     this.setState({ errors: 'Invaild Phone' });
        //     return;
        // }
        else if (gender === '') {
            this.setState({ errors: 'Please Enter Gender' });
            return;
        }
        // else if(password === "" && password.length <6){
        // 	this.setState({ errors: 'Enter 6 Character Password' });
        // 		return;
        // }
        else if (password !== "" && password.length < 6) {
            this.setState({ errors: 'Passwords must be at least 6 characters long' });
            return;
        }
        else if (password !== "" && conpassword === '') {
            console.log("erject")

            this.setState({ errors: 'Passwords must be at least 6 characters long' });
            return;

        }
        else if (password !== conpassword) {
            console.log("erject")

            this.setState({ errors: 'Conform Password does not match' });
            return;

        } else {
            let body = {
                firstName: this.state.firstname,
                lastName: this.state.lastname,
                business: this.state.role === 'admin' ? this.state.business : '',
                gender: this.state.gender,
                email: this.state.email,
                phone: this.state.phone,
                password: this.state.password,
                status: this.state.editstatus,
                isAgent: this.state.editagent,
            }
            this.alluserService.updateUser(this.state.userIDforUpdate, body).then(data => {

                if (data.code === 403) {
                    this.setState({
                        errors: data.message
                    })
                }

                if (data.code === 406) {
                    this.setState({
                        errors: data.message
                    })
                }
                if (data.code === 200) {
                    this.setState({
                        errors: '',
                        firstname: '',
                        lastname: '',
                        business: '',
                        gender: '',
                        phone: '',
                        email: '',
                        password: '',
                        editstatus: 'inactive',
                        editagent: 'inactive',
                        editUserModal: false
                    });
                    this.allUsersAPI();
                    notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    deleteUser(id) {

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-style modal-delete">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body modal-delete-body">
                                    <h2>Are you sure?</h2>
                                    <p>You want to delete this file?</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
                                    <button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(id); onClose(); }}>Yes, Delete it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });

    }
    deletesubUser(id) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="modal-style modal-delete">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body modal-delete-body">
                                    <h2>Are you sure?</h2>
                                    <p>You want to delete this User?</p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
                                    <button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.delSubUser(id); onClose(); }}>Yes, Delete it!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    conformDelete(id) {

        this.alluserService.delUser(id).then(data => {
            console.log(data);
            if (data.code === 200) {
                this.allUsersAPI();
                // notify.show(data.message, "success", 3000)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    delSubUser(id) {
        let loginUser = JSON.parse(localStorage.getItem('user'));
        this.alluserService.delSubUser(id).then(data => {
            console.log(data);
            if (data.code === 200) {
                this.allSubUsersAPI(loginUser.id);
                // notify.show(data.message, "success", 3000)
            }
        }).catch(err => {
            console.log(err)
        })
    }


    validatePhoneNumber(phone) {

        var re = /^\d{11}$/;
        console.log(re.test(String(phone).toLowerCase()))
        return re.test(String(phone).toLowerCase())
    }

    render() {
        console.log(this.state.role);
        var th = this;
        return (

            <React.Fragment>
                {this.state.pandaLoader === true ?
                    <div className="loading">Loading&#8230;</div>
                    : ""}
                <Notifications options={{ zIndex: 200, top: '20px' }} />

                <div className="content-page template-tab" id="sitetable">

                    <div className="content-header">

                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div className="page-heading">
                                    <h3>All Users </h3>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
                                    <div className="head-search">
                                        <input type="text" name="usersearch123" placeholder="Search" value={this.state.usersearch123} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
                                    </div>
                                    <div className="add-btn text-right">
                                        {/* <!-- Add Site Modal --> */}
                                        {this.state.role != "subUser" ?
                                            <button type="button" onClick={() => this.createUserOpenModal()} className="btn-style-md btn-purple btn-round">Add New User</button>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-wrap">
                        <div className="table-responsive">
                            <table className="table table-hover table-striped table-bordered table-condensed">
                                <thead className="thead-light">


                                    <tr>
                                        <th>#</th>
                                        {this.state.role === 'admin' ?
                                            <th>Business Name</th>
                                            :
                                            ''
                                        }
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Gender</th>

                                        {/* <th>Phone</th> */}
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        th.state.allusersArray.length > 0 ?
                                            th.state.allusersArray.map((val, key) => {
                                                if (val.role !== "admin" && val.role === "user" && val.role !== 'subUser') {
                                                    return (
                                                        <tr key={key}>
                                                            <th title={key + 1}>{key + 1}</th>
                                                            <td title={val.business}>{val.business}</td>
                                                            <td title={val.firstName + val.lastName}>{val.firstName} {val.lastName}</td>
                                                            <td title={val.email}>{val.email}</td>
                                                            <td title={val.gender}>{val.gender}</td>
                                                            {/* <td>{val.phone}</td> */}
                                                            <td>
                                                                {val.status === 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}
                                                            </td>
                                                            <td>
                                                                <div className="table-option-btns">
                                                                    <button type="button" className="btn btn-table mr-5" onClick={() => this.editUser(val._id)}>
                                                                        <i className="fa fa-pencil-square-o"></i> Edit
                                                                </button>
                                                                    <button type="button" className="btn btn-table mr-5" onClick={() => this.editInnerModalOpenModal(val._id)}>
                                                                        <i className="fa fa-pencil-square-o"></i>Inner Permission
                                                                </button>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )

                                                } else if (val.role === 'subUser' && this.state.role !== 'admin') {
                                                    return (
                                                        <tr key={key}>
                                                            <th title={key + 1}>{key + 1}</th>
                                                            <td title={val.firstName + val.lastName}>{val.firstName} {val.lastName}</td>
                                                            <td title={val.email}>{val.email}</td>
                                                            <td title={val.gender}>{val.gender}</td>
                                                            {/* <td>{val.phone}</td> */}
                                                            <td>
                                                                {val.status === 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}
                                                            </td>
                                                            <td width="550">
                                                                <div className="table-option-btns">
                                                                    <button type="button" className="btn btn-table mr-5" onClick={() => this.editUser(val._id)}>
                                                                        <i className="fa fa-pencil-square-o"></i> Edit User
                                                            </button>
                                                                    <button type="button" className="btn btn-table mr-5" onClick={() => this.getPermission(val._id)}>
                                                                        <i className="fa fa-pencil-square-o"></i> Edit Permission
                                                            </button>
                                                                    <Link className="btn btn-table mr-5" to={`user/leadSubmit/${val._id}`}>
                                                                        <i className="fa fa-pencil-square-o"></i> View Leads
                                                            </Link>
                                                                    <Link className="btn btn-table mr-5" to={`unSubsribeAgent/${val._id}`}>
                                                                        <i className="fa fa-pencil-square-o"></i> Unsubscribe Leads
                                                            </Link>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                }


                                            }) : <tr >
                                                {this.state.role === 'admin' ?
                                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                                        {this.state.isloading ? <ClipLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={50}
                                                            color={'#123abc'}
                                                            loading={this.state.isloading}
                                                        /> : "No Record"}

                                                    </td>
                                                    :
                                                    <td colSpan="6" style={{ textAlign: "center" }}>
                                                        {this.state.isloading ? <ClipLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={50}
                                                            color={'#123abc'}
                                                            loading={this.state.isloading}
                                                        /> : "No Record"}

                                                    </td>
                                                }
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.createUserModel} onClose={this.createUserCloseModel} closeOnOverlayClick={false}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.createUserCloseModel()} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add User </h4>
                    </div>
                    <div className="modal-body">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div> : ''}
                        {/* <form className="add-template-modal"> */}
                        <div className="form-group row">
                            <label className="col-md-4">First Name</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="firstname" value={this.state.firstname || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Last Name</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="lastname" value={this.state.lastname || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        {this.state.role === 'admin' ?
                            <div className="form-group row">
                                <label className="col-md-4">Business</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="business" value={this.state.business || ''} onChange={(e) => this.onchange(e)} />
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="form-group row">
                            <label className="col-md-4">Email</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="email" value={this.state.email || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Phone</label>
                            <div className="col-md-8">
                                <ReactPhoneInput

                                    inputExtraProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                    placeholder="Enter phone number"

                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Gender</label>
                            <div className="col-md-8">
                                <select type="select" className="form-control" name="gender" onChange={(e) => this.onchange(e)} value={this.state.gender || ''} >
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                                {/* <input type="text" className="form-control" name="gender" value={this.state.gender || ''} onChange={(e) => this.onchange(e)} /> */}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Password</label>
                            <div className="col-md-8">
                                <input type="password" className="form-control" name="password" value={this.state.password || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Confirm Password</label>
                            <div className="col-md-8">
                                <input type="password" className="form-control" name="conpassword" value={this.state.conpassword || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Call center Agent</label>
                            <div className="col-md-8">
                                <Switch name="status"
                                    onChange={(e) => this.onChangeIfAgent(e)} checked={this.state.isAgent === 'active' ? true : false} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Status</label>
                            <div className="col-md-8">
                                <Switch name="status"
                                    onChange={(e) => this.onchangeUserActive(e)} checked={this.state.status === 'active' ? true : false} />
                            </div>
                        </div>


                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.addUserSubmit(e)}>Save</button>
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.createUserCloseModel()}>Close</button>
                    </div>
                </Modal>


                <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.editUserModal} onClose={this.editUserCloseModel}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.editUserCloseModel()} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Edit User</h4>
                    </div>
                    <div className="modal-body">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">
                                {this.state.errors}
                            </div> : ''}
                        {/* <form className="add-template-modal"> */}
                        <div className="form-group row">
                            <label className="col-md-4">First Name</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="firstname" value={this.state.firstname || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Last Name</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="lastname" value={this.state.lastname || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        {this.state.role === 'admin' ?
                            <div className="form-group row">
                                <label className="col-md-4">Business</label>
                                <div className="col-md-8">
                                    <input type="text" className="form-control" name="business" value={this.state.business || ''} onChange={(e) => this.onchange(e)} />
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="form-group row">
                            <label className="col-md-4">Email</label>
                            <div className="col-md-8">
                                <input type="text" className="form-control" name="email" value={this.state.email || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Phone</label>
                            <div className="col-md-8">
                                <ReactPhoneInput

                                    inputExtraProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                    placeholder="Enter phone number"

                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Gender</label>
                            <div className="col-md-8">
                                <select type="select" className="form-control" name="gender" onChange={(e) => this.onchange(e)} value={this.state.gender || ''} >
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                                {/* <input type="text" className="form-control" name="gender" value={this.state.gender || ''} onChange={(e) => this.onchange(e)} /> */}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Password</label>
                            <div className="col-md-8">
                                <input type="password" className="form-control" name="password" value={this.state.password || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4">Confirm Password</label>
                            <div className="col-md-8">
                                <input type="password" className="form-control" name="conpassword" value={this.state.conpassword || ''} onChange={(e) => this.onchange(e)} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Call center Agent</label>
                            <div className="col-md-8">
                                <Switch name="status"
                                    onChange={(e) => this.onchangeAgentEditActive(e)} checked={this.state.editagent === 'active' ? true : false} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-md-4">Status</label>
                            <div className="col-md-8">
                                <Switch name="status"
                                    onChange={(e) => this.onchangeUserEditActive(e)} checked={this.state.editstatus === 'active' ? true : false} />
                            </div>
                        </div>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.editUserSubmit(e)}>Save</button>
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.editUserCloseModel()}>Close</button>

                    </div>
                </Modal>




                <Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.editInnerModal} onClose={this.editInnerModalCloseModel}>
                    <div className="modal-header">
                        <button type="button" className="close" onClick={() => this.editInnerModalCloseModel()} data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Inner Permission</h4>
                    </div>
                    <div className="modal-body">

                        <div className="form-group row">
                            <label className="col-md-4">Agent Lead Search</label>
                        </div>

                        {

                            th.state.agentLeadSearch.length > 0 ?
                                th.state.agentLeadSearch.map((val, key) => {
                                    return (
                                        <div className="form-group row">
                                            <label className="col-md-4">{val.name}</label>
                                            <div className="col-md-8">
                                                <Switch name="status"
                                                    onChange={(e) => this.onchangeUserEditPermission(key, val.check)} checked={val.check ? true : false} />
                                            </div>
                                        </div>
                                    )
                                }) : ""
                        }


                        {

                            th.state.unsubscribe.length > 0 ?
                                th.state.unsubscribe.map((val, key) => {
                                    return (
                                        <div className="form-group row">
                                            <label className="col-md-4">{val.name}</label>
                                            <div className="col-md-8">
                                                <Switch name="status"
                                                    onChange={(e) => this.onchangeUserEditPermissionUnsubscribe(val.check, key, "check")} checked={val.check ? true : false} />
                                            </div>
                                            <div className="col-md-8">
                                                <input type="text" className="form-control" placeholder="Link" name="zip" value={val.link} onChange={(e) => this.onchangeUserEditPermissionUnsubscribe(e, key, "input")} />
                                            </div>
                                        </div>
                                    )
                                }) : ""
                        }


                        {

                            th.state.claimLink.length > 0 ?
                                th.state.claimLink.map((val, key) => {
                                    return (
                                        <div className="form-group row">
                                            <label className="col-md-4">{val.name}</label>
                                            <div className="col-md-8">
                                                <Switch name="status"
                                                    onChange={(e) => this.onchangeUserEditPermissionClaim(val.check, key, "check")} checked={val.check ? true : false} />
                                            </div>
                                            <div className="col-md-8">
                                                <input type="text" className="form-control" placeholder="Link" name="zip" value={val.link} onChange={(e) => this.onchangeUserEditPermissionClaim(e, key, "input")} />
                                            </div>
                                        </div>
                                    )
                                }) : ""
                        }





                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.UpdateEditPermission(e)}>Save</button>
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.editInnerModalCloseModel()} >Close</button>

                    </div>
                </Modal>



                <Modal open={this.state.openRole} onClose={this.onCloseRoleModal} id="roleassign-modal">
                    <div className="roleassign-modal">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={() => this.onCloseRoleModal()} data-dismiss="modal">&times;</button>
                            <h4 className="modal-title">Role Assignment</h4>
                        </div>
                        <div className="box-column">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>SMTP Setting</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="smtpSetting" onChange={(e) => this.switchHandler('smtpSetting', e)} checked={this.state.smtpSetting} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="smtpSetting_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.smtpSetting_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.smtpSetting_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>SMS Setting</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="smsSetting" onChange={(e) => this.switchHandler('smsSetting', e)} checked={this.state.smsSetting} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="smsSetting_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.smsSetting_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.smsSetting_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Bitly Setting</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="bitlaySetting" onChange={(e) => this.switchHandler('bitlaySetting', e)} checked={this.state.bitlaySetting} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="bitlaySetting_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.bitlaySetting_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.bitlaySetting_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* {this.state.isTemplate ? <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Template</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="Template" onChange={(e) => this.switchHandler('Template', e)} checked={this.state.Template} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="Template_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.Template_Status == 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.Template_Status == 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div> : ''} */}

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Email Template</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="emailTemplate" onChange={(e) => this.switchHandler('emailTemplate', e)} checked={this.state.emailTemplate} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="emailTemplate_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.emailTemplate_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.emailTemplate_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>SMS Template</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="smsTemplate" onChange={(e) => this.switchHandler('smsTemplate', e)} checked={this.state.smsTemplate} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="smsTemplate_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.smsTemplate_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.smsTemplate_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Sites</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="Sites" onChange={(e) => this.switchHandler('Sites', e)} checked={this.state.Sites} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="Sites_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.Sites_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.Sites_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Leads Listing</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="leadsListing" onChange={(e) => this.switchHandler('leadsListing', e)} checked={this.state.leadsListing} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" value={this.state.leadsListing_Status} name="leadsListing_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.leadsListing_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.leadsListing_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>All CSV</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="csv" onChange={(e) => this.switchHandler('csv', e)} checked={this.state.csv} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" value={this.state.csv_Status} name="csv_Status" onChange={this.selectHandler}>
                                            <option value="R" selected={(this.state.csv_Status === 'R') ? true : false}>Read</option>
                                            <option value="RW" selected={(this.state.csv_Status === 'RW') ? true : false}>Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Out Box</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="outbox" onChange={this.switchHandler.bind(this, 'outbox')} checked={this.state.outbox} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="outbox_status" onChange={this.selectHandler}>
                                            <option value="R">Read</option>
                                            <option value="RAW">Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Logs</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="logs" onChange={this.switchHandler.bind(this, 'logs')} checked={this.state.logs} /></div>
                                    <div className="drop-select">
                                        <select className="form-control" name="logs_status" onChange={this.selectHandler}>
                                            <option value="R">Read</option>
                                            <option value="RAW">Read & Write</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Send Sms/Email</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="sendMessage" onChange={this.switchHandler.bind(this, 'sendMessage')} checked={this.state.sendMessage} /></div>
                                    {/* <div className="drop-select">
                                        <select className="form-control" name="send_message" onChange={this.selectHandler}>
                                            <option value="A" selected={(this.state.sendMessage_Status == 'A') ? true : false}>Allow</option>
                                            <option value="NA" selected={(this.state.sendMessage_Status == 'NA') ? true : false}>Not Allowed</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Get Package</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="getPackage" onChange={this.switchHandler.bind(this, 'getPackage')} checked={this.state.getPackage} /></div>
                                    {/* <div className="drop-select">
                                        <select className="form-control" name="send_message" onChange={this.selectHandler}>
                                            <option value="A" selected={(this.state.getPackage == 'A') ? true : false}>Allow</option>
                                            <option value="NA" selected={(this.state.getPackage == 'NA') ? true : false}>Not Allowed</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Dashboard</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="dashboardperm" onChange={this.switchHandler.bind(this, 'dashboardperm')} checked={this.state.dashboardperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Form</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="formperm" onChange={this.switchHandler.bind(this, 'formperm')} checked={this.state.formperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Banks</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="banksperm" onChange={this.switchHandler.bind(this, 'banksperm')} checked={this.state.banksperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Template</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="templateperm" onChange={this.switchHandler.bind(this, 'templateperm')} checked={this.state.templateperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Signature</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="signatureperm" onChange={this.switchHandler.bind(this, 'signatureperm')} checked={this.state.signatureperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>User</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="userperm" onChange={this.switchHandler.bind(this, 'userperm')} checked={this.state.userperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Unsubscribers</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="unsubscribersperm" onChange={this.switchHandler.bind(this, 'unsubscribersperm')} checked={this.state.unsubscribersperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Agent Lead Search</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="agentleadsperm" onChange={this.switchHandler.bind(this, 'agentleadsperm')} checked={this.state.agentleadsperm} /></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Payment History</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="paymenhistoryperm" onChange={this.switchHandler.bind(this, 'paymenhistoryperm')} checked={this.state.paymenhistoryperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Billing</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="billingperm" onChange={this.switchHandler.bind(this, 'billingperm')} checked={this.state.billingperm} /></div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Lead Sms Credit</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="lead_sms_credit" onChange={this.switchHandler.bind(this, 'lead_sms_credit')} checked={this.state.lead_sms_credit} /></div>
                                </div>
                            </div>

                      

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>SMS Status</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="sms_status" onChange={this.switchHandler.bind(this, 'sms_status')} checked={this.state.sms_status} /></div>
                                </div>
                            </div>

                     


                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3>Email Status</h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="email_status" onChange={this.switchHandler.bind(this, 'email_status')} checked={this.state.email_status} /></div>
                                </div>
                            </div>

                         

                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="form-box">
                                    <div className="roleSetting"><h3> Compain Setting </h3></div>
                                    <div className="custom-switch ml-15 pull-right"><Switch name="campaignsperm" onChange={this.switchHandler.bind(this, 'campaignsperm')} checked={this.state.campaignsperm} /></div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            {this.state.iseditPermission ? <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.editPermission(e)}>Update</button> : <button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.subUserSubmit(e)}>Save</button>}
                            <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.onCloseRoleModal()}>Close</button>

                        </div>
                    </div>
                </Modal>

            </React.Fragment>
        );

    }
}

export default withRouter(AllUser);