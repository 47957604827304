import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import SMSSettingService from '../../services/smssetting.service';
import AllUserService from './../../services/alluser.service';

import $ from 'jquery';
import Notifications, { notify } from 'react-notify-toast';
import Switch from "react-switch";
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;;
class Smssetting extends Component {
	constructor(props) {
		super(props);
		this.state = {

			authToken: '',
			accountSID: '',
			twilioUser: '',
			apikey2: '',
			smssender: '',
			compainId:'',
			smssender67:'',
			routeId:'',
			errors: '',
			usernameTextmarket : '',
			passwordTextmarket : '',
			originatorTextmarketr : '',

			//using for switch ON/OFF
			seven67:false,   
			smswhatsapp: false,
			smsTwilio: false,
			smsLocal: false,
			textmarket : false,

			twilioID: '',
			localID: '',
			seven67ID:'',
			twilioIDW: '',
			textmarketID : '',

			role: '',
			allUsers: [],
			selectedUserID: '',
			selectedUserID2: '',

			listing: [],
			listingDuplicate: [],
			listing2: [],
			listing2Duplicate: [],
			listing3: [],
			listing3Duplicate: [],
			listing4: [],
			listing4Duplicate: [],
			listing5: [],
			listing5Duplicate: [],
			permission: '',
			pandaLoader: true,

			tab : "twillio",
			usersearch : "",
			

			disableTwilioButton: false,
			disableLocalButton: false,
			seven67Button:false,
			disableWhatsappButton: false,
			disableTextmarketButton : false,
			userStatus: '',


		};
		this.smsSettingService = new SMSSettingService();
		this.alluserService = new AllUserService();

	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		// this.getSMSlocal();
		this.getSMSTwilioWhatsapp();
		this.getSMSTwilio();
		this.getSMSlocal();
		this.getSeven67();
		this.allUsersAPI();

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.smsSetting === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			if (data.code === 200) {
				this.setState({
					allUsers: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getSMSTwilioWhatsapp() {
		this.setState({
			isloading: true
		})
		this.smsSettingService.smsTwiliowhatsapp().then(data => {
			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						authTokenW: data.data.authToken,
						twilioUserW: data.data.twilioUser,
						accountSIDW: data.data.accountSid,
						twilioIDW: data.data._id,
						smswhatsapp: data.data.isActive,
						disableWhatsappButton: false,
						isloading: false
					})
				} else {
					this.setState({
						listing3: data.data,
						listing3Duplicate: data.data,
						isloading: false

					})
				}

				
			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}

	getSMSlocal() {
		this.setState({
			isloading: true
		})
		this.smsSettingService.smsLocal().then(data => {
			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						apikey2: data.data.apiKey,
						smssender: data.data.smsSender,
						localID: data.data._id,
						smsLocal: data.data.isActive,
						isloading: false,
						disableLocalButton: false
					})
				} else {
					this.setState({
						listing2: data.data,
						listing2Duplicate: data.data,
						isloading: false
					})
				}
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}


	getSeven67() {
		this.setState({
			isloading: true
		})
		this.smsSettingService.seven67().then(data => {

			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						apikey4: data.data.apiKey,
						smssender67: data.data.smsSender,
						seven67ID: data.data._id,
						compainId: data.data.compainId,
						routeId: data.data.routeId,
						seven67: data.data.isActive,
						isloading: false,
						seven67Button: false
					})
				} else {
					this.setState({
						listing4: data.data,
						listing4Duplicate: data.data,
						isloading: false
					})
				}
			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}

	getSMSTwilio() {
		this.setState({
			isloading: true
		})
		this.smsSettingService.smsTwilio().then(data => {
			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						authToken: data.data.authToken,
						twilioUser: data.data.twilioUser,
						accountSID: data.data.accountSid,
						twilioID: data.data._id,
						smsTwilio: data.data.isActive,
						isloading: false,
						disableTwilioButton: false
					})
				} else {
					this.setState({
						listing: data.data,
						listingDuplicate: data.data,
						isloading: false

					})
				}

				// notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}





	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	submit1(e) {
		e.preventDefault();
		const { twilioUser, authToken, accountSID } = this.state;
		if (authToken === '') {
			this.setState({ errors: 'Auth Token is Required' });
			return;
		}
		else if (accountSID === '') {
			this.setState({ errors: 'Account SID is Required' });
			return;
		}
		else if (twilioUser === '') {
			this.setState({ errors: 'TwilioUser is Required' });
			return;
		} else {
			this.setState({ errors: '' });

			var twilioCheck = this.state.twilioID;
			var id = '';
			if (twilioCheck == null || twilioCheck == undefined || twilioCheck == "") {
				id = "123";
				this.setState({
					twilioCheck: id,
					disableTwilioButton: true
				});
			}
			else {
				id = this.state.twilioID;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				twilioUser: this.state.twilioUser,
				accountSid: this.state.accountSID,
				authToken: this.state.authToken,
				isActive: this.state.smsTwilio,
				updatedBy: updater.id,

			}

			this.smsSettingService.updateSMSTwilio(id, body).then(data => {
				notify.show(data.message, "success", 2000);
				this.getSMSTwilio();
				// this.getSMSlocal();
				this.setState({
					smsTwilio: data.data.isActive,
					smsLocal: false,

				})

			}).catch(err => {
				console.log(err)
			})
		}
	}

	submit2(e) {
		e.preventDefault();
		const { url2, apikey2, smssender } = this.state;
		if (apikey2 === '') {
			this.setState({ errors: 'API Key is Required' });
			return;
		}
		else if (smssender === '') {
			this.setState({ errors: 'SMS Sender is Required' });
			return;
		}
		else if (url2 === '') {
			this.setState({ errors: 'URL is Required' });
			return;
		}
		else {
			this.setState({ errors: '' });


			var localIDCheck = this.state.localID;
			var id = '';
			if (localIDCheck == null || localIDCheck == undefined || localIDCheck == "") {
				id = "123";
				this.setState({
					localID: id,
					disableLocalButton: true
				});
			}
			else {
				id = this.state.localID;
			}


			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				smsSender: this.state.smssender,
				apiKey: this.state.apikey2,
				isActive: this.state.smsLocal,
				updatedBy: updater.id,
			}

			this.smsSettingService.updateSMSlocal(id, body).then(data => {
				notify.show(data.message, "success", 2000);
				this.getSMSlocal();
				// this.getSMSTwilio();
				this.setState({
					smsLocal: data.data.isActive,
					smsTwilio: false,


				})
			}).catch(err => {
				console.log(err)
			})
		}
	}

	submit3(e) {
		e.preventDefault();
		const { twilioUserW, authTokenW, accountSIDW } = this.state;
		if (authTokenW === '') {
			this.setState({ errors: 'Auth Token is required.' });
			return;
		}
		else if (accountSIDW === '') {
			this.setState({ errors: 'Account SID is required.' });
			return;
		}
		else if (twilioUserW === '') {
			this.setState({ errors: 'TwilioWhatsapp User is required.' });
			return;
		} else {
			this.setState({ errors: '' });

			var twilioCheck = this.state.twilioIDW;
			var id = '';
			if (twilioCheck == null || twilioCheck == undefined || twilioCheck == "") {
				id = "123";
				this.setState({
					twilioCheck: id,
					disableWhatsappButton: true
				});
			}
			else {
				id = this.state.twilioIDW;
			}

			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				twilioUser: this.state.twilioUserW,
				accountSid: this.state.accountSIDW,
				authToken: this.state.authTokenW,
				isActive: this.state.smswhatsapp,
				updatedBy: updater.id,

			}

			this.smsSettingService.updateSMSTwilioWhatsapp(id, body).then(data => {
				notify.show(data.message, "success", 2000);
				this.getSMSTwilio();
				this.getSMSlocal();
				this.getSMSTwilioWhatsapp();
				this.setState({
					smswhatsapp: data.data.isActive,

				})

			}).catch(err => {
				console.log(err)
			})
		}
	}

	submit4(e) {
		e.preventDefault();
		const { apikey4, smssender67 , routeId , compainId } = this.state;

		if (apikey4 === '') {
			this.setState({ errors: 'API Key is Required' });
			return;
		}
		else if (smssender67 === '') {
			this.setState({ errors: 'SMS Sender is Required' });
			return;
		}
		else if (routeId === '') {
			this.setState({ errors: 'RouteId is Required' });
			return;
		}
		else if (compainId === '') {
			this.setState({ errors: 'CompainId is Required' });
			return;
		}
		else {
			this.setState({ errors: '' });
			var seven67IDCheck = this.state.seven67ID;
			var id = '';
			if (seven67IDCheck == null || seven67IDCheck == undefined || seven67IDCheck == "") {
				id = "123";
				this.setState({
					seven67ID: id,
					seven67Button: true
				});
			}
			else {
				id = this.state.seven67ID;
			}

			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				smsSender: this.state.smssender67,
				apiKey: this.state.apikey4,
				isActive: this.state.seven67,
				updatedBy: updater.id,
				routeId: this.state.routeId,
				compainId: this.state.compainId
			}

			this.smsSettingService.updateSeven67(id, body).then(data => {
				notify.show(data.message, "success", 2000);
				this.getSeven67();
				this.setState({
					seven67: data.data.isActive
				})
			}).catch(err => {
				console.log(err)
			})
		}
	}



	emptyError() {
		this.setState({
			errors: ''
		})
	}

	onchangeTwilio(check) {
		//   if(this.state.smslocalcheck === false){
		this.setState({
			smsTwilio: check,
			smsLocal: false
		})
		//   }

	}

	onchangeLocal(check) {
		this.setState({
			smsLocal: check,
			smsTwilio: false
		});


	}

	onchangeSeven67(check) {
		this.setState({
			seven67: check
		});


	}

	onchangewhatsapp(check) {
		this.setState({
			smswhatsapp: check,

		});
	}
	onchangeTextMarket(check) {
		this.setState({
			textmarket: check,
		});
	}

	cancel(e) {
		e.preventDefault();
		this.props.history.push('/dashboard');

	}

	tabsClick(val){
		if(val === 'twillio'){
			
			this.setState({
				errors : "",
				tab : "twillio",
				usersearch : ""
			})

		}else if(val === 'smslocal'){
			this.setState({
				errors : "",
				tab : "smslocal",
				usersearch : ""
			})

		}else if(val === 'whatsapp'){
			this.setState({
				errors : "",
				tab : "whatsapp",
				usersearch : ""
			})

		}else if(val === 'kevsms'){
			this.setState({
				errors : "",
				tab : "kevsms",
				usersearch : ""
			})
		}else if(val === 'textmarket'){
			this.setState({
				errors : "",
				tab : "textmarket",
				usersearch : ""
			})

		}
	}

	onchangeSearch(e){
		e.preventDefault();
		
		if(this.state.tab === "twillio"){
			var current = this;
			this.setState({
				[e.target.name]: e.target.value
			}, () => {
				let myArray = this.state.listingDuplicate;
				let data = myArray.filter(x => {
					return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.accountSid.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.twilioUser.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.authToken.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
				})
				current.setState({
					listing: data
				})
			})
		}else if(this.state.tab === "smslocal"){
			var current = this;
			this.setState({
				[e.target.name]: e.target.value
			}, () => {
				let myArray = this.state.listing2Duplicate;
	
				let data = myArray.filter(x => {
					return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.apiKey.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.smsSender.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
				})
				current.setState({
					listing2: data
				})
			})
			
		}else if(this.state.tab === "whatsapp"){
			var current = this;
			this.setState({
				[e.target.name]: e.target.value
			}, () => {
				let myArray = this.state.listing3Duplicate;
	
				let data = myArray.filter(x => {
					return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.accountSid.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.authToken.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.twilioUser.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
				})
				current.setState({
					listing3: data
				})
			})
			
		}else if(this.state.tab === "kevsms"){
			var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.listing4Duplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.apiKey.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.smsSender.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
			})
			current.setState({
				listing4: data
			})
		})
			
		}else if(this.state.tab === "textmarket"){
			var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.listing5Duplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.username.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.originator.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
			})
			current.setState({
				listing5: data
			})
		})
			
		}

	}
	render() {
		return (
			<div className="content-page Setting-tab tab-structure" id="setting03">
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />

				<div className="setting-fields field-set">
					<div className="content-header">

						<div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>SMS Settings</h3>
									</div>
                            </div>
							<div className="col-md-9 col-sm-9 col-xs-12">
								{
									this.state.role === "admin" ?
										<div className="head-right-wrap">
											<div className="head-search">
												<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
											</div>
										</div>
										: ""
								}

							</div>
                        </div>


					</div>
					{/* <!-- Nav tabs --> */}
					<div className="tab leads-tabs" role="tabpanel">
						<ul className="nav nav-tabs" role="tablist">
							<li role="presentation" className="active">
								{
									this.state.role === "admin" ? <a href="#smtp01" onClick={this.tabsClick.bind(this, "twillio")} aria-controls="home" role="tab" data-toggle="tab" >Twillio</a> : <a href="#smtp01user" onClick={() => this.emptyError()} aria-controls="home" role="tab" data-toggle="tab">Twillio</a>
								}
							</li>
							<li role="presentation">
								{
									this.state.role === "admin" ? <a href="#smtp02" onClick={this.tabsClick.bind(this, "smslocal")} aria-controls="profile" role="tab" data-toggle="tab">SMS Local</a> : <a href="#smtp02user" onClick={() => this.emptyError()} aria-controls="profile" role="tab" data-toggle="tab">SMS Local</a>
								}
							</li>
							<li role="presentation">
								{
									this.state.role === "admin" ? <a href="#smtp03" onClick={this.tabsClick.bind(this, "whatsapp")} aria-controls="profile" role="tab" data-toggle="tab">SMS Whatsapp</a>
										: <a href="#smtp03user" onClick={() => this.emptyError()} aria-controls="profile" role="tab" data-toggle="tab">SMS Whatsapp</a>
								}
							</li>
							<li role="presentation">
								{
									this.state.role === "admin" ? <a href="#smtp04" onClick={this.tabsClick.bind(this, "kevsms")}aria-controls="home" role="tab" data-toggle="tab" >Kev SMS</a> : <a href="#smtp04user" onClick={() => this.emptyError()} aria-controls="home" role="tab" data-toggle="tab">Kev SMS</a>
								}
							</li>
						</ul>
						{/* <!-- Tab panes --> */}
						<div className="tab-content tabs">
							{
								this.state.role === "admin" ?
									<div role="tabpanel" className="tab-pane fade in active" id="smtp01">
										<div className="row r-tab-form">

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>UserName</th>
																<th>AccountSid</th>
																<th>AuthToken</th>
																<th>Status</th>
																<th>TwilioUser</th>

															</tr>
														</thead>
														<tbody>
															{
																this.state.listing.length > 0 ?
																	this.state.listing.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.accountSid}>{val.accountSid}</td>
																					<td title={val.authToken}>{val.authToken}</td>
																					<td title={val.isActive === true ? "Active" : "InActive"}>{val.isActive === true ? "Active" : "InActive"}</td>
																					<td title={val.twilioUser}>{val.twilioUser}</td>
																				</tr>
																			)
																		}

																	})

																	:
																	<tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>


										</div>
									</div>
									:
									<div role="tabpanel" className="tab-pane fade in active" id="smtp01user">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Auth Token</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Auth Token" value={this.state.authToken || ''} name="authToken" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Account SID</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Account SID" value={this.state.accountSID || ''} name="accountSID" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Twilio User</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Twilio User" value={this.state.twilioUser || ''} name="twilioUser" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="smsTwilio"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangeTwilio(e)} checked={this.state.smsTwilio} />
													</div>
												</div>
												{this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW' ? "" :
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit1(e)} disabled={this.state.disableTwilioButton} className=" mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>													
													</div>
												}
											</div>
										</div>
									</div>
							}
							{/* Tab 1 */}

							{
								this.state.role === "admin" ?
									<div role="tabpanel" className="tab-pane fade" id="smtp02">
										<div className="row r-tab-form">

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>UserName</th>
																<th>API Key</th>
																<th>SMS Sender</th>
																<th>Status</th>

															</tr>
														</thead>
														<tbody>
															{
																this.state.listing2.length > 0 ?
																	this.state.listing2.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.apiKey}>{val.apiKey}</td>
																					<td title={val.smsSender}>{val.smsSender}</td>
																					<td title={val.isActive === true ? "Active" : "InActive"}>{val.isActive === true ? "Active" : "InActive"}</td>
																				</tr>
																			)
																		}

																	})

																	:
																	<tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>


										</div>
									</div>
									:
									<div role="tabpanel" className="tab-pane fade" id="smtp02user">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">API Key</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="API Key" value={this.state.apikey2 || ''} name="apikey2" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">SMS Sender</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="SMS Sender" value={this.state.smssender || ''} name="smssender" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="smsLocal"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangeLocal(e)} checked={this.state.smsLocal} />
													</div>
												</div>
												{this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW' ? "" :
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit2(e)} disabled={this.state.disableLocalButton} className="mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round " onClick={(e) => this.cancel(e)} >Cancel</button>
													
													</div>
												}
											</div>
										</div>
									</div>
							}

							{/* Tab 2 */}

							{
								this.state.role === "admin" ?
									<div role="tabpanel" className="tab-pane fade " id="smtp03">
										<div className="row r-tab-form">
										

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>UserName</th>
																<th>AccountSid</th>
																<th>AuthToken</th>
																<th>Status</th>
																<th>TwilioUser</th>

															</tr>
														</thead>
														<tbody>
															{
																this.state.listing3.length > 0 ?
																	this.state.listing3.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.accountSid}>{val.accountSid}</td>
																					<td title={val.authToken}>{val.authToken}</td>
																					<td title={val.isActive === true ? "Active" : "InActive"}>{val.isActive === true ? "Active" : "InActive"}</td>
																					<td title={val.twilioUser}>{val.twilioUser}</td>
																				</tr>
																			)
																		}

																	})

																	:
																	<tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>


										</div>
									</div>
									:
									<div role="tabpanel" className="tab-pane fade " id="smtp03user">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Auth Token</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Auth Token" value={this.state.authTokenW || ''} name="authTokenW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Account SID</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Account SID" value={this.state.accountSIDW || ''} name="accountSIDW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Twilio Whatsapp User</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Twilio User" value={this.state.twilioUserW || ''} name="twilioUserW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="smsTwilio"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangewhatsapp(e)} checked={this.state.smswhatsapp} />
													</div>
												</div>
												{this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW' ? "" :
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit3(e)} disabled={this.state.disableWhatsappButton} className=" mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>
													
													</div>
												}
											</div>
										</div>
									</div>
							}
							{/* Tab 3*/}

							{
								this.state.role === "admin" ?
									<div role="tabpanel" className="tab-pane fade " id="smtp03">
										<div className="row r-tab-form">

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>UserName</th>
																<th>AccountSid</th>
																<th>AuthToken</th>
																<th>Status</th>
																<th>TwilioUser</th>

															</tr>
														</thead>
														<tbody>
															{
																this.state.listing3.length > 0 ?
																	this.state.listing3.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.accountSid}>{val.accountSid}</td>
																					<td title={val.authToken}>{val.authToken}</td>
																					<td title={val.isActive === true ? "Active" : "InActive"}>{val.isActive === true ? "Active" : "InActive"}</td>
																					<td title={val.twilioUser}>{val.twilioUser}</td>
																				</tr>
																			)
																		}

																	})

																	:
																	<tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>


										</div>
									</div>
									:
									<div role="tabpanel" className="tab-pane fade " id="smtp03user">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Auth Token</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Auth Token" value={this.state.authTokenW || ''} name="authTokenW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Account SID</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Account SID" value={this.state.accountSIDW || ''} name="accountSIDW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Twilio Whatsapp User</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Twilio User" value={this.state.twilioUserW || ''} name="twilioUserW" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="smsTwilio"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangewhatsapp(e)} checked={this.state.smswhatsapp} />
													</div>
												</div>
												{this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW' ? "" :
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit3(e)} disabled={this.state.disableWhatsappButton} className=" mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>													
													</div>
												}
											</div>
										</div>
									</div>
							}
							{/* Tab 4 */}
							{
								this.state.role === "admin" ?
									<div role="tabpanel" className="tab-pane fade" id="smtp04">
										<div className="row r-tab-form">

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>UserName</th>
																<th>API Key</th>
																<th>SMS Sender</th>
																<th>Status</th>

															</tr>
														</thead>
														<tbody>
															{
																this.state.listing4.length > 0 ?
																	this.state.listing4.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.apiKey}>{val.apiKey}</td>
																					<td title={val.smsSender}>{val.smsSender}</td>
																					<td title={val.isActive === true ? "Active" : "InActive"}>{val.isActive === true ? "Active" : "InActive"}</td>
																				</tr>
																			)
																		}

																	})

																	:
																	<tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>


										</div>
									</div>
									:
									<div role="tabpanel" className="tab-pane fade" id="smtp04user">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">API Key</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="API Key" value={this.state.apikey4 || ''} name="apikey4" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">SMS Sender</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="SMS Sender" value={this.state.smssender67 || ''} name="smssender67" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Route Id</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Agent Id" value={this.state.routeId || ''} name="routeId" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Compain-Id</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Compain-Id" value={this.state.compainId || ''} name="compainId" onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="seven67"
															disabled={(this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangeSeven67(e)} checked={this.state.seven67} />
													</div>
												</div>
												{this.state.role === 'subUser' && this.state.permission.smsSetting_Status !== 'RW' ? "" :
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit4(e)} disabled={this.state.seven67Button} className=" mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round " onClick={(e) => this.cancel(e)} >Cancel</button>
													
													</div>
												}
											</div>
										</div>
									</div>
							}
							
						</div>
					</div>
				</div>	</div>
		);
	}
}

export default withRouter(Smssetting);