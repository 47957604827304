import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LoginService from './../../services/login.service';
import Modal from 'react-responsive-modal';

import $ from 'jquery';
class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			resetEmail :'',
			password: '',
			errors: '',
			errorsModal:"",
			errorSuccess : "",
			rememberMe: false,
			loginCheck: false,
			openModalForgotPassword: false,
		}
		this.loginService = new LoginService();
	}

	//Life Cycle
	componentDidMount() {

		var token1 = localStorage.getItem('token');
		var token = localStorage.getItem("remember");
		var section = sessionStorage.getItem('remember');
		if (token == "true" && token1) {
			var loginTime = parseInt(localStorage.getItem('loginTime')) / 1000;
			var timeNow = (new Date().getTime()) / 1000;
			var timeDifference = timeNow - loginTime;
			var timeDifferenceInMinutes = timeDifference / 60;
			if (timeDifferenceInMinutes < window.loginExpiresAfter) {
				this.props.history.push('/dashboard')
			} else {
				sessionStorage.removeItem('token');
				localStorage.removeItem('token');
				this.props.history.push('/login');
			}
		} else if (section != null && section == true && token1) {
			this.props.history.push('/dashboard')
		} else {
			sessionStorage.removeItem('remember');
			localStorage.removeItem('token');
			this.props.history.push('/login');
		}
	}

	onOpenModalForgotPassword = () => {
		this.setState({ openModalForgotPassword: true });
	};

	onCloseModalForgotPassword = () => {
		this.setState({ openModalForgotPassword: false, errorsModal : "", resetEmail :"" });
	};





	login() {

		const { email, password } = this.state;
		// e.preventDefault();
		//var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (email === '') {
			this.setState({ errors: 'Email is required.' });
			return;
		}
		else if (!email.match(regexp)) {
			this.setState({ errors: 'Email is invalid.' });
			return;
		}
		else if (password === '') {
			this.setState({ errors: 'Password is required.' });
			return;
		}
		else {
			this.setState({
				errors: ''
			})

			var that = this;
			let response = this.loginService.Login(this.state.email, this.state.password);
			response.then(data => {
				console.log("data", data);
				if (data.code === 405) {
					this.setState({
						loginCheck: false,
						errors: data.message
					});
					return
				}
				if (data.token) {
					this.setState({
						loginCheck: true
					});
					let rememberme = this.state.rememberMe;
					var tokenObject = JSON.stringify(data.token);
					localStorage.setItem("token", tokenObject);
					console.log(data.user);
					localStorage.setItem('user', JSON.stringify(data.user));
					sessionStorage.setItem("remember", 'true');
					if (this.state.rememberMe == true) {

						sessionStorage.setItem("remember", 'false');
						localStorage.setItem("remember", 'true');
						localStorage.setItem('loginTime', new Date().getTime());
					}
					var intervalToSetToken = setInterval(function () {
						if (localStorage.getItem("token")) {
							clearInterval(intervalToSetToken);
							that.props.history.push('/dashboard');
						}
						else {
							localStorage.setItem("token", tokenObject);
						}
					}, 100);

					$(".modal-backdrop.in").fadeOut("slow");
					setTimeout(function () {
						//that.props.history.push('/profile');
					}, 1000)

				}
			}).catch((err) => {
				console.log(err)
				this.setState({
					loginCheck: false,
					errors: 'Incorrect email or password'
				});
				console.log(err)
			})


		}
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onchangeCheckbox(e) {
		if (this.state.rememberMe === true) {
			this.setState({
				[e.target.name]: false
			});
		} else {
			this.setState({
				[e.target.name]: true
			});
		}
	}

	_handleKeyDown = (e) => {
		var current = this;
		if (e.key === 'Enter') {
			current.login()
		}
	}

	resetPassword(){

		const { resetEmail } = this.state;
		// e.preventDefault();
		//var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (resetEmail === '') {
			this.setState({ errorsModal: 'Email is required.' });
			return;
		}
		else if (!resetEmail.match(regexp)) {
			this.setState({ errorsModal: 'Email is invalid.' });
			return;
		}else{
			this.loginService.forgotPassword(this.state.resetEmail).then(data=>{
				if(data.code === 401){
					this.setState({
						errorsModal : data.message
					})
				}else if(data.code ===200){
					let th = this;
					this.onCloseModalForgotPassword();
					this.setState({
						errorSuccess : data.message
					});
					setTimeout(() => { // hide success message after 3 secongds
						th.setState({
							errorSuccess :""
						})
					}, 3000);
				}
			})
		}

	}
	render() {
		return (
			<section className="lead-login">
				<div className="lead-login-pop landing-bg">
					{/* <button type="button" className="btn-lg btn-go hvr-push">Let's <br/>Go!</button> */}
					{/* <!-- Modal --> */}
					<div id="loginmodal" className="login-modal">
						{/* <!-- Modal content--> */}
						<div className="modal-content">
							<div className="login-wrap">
								<div className="header text-center">
									<a href="">
										<img className="img-responsive center-block" src={require('./../../assets/images/logo-icon.png')} alt="" />
										<h4 className="text-italic text-white mt-10 fw400">eSignRePack</h4>
									</a>
								</div>
								<div className="login-content">
									<div className="heading">Sign in to eSignRePack</div>

									<div className="leadlogin-form">
										<div className="login-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
												{Object.keys(this.state.errorSuccess).length > 0 && this.state.errorSuccess ?
												<div className="alert alert-success">
													{this.state.errorSuccess}
												</div> : ''}

											<div className="form-group"><input type="email" className="form-control" name="email" placeholder="Email" onKeyDown={this._handleKeyDown} onChange={(e) => this.onChange(e)} /><i className="fa fa-user input-icon"></i></div>
											<div className="form-group"><input type="password" className="form-control" name="password" placeholder="Password" onKeyDown={this._handleKeyDown} onChange={(e) => this.onChange(e)} /><i className="fa fa-lock input-icon"></i></div>
											<div className="clearfix"></div>
											<div className="form-group">
												<label className="checkbox-container">Remember Me
													<input type="checkbox" defaultChecked={false} name="rememberMe" value={this.state.rememberMe} onChange={(e) => this.onchangeCheckbox(e)} />
													<span className="checkmark"></span>
												</label>
												<a className="forgot-password"  onClick={this.onOpenModalForgotPassword}>Forgot password?</a>
											</div>
											<div className="form-group text-center">
												<a type="submit" onClick={(e) => this.login(e)} className="btn btn-style-lg btn-purple btn-round">Sign In</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal className="modal modal-style fade" open={this.state.openModalForgotPassword} onClose={this.onCloseModalForgotPassword} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={this.onCloseModalForgotPassword} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Recover Your Password</h4>
					</div>
					<div className="modal-body">
						{Object.keys(this.state.errorsModal).length > 0 && this.state.errorsModal ?
							<div className="alert alert-danger">{this.state.errorsModal}</div>
						: ''}
						<div className="text-center forget-modal">
							<i className="fa fa-lock"></i>
							<h3 className="text-center fw400">Forgot Password?</h3>
							<p className="text-center fw400">You can reset your password here.</p>
							<div className="forget-form signup-form">
								<div className="form-group">
									<input id="email" name="resetEmail" value={this.state.resetEmail} placeholder="email address" className="form-control" onChange={(e)=>this.onChange(e)} type="text" />
								</div>
								<div className="form-group">
									<button onClick={(e) => this.resetPassword(e)} name="recover-submit" className="btn btn-style-lg btn-purple btn-round" >Reset Password</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer"></div>
				</Modal>

			</section>
		);
	}
}

export default withRouter(Login);