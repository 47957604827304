import React, { Component } from 'react'
import './smtp.css';
import { withRouter } from 'react-router-dom';
import MailGunService from '../../services/mailgun.service';
import AllUserService from './../../services/alluser.service';
import Switch from "react-switch";

import $ from 'jquery';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

// import responsiveTabs from './../../assets/js/responsiveTabs.min.js';
class SMTP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mailgunuser: '',
			allMailgunuser: [],
			singleMailgundata: {},
			apikey: '',
			domain: '',
			mailgunid: '',
			origin: 'US',
			mailgunActive: false,
			errors: '',
			role: '',
			selectedMailgunUserID: '',
			isloading: false,
			listing: [],
			listingDuplicate: [],

			mailchipActive: false, // for switch
			apikeyMailChimp: '',
			mailchimpID: '',
			mailChimpEmail: '',
			selectedMailgunUserID2: "",
			listing2: [],
			listing2Duplicate: [],
			permission: '',
			pandaLoader: true,

			usersearch: "",
			tab : "mailgun",
			// usersearchMailgun: "",
			// usersearchMailchimp: "",

			disabledMailgunButton: false,
			disabledMailchimpButton: false,
			userStatus: '',

		}
		this.mailgunService = new MailGunService();
		this.alluserService = new AllUserService();
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}

		this.getMailgun();
		if (localStorage.getItem('token')) {
			this.allUsersAPI();
			this.getmailChimp();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.smtpSetting === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			if (data.code === 200) {
				this.setState({
					allMailgunuser: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getmailChimp() {
		this.mailgunService.getMailChimp().then(data => {
			if (data.code === 200) {
				if (typeof data.data.length === "undefined") {
					this.setState({
						mailchimpID: data.data._id,
						apikeyMailChimp: data.data.apiKey,
						mailchipActive: data.data.isActive,
						mailChimpEmail: data.data.email,
						disabledMailchimpButton: false
					})
				} else {
					this.setState({
						listing2: data.data,
						listing2Duplicate: data.data
					})
				}


			}
			if (data.code === 404) {
				this.setState({
					mailchimpID: "",
					apikeyMailChimp: "",
					mailchipActive: ""
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getMailgun() {
		this.setState({
			isloading: true
		})
		this.mailgunService.getMailgun().then(data => {
			if (data.code === 200) {
				if (typeof data.data.length === 'undefined') {
					this.setState({
						mailgunid: data.data._id,
						apikey: data.data.apiKey,
						mailgunuser: data.data.mailgunUser,
						domain: data.data.domain,
						origin: data.data.origin,
						mailgunActive: data.data.isActive,
						isloading: false,
						disabledMailgunButton: false
					})
				} else {
					this.setState({
						listing: data.data,
						listingDuplicate: data.data,
						isloading: false
					})
				}
				// notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			console.log(err);
			this.setState({
				isloading: false
			})
		})
	}

	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	submit(e) {
		e.preventDefault();
		const { mailgunuser, apikey, domain } = this.state;

		if (mailgunuser === '') {
			this.setState({ errors: 'MailGun User is required.' });
			return;
		}
		else if (apikey === '') {
			this.setState({ errors: 'API Key is required.' });
			return;
		}
		else if (domain === '') {
			this.setState({ errors: 'Domain is required.' });
			return;
		}
		else {
			this.setState({
				errors: ''
			});
			var mailgunIdCheck = this.state.mailgunid;
			var id = '';
			if (mailgunIdCheck === null || mailgunIdCheck === undefined || mailgunIdCheck === "") {
				id = "123";
				this.setState({
					mailgunid: id,
					disabledMailgunButton: true
				});
			}
			else {
				id = this.state.mailgunid;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				mailgunUser: this.state.mailgunuser,
				apiKey: this.state.apikey,
				domain: this.state.domain,
				origin: this.state.origin,
				isActive: this.state.mailgunActive,
				updatedBy: updater.id,
			}
			this.mailgunService.updateMailGun(id, body).then(data => {
				if (data.code == 200) {
					this.getMailgun();
					notify.show(data.message, "success", 2000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	cancel(e) {

		e.preventDefault();
		this.props.history.push('/dashboard');
	}

	_handleRadio(e) {
		// this.setState({ [e.target.name]: e.target.value === "US" ? "EU" : "US" });

		if (this.state.origin == "US") {
			this.setState({
				origin: "EU"
			})
		} else {
			this.setState({
				origin: "US"
			})
		}
	}


	onchangeMailchipActive(check) {
		this.setState({
			mailchipActive: check,
		});
	}

	onchangeMailgunActive(check) {
		this.setState({
			mailgunActive: check,
		});
	}

	emptyError() {
		this.setState({
			errors: ''
		})
	}


	submit2(e) {
		e.preventDefault();
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		const { apikeyMailChimp, mailChimpEmail } = this.state;

		if (mailChimpEmail === '') {
			this.setState({ errors: 'Email is required.' });
			return;
		}
		else if (!mailChimpEmail.match(regexp)) {
			this.setState({ errors: 'Email is invalid.' });
			return;
		}

		else if (apikeyMailChimp === '') {
			this.setState({ errors: 'API key is required.' });
			return;
		}

		else {
			this.setState({
				errors: ''
			});
			var mailchimpID = this.state.mailchimpID;
			var id = '';
			if (mailchimpID == null || mailchimpID == undefined || mailchimpID == "") {
				id = "123";
				this.setState({
					mailchimpID: id,
					disabledMailchimpButton: true
				});
			}
			else {
				id = this.state.mailchimpID;
			}
			var updater = JSON.parse(localStorage.getItem('user'));
			let body = {
				campaign_id: id,
				apiKey: this.state.apikeyMailChimp,
				email: this.state.mailChimpEmail,
				isActive: this.state.mailchipActive,
				updatedBy: updater.id
			}
			this.mailgunService.updateMailChimp(id, body).then(data => {
				if (data.code == 200) {
					this.getmailChimp();
					notify.show(data.message, "success", 2000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}
	
	tabsClick(val){
		if(val === 'mailgun'){
			
			this.setState({
				errors : "",
				tab : "mailgun",
				usersearch : ""
			})

		}else if(val === 'mailchimp'){
			this.setState({
				errors : "",
				tab : "mailchimp",
				usersearch : ""
			})

		}
	}

	onchangeSearch(e){
		if(this.state.tab === "mailgun"){
			var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.listingDuplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.apiKey.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.domain.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.mailgunUser.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.origin.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
			})
			current.setState({
				listing: data
			})
		})
		}else if(this.state.tab === "mailchimp"){
			var current = this;
			this.setState({
				[e.target.name]: e.target.value
			}, () => {
				let myArray = this.state.listing2Duplicate;
	
				let data = myArray.filter(x => {
					return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.email.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
						(x.apiKey.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())
				})
				current.setState({
					listing2: data
				})
			})
		}
	}

	render() {
		return (
			<div className="content-page Setting-tab tab-structure" id="setting02">
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />

				{/* For Super Admin */}
				{
					this.state.role === "admin" ?


						<div className="setting-fields field-set">
							<div className="content-header">

						<div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>SMTP Settings</h3>
									</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
                                <div className="head-search">
								<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />	
								</div>
                                </div>
                            </div>
                        </div>


							</div>
							<div className="tab leads-tabs" role="tabpanel">
								<ul className="nav nav-tabs" role="tablist">
									<li role="presentation" className="active">
										<a href="#smtp01" onClick={this.tabsClick.bind(this, "mailgun")} aria-controls="home" role="tab" data-toggle="tab" >Mailgun</a>
									</li>
									<li role="presentation">
										<a href="#smtp02" onClick={this.tabsClick.bind(this, "mailchimp")} aria-controls="home" role="tab" data-toggle="tab">Mail Chimp</a>

									</li>
								</ul>
								<div className="tab-content tabs">

									<div role="tabpanel" className="tab-pane fade in active " id="smtp01">
										<div className="row r-tab-form">
											{/* <div className="row">
												<div className="col-md-4 col-sm-4 col-xs-12">
												</div>
												<div className="col-md-4 col-sm-4 col-xs-12">
													<div className="page-heading mr-btm-15">
														<input type="text" name="usersearchMailgun" placeholder="Search" value={this.state.usersearchMailgun} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
													</div>
												</div>
												<div className="col-md-4 col-sm-4 col-xs-12">
												</div>
											</div> */}

											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>User</th>
																<th>API Key</th>
																<th>Domain Name</th>
																<th>Mailgun User</th>
																<th>Origin</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.listing.length > 0 ?
																	this.state.listing.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.apiKey}>{val.apiKey}</td>
																					<td title={val.domain}>{val.domain}</td>
																					<td title={val.mailgunUser}>{val.mailgunUser}</td>
																					<td title={val.origin}>{val.origin}</td>
																				</tr>
																			)
																		}

																	})





																	: <tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>

									<div role="tabpanel" className="tab-pane fade " id="smtp02">
										<div className="row r-tab-form">
											{/* <div className="row">
												<div className="col-md-4 col-sm-4 col-xs-12">
												</div>
												<div className="col-md-4 col-sm-4 col-xs-12">
													<div className="page-heading mr-btm-15">
														<input type="text" name="usersearchMailchimp" placeholder="Search" value={this.state.usersearchMailchimp} onChange={(e) => this.onchangeSearch2(e)} className="form-control" />
													</div>
												</div>
												<div className="col-md-4 col-sm-4 col-xs-12">
												</div>
											</div> */}



											<div className="table-wrap">
												<div className="table-responsive">
													<table className="table table-hover table-striped table-bordered table-condensed">
														<thead className="thead-light">
															<tr>
																<th>User</th>
																<th>Email</th>
																<th>API Key</th>
																<th>Active</th>
															</tr>
														</thead>
														<tbody>
															{
																this.state.listing2.length > 0 ?
																	this.state.listing2.map((val, key) => {
																		if (val.user) {
																			return (
																				<tr key={key}>
																					<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																					<td title={val.email}>{val.email}</td>
																					<td title={val.apiKey}>{val.apiKey}</td>
																					<td title={val.isActive === true ? "True" : "False"}>{val.isActive === true ? "True" : "False"}</td>
																				</tr>
																			)
																		}

																	})





																	: <tr >
																		<td colSpan="5" style={{ textAlign: "center" }}>
																			{this.state.isloading ? <ClipLoader
																				css={override}
																				sizeUnit={"px"}
																				size={50}
																				color={'#123abc'}
																				loading={this.state.isloading}
																			/> : "No Record Found"}

																		</td>
																	</tr>
															}
															<tr>


															</tr>

														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						: // user or SubUser
						<div className="setting-fields field-set" >
							<div className="content-header">
								<div className="row">
									<div className="col-md-6 col-sm-6 col-xs-12">
										<div className="page-heading">
											<h3>SMTP Settings</h3>
										</div>
									</div>
									<div className="col-md-6 col-sm-6 col-xs-12">
										{/* <!-- <div className="add-btn text-right">
										<button type="button" className="btn-style-md btn-purple btn-round">Your Button name</button>
									</div> --> */}
									</div>
								</div>
							</div>
							<div className="tab leads-tabs" role="tabpanel">
								<ul className="nav nav-tabs" role="tablist">
									<li role="presentation" className="active">
										<a href="#smtp01" aria-controls="home" role="tab" data-toggle="tab">Mail Gun</a>
									</li>
									<li role="presentation" >
										<a href="#smtp02" aria-controls="home" role="tab" data-toggle="tab">Mail Chimp</a>
									</li>

								</ul>
								{/* <!-- Tab panes --> */}
								<div className="tab-content tabs">
									<div role="tabpanel" className="tab-pane fade in active" id="smtp01">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">MailGun User</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="MailGun User" name="mailgunuser" value={this.state.mailgunuser} onChange={(e) => this.onchange(e)} />
													</div>
												</div>

												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">API Key</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Api Key" name="apikey" value={this.state.apikey} onChange={(e) => this.onchange(e)} />
													</div>
												</div>
												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Domain</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Domain" name="domain" value={this.state.domain} onChange={(e) => this.onchange(e)} />
													</div>
												</div>

												<div className="form-group row">
													<label className="col-md-3">Mailgun Origin</label>
													<div className="col-md-9">
														<div className="need-buttons">
															<label className="custom-radio">US
															<input type="radio" name="origin"
																	disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
																	value="US" checked={this.state.origin == 'US' ? true : false} onChange={(e) => this._handleRadio(e)} />
																<span className="checkmark"></span>
															</label>
															<label className="custom-radio">EU
															<input type="radio" name="origin"
																	disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
																	value="EU" checked={this.state.origin == "EU" ? true : false} onChange={(e) => this._handleRadio(e)} />
																<span className="checkmark"></span>
															</label>
														</div>
													</div>
												</div>

												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="mailgunActive"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangeMailgunActive(e)} checked={this.state.mailgunActive} />
													</div>
												</div>

												{this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW' ?
													""
													:
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit(e)} disabled={this.state.disabledMailgunButton} className="mr-10 btn-style-md btn-purple btn-round">Save</button>
														<button className="btn-style-md btn-purple btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>

													</div>
												}
											</div>
										</div>
									</div>

									<div role="tabpanel" className="tab-pane fade " id="smtp02">
										<div className="row r-tab-form">
											{Object.keys(this.state.errors).length > 0 && this.state.errors ?
												<div className="alert alert-danger">
													{this.state.errors}
												</div> : ''}
											<div className="col-lg-8 col-md-10 col-sm-12 col-xs-12">

												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Email</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Email" name="mailChimpEmail" value={this.state.mailChimpEmail} onChange={(e) => this.onchange(e)} />
													</div>
												</div>

												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">API Key</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<input type="text" className="form-control"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? "disabled" : ""}
															placeholder="Api Key" name="apikeyMailChimp" value={this.state.apikeyMailChimp} onChange={(e) => this.onchange(e)} />
													</div>
												</div>


												<div className="form-group row">
													<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
													<div className="col-md-9 col-sm-8 col-xs-12">
														<Switch name="mailchipActive"
															disabled={(this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW') ? true : false}
															onChange={(e) => this.onchangeMailchipActive(e)} checked={this.state.mailchipActive? true: false} />
													</div>
												</div>

												{this.state.role === 'subUser' && this.state.permission.smtpSetting_Status !== 'RW' ?
													""
													:
													<div className="form-group-btn top-10 text-right">
														<button onClick={(e) => this.submit2(e)} disabled={this.state.disabledMailchimpButton} className="btn-style-md btn-purple btn-round mr-10">Save</button>
														<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>
													
													</div>
												}
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

				}
			</div>


		);
	}
}

export default withRouter(SMTP);



