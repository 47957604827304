import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import Switch from "react-switch";
import SiteService from './../../services/sites.service';
import AllUserService from './../../services/alluser.service';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class EditSite extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disableInput: true,
			linkState: '',
			domainname: '',
			host: '',
			username: '',
			password: '',
			conpassword: '',
			dbname: '',
			tablename: '',
			link: [],
			status: 'inactive',
			errors: '',
			id: '',
			userStatus: '',
		}
		this.alluserService = new AllUserService();
		this.siteService = new SiteService();
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		if (this.props.location.state != undefined) {
			this.setState({
				domainname: this.props.location.state.edit.domainName,
				host: this.props.location.state.edit.hostIp,
				username: this.props.location.state.edit.username,
				// password: this.props.location.state.edit.password,
				dbname: this.props.location.state.edit.dbName,
				tablename: this.props.location.state.edit.tableName,
				link: this.props.location.state.edit.link,
				id: this.props.location.state.edit._id,
				status: this.props.location.state.edit.status
			})
		} else {
			this.props.history.push('/*');
		}

	}

	onchangeSiteActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}



	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	changeSchema(e, index) {
		var arrayOfObjs = this.state.link;
		arrayOfObjs[index] = e.currentTarget.value;
		this.setState({ link: arrayOfObjs });
	}

	addTextfield(e) {
		e.preventDefault();
		var linkValue = this.state.linkState;
		var arrayOfObjs = this.state.link;
		arrayOfObjs.push(linkValue);
		// arrayOfObjs[index] = e.currentTarget.value;
		this.setState({ link: arrayOfObjs, linkState: '' });

		var inputs = this.state.link;

		// inputs.push('');
		// this.setState({
		// 	inputs
		// }, () => {
		// 	$("#afterHide").hide();
		// })
	}
	confirmCancel() {
		this.props.history.push('/sites');
	}

	cancelEditSite() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to abort your changes?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.confirmCancel(); onClose(); }}>Cancel</button>
									{/* <button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(id); onClose(); }}>Cancel</button> */}
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	removeTextField(indexValue) {
		var array123 = [];
		array123 = this.state.link;

		array123.splice((indexValue), 1);
		// if (array123.length > 0) {
		// 	$("#afterHide").hide();

		// } else {
		// 	$("#afterHide").show();

		// }

		this.setState({
			link: array123.slice()
		});
	}

	submit() {
		const { domainname, host, username, password, dbname, tablename, conpassword } = this.state;

		var count = 0;
		if (domainname === '') {
			this.setState({ errors: 'Domain name is required' });
			return;
		}
		else if (host === '') {
			this.setState({ errors: 'Host is required' });
			return;
		}
		else if (username === '') {
			this.setState({ errors: 'Username is required' });
			return;
		} else if (password !== "" && password.length < 6) {
			this.setState({ errors: 'Enter min 6 character password' });
			return;
		}
		else if (password !== "" && conpassword === '') {
			this.setState({ errors: 'Conform Password is required' });
			return;

		}
		else if (password !== conpassword) {
			console.log("erject")

			this.setState({ errors: 'Conform Password does not match' });
			return;

		}
		else if (dbname === '') {
			this.setState({ errors: 'DB name is required' });
			return;
		}
		else if (tablename === '') {
			this.setState({ errors: 'Table name is required' });
			return;
		}

		else if (this.state.link.length > 0) {
			this.state.link.map((val, index) => {
				if (val === '') {
					count++;
					this.setState({ errors: 'Remove Empty Link Fields' });
					return;
				} else {
					this.setState({ errors: "" })
				}
			})
		}


		if (count === 0 && domainname !== '' && host !== '' && username !== '' && dbname !== '' && tablename !== '') {
			console.log("success")
			let body = {
				domainName: this.state.domainname,
				hostIp: this.state.host,
				username: this.state.username,
				password: this.state.password,
				tableName: this.state.tablename,
				dbName: this.state.dbname,
				link: this.state.link,
				status: this.state.status,
			}
			this.siteService.editSite(this.state.id, body).then(data => {
				console.log(data)
				if (data.code === 200) {
					notify.show(data.message, "success", 2000);
					if (data.data.status === 'inactive') {
						localStorage.removeItem('bodysiteID');
					}
					setTimeout(() => {
						this.setState({
							domainname: '',
							host: '',
							username: "",
							password: "",
							tablename: "",
							dbname: "",
							link: [],
							status: 'inactive',

						})
						this.props.history.push('/sites')
					}, 1000);

				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	render() {
		var currentThis = this;
		return (
			<React.Fragment>
				<div>
					<Notifications options={{ zIndex: 200, top: '20px' }} />

					<div className="content-header">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>Edit site</h3>
								</div>
							</div>
							<div className="col-md-6 col-sm-6 col-xs-12">
								<div className="add-btn text-right list-item">
									<button type="button" className="btn-purple btn-style-md btn-round mr-5" onClick={() => this.submit()}>Save</button>
									<button className="btn-purple btn-style-md btn-round " onClick={() => this.confirmCancel()}>Cancel</button>

								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-8 col-xs-12">
							{Object.keys(this.state.errors).length > 0 && this.state.errors ?
								<div className="alert alert-danger">{this.state.errors}</div> : ''}
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Domain name</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="domainname" value={this.state.domainname} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Host</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="host" value={this.state.host} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Username</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="username" value={this.state.username} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Password</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="password" value={this.state.password} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Confirm Password</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="conpassword" value={this.state.conpassword} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">DB Name</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="dbname" value={this.state.dbname} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Table Name</label>
								<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
									<input type="text" className="form-control" name="tablename" value={this.state.tablename} onChange={(e) => this.onchange(e)} />
								</div>
							</div>
							<div className="form-group row" id="dynamic">
								<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Add Links</label>
								<div className="col-md-8">
									{this.state.link.map((val, i) => {
										return (
											<div className="field-add" key={i}>
												<div className="field-add-input mr-10" id="dynamic">
													<input type="text" className="form-control" id={i} name="link" value={this.state.link[i]} onChange={(e) => this.changeSchema(e, i)} />
												</div>
												<div className="field-add-btns" id="minusbutton">
													<button className="btn-rem ml-5" onClick={() => currentThis.removeTextField(i)}>
														<i className="fa fa-minus" aria-hidden="true"></i>
													</button>
												</div>
											</div>
										)
									})}
									<div className="field-add-btns" id="afterHide">
										<button className="btn-add" onClick={(e) => this.addTextfield(e)}>
											<i className="fa fa-plus" aria-hidden="true"></i>
										</button>
									</div>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Status</label>
								<div className="col-md-9 col-sm-8 col-xs-12">
									<Switch name="status"
										onChange={(e) => this.onchangeSiteActive(e)} checked={this.state.status == 'active' ? true : false} />
								</div>
							</div>

						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(EditSite);