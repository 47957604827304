import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './resetPassword.css';
import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import 'react-circular-progressbar/dist/styles.css';
import LoginService from '../../services/login.service';


class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			conpassword: '',
			errors :"",
			userID : ''
		}
		this.loginService = new LoginService();
	}
	//life Cycle
	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const UserID = urlParams.get('userID');
		this.setState({
			userID: UserID
		})
        
	}



	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	reset(){
		const {password, conpassword} = this.state;
		if (password === "") {
			this.setState({ errors: 'Enter min 6 character password' });
			return;
		}
		else if (password !== "" && password.length < 6) {
			this.setState({ errors: 'Enter min 6 character password' });
			return;
		}
		else if (password !== "" && conpassword === '') {
			this.setState({ errors: 'Conform Password is required' });
			return;

		}
		else if (password !== conpassword) {

			this.setState({ errors: 'Conform Password does not match' });
			return;

		}else {
			this.setState({
				errors : ""
			})
			let data = {
				password : this.state.password,
				userId: this.state.userID
			}
			this.loginService.resetPassword(data).then(data => {
				if(data.code ===400){
					this.setState({
						errors: data.message
					})
				}else if(data.code === 200){
					let th =this;
					notify.show(data.message, 'success',2000);
					setTimeout(() => {
						th.props.history.push('/login')
					}, 3000);
				}
			})

		}
	}

	

	render() {
		return (
			<React.Fragment>
				<div className="lead-login-pop">
					{/* <button type="button" className="btn-lg btn-go hvr-push">Let's <br/>Go!</button> */}
					{/* <!-- Modal --> */}
					<div id="loginmodal" className="modal show in" role="dialog" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
						
						<div className="modal-dialog">
							{/* <!-- Modal content--> */}
							<div className="modal-content">
								<div className="login-wrap">
									<div className="header text-center">
										<a href="">
											<img className="img-responsive center-block" src={require('./../../assets/images/logo-icon.png')} alt="" />
											<h3 className="text-italic text-white mt-10">eSignRePack</h3>
										</a>
									</div>
									<div className="login-content">
										<div className="heading">Reset Password</div>

										<div className="leadlogin-form">
											<div className="login-form">
												{Object.keys(this.state.errors).length > 0 && this.state.errors ?
													<div className="alert alert-danger">
														{this.state.errors}
													</div> : ''}

												<div className="form-group"><input type="password" className="form-control" name="password" placeholder="Password" onKeyDown={this._handleKeyDown} onChange={(e) => this.onchange(e)} /><i className="fa fa-lock input-icon"></i></div>
												<div className="form-group"><input type="password" className="form-control" name="conpassword" placeholder="Confirm Password" onKeyDown={this._handleKeyDown} onChange={(e) => this.onchange(e)} /><i className="fa fa-lock input-icon"></i></div>
												<div className="clearfix"></div>
												<div className="form-group text-center">
													<a type="submit" onClick={(e) => this.reset(e)} className="btn btn-style-lg btn-purple btn-round">Reset</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(ResetPassword)
