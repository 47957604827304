import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class AllCampaignService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }

    async createCampaign(body) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data = await fetch(url + 'add/campaign', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data;
    }

    async editCampaign(body , id) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data = await fetch(url + `api/campaigns/edit/${id}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data;
    }

    // async deleteCampaign(id){
    //     var token = JSON.parse(localStorage.getItem("token"));
    //     var url = ENV.url;
    //     const data = await fetch(url + 'delete/campaign/:id',{
    //         method : 'GET',
    //         body: JSON.stringify(body),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': token.tokenType + ' ' + token.accessToken,
    //         }
    //     })
    // }

    async get() {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data = await fetch(url + 'api/campaigns', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data;
    }

    async editSingleCompain(compainId) {
        var token = JSON.parse(localStorage.getItem("token"));      
        var url = ENV.url;
        debugger
        const data = await fetch(url + `api/campaigns/get/${compainId}`, {
           
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
       
        return data;
    }



    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default AllCampaignService;