import React, { Component } from "react";
import moment, { relativeTimeThreshold } from "moment";
import SmSTemplateService from "../../services/smstemplate.service";
import EmailTemplateService from "../../services/emailtemplate.service";
import InfiniteScroll from "react-infinite-scroll-component";
import AllUserService from "./../../services/alluser.service";
import "./leadslisting.css";
import $ from "jquery";
import LeadsListingService from "../../services/leadslisting.service";
import BitlayService from "./../../services/bitlay.service";
import "react-datepicker/dist/react-datepicker.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Notifications, { notify } from "react-notify-toast";
import ClipLoader from "react-spinners/ClipLoader";
import tick from "./../../assets/images/tick.gif";
import loader from "./../../assets/images/loader.gif";
import cross from "./../../assets/images/cross.gif";
import orange from "./../../assets/images/orange.gif";
import check from "./../../assets/images/check.png";

import { ENV } from "./../../env";
import { css } from "@emotion/core";
import GlobalSearchService from "../../services/globalSearch.service";
import copy from "copy-to-clipboard";
import Modal from "react-responsive-modal";
import Swal from "sweetalert2";
var base64 = require("base-64");

const override = css`
  margin: 0 auto;
  overflow: hidden;
`;

class LeadsListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //__ __ //
      globalSearchCheck: false,
      globalSearch: [],
      search: "",
      filter_fname: "",
      filter_lname: "",
      filter_email: "",
      filter_ref: "",
      filter_phone: "",
      leadsType: "PBA",
      zip: "",
      isloading: false,
      leadStatus: "",
      signature: "",
      id_upload:"",
      linktoCopy: "",
      csvID: "",
      searchDropdown: "",
      multiSelect: [],
      editPhoneModel: false,
      editPhoneNumber: "",
      title: "",
      email: "",
      firstname: "",
      lastname: "",
      editCsvId: "",
      editleadId: "",
      //__ __ //
      filterCheck: false,
      hasMorephone: true,
      hasMoreemail: true,
      hasMorewithout: true,
      hasMorewith: true,
      smstype: "",
      emailtype: "",
      smstypeList: [],
      emailtypeList: [],
      smssentTo: "",
      emailsentTo: "",
      signedleadsTo: "",
      openedLinks: "",
      Conversion: "",
      csvError: "",
      link1: [],
      isLoading: false,
      csvLoaderButton: false,
      file: File,
      selectedCSV: "", // in exisiting csv dropdown value
      csvName: "",
      csvID: "", // set csvID during upload of CSVas
      errors: "",
      errorsModal1: "",
      errorsLink: "",
      linkExpiryDate: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      // filterError: "",
      siteArray: [],
      sendTheMessages: true,
      selectedSiteID: "", // dropdown site in leadlisting
      start: 0,
      limit: 50,
      to: "",
      from: "",
      selectedCsvName: "",
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      dataCounts: [],
      tabs: "phone",
      leadwithphone: [],
      // leadwithphone_search: [],
      leadwithemail: [],
      leadwithsignature: [],
      leadwithoutsignature: [],
      leadwithphonecheck: [],
      leadwithsignaturecheck: [],
      leadwithoutsignaturecheck: [],
      agentNotesCheck: [],
      leadwithemailcheck: [],
      smstemplate: [],
      pdfTemplates: [],
      emailtemplate: [],
      selectedEmailID: "",
      selectedSMSID: "",
      openModal: false,
      isHeaderModal: false,
      ImportCSVModal: false,
      csvCreatedModal: false,
      permission: "",

      uploadedFileHeader: [],
      keyValCSVdata: [],
      jsonCreatedDropdown: [],
      isHeader: false,
      link: [""],
      linktoCopy: "",
      path: ENV.url,
      csvLoader: false,
      csv_edit_array: [],

      // //////////////////////
      // pagination
      pg_start: "",
      pg_limit: "",

      //filter serach
      filter_fname: "",
      filter_lname: "",
      filter_email: "",
      filter_phone: "",
      zip: "",

      //loader
      pandaLoader: true,
      userStatus: "",
      role: "",
      externalLink: "",
      pdfTemplateID: "",
      pdfTemplates: [],
      is_signed: 0,
      agentId: "",

      webLink: "",
      webLinkCheck: false,

      agentPaperPackLink: "",
      agentPaperPackCheck: false,

      leadsTypeArray: [],

      notes: "",
    };
    this.leadlistingService = new LeadsListingService();
    this.bitlayservice = new BitlayService();
    this.alluserService = new AllUserService();
    this.globalSearchService = new GlobalSearchService();
    this.emailTemplateService = new EmailTemplateService();
    this.addSmsTemplateService = new SmSTemplateService();
  }

  componentDidMount() {
    var that = this;

    window.scrollTo(0, 0);

    if (this.props.location.state) {
      //1this.props.location.state
      this.setState({
        selectedCSV: this.props.location.state.csvId,
      });
      localStorage.setItem(
        "bodyselectedCSV",
        JSON.stringify(this.props.location.state.csvId)
      );
    }
    let role = JSON.parse(localStorage.getItem("user"));
    this.setState({
      role: role,
      externalLink: role.externalLink,
      agentId: role.id,
    });
    if (role) {
      let roleStatus = role.id;
      this.alluserService
        .editUser(roleStatus)
        .then((data) => {
          if (data.code === 200) {
            let result = data.data;
            this.setState({
              userStatus: result,
            });
            if (this.state.userStatus.status === "inactive") {
              localStorage.clear();
              this.props.history.push("/");
            }
            this.getPageInnerPermision(result);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    let rolename = role.role;
    if (rolename === "subUser") {
      this.getPermissions();
    } else if (rolename === "admin") {
      this.setState({
        sendTheMessages: false,
        pandaLoader: false,
      });
    } else {
      this.setState({ pandaLoader: false });
    }
    this.smsTemplate();
    this.emailTemplate();
    this.sideBarShowHideScript();
    this.getSMStype();
    this.getEmailtype();
  }

  getPermissions() {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = user.id;
    let role = user.role;
    if (role === "subUser") {
      this.alluserService
        .userPermission(id)
        .then((data) => {
          if (data.code === 200) {
            let result = data.data;
            this.setState({
              permission: result,
              pandaLoader: false,
            });
            let perm = this.state.permission;
            if (false) {
              this.props.history.push("*");
            } else if (this.state.permission.sendMessage === false) {
              this.setState({
                sendTheMessages: false,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getPageInnerPermision(data) {
    debugger;
    let role = data.role;
    let userId = data._id;
    if (role == "admin") {
      userId = data._id;
    } else if (role == "user") {
      userId = data._id;
    } else if (role == "subUser") {
      userId = data.parentId;
    }
    let dataArray = [];
    this.alluserService
      .getInnerPermission(userId)
      .then((data) => {
        debugger;
        let agentLeadSearch = data.data.agentLeadSearch;
        let claimLink = data.data.claimLink[0];
        let agentPaperPack = data.data.agentPaperPack[0];
        let agentNotes = data.data.agentNotes[0];
        let qq = 0;
        var emailId = "";
        var smsId = "";
        for (var i = 0; agentLeadSearch.length > i; i++) {
          if (agentLeadSearch[i].check == true && agentLeadSearch[i].value) {
            if (qq == 0) {
              // this.setState({leadsType : agentLeadSearch[i].value});
              emailId = agentLeadSearch[i].emailTemplate;
              smsId = agentLeadSearch[i].smsTemplate;
            }

            dataArray.push(agentLeadSearch[i]);
            qq = 1;
          }
        }
        debugger;
        this.setState({
          leadsTypeArray: dataArray,
          ppiq: agentLeadSearch[0].check,
          long_form: agentLeadSearch[1].check,
          webLink: claimLink.link,
          webLinkCheck: claimLink.check,
          agentPaperPackCheck: agentPaperPack.check,
          agentNotesCheck: agentNotes.check,
        });

        this.smsTemplate(smsId);
        this.emailTemplate(emailId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSMStype() {
    this.leadlistingService.getSMStype().then((data) => {
      if (data.code === 200) {
        this.setState({
          smstypeList: data.data,
        });
      }
    });
  }

  getEmailtype() {
    this.leadlistingService.getEmailtype().then((data) => {
      console.log(
        "-------------------------------------------------------",
        data
      );
      if (data.code === 200) {
        this.setState({
          emailtypeList: data.data,
        });
      }
    });
  }

  sideBarShowHideScript() {
    $("#sidebarCollapseLead").on("click", function () {
      $("#sidebar").toggleClass("active");
    });
    $("#sidebarCollapseLead").on("click", function () {
      $("#sidebarCollapse").toggleClass("active");
    });
  }
  onOpenModal = () => {
    this.setState({ openModal: true });
  };
  onCloseImportCSV = () => {
    this.setState({
      ImportCSVModal: false,
      csvName: "",
      isHeader: false,
      link: [],
      errorsModal1: "",
      file: File,
    });
  };
  onCSVcreated = (e) => {
    this.setState({ csvCreatedModal: true });
  };

  OnClosecsvCreatedModal = () => {
    this.setState({ csvCreatedModal: false });
  };

  smsTemplate(id) {
    this.addSmsTemplateService
      .getOneSmsTemplate(id)
      .then((data) => {
        console.log(data, "sms");
        if (data.code === 200) {
          this.setState({
            smstemplate: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  emailTemplate(id) {
    this.emailTemplateService
      .editEmailTemplate(id)
      .then((data) => {
        console.log(data, "email");
        if (data.code === 200) {
          this.setState({
            emailtemplate: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showInput(e) {
    e.preventDefault();
    $("#showInput").trigger("click");
  }

  handleChange(e) {
    console.log("handlechange empty site id");
    var current = this;
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.leadlistingService
        .getSingleCSV(this.state.selectedCSV)
        .then((data) => {
          console.log(data);
          this.setState({
            file: current.state.path + data.data.file,
            csvName: data.data.name,
            // csvID: data.data._id,
            linktoCopy: data.data.link.length > 0 ? data.data.link[0] : "",
            csvID: "",
            errorsModal1: "",
            selectedSiteID: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  onchange(e) {
    debugger;
    var name = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (name == "leadsType") {
      this.setState({
        globalSearch: [],
      });
    }
  }

  onchangelead(e) {
    debugger;
    var name = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (name == "leadsType") {
      this.setState({
        globalSearch: [],
      });
    }
    let leadsTypeArray = this.state.leadsTypeArray;
    let emailId = "";
    let smsId = "";
    for (var i = 0; leadsTypeArray.length > i; i++) {
      if (leadsTypeArray[i].value == e.target.value) {
        emailId = leadsTypeArray[i].emailTemplate;
        smsId = leadsTypeArray[i].smsTemplate;
      }
    }

    this.smsTemplate(smsId);
    this.emailTemplate(emailId);
  }

  onchangeNote(e, val) {
    let globalSearch = this.state.globalSearch;
    globalSearch[0].notes = e.target.value;
    this.setState({
      globalSearch: globalSearch,
    });
  }

  onchangeSelectSite(e) {
    this.setState({
      [e.target.name]: e.target.value,
      csvID: "",
      selectedCSV: "",
    });
  }

  selectEmailSMS(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  selectPdfTemplate(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  checkLeadCurrentStatus(lead) {

    let leadCheck = this.state.globalSearch;
    let leadsType = this.state.leadsType;
    var current = this;
    if (leadCheck.length != 0 && leadsType != "pcp") {
      console.log("tyyyyyyyyyyyyyyyyyyyyyy");
      console.log(lead);
      var data = {
        lead: lead,
      };
      
      this.leadlistingService
        .currentLeadStatus(data)
        .then((data) => {
          if (data.code == 200) {

            let check_run = 0;

            let lead1 = data.lead;
            if (lead1.is_signed == 0) {
              this.setState({
                leadStatus: "process",
              });
              setTimeout(function () {
                current.checkLeadCurrentStatus(lead);
              }, 5000);
            }
            if (lead1.is_signed == 1) {
              this.setState({ leadStatus: "true", signature: lead1?.signature_src, id_upload: lead1?.pbaPdf_url });
              check_run = 1;
              // aim878
              if (
                lead1.is_signed == 1 &&
                lead1.is_plevin > 7 &&
                lead1.is_plevin < 11 
              ) {
                let formData = {
                  eLid: lead1.leadid,
                  is_signed: "iamzero",
                  ecRef:
                    this.state.selectedCSV == ""
                      ? this.state.csvID
                      : this.state.selectedCSV,
                };
                console.log("formData1111111111111111111111111", formData);
                fetch("https://www.esignrepack.co.uk/logger/leadNotSigned", {
                  method: "POST",
                  body: JSON.stringify(formData),
                  headers: { "Content-Type": "application/json" },
                })
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      debugger;
                      console.log("result", result);
                    },
                    (error) => {
                      debugger;
                      console.log(error);
                    }
                  );
              }
            }

            if (
              lead1.pbaDirectLink == "" &&
              this.state.leadsType == "pcp_finance"
            ) {
              this.setState({
                long_form_Status: "process",
              });
              if (check_run === 1) {
                setTimeout(function () {
                  current.checkLeadCurrentStatus(lead);
                }, 5000);
              }
         
            
            }
            if (
              lead1.pbaDirectLink != "" &&
              this.state.leadsType == "pcp_finance"
            ) {
              this.setState({ long_form_Status: "true" });
            }
          }
        })
        .catch((err) => {});
    } else {
      this.setState({
        leadStatus: "",
        long_form_Status: "",
      });
    }

    if(leadsType == "pcp"){
     
        setTimeout(function () {
          current.filterData();
        }, 5000);
    }

  }

  sendEmail(e, typeLink) {
    var current = this;
    var checkboxes = [];
    checkboxes = [
      ...this.state.leadwithemailcheck,
      ...this.state.leadwithsignaturecheck,
      ...this.state.leadwithoutsignaturecheck,
      ...this.state.leadwithphonecheck,
    ];

    if (checkboxes.length == 0) {
      this.setState({
        errors: "Please select lead",
      });
      return false;
    } else {
      if (current.state.emailtemplate.length == 0) {
        this.setState({
          errors: "No Email Template In System",
        });
        return false;
      }
      let templateId =
        current.state.emailtemplate[current.state.emailtemplate.length - 1];
      let body = {
        leads: checkboxes,
        type: 1,
        emailTemplate: templateId._id,
        typeLink: typeLink,
      };
      current.leadlistingService
        .GlobalSendCSV(body)
        .then((data) => {
          if (data.code == 404) {
            notify.show(data.message, "error", 2000);
          } else if (data.code == 200) {
            debugger;
            current.checkLeadCurrentStatus(body.leads);
            current.sendPhpDashboard(body);
            current.setState({
              leadwithphonecheck: [],
              leadwithemailcheck: [],
              leadwithsignature: [],
              leadwithoutsignaturecheck: [],
              selectedEmailID: "",
              // selectedCSV:'',
              emailtype: "",
              errors: "",
            });

            notify.show(data.message, "success", 2000);
            $("#emailtype").val($("#target option:first").val());

            $(".leadphone").prop("checked", false);
            $(".leademail").prop("checked", false);
            $(".leadwith").prop("checked", false);
            $(".leadwithout").prop("checked", false);

            $(".leadphoneall").prop("checked", false);
            $(".leademailall").prop("checked", false);
            $(".leadwithall").prop("checked", false);
            $(".leadwithoutall").prop("checked", false);
          }
        })
        .catch((err) => {
          this.setState({
            selectedEmailID: "",
          });
          console.log(err);
        });
    }
  }

  sendSMS(e, typeLink) {
    var current = this;
    var checkboxes = [];
    checkboxes = [
      ...this.state.leadwithemailcheck,
      ...this.state.leadwithsignaturecheck,
      ...this.state.leadwithoutsignaturecheck,
      ...this.state.leadwithphonecheck,
    ];
    debugger;
    if (checkboxes.length == 0) {
      this.setState({
        errors: "Please select lead",
      });
      return false;
    } else {
      if (current.state.smstemplate.length == 0) {
        this.setState({
          errors: "No SMS template present in System!",
        });
        return false;
      }

      let templateId =
        current.state.smstemplate[current.state.smstemplate.length - 1];
      let body = {
        leads: checkboxes,
        type: 1,
        smsTemplate: templateId._id,
        typeLink: typeLink,
      };

      current.leadlistingService
        .GlobalSendSmsCSV(body)
        .then((data) => {
          if (data.code == 404) {
            notify.show(data.message, "error", 2000);
          } else if (data.code === 200) {
            current.checkLeadCurrentStatus(body.leads);
            current.sendPhpDashboard(body);
            current.setState({
              leadwithphonecheck: [],
              leadwithemailcheck: [],
              leadwithsignature: [],
              leadwithoutsignaturecheck: [],
              selectedSMSID: "",
              // selectedSiteID : '',
              errors: "",
              smstype: "",
            });
            $("#smstype").val($("#target option:first").val());

            notify.show(data.message, "success", 2000);

            $(".leadphone").prop("checked", false);
            $(".leademail").prop("checked", false);
            $(".leadwith").prop("checked", false);
            $(".leadwithout").prop("checked", false);

            $(".leadphoneall").prop("checked", false);
            $(".leademailall").prop("checked", false);
            $(".leadwithall").prop("checked", false);
            $(".leadwithoutall").prop("checked", false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async SendSMSEmail(e, typeLink) {
    var current = this;
    var checkboxes = [];
    checkboxes = [
      ...this.state.leadwithemailcheck,
      ...this.state.leadwithsignaturecheck,
      ...this.state.leadwithoutsignaturecheck,
      ...this.state.leadwithphonecheck,
    ];

    if (checkboxes.length == 0) {
      this.setState({
        errors: "Please select lead",
      });
      return false;
    } else {
      if (current.state.smstemplate.length == 0) {
        this.setState({
          errors: "No SMS template present in System!",
        });
        return false;
      }
      if (current.state.emailtemplate.length == 0) {
        this.setState({
          errors: "No Email Template In System",
        });
        return false;
      }

      let smsTemplate =
        current.state.smstemplate[current.state.smstemplate.length - 1];
      let emailTemplate =
        current.state.emailtemplate[current.state.emailtemplate.length - 1];

      let body = {
        leads: checkboxes,
        type: 1,
        smsTemplate: smsTemplate._id,
        emailTemplate: emailTemplate._id,
        typeLink: typeLink,
      };
      current.leadlistingService
        .GlobalSendCSV(body)
        .then((data) => {
          if (data.code == 404) {
            notify.show(data.message, "error", 2000);
          } else if (data.code == 200) {
            current.sendPhpDashboard(body);
            current.leadlistingService
              .GlobalSendSmsCSV(body)
              .then((data) => {
                if (data.code == 404) {
                  notify.show(data.message, "error", 2000);
                } else if (data.code === 200) {
                  current.setState({
                    leadwithphonecheck: [],
                    leadwithemailcheck: [],
                    leadwithsignature: [],
                    leadwithoutsignaturecheck: [],
                    selectedSMSID: "",
                    // selectedSiteID : '',
                    errors: "",
                    smstype: "",
                  });
                  $("#smstype").val($("#target option:first").val());
                  notify.show(
                    "Send Email and Sms Successfully!",
                    "success",
                    2000
                  );
                  $(".leadphone").prop("checked", false);
                  $(".leademail").prop("checked", false);
                  $(".leadwith").prop("checked", false);
                  $(".leadwithout").prop("checked", false);
                  $(".leadphoneall").prop("checked", false);
                  $(".leademailall").prop("checked", false);
                  $(".leadwithall").prop("checked", false);
                  $(".leadwithoutall").prop("checked", false);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          this.setState({
            selectedEmailID: "",
          });
          console.log(err);
        });
    }
  }

  sendPhpDashboard(body) {
    var current = this;
    current.leadlistingService
      .PhpDashboardLead(body)
      .then((data) => {
        if (data.code == 404) {
          notify.show(data.message, "error", 2000);
        } else if (data.code === 200) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  changePhoneCheckBox(e, lead) {
    var options = [];
    options = this.state.leadwithphonecheck;
    let index;
    let obj = {};
    obj.csvId = lead.csvId;
    obj.leadId = e.target.value;
    if (e.target.checked) {
      options.push(obj);
    } else {
      var result = options.filter(function (obj) {
        return obj.leadId === e.target.value;
      })[0];
      console.log(result);

      index = options.indexOf(result);
      options.splice(index, 1);
    }
    this.setState({ leadwithphonecheck: options });
  }

  selectAllwithPhone(e) {
    alert("hello");
    if (e.target.checked) {
      let array = [];
      for (let index = 0; index < this.state.leadwithphone.length; index++) {
        let key = this.state.leadwithphone[index].leadid;
        array.push(key);
      }
      this.setState({
        leadwithphonecheck: array,
      });
      $(".leadphone").prop("checked", true);
    } else {
      this.setState({
        leadwithphonecheck: [],
      });
      $(".leadphone").prop("checked", false);
    }
  }

  reset() {
    this.setState({
      csvID: "",
      selectedCSV: "",
      selectedSiteID: "",
      leadwithsignature: [],
      leadwithoutsignature: [],
      leadwithphone: [],
      leadwithemail: [],
      leadwithsignaturecheck: [],
      leadwithoutsignaturecheck: [],
      leadwithphonecheck: [],
      globalSearch: [],
      leadwithemailcheck: [],
      leadStatus: "",
      long_form_Status: "",
      filter_email: "",
      filter_phone: "",
      filter_fname: "",
      filter_lname: "",
      filter_ref: "",
      zip: "",
      from: "",
      to: "",

      reminder: "",
      selectedEmailID: "",
      selectedSMSID: "",
      errors: "",
      dataCounts: [],
      isLoading: false,
      emailsentTo: "",
      smssentTo: "",
      signedleadsTo: "",
      openedLinks: "",
      conversion: "",
      start: 0,
      limit: 50,
      isLoading: false,
      hasMoreemail: false,
      hasMorephone: false,
      hasMorewith: false,
      hasMorewithout: false,
    });
  }

  unSubscribe(e, lead) {
    debugger;
    Swal.fire({
      title: "Are you sure?",
      text: "You will able to Un-Subscribers!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Un subscribers it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        const body = {
          email: lead.email,
          phone: lead.phone1,
          reason: "unsubscribe from Agent",
          fromEmails: true,
          fromMessage: true,
          firstName: lead.firstname,
          lastName: lead.lastname,
          agentId: this.state.agentId,
          csvId: lead.csvId,
        };
        debugger;
        this.leadlistingService
          .unSubscribe(body)
          .then((data) => {
            if (data.status === true) {
              if (data) {
                debugger;
                this.setState({
                  isLoading: false,
                });
                this.leadlistingService
                  .deleteLead(lead.csvId, lead.leadid)
                  .then((data) => {
                    if (data.code === 200) {
                    }
                  });
                Swal.fire(
                  "UN Subscribe!",
                  "Your Lead file has been unsubscribe.",
                  "success"
                );
                this.reset();
              } else {
                notify.show(data.message, "error", 2000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your unsubscribe lead is safe :)", "error");
      }
    });
  }

  agentPaperPack(e, lead) {
    debugger;
    Swal.fire({
      title: "Are you sure?",
      text: "You will able to Create Request Paper Pack !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes,  Request Paper Pack it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        const body = {
          leads: lead,
          email: lead.email,
          phone: lead.phone1,
          reason: " Request Paper Pack ",
          fromEmails: true,
          fromMessage: true,
          firstName: lead.firstname,
          lastName: lead.firstname,
          agentId: this.state.agentId,
          csvId: lead.csvId,
        };
        this.leadlistingService
          .agentPaperPack(body)
          .then((data) => {
            if (data.status === true) {
              if (data) {
                this.setState({
                  isLoading: false,
                });
                Swal.fire(
                  "UN Subscribe!",
                  "Your Lead file has been not submit  Request Paper Pack ",
                  "success"
                );
                this.reset();
              } else {
                notify.show(data.message, "error", 2000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelled",
          "Your  Request Paper Pack lead is safe :)",
          "error"
        );
      }
    });
  }

  saveNotes(e, lead) {
    debugger;
    var th = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You will able to Create Notes Lead !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes,  Notes Lead it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        const body = {
          leads: lead,
          email: lead.email,
          phone: lead.phone1,
          reason: " Request Paper Pack ",
          fromEmails: true,
          fromMessage: true,
          firstName: lead.firstname,
          lastName: lead.firstname,
          agentId: this.state.agentId,
          csvId: lead.csvId,
        };
        th.leadlistingService
          .saveNotes(body)
          .then((data) => {
            if (data.status === true) {
              if (data) {
                this.setState({
                  isLoading: false,
                });
                Swal.fire(
                  "Notes Lead!",
                  "Your Lead  has been not Notes Lead ",
                  "success"
                );
                this.reset();
              } else {
                notify.show(data.message, "error", 2000);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your  Notes Lead lead is safe :)", "error");
      }
    });
  }

  fetchMoreDataWithPhone = () => {
    var that = this;
    console.log(this.state.limit);
    this.setState({
      hasMorephone: true,
      limit: that.state.limit + 50,
    });
    // setTimeout(() => {
    that.leadlistingService
      .getCsvCollection(
        that.state.csvID ? that.state.csvID : that.state.selectedCSV,
        that.state.start,
        that.state.limit
      )
      .then((data) => {
        if (data.code === 200) {
          if (data.dataCounts[0].with_phone === data.leadsWithPhone.length) {
            console.log("yes");
            that.setState({
              hasMorephone: false,
            });
          }
          that.setState({
            csvCreatedModal: false,
            leadwithemail: data.leadsWithEmail,
            leadwithphone: data.leadsWithPhone,
            leadwithsignature: data.leadsWithSignature,
            leadwithoutsignature: data.leadsWithoutSignature,
            dataCounts: data.dataCounts,
            link: [],
          });
        }
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
        });
        console.log(err);
      });
  };

  fetchMoreDataWithEmail = () => {
    var that = this;
    this.setState({
      hasMoreemail: true,
      limit: that.state.limit + 50,
    });
    // setTimeout(() => {
    that.leadlistingService
      .getCsvCollection(
        that.state.csvID ? that.state.csvID : that.state.selectedCSV,
        that.state.start,
        that.state.limit
      )
      .then((data) => {
        if (data.code === 200) {
          if (data.dataCounts[0].with_email === data.leadsWithEmail.length) {
            that.setState({
              hasMoreemail: false,
            });
          }
          that.setState({
            csvCreatedModal: false,
            leadwithemail: data.leadsWithEmail,
            leadwithphone: data.leadsWithPhone,
            leadwithsignature: data.leadsWithSignature,
            leadwithoutsignature: data.leadsWithoutSignature,
            dataCounts: data.dataCounts,
            link: [],
          });
        }
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
        });
        console.log(err);
      });
    // }, 1000);
  };

  filterData() {
    if (
      this.state.filter_email === "" &&
      this.state.filter_phone === "" &&
      this.state.filter_fname === "" &&
      this.state.filter_lname === "" &&
      this.state.filter_ref === "" &&
      this.state.zip === ""
    ) {
      return;
    }

    let data = {
      csvArray: this.state.multiSelect,
    };
    let body = {
      filter_email: this.state.filter_email,
      filter_phone: this.state.filter_phone,
      filter_fname: this.state.filter_fname,
      filter_lname: this.state.filter_lname,
      filter_ref: this.state.filter_ref,
      zip: this.state.zip,
      leadsType: this.state.leadsType,
      is_signed: 0,
    };
    this.setState({
      globalSearchCheck: true,
    });
    this.globalSearchService
      .globalSearch(body, data)
      .then((data) => {
        debugger;
        if (data.code === 200) {
          console.log("Ahmad Testing", data.data);
          this.setState({
            globalSearch: data.data,
            globalSearchCheck: false,
            signature: "", 
            id_upload: "",
            leadStatus: "false",
            csvID: data.data[0].csvId,
          });

          this.checkLeadCurrentStatus(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          globalSearchCheck: false,
        });
      });
  }

  openPDFLink(e, lead, url) {
    $(e.target).parent().addClass("do-red");
    const { leadid, pdf_url, complain_url, loas, pbaPdf_url, unsigned_url } =
      lead;
    let currentURL = "";
    let formData = {
      eLid: leadid,
      ecRef:
        this.state.selectedCSV == ""
          ? this.state.csvID
          : this.state.selectedCSV,
    };
    if (url === "pdf_url") {
      formData.pdf_url = pdf_url;
      formData.isPdfDownload = true;
      currentURL = pdf_url;
    } else if (url === "unsigned_url") {
      formData.unsigned_url = unsigned_url;
      formData.isUnsignedDownload = true;
      currentURL = unsigned_url;
    } else if (url === "complain_url") {
      formData.complain_url = complain_url;
      formData.isComplainDownload = true;
      currentURL = complain_url;
    } else if (url === "loas") {
      formData.loas = loas;
      formData.isLoasDownload = true;
      currentURL = loas;
    } else if (url === "pbaPdf_url") {
      formData.pbaPdf_url = pbaPdf_url;
      formData.isPbaPdfDownload = true;
      currentURL = pbaPdf_url;
    }
    console.log(formData);
    fetch("https://www.esignrepack.co.uk/logger/LeadSigned", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("result", result);
          window.open(currentURL);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  gotoGlobalSearch() {
    this.props.history.push("/global-search");
  }

  copyData(test) {
    let global = this.state.globalSearch;
    this.checkLeadCurrentStatus(global);
    copy(test);
    notify.show("Link Copy Successfully!", "success", 2000);
  }

  editPhoneNumber(val) {
    debugger;
    console.log("val", val);
    this.setState({
      editPhoneModel: true,
      editPhoneNumber: val.phone1,
      editCsvId: val.csvId,
      editleadId: val._id,
      title: val.title,
      email: val.email,
      firstname: val.firstname,
      lastname: val.lastname,
    });
  }


  resetlink(val) {
    console.log("val", val);
    var current = this;
    let payload = {
      editCsvId: val.csvId,
      editleadId: val._id,
      leadid: val.primary_lead_id,
      url: val.directLink
    };

    current.leadlistingService
      .reset(payload)
      .then((data) => {
        if (data.code == 404) {
          notify.show(data.message, "error", 2000);
        } else if (data.code == 200) {
          notify.show(data.message, "success", 2000);
          current.filterData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  UpdatePhone() {
    var current = this;
    let body = {
      editPhoneNumber: this.state.editPhoneNumber,
      editCsvId: this.state.editCsvId,
      editleadId: this.state.editleadId,
      title: this.state.title,
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
    };
    debugger;
    current.leadlistingService
      .UpdatePhone(body)
      .then((data) => {
        if (data.code == 404) {
          notify.show(data.message, "error", 2000);
        } else if (data.code == 200) {
          current.setState({
            editPhoneModel: false,
          });
          if (current.state.filter_phone != "") {
            this.setState(
              {
                filter_phone: current.state.editPhoneNumber,
              },
              () => {
                current.filterData();
              }
            );
          } else {
            current.filterData();
          }
          notify.show(data.message, "success", 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  closeSMSModal = () => {
    this.setState({ editPhoneModel: false });
  };

  ppiqURL(primary_leads_row_id, leadid, urlDirect) {
    var url = "pba-checker.co.uk";

    if (urlDirect.indexOf(url) !== -1) {
      return `https://pba-checker.co.uk/plevin?key=${primary_leads_row_id}&childKey=${leadid}`;
    }

    url = "unaffordable.co.uk";
    if (urlDirect.indexOf(url) !== -1) {
      return `https://unaffordable.co.uk/cap1/ppiq-form/${primary_leads_row_id}`;
    }

    url = "legalukrefund.co.uk";
    if (urlDirect.indexOf(url) !== -1) {
      return `https://legalukrefund.co.uk/cap1stg/ppiq-form/${primary_leads_row_id}`;
    }
  }

  render() {
    var questionCheck = "?";

    var currentThis = this;
    console.log(this.state.globalSearch);
    console.log(this.state.leadsType);
    return (
      <React.Fragment>
        {this.state.pandaLoader === true ? (
          <div className="loading">Loading&#8230;</div>
        ) : (
          ""
        )}

        <Notifications options={{ zIndex: 200, top: "20px" }} />

        {this.state.csvLoader === true ? (
          <div className="loading">Loading&#8230;</div>
        ) : (
          ""
        )}
        <div className="content-page template-tab" id="leadstable">
          <div className="content-header">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className="page-heading">
                  <h3>Global Agent Search </h3>
                </div>
              </div>
            </div>
            {Object.keys(this.state.errors).length > 0 && this.state.errors ? (
              <div className="alert alert-danger">{this.state.errors}</div>
            ) : (
              ""
            )}
            <div className="accordian-expand">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="listing-filter-search gobal-search gobal-search select">
                    <select
                      type="select"
                      className="form-control"
                      name="leadsType"
                      onChange={(e) => this.onchangelead(e)}
                      value={this.state.leadsType || ""}
                    >
                      {this.state.leadsTypeArray.map((val, key) => {
                        return (
                          <>
                            <option value={val.value}>{val.name}</option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12">
                  <div className="listing-filter-search gobal-search">
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="filter_fname"
                        value={this.state.filter_fname}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="filter_lname"
                        value={this.state.filter_lname}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ref Number"
                        name="filter_ref"
                        value={this.state.filter_ref}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="filter_email"
                        value={this.state.filter_email}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                        name="filter_phone"
                        value={this.state.filter_phone}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group text-filed">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip code"
                        name="zip"
                        value={this.state.zip}
                        onChange={(e) => this.onchange(e)}
                      />
                    </div>
                    <div className="form-group btn-filed">
                      <button
                        type="button"
                        className="btn-search btn-style-md mr-10"
                        onClick={(e) => this.filterData(e)}
                      >
                        Filter
                      </button>
                      <button
                        type="button"
                        className="btn-search btn-style-md"
                        onClick={(e) => this.reset(e)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  <div className="gobal-search "></div>
                </div>
              </div>
            </div>
            {this.state.leadsType != "pcp" &&
            this.state.leadsType != "pcp_finance" &&
            this.state.leadsType != "pcp_declined" ? (
              <div className="row">
                <div className="col-xs-4">
                  <div className="head-left-wrap mb-10">
                    <h5> Lead Sign Status :</h5>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="head-left-wrap mb-10">
                    {this.state.leadStatus == "false" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={cross}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {this.state.leadStatus == "process" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={loader}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {this.state.leadStatus == "true" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={tick}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.leadsType == "pcp_finance" ? (
              <div className="row">
                <div className="col-xs-4">
                  <div className="head-left-wrap mb-10">
                    <h5> Lead Finance Agreement:</h5>
                  </div>
                </div>
                <div className="col-xs-4">
                  <div className="head-left-wrap mb-10">
                    {this.state.long_form_Status == "false" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={cross}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {this.state.long_form_Status == "process" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={loader}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    {this.state.long_form_Status == "true" ? (
                      <img
                        style={{ width: "50px", height: "50px" }}
                        className="main-img"
                        src={orange}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="tab leads-tabs" role="tabpanel">
            <div className="head-right-wrap mb-10">
              <h5> Esign Form link</h5>
              <div className="filter-action-btn">
                <React.Fragment>
                  <a
                    style={{ "margin-left": "2px" }}
                    className="btn btn-search"
                    onClick={(e) => this.sendEmail(e, "esign")}
                  >
                    Send Email
                  </a>
                </React.Fragment>

                <React.Fragment>
                  <a
                    style={{ "margin-left": "2px" }}
                    className="btn btn-search"
                    onClick={(e) => this.sendSMS(e, "esign")}
                  >
                    Send SMS
                  </a>
                </React.Fragment>

                <React.Fragment>
                  <a
                    style={{ "margin-left": "2px" }}
                    className="btn btn-search"
                    onClick={(e) => this.SendSMSEmail(e, "esign")}
                  >
                    Send Both
                  </a>
                </React.Fragment>
              </div>
            </div>
            {this.state.long_form ? (
              <div className="head-right-wrap mb-10">
                <h5>Long Form Link</h5>
                <div className="filter-action-btn">
                  <React.Fragment>
                    <a
                      style={{ "margin-left": "2px" }}
                      className="btn btn-search"
                      onClick={(e) => this.sendEmail(e, "long")}
                    >
                      Send Email
                    </a>
                  </React.Fragment>

                  <React.Fragment>
                    <a
                      style={{ "margin-left": "2px" }}
                      className="btn btn-search"
                      onClick={(e) => this.sendSMS(e, "long")}
                    >
                      Send SMS
                    </a>
                  </React.Fragment>

                  <React.Fragment>
                    <a
                      style={{ "margin-left": "2px" }}
                      className="btn btn-search"
                      onClick={(e) => this.SendSMSEmail(e, "long")}
                    >
                      Send Both
                    </a>
                  </React.Fragment>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="tab-content tabs">
              <div
                role="tabpanel"
                className="tab-pane fade in active"
                id="tab-1"
              >
                <div className="table-wrap leads-table">
                  <div
                    className="table-responsive"
                    style={{ textAlign: "center" }}
                  >
                    <InfiniteScroll
                      dataLength={this.state.leadwithphone.length}
                      next={this.fetchMoreDataWithPhone}
                      hasMore={this.state.globalSearchCheck}
                      loader={
                        <ClipLoader
                          css={override}
                          sizeUnit={"px"}
                          size={50}
                          color={"#123abc"}
                          loading={this.state.globalSearchCheck}
                        />
                      }
                    >
                      <table
                        className="table table-hover table-striped table-bordered table-condensed"
                        style={{}}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th>
                              <div className="table-check">
                                <label className="custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="leadphoneall"
                                    onClick={(e) => this.selectAllwithPhone(e)}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </th>

                            <th>Lead ID</th>
                            <th>Supply Company Name</th>
                            <th>Lead Status</th>
                            <th>Email</th>
                            <th>PostUrl</th>
                            <th>Title</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone</th>
                            <th>Email Count</th>
                            <th>SMS Count</th>
                            <th>Ref</th>
                            <th>Esign form link</th>
                            {this.state.long_form ? (
                              <th>Long form link</th>
                            ) : (
                              ""
                            )}
                            <th>Reset</th>
                            <th>un-Subscriber</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.globalSearch.length > 0 ? (
                            this.state.globalSearch.map((val, key) => {
                              return (
                                <tr
                                  key={key}
                                  style={{
                                    background:
                                      val.sms_send_count > 0 ||
                                      val.email_send_count > 0
                                        ? "#c4d0c4"
                                        : "#ffffff",
                                  }}
                                >
                                  <td>
                                    <div className="table-check">
                                      <label className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="leadphone"
                                          value={val.leadid}
                                          onChange={(e) =>
                                            this.changePhoneCheckBox(e, val)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td title={val.leadid}>{val.leadid}</td>
                                  <td title={val.BankNames}>{val.BankNames}</td>
                                  <td title={val.BankNames}>
                                    {val.is_plevin == "8" ? (
                                      <img
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        className="main-img"
                                        src={check}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {val.is_plevin == "9" ? (
                                      <>
                                        {" "}
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="main-img"
                                          src={check}
                                        />{" "}
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="main-img"
                                          src={check}
                                        />{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {val.is_plevin == "10" ? (
                                      <>
                                        {" "}
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="main-img"
                                          src={check}
                                        />{" "}
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="main-img"
                                          src={check}
                                        />{" "}
                                        <img
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          className="main-img"
                                          src={check}
                                        />{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td title={val.email}>{val.email}</td>
                                  <td
                                    title={val.directLink}
                                    style={{ color: "red" }}
                                  >
                                    {val.directLink}
                                  </td>
                                  <td title={val.title}>{val.title}</td>
                                  <td title={val.firstname}>{val.firstname}</td>
                                  <td title={val.lastname}>{val.lastname} </td>
                                  <td title={val.phone1}>
                                    {val.phone1}{" "}
                                    <button
                                      type="button"
                                      onClick={(e) => this.editPhoneNumber(val)}
                                      className="btn-round btn-edit-icon"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                  </td>
                                  <td title={val.email_send_count}>
                                    {val.email_send_count}
                                  </td>
                                  <td title={val.sms_send_count}>
                                    {val.sms_send_count}
                                  </td>
                                  <td> {val.Ref}</td>
                                  <td title={val.directLink}>
                                    {val.directLink.indexOf(questionCheck) !==
                                    -1 ? (
                                      val.directLink != "" &&
                                      val.directLink != null &&
                                      val.directLink != undefined ? (
                                        <a
                                          onClick={(e) =>
                                            this.copyData(
                                              `${val.directLink}${val.directLink.includes("?") ? "&" : "?"}csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`
                                            )
                                          }
                                          // href={val.directLink + `?csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`}
                                          style={{ marginLeft: "5px" }}
                                          target="_blank"
                                          className="btn-round btn-edit-icon"
                                        >
                                          <i className="fa fa-link"></i>
                                        </a>
                                      ) : (
                                        ""
                                      )
                                    ) : val.directLink != "" &&
                                      val.directLink != null &&
                                      val.directLink != undefined ? (
                                      <a
                                        onClick={(e) =>
                                          this.copyData(
                                            `${val.directLink}${val.directLink.includes("?") ? "&" : "?"}csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`
                                          )
                                        }
                                        // href={val.directLink + `?csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`}
                                        style={{ marginLeft: "5px" }}
                                        target="_blank"
                                        className="btn-round btn-edit-icon"
                                      >
                                        <i className="fa fa-link"></i>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  {this.state.long_form ? (
                                    <td title={val.pbaDirectLink}>
                                      {val.pbaDirectLink != "" &&
                                      val.pbaDirectLink != null &&
                                      val.pbaDirectLink != undefined ? (
                                        <a
                                          onClick={(e) =>
                                            this.copyData(
                                              `${val.pbaDirectLink}${val.pbaDirectLink.includes("?") ? "&" : "?"}csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`
                                            )
                                          }
                                          style={{ marginLeft: "5px" }}
                                          target="_blank"
                                          className="btn-round btn-edit-icon"
                                        >
                                          <i className="fa fa-link"></i>
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  ) : (
                                    ""
                                  )}


                                  <td>
                                    <button
                                      type="button"
                                      onClick={(e) => this.resetlink(val)}
                                      className="btn-round btn-edit-icon"
                                    >
                                      <i className="fa fa-edit"></i>
                                    </button>
                                  </td>                                  

                                  <td title={val.CBN}>
                                    <a
                                      onClick={(e) => this.unSubscribe(e, val)}
                                      style={{
                                        marginLeft: "5px",
                                        width: "80px",
                                      }}
                                      target="_blank"
                                      className="btn-round btn-edit-icon"
                                    >
                                      Cancel
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="16" style={{ textAlign: "center" }}>
                                {this.state.globalSearchCheck
                                  ? ""
                                  : this.state.globalSearch.length === 0
                                  ? "No Record Found"
                                  : "No Record Found"}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                  {this.state.globalSearch.length > 0 &&
                  this.state.leadsType != "pcp" &&
                  this.state.leadsType != "pcp_finance" &&
                  this.state.leadsType != "pcp_declined"
                    ? this.state.globalSearch.map((val, key) => {
                        return (
                          <div
                            className="form-group btn-filed lender_btn_wrap"
                            style={{ "margin-top": "25px" }}
                          >
                            {val.directLink.indexOf(questionCheck) !== -1 ? (
                              <a
                                type="button"
                                target="_blank"
                                className="btn-search btn-style-md mr-10"
                                href={`${val.directLink}&csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`}
                              >
                                Edit Lead
                              </a>
                            ) : (
                              <a
                                type="button"
                                target="_blank"
                                className="btn-search btn-style-md mr-10"
                                href={`${val.directLink}?csvId=${val.csvId}&leadId=${val.leadid}&agentId=${this.state.agentId}&leadSendBy=sms&sms=21`}
                              >
                                Edit Lead
                              </a>
                            )}

                            {this.state.leadsType == "Plevin" &&
                            this.state.ppiq == true ? (
                              <a
                                type="button"
                                target="_blank"
                                className="btn-search btn-style-md mr-10"
                                href={this.ppiqURL(
                                  base64.encode(val.primary_leads_row_id),
                                  base64.encode(val.leadid),
                                  val.directLink
                                )}
                              >
                                PPIQ
                              </a>
                            ) : (
                              ""
                            )}

                            {this.state.agentPaperPackCheck ? (
                              <a
                                type="button"
                                target="_blank"
                                className="btn-search btn-style-md mr-10"
                                onClick={(e) => this.agentPaperPack(e, val)}
                              >
                                Request Paper Pack
                              </a>
                            ) : (
                              ""
                            )}

                            {this.state.webLinkCheck ? (
                              <a
                                type="button"
                                href={`${this.state.webLink}?agentId=${this.state.agentId}`}
                                className="btn-search btn-style-md"
                                target="_blank"
                              >
                                NewClaim
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })
                    : ""}

                  {this.state.globalSearch.length == 0 ? (
                    <div
                      className="form-group btn-filed lender_btn_wrap"
                      style={{ "margin-top": "25px" }}
                    >
                      {this.state.webLinkCheck ? (
                        <a
                          type="button"
                          href={`${this.state.webLink}?agentId=${this.state.agentId}`}
                          className="btn-search btn-style-md"
                          target="_blank"
                        >
                          NewClaim
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {
                    this?.state?.signature &&
                        <div className="form-group row">
                          <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            signature:
                          </label>
                          <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                            <img width="200" src={this?.state?.signature}/>
                          </div>
                        </div>
                  }
                  {
                    this?.state?.id_upload &&
                        <div className="form-group row">
                          <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            Id Upload:
                          </label>
                          <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                            <img width="200" src={this?.state?.id_upload}/>
                          </div>
                        </div>
                  }
                  {this.state.globalSearch.length > 0 &&
                  this.state.agentNotesCheck &&
                  this.state.leadsType != "pcp" &&
                  this.state.leadsType != "pcp_finance" &&
                  this.state.leadsType != "pcp_declined"
                    ? this.state.globalSearch.map((val, key) => {
                        return (
                          <>
                            <div className="form-group row">
                              <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                                Lead Notes:
                              </label>
                              <div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
                                <textarea
                                  className="form-control"
                                  value={val.notes ? val.notes : ""}
                                  onChange={(e) => this.onchangeNote(e, val)}
                                  rows="6"
                                ></textarea>
                              </div>
                            </div>

                            <div className="content-header">
                              <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <div className="page-heading"></div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                  <div className="add-btn text-right">
                                    <button
                                      type="button"
                                      className="btn-style-md btn-purple btn-round mr-5"
                                      onClick={(e) => this.saveNotes(e, val)}
                                    >
                                      Save Note
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}

                    
                    
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="modal modal-style fade"
          open={this.state.editPhoneModel}
          onClose={this.closeSMSModal}
        >
          <div className="modal-header">
            <h4 className="modal-title">Edit Info</h4>
          </div>
          <div className="modal-body">
          <div className="form-group row">
              <label className="col-md-4">Title :</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={this.state.title || ""}
                  onChange={(e) => this.onchange(e)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4">Phone Number :</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="editPhoneNumber"
                  value={this.state.editPhoneNumber || ""}
                  onChange={(e) => this.onchange(e)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4">Email :</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.email || ""}
                  onChange={(e) => this.onchange(e)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4">First Name :</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="firstname"
                  value={this.state.firstname || ""}
                  onChange={(e) => this.onchange(e)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4">Last Name :</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  name="lastname"
                  value={this.state.lastname || ""}
                  onChange={(e) => this.onchange(e)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-purple btn-style-md btn-round"
              onClick={(e) => this.UpdatePhone(e)}
            >
              Update{" "}
            </button>
            <button
              className="btn btn-purple btn-style-md btn-round"
              onClick={(e) => this.closeSMSModal(e)}
            >
              Close
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default LeadsListing;
