import React, { Component } from 'react';
import {ENV} from './../env';

// let token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class SmSTemplateService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
        
    }

    async addSmSTemplate(body) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
        const data_1 = await fetch(url + 'smsTemplate/create', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async allSmSTemplate() {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'smsTemplate/templates', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }


    async getOneSmsTemplate(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'smsTemplate/templates/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async updateSMS(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'smsTemplate/templates/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),

            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }



    async delSMS(id) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'smsTemplate/templates/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    async getSingleUserTemplate(id) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'userSmsTemplate/templates/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }



    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default SmSTemplateService;