import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import CSVService from '../../services/cvs.service';
import LeadsListingService from '../../services/leadslisting.service';
import BitlayService from './../../services/bitlay.service';
import AllUserService from './../../services/alluser.service';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './editCSVsite.css'
import moment from 'moment';

import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';

var ppi_CheckerJson = require('../../services/data/ppi_checker.json');
var refund_checker = require('../../services/data/refund_checker.json');


class EditCSVsite extends Component {
    constructor(props) {

        super(props);
        this.state = {
            previousData: [],
            previousAddresss: false,
            originalAddress: false,
            zipPerivous: '',
            zipPerivousAddress: '',
            zipPerviousCode: '',
            FindAddressArray: [],
            newData: [],
            dataAddress: null,
            resultsHTML: '',
            gl: [],
            elementID: null,
            title: '',
            firstname: '',
            lastname: '',
            DateOfBirth: new Date(),
            previous_postcode1: '',
            address1: '',
            phone1: '',
            email: '',
            zip: '',
            smstemplate: [],
            emailtemplate: [],
            selectedEmailID: '',
            selectedSMSID: '',
            link: [],

            leads: '',
            LeadsId: [],
            domainname: '',
            host: '',
            username: '',
            password: '',
            dbname: '',
            tablename: '',
            addressnew: [],
            errors: '',
            id: '',


            smstype: "",
            smstypeList: [],
            emailtype: "",
            emailtypeList: [],

            leadModelOpen: false,
            userModelOpen: false,
            postalCodeAddress: false,
            sendSMSModal: false,


            // //////////////////////    CSV       //////////////////////////////////
            csvid: '',
            siteid: '',
            leadid: "",
            previousName: "",
            leadip: "",
            xAuthentication: "",
            title: "",
            firstname: "",
            lastname: "",
            BankName: "",
            LenderIDs: "",
            address: "",
            address1: "",
            address2: "",
            address3: "",
            previous_name: "",
            previous_address1: "",
            previous_address2: "",
            previous_address3: "",
            previous_address4: "",
            previous_address5: "",
            previous_postcode1: "",
            previous_postcode2: "",
            previous_postcode3: "",
            previous_postcode4: "",
            previous_postcode5: "",
            previous_address1_line1: "",
            previous_address2_line1: "",
            previous_address3_line1: "",
            previous_address4_line1: "",
            previous_address5_line1: "",
            previous_address1_line2: "",
            previous_address2_line2document: "",
            previous_address3_line2: "",
            previous_address4_line2: "",
            previous_address5_line2: "",
            previous_address1_line3: "",
            previous_address2_line3: "",
            previous_address3_line3: "",
            previous_address4_line3: "",
            previous_address5_line3: "",
            previous_address1_city: "",
            previous_address2_city: "",
            previous_address3_city: "",
            previous_address4_city: "",
            previous_address5_city: "",
            previous_address1_province: "",
            previous_address2_province: "",
            previous_address3_province: "",
            previous_address4_province: "",
            previous_address5_province: "",
            previous_address1_company: "",
            previous_address2_company: "",
            previous_address3_company: "",
            previous_address4_company: "",
            previous_address5_company: "",
            city: "",
            province: "",
            country: "",
            company: "",
            phone1: "",
            email: "",
            zip: "",
            dob_day: "",
            dob_month: "",
            dob_year: "",
            dob: "",
            document_id: "",
            pdf_url: "",
            lender_id_doc_order: "",
            signature_src: "",
            PostURL: "",
            is_waypoint: "",
            is_submit: "",
            is_signed: "",
            other: [],
            userStatus: '',
        }
        this.csvService = new CSVService();
        this.leadlistingService = new LeadsListingService();
        this.bitlayservice = new BitlayService();
        this.alluserService = new AllUserService();


    }



    componentDidMount() {
		window.scrollTo(0, 0);

        let role = JSON.parse(localStorage.getItem('user'));
        if (role) {
            let roleStatus = role.id;
            this.alluserService.editUser(roleStatus).then(data => {
                if (data.code === 200) {
                    let result = data.data;
                    this.setState({
                        userStatus: result,
                    })
                    if (this.state.userStatus.status === 'inactive') {
                        localStorage.clear();
                        this.props.history.push('/');
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        }
        // var scripts = '<link href="https://controlfunnel.co.uk/static/css/style.css" rel="stylesheet" type="text/css"><script type="application/javascript" src="https://controlfunnel.co.uk/static/js/script.js">';  
        // $("body").append(scripts);  
        this.smsTemplate();
        this.emailTemplate();
        this.getSMStype();
        this.getEmailtype();

        var siteid = this.props.match.params.siteid;
        var leadId = this.props.match.params.leadid;
        this.setState({
            leadid: leadId,
            siteid: siteid
        })
        this.csvService.getCsvSiteData(siteid, leadId).then(data => {
            console.log(data, "--------------------------------------------")
            if (data.code === 200) {
                debugger
                var payload = data.data[0];
                // payload.DateOfBirth = new Date(payload.DateOfBirth);
                if (moment(new Date(payload.DateOfBirth)).isValid() == false) {
                    payload.DateOfBirth = "";
                } else {
                    payload.DateOfBirth = new Date(payload.DateOfBirth);
                }
                this.setState({
                    isInputDisabled: true,
                    title: payload.title,
                    firstname: payload.firstname,
                    lastname: payload.lastname,
                    DateOfBirth: payload.DateOfBirth != undefined || payload.DateOfBirth != null ? payload.DateOfBirth : '',
                    previous_postcode1: payload.previous_postcode1,
                    address1: payload.address,
                    phone1: payload.phone1 === null ? "+92" : payload.phone1,
                    email: payload.email,
                    LenderIDs: payload.LenderIDs,
                    siteArray: payload.data,
                    zip: payload.zip,
                    leads: payload.BankName,

                    previous_name: payload.previous_name,
                    leadid: payload.leadid,
                    leadip: payload.leadip,
                    xAuthentication: payload.xAuthentication,
                    BankName: payload.BankName,
                    address: payload.address,
                    address2: payload.address2,
                    address3: payload.address3,
                    previous_address1: payload.previous_address1,
                    previous_address2: payload.previous_address2,
                    previous_address3: payload.previous_address3,
                    previous_address4: payload.previous_address4,
                    previous_address5: payload.previous_address5,
                    previous_postcode2: payload.previous_postcode2,
                    previous_postcode3: payload.previous_postcode3,
                    previous_postcode4: payload.previous_postcode4,
                    previous_postcode5: payload.previous_postcode5,
                    previous_address1_line1: payload.previous_address1_line1,
                    previous_address2_line1: payload.previous_address2_line1,
                    previous_address3_line1: payload.previous_address3_line1,
                    previous_address4_line1: payload.previous_address4_line1,
                    previous_address5_line1: payload.previous_address5_line1,
                    previous_address1_line2: payload.previous_address1_line2,
                    previous_address2_line2: payload.previous_address2_line2,
                    previous_address3_line2: payload.previous_address3_line2,
                    previous_address4_line2: payload.previous_address4_line2,
                    previous_address5_line2: payload.previous_address5_line2,
                    previous_address1_line3: payload.previous_address1_line3,
                    previous_address2_line3: payload.previous_address2_line3,
                    previous_address3_line3: payload.previous_address3_line3,
                    previous_address4_line3: payload.previous_address4_line3,
                    previous_address5_line3: payload.previous_address5_line3,
                    previous_address1_city: payload.previous_address1_city,
                    previous_address2_city: payload.previous_address2_city,
                    previous_address3_city: payload.previous_address3_city,
                    previous_address4_city: payload.previous_address4_city,
                    previous_address5_city: payload.previous_address5_city,
                    previous_address1_province: payload.previous_address1_province,
                    previous_address2_province: payload.previous_address2_province,
                    previous_address3_province: payload.previous_address3_province,
                    previous_address4_province: payload.previous_address4_province,
                    previous_address5_province: payload.previous_address5_province,
                    previous_address1_company: payload.previous_address1_company,
                    previous_address2_company: payload.previous_address2_company,
                    previous_address3_company: payload.previous_address3_company,
                    previous_address4_company: payload.previous_address4_company,
                    previous_address5_company: payload.previous_address5_company,
                    city: payload.city,
                    province: payload.province,
                    country: payload.country,
                    company: payload.country,
                    dob_day: payload.dob_day,
                    dob_month: payload.dob_month,
                    dob_year: payload.dob_year,
                    dob: payload.dob,
                    document_id: payload.document_id,
                    pdf_url: payload.pdf_url,
                    lender_id_doc_order: payload.lender_id_doc_order,
                    signature_src: payload.signature_src,
                    PostURL: payload.PostURL,
                    is_waypoint: payload.is_waypoint,
                    is_submit: payload.is_submit,
                    is_signed: payload.is_signed,
                    other: [],
                })

                var tempArray = [];
                var temLeadsArray = [];
                var leads = payload.BankName;
                this.setState({
                    leads: leads
                })
                tempArray = leads.split('|');
                var trimArray = []
                for (var i = 0; tempArray.length > i; i++) {
                    var trimData = tempArray[i].trim();
                    if (trimData != '') {
                        trimArray.push(trimData.toLowerCase());
                    }
                }
                debugger
                console.log("leads", trimArray)
                this.setState({
                    LeadsId: trimArray
                })

                var previousAddressTemp = [];
                if (payload.previous_address1 != '' && payload.previous_postcode1 != '' && payload.previous_address1 != null && payload.previous_postcode1 != null) {
                    var data = {
                        zip: payload.previous_postcode1,
                        address: payload.previous_address1
                    }
                    previousAddressTemp.push(data);
                }
                if (payload.previous_address2 != '' && payload.previous_postcode2 != '' && payload.previous_address2 != null && payload.previous_postcode2 != null) {
                    var data = {
                        zip: payload.previous_postcode2,
                        address: payload.previous_address2
                    }
                    previousAddressTemp.push(data);
                }
                if (payload.previous_address3 != '' && payload.previous_postcode3 != '' && payload.previous_address3 != null && payload.previous_postcode3 != null) {
                    var data = {
                        zip: payload.previous_postcode3,
                        address: payload.previous_address3
                    }
                    previousAddressTemp.push(data);
                }
                if (payload.previous_address4 != '' && payload.previous_postcode4 != '' && payload.previous_address4 != null && payload.previous_postcode4 != null) {
                    var data = {
                        zip: payload.previous_postcode4,
                        address: payload.previous_address4
                    }
                    previousAddressTemp.push(data);
                }
                if (payload.previous_address5 != '' && payload.previous_postcode5 != '' && payload.previous_address5 != null && payload.previous_postcode5 != null) {
                    var data = {
                        zip: payload.previous_postcode5,
                        address: payload.previous_address5
                    }
                    previousAddressTemp.push(data);
                }

                // }else if(){

                // }
                this.setState({
                    FindAddressArray: previousAddressTemp
                })

            }
        }).catch(err => {
            console.log(err)
        })

        // this.getLeadsData();
        // this.setState({
        // domainname: this.props.location.state.edit.domainName,
        // host: this.props.location.state.edit.hostIp,
        // username: this.props.location.state.edit.username,
        // password: this.props.location.state.edit.password,
        // dbname: this.props.location.state.edit.dbName,
        // tablename: this.props.location.state.edit.tableName,
        // link: this.props.location.state.edit.link,
        // id: this.props.location.state.edit._id
        // })
    }

    getEmailtype() {
        this.leadlistingService.getEmailtype().then(data => {
            if (data.code === 200) {
                this.setState({
                    emailtypeList: data.data
                })
            }
        })
    }

    getSMStype() {
        this.leadlistingService.getSMStype().then(data => {
            if (data.code === 200) {
                this.setState({
                    smstypeList: data.data
                })
            }
        })
    }

    openSMSModal = (e) => {
        this.setState({ sendSMSModal: true });
    };

    closeSMSModal = () => {
        this.setState({ sendSMSModal: false });
    };

    // getLeadsData(){
    //     console.log(this.state.LenderIDs);
    //     var Leads = this.state.LeaderIDs;
    //     console.log(Leads);
    //     var tempArray = Leads.split('|');
    //     console.log(tempArray);
    // }

    getAddress(data) {
        if (data != '') {
            this.setState({ originalAddress: true });
            var x = data;
            fetch(`https://controlfunnel.co.uk/address/${x}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => res.json()).then(
                (result) => {
                    debugger
                    var tempArray = [];
                    tempArray = result.data

                    this.setState({
                        gl: tempArray
                    })
                    // if(result.status === "success"){  
                    // }else{ 
                    // }
                },
                (error) => {
                    console.log(error);
                });
        } else {

        }

    }
    smsTemplate() {
        this.leadlistingService.allSmSTemplate().then(data => {
            console.log(data, "sms");
            if (data.code === 200) {
                this.setState({
                    smstemplate: data.data
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }
    emailTemplate() {
        this.leadlistingService.allEmailTemplate().then(data => {
            console.log(data, "email");
            if (data.code === 200) {
                this.setState({
                    emailtemplate: data.data
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }
    onchange(e) {
        debugger
        this.setState({
            [e.target.name]: e.target.value
        })

    }

    onzipChange(e) {
        debugger
        console.log(e.target.value);
        this.setState({
            zipPerivous: e.target.value
        })

    }
    onzipPreviousChange(e) {
        debugger
        console.log(e.target.value);
        this.setState({
            zipPerviousCode: e.target.value,
            zip: e.target.value
        })
    }
    addTextfield(e) {
        debugger
        var zipCode = this.state.zipPerivous;
        var addressCode = this.state.zipPerivousAddress;
        var checkLength = this.state.FindAddressArray.length;
        if (checkLength < 5) {
            if (zipCode != '', addressCode != '') {
                e.preventDefault();
                // var inputs = this.state.addressnew;
                var temp = [];
                temp = this.state.FindAddressArray;
                var data = {
                    zip: zipCode,
                    address: addressCode
                }
                temp.push(data);
                var splitAddress = [];
                splitAddress = addressCode.split(',');
                var length = temp.length;
                this.setState({
                    // address: address,
                    // address1: AddressArray[1],
                    // address2: AddressArray[2],
                    // city: AddressArray[3],
                    // country: AddressArray[4],
                    [`previous_address` + length]: addressCode,
                    [`previous_postcode` + length]: splitAddress[1],
                    [`previous_address1_line` + length]: splitAddress[2],
                    [`previous_address` + length + `_city`]: splitAddress[3],
                })
                this.setState({
                    FindAddressArray: temp,
                    zipPerivousAddress: ''
                })
            } else {
                notify.show("Zip code and Address Must be Selected!", "success", 3000);
            }
        } else {
            notify.show("Only 5 Previous Code allow to add!", "success", 3000);
        }
    }
    getPreivous(e, i) {
        debugger
        this.setState({
            previousAddresss: true
        })
        var x = this.state.zipPerivous;
        fetch(`https://controlfunnel.co.uk/address/${x}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => res.json()).then(
            (result) => {
                debugger
                var tempArray = [];
                tempArray = result.data

                this.setState({
                    previousData: tempArray
                })
                // if(result.status === "success"){  
                // }else{ 
                // }
            },
            (error) => {
                console.log(error);
            });
    }
    onCheckBox(e) {
        debugger
        if (e.target.checked == true) {
            var name = e.target.value;
            var leads = this.state.leads === "" || this.state.leads == undefined ? name : this.state.leads + ' | ' + name;
            this.setState({
                leads: leads
            })
            var tempArray = [];
            tempArray = leads.split('|');
            var trimArray = []
            for (var i = 0; tempArray.length > i; i++) {
                var trimData = tempArray[i].trim();
                if (trimData != '') {
                    trimArray.push(trimData.toLowerCase());
                }
            }
            console.log("leads", trimArray)
            this.setState({
                LeadsId: trimArray
            })
        } else {
            var name = e.target.value;
            var tempArray = [];
            var checkBox = [];
            tempArray = this.state.LeadsId;
            // splice("Kiwi", 1);

            for (var y = 0; tempArray.length > y; y++) {
                if (name != tempArray[y] && name != '') {
                    checkBox.push(tempArray[y]);
                }
            }
            console.log(checkBox);
            this.setState({
                LeadsId: checkBox
            })
            var dataString = '';
            for (var i = 0; checkBox.length > i; i++) {
                debugger
                dataString = dataString + checkBox[i] + "|";
            }
            console.log(dataString);
            this.setState({
                leads: dataString
            })
        }
    }
    changeSchema(e, index) {
        var arrayOfObjs = this.state.addressnew;
        arrayOfObjs[index] = e.currentTarget.value;
        this.setState({ addressnew: arrayOfObjs });
    }
    removeTextField(indexValue) {
        debugger
        var temp = [];
        temp = this.state.FindAddressArray;
        var length = temp.length;
        var newArray = [];
        for (var i = 0; temp.length > i; i++) {
            if (i != indexValue) {
                newArray.push(temp[i]);
            }
        }

        for (var z = 0; 6 > z; z++) {
            this.setState({
                [`previous_address` + z]: '',
                [`previous_postcode` + z]: '',
                [`previous_address1_line` + z]: '',
                [`previous_address` + z + `_city`]: '',
            })
        }


        for (var y = 0; newArray.length > y; y++) {
            debugger
            var splitAddress = [];
            splitAddress = newArray[y].address.split(',');
            var length = temp.length;
            this.setState({
                [`previous_address` + (parseInt(y) + 1)]: newArray[y].address,
                [`previous_postcode` + (parseInt(y) + 1)]: splitAddress[1],
                [`previous_address1_line` + (parseInt(y) + 1)]: splitAddress[2],
                [`previous_address` + (parseInt(y) + 1) + `_city`]: splitAddress[3],
            })
        }
        this.setState({
            FindAddressArray: newArray.slice()
            // address: array123.slice()
        });
    }

    onEditLeadsModel = (e) => {

        if (this.state.leadModelOpen === false) {
            this.setState({ leadModelOpen: true });
        } else {
            this.setState({ leadModelOpen: false });
        }
    };

    onEdituserModelOpen = (e) => {
        if (this.state.userModelOpen === false) {
            this.setState({ userModelOpen: true });
        } else {
            this.setState({ userModelOpen: false });
        }
    }

    postalCodeAddressOpen = (e) => {

        if (this.state.postalCodeAddressOpen === false) {
            this.setState({ postalCodeAddressOpen: true });
        } else {
            this.setState({ postalCodeAddressOpen: false });
        }
    }

    findAddress(e) {
        e.preventDefault();
        this.csvService.findAddress().then(data => {
            console.log(data)
        }).catch(err => {
            console.log(err)
        })
    }

    onChangedate = date => this.setState({ DateOfBirth: date })

    selectEmailSMS(e) {

        this.setState({
            [e.target.name]: e.target.value
        });

    }

    confirmSendSMS() {
        this.bitlayservice.getBitlay().then(data => {
            console.log(data)
            if (data.code === 200) {
                if (data.data.length > 0) {
                    this.sendSMS();

                } else if (data.data) {
                    this.sendSMS();

                }
                else {

                    if (this.state.selectedSMSID == "") {
                        this.setState({
                            errors: "Select SMS template"
                        });
                        return;
                    } else if (this.state.smstype === "") {
                        this.setState({
                            errors: "Select SMS type"
                        });
                        return;
                    }

                    this.setState({
                        errors: ""
                    })
                    this.openSMSModal();
                }

            }
            else if (data.code === 404) {
                if (this.state.selectedSMSID == "") {
                    this.setState({
                        errors: "Select SMS template"
                    });
                    return;
                } else if (this.state.smstype === "") {
                    this.setState({
                        errors: "Select SMS type"
                    });
                    return;
                }

                this.setState({
                    errors: ""
                })
                this.openSMSModal();
            }
        }).catch(err => {
            console.log(err);
            this.setState({
            })

        })

    }


    sendSMS() {
        this.closeSMSModal();
        if (this.state.selectedSMSID === "") {
            this.setState({
                errors: "Select SMS template"
            })
        }
        else if (this.state.smstype === "") {
            this.setState({
                errors: "Select SMS type"
            })
        }
        else if (this.state.selectedSMSID) {
            var leads = [];
            leads.push(this.state.leadid);
            let body = {
                siteId: this.state.siteid,
                leads: leads,
                templateId: this.state.selectedSMSID,
                smsType: this.state.smstype
            }
            this.csvService.csvSiteSMSsend(body).then(data => {
                console.log("------------------------data-------------------------", data);
                if (data.code == 404) {
                    notify.show(data.message, "error", 2000);
                }
                else if (data.code === 200) {
                    this.setState({
                        selectedSMSID: "",
                        errors: "",

                    });
                    notify.show(data.message, "success", 3000)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    sendEmail() {
        
        if (this.state.selectedEmailID) {
            if (this.state.emailtype !== "") {
                let body = {
                    siteId: this.state.siteid,
                    leadId: this.state.leadid,
                    templateId: this.state.selectedEmailID,
                    type: this.state.emailtype

                }
                this.csvService.csvSiteEmailsend(body).then(data => {
                    console.log("------------------------data-------------------------", data);
                    if (data.code == 404) {
                        notify.show(data.message, "error", 2000);
                    }
                    else if (data.code === 200) {
                        this.setState({
                            selectedEmailID: "",
                            emailtype: "",
                            errors: ""
                        });
                        $("#emailtype").val($("#target option:first").val());
                        $("#selectedEmailID").val($("#target option:first").val());
                        notify.show(data.message, "success", 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                this.setState({
                    errors: "Please select Email type."
                })
            }


        } else {
            this.setState({
                errors: "Select Email Template"
            })
        }
    }
    //script added
    addressSelection(data) {
        this.setState({ originalAddress: false })
        var address = data;
        var temp = [];
        this.setState({
            gl: temp
        })
        debugger
        var AddressArray = address.split(',');
        console.log(AddressArray);
        this.setState({
            address: address,
            address1: AddressArray[1],
            address2: AddressArray[2],
            city: AddressArray[3],
            country: AddressArray[4],
        })
    }
    addressSelectionPrevious(data) {
        debugger
        this.setState({
            previousAddresss: false
        })

        this.setState({
            zipPerivousAddress: data
        })

        var zipCode = this.state.zipPerivous;
        var addressCode = data;
        var checkLength = this.state.FindAddressArray.length;
        if (checkLength < 5) {
            if (zipCode != '', addressCode != '') {
                // e.preventDefault();
                // var inputs = this.state.addressnew;
                var temp = [];
                temp = this.state.FindAddressArray;
                var data = {
                    zip: zipCode,
                    address: addressCode
                }
                temp.push(data);
                var splitAddress = [];
                splitAddress = addressCode.split(',');
                var length = temp.length;
                this.setState({
                    // address: address,
                    // address1: AddressArray[1],
                    // address2: AddressArray[2],
                    // city: AddressArray[3],
                    // country: AddressArray[4],
                    [`previous_address` + length]: addressCode,
                    [`previous_postcode` + length]: splitAddress[1],
                    [`previous_address1_line` + length]: splitAddress[2],
                    [`previous_address` + length + `_city`]: splitAddress[3],
                })
                this.setState({
                    FindAddressArray: temp,
                    zipPerivousAddress: ''
                })
            } else {
                notify.show("Zip code and Address Must be Selected!", "success", 3000);
            }
        } else {
            notify.show("Only 5 Previous Code allow to add!", "success", 3000);
        }
        //   var lengthArray = [];
        //   lengthArray =  this.state.FindAddressArray;
        //   var arrayLength  = lengthArray.length;

    }

    submit() {

        if (isValidPhoneNumber(this.state.phone1) === false) {
            this.setState({
                errors: "Invalid Phone No"
            })
            return
        }
        let body = {
            leadip: this.state.leadip,
            previousName: this.state.previousName,
            xAuthentication: this.state.xAuthentication,
            title: this.state.title,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            BankName: this.state.leads,
            previous_name: this.state.previous_name,
            LenderIDs: this.state.LenderIDs,
            address: this.state.address,
            address1: this.state.address1,
            address2: this.state.address2,
            address3: this.state.address3,
            previous_address1: this.state.previous_address1,
            previous_address2: this.state.previous_address2,
            previous_address3: this.state.previous_address3,
            previous_address4: this.state.previous_address4,
            previous_address5: this.state.previous_address5,
            previous_postcode1: this.state.previous_postcode1,
            previous_postcode2: this.state.previous_postcode2,
            previous_postcode3: this.state.previous_postcode3,
            previous_postcode4: this.state.previous_postcode4,
            previous_postcode5: this.state.previous_postcode5,
            previous_address1_line1: this.state.previous_address1_line1,
            previous_address2_line1: this.state.previous_address2_line1,
            previous_address3_line1: this.state.previous_address3_line1,
            previous_address4_line1: this.state.previous_address4_line1,
            previous_address5_line1: this.state.previous_address5_line1,
            previous_address1_line2: this.state.previous_address1_line2,
            previous_address2_line2: this.state.previous_address2_line2,
            previous_address3_line2: this.state.previous_address3_line2,
            previous_address4_line2: this.state.previous_address4_line2,
            previous_address5_line2: this.state.previous_address5_line2,
            previous_address1_line3: this.state.previous_address1_line3,
            previous_address2_line3: this.state.previous_address2_line3,
            previous_address3_line3: this.state.previous_address3_line3,
            previous_address4_line3: this.state.previous_address4_line3,
            previous_address5_line3: this.state.previous_address5_line3,
            previous_address1_city: this.state.previous_address1_city,
            previous_address2_city: this.state.previous_address2_city,
            previous_address3_city: this.state.previous_address3_city,
            previous_address4_city: this.state.previous_address4_city,
            previous_address5_city: this.state.previous_address5_city,
            previous_address1_province: this.state.previous_address1_province,
            previous_address2_province: this.state.previous_address1_province,
            previous_address3_province: this.state.previous_address1_province,
            previous_address4_province: this.state.previous_address1_province,
            previous_address5_province: this.state.previous_address1_province,
            previous_address1_company: this.state.previous_address1_company,
            previous_address2_company: this.state.previous_address2_company,
            previous_address3_company: this.state.previous_address3_company,
            previous_address4_company: this.state.previous_address4_company,
            previous_address5_company: this.state.previous_address5_company,
            city: this.state.city,
            province: this.state.province,
            country: this.state.country,
            company: this.state.company,
            phone1: this.state.phone1,
            email: this.state.email,
            zip: this.state.zip,
            dob_day: this.state.dob_day,
            dob_month: this.state.dob_month,
            dob_year: this.state.dob_year,
            dob: this.state.dob,
            DateOfBirth: moment(this.state.DateOfBirth).format('YYYY/MM/DD'),
            document_id: this.state.document_id,
            pdf_url: this.state.pdf_url,
            lender_id_doc_order: this.state.lender_id_doc_order,
            signature_src: this.state.signature_src,
            PostURL: this.state.PostURL,
            is_waypoint: this.state.is_waypoint,
            is_submit: this.state.is_submit,
            is_signed: this.state.is_signed,
            other: [],
        }

        this.csvService.updateCsvSiteData(this.state.siteid, this.state.leadid, body).then(data => {
            console.log("----------------------data csv-----------------", data)
            if (data.code === 200) {
                var current = this;
                notify.show(data.message, "success", 3000);
                setTimeout(() => {
                    current.props.history.push('/leadslisting')

                }, 2000);

            }
        }).catch(err => {
            console.log(err)
        })
    }
    render() {
        var that = this;
        if (this.state.gl) {
            var data = this.state.gl.map(function (dat, key) {
                return (
                    <div>
                        <p onClick={() => that.addressSelection(dat.address)} > {dat.address} </p>
                    </div>
                )
            });

        }
        if (this.state.previousData) {
            var dataprevious = this.state.previousData.map(function (dat, key) {
                return (
                    <div>
                        <p onClick={() => that.addressSelectionPrevious(dat.address)} > {dat.address} </p>
                    </div>
                )
            });

        }


        console.log(data);
        var currentThis = this;
        console.log(this.state);
        console.log(this.state.FindAddressArray);
        return (
            <React.Fragment>
                <div>
                    {/* {data} */}
                    <Notifications options={{ zIndex: 200, top: '20px' }} />

                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                <div className="page-heading">
                                    <h3>Site Lead Edit</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
                                {/* <div className="add-btn text-right list-item">
                                    <Link to="/leadslisting" type="button" className="btn-purple btn-style-md btn-round mr-5">Cancel</Link>
                                    <button type="button" className="btn-purple btn-style-md btn-round" onClick={(e) => this.submit(e)}>Save</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {Object.keys(this.state.errors).length > 0 && this.state.errors ?
                            <div className="alert alert-danger">{this.state.errors}</div> : ''}
                        <div className="col-md-12 col-xs-12">
                            <div className="row" style={{ marginBottom: "20px" }}>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="add-btn sent-btns display-flex">
                                        <div className="form-group mr-20">
                                            <div className="dropdown lead-sentdrop custom-select mr-10">
                                                <label>Select Email Template</label>
                                                <select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedEmailID" value={this.state.selectedEmailID} onChange={(e) => this.selectEmailSMS(e)} data-toggle="dropdown">
                                                    <option value="">Email Template</option>
                                                    {
                                                        this.state.emailtemplate.length > 0 ?
                                                            this.state.emailtemplate.map((val, key) => {
                                                                return (
                                                                    <option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>

                                                                )
                                                            }) : <option value="">No Record Found</option>
                                                    }
                                                </select>
                                            </div>
                                            <div className="dropdown lead-sentdrop custom-select mr-10">
                                                <label>Select Email Type</label>
                                                <select className="btn-dropdown dropdown-toggle btn-dropsent" name="emailtype" id="emailtype" value={this.state.emailtype} onChange={(e) => this.selectEmailSMS(e)} data-toggle="dropdown">
                                                    <option value="">Select Email Type</option>
                                                    {
                                                        this.state.emailtypeList.length > 0 ?
                                                            this.state.emailtypeList.map((val, key) => {
                                                                return (
                                                                    <option value={val.type} title={val.name} key={key}>{val.name}</option>
                                                                )
                                                            }) : <option value="">No Record Found</option>
                                                    }
                                                </select>
                                            </div>
                                            <div className="sendEmail">
                                                <a className="btn btn-purple btn-style-md btn-send mr-5" onClick={() => this.sendEmail()}>Send Email</a>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="dropdown lead-sentdrop custom-select mr-10">
                                                <label>Select SMS Template</label>
                                                <select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedSMSID" value={this.state.selectedSMSID} onChange={(e) => this.selectEmailSMS(e)} data-toggle="dropdown">
                                                    <option>SMS Template</option>
                                                    {
                                                        this.state.smstemplate.length > 0 ?
                                                            this.state.smstemplate.map((val, key) => {
                                                                return (
                                                                    <option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>

                                                                )
                                                            }) : <option value="">No Record Found</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="dropdown lead-sentdrop custom-select mr-10">
                                                <label>Select SMS Type</label>
                                                <select className="btn-dropdown dropdown-toggle btn-dropsent" id="smstype" name="smstype" value={this.state.smstype} onChange={(e) => this.selectEmailSMS(e)} data-toggle="dropdown">
                                                    <option value="">Select SMS Type</option>
                                                    {
                                                        this.state.smstypeList.length > 0 ?
                                                            this.state.smstypeList.map((val, key) => {
                                                                return (
                                                                    <option value={val.type} title={val.name} key={key}>{val.name.length < 15 ? val.name : val.name.slice(0, 15) + " ..."}</option>

                                                                )
                                                            }) : <option value="">No Record Found</option>
                                                    }
                                                </select>


                                            </div>
                                            <div className="sendEmail">
                                                <a className="btn btn-purple btn-style-md btn-send" onClick={() => this.confirmSendSMS()}>Send SMS</a>
                                            </div>
                                        </div>
                                        {/* <div className="sendEmail">
                                            <a className="btn btn-purple btn-style-md" onClick={() => this.sendEmailSMS()}>Send</a>
                                        </div> */}
                                        {/* <div className="form-group">
                                            <div className="sendEmail">
                                                <a className="btn btn-purple btn-style-md btn-send mr-5" onClick={() => this.sendEmail()}>Send Email</a>
                                                <a className="btn btn-purple btn-style-md btn-send" onClick={() => this.sendSMS()}>Send SMS</a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Selected Lenders</label>
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="tag-wrap edit-csv-tags">
                                        {this.state.LeadsId.length > 0 ?
                                            this.state.LeadsId.map((item, key) =>
                                                item !== "" ? <span key={key} className="tag tag-label">{item}</span> : "") : ""
                                        }
                                    </div>
                                    <div className="edit-tag-btn">
                                        <button className="btn-style-md btn-round btn-purple" onClick={this.onEditLeadsModel} >Edit</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Title</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="title" value={this.state.title} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">First Name</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Last Name</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Date of Birth</label>
                                        <div className="custom-datepicker">
                                            <DatePicker className="form-control"
                                                selected={this.state.DateOfBirth}
                                                onChange={this.onChangedate} />
                                            {/* <input type="text" className="form-control" name="DateOfBirth" value={this.state.DateOfBirth} onChange={(e) => this.onchange(e)} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Phone</label>
                                        <div className="">
                                            <ReactPhoneInput
                                                inputExtraProps={{
                                                    name: 'phone1',
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                value={this.state.phone1}
                                                onChange={phone1 => this.setState({ phone1 })} />
                                            {/* <input type="text" className="form-control" name="phone1" value={this.state.phone1} onChange={(e) => this.onchange(e)} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Email</label>
                                        <div className="">
                                            <input type="text" className="form-control" name="email" value={this.state.email} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="form-group">
                                        <label className="">Your Postal Code and Address</label>
                                        <div className="">
                                            <div> <span>Zip:</span>{this.state.zip}</div>
                                            <div><span>Address:</span>{this.state.address}</div>
                                        </div>
                                        {/* <div className="addressShow">
                                            {data}
                                        </div> */}

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-xs-12">
                                    <div className="form-group row">
                                        <label className="col-md-12 col-xs-12">Zip code:</label>
                                        <div className="col-md-5 col-xs-12">
                                            <div className="pos-rel">
                                                <input type="text" className="form-control" name="zip" value={this.state.zipPerviousCode} onChange={(e) => this.onzipPreviousChange(e)} />
                                                <div style={{ "display": this.state.originalAddress ? "block" : "none" }} className="addressShow">
                                                    {data != undefined > 0 ? data : "No Record Found"}
                                                </div>
                                                <div></div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 col-xs-12">
                                            <button className="btn btn-style-md btn-purple" onClick={(e) => this.getAddress(this.state.zipPerviousCode)}>Find Address</button>
                                        </div>
                                        <div className="col-md-5 col-xs-12">
                                            <input type="text" className="form-control" name="zip" value={this.state.address} disabled={this.state.isInputDisabled} onChange={(e) => this.onchange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="address-box">
                                <h4>Please make sure you give us your previous names and any addresses linked to your lenders, in the fields below.</h4>
                                <div className="form-group row">
                                    <label className="col-md-12 col-sm-12 col-xs-12">Previous Surname</label>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                        <input type="text" className="form-control" value={this.state.previous_name} onChange={(e) => this.onchange(e)} name="previous_name" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <h4>Add Address</h4>
                                </div>

                                {/* <div className="form-group">
                                    <label className="">Previous Postcode</label>
                                    <div className="add-address-row">
                                        <div className="add-address-col">
                                            <div className="input-field">
                                                <input type="text" className="form-control" readOnly name="zip" value={this.state.zip} onChange={(e) => this.onchange(e)} />
                                            </div>
                                            <div className="input-field-btn">
                                                <button className="btn btn-style-md btn-purple" onClick={(e) => this.getAddress(this.state.zip)}>Find Address</button>
                                            </div>
                                            <div className="input-field">
                                                <input type="text" className="form-control" name="zip" value={this.state.zip} onChange={(e) => this.onchange(e)} />
                                            </div>
                                        </div>
                                        <div className="add-address-col add-remove">
                                            <div className="field-add-btns" id="minusbutton">
                                                <button className="btn-add">
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                </button>
                                                <button className="btn-rem ml-5">
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="form-group" id="dynamic">
                                    {this.state.FindAddressArray.map((val, i) => {
                                        return (
                                            <div className="form-group" key={i}>
                                                {/* <label className="">Previous Postcode</label> */}
                                                <div className="add-address-row">
                                                    <div className="add-address-col">
                                                        <div className="input-field">
                                                            <label>Zip code:</label>
                                                            <input type="text" className="form-control" name="zip" disabled={this.state.isInputDisabled} value={val.zip} />
                                                        </div>
                                                        <div className="input-field-btn">
                                                            {/* <button className="btn btn-style-md btn-purple" onClick={(e) => this.getPreivous(e , i)}>Find Address</button> */}
                                                        </div>
                                                        <div className="input-field">
                                                            <label>Address:</label>
                                                            <input type="text" className="form-control" name={this.state.previous_address + i} value={val.address} disabled={this.state.isInputDisabled} />
                                                        </div>
                                                    </div>
                                                    <div className="add-address-col add-remove mt-auto">
                                                        <label></label>
                                                        <div className="field-add-btns" id="minusbutton">
                                                            <button className="btn-rem ml-5" onClick={(e) => this.removeTextField(i)}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                    <div className="form-group">
                                        <label className="">Previous Postcode</label>
                                        <div className="add-address-row">
                                            <div className="add-address-col">
                                                <div className="input-field pos-rel">
                                                    <input type="text" className="form-control" name="zip" onChange={(e) => this.onzipChange(e)} />
                                                    <div style={{ "display": this.state.previousAddresss ? "block" : "none" }} className="addressShow bottom">
                                                        {dataprevious != undefined > 0 ? dataprevious : "No Record Found"}
                                                    </div>
                                                </div>
                                                <div className="input-field-btn">
                                                    <button className="btn btn-style-md btn-purple" onClick={(e) => this.getPreivous(e)}>Find Address</button>
                                                </div>
                                                {/* <div className="input-field">
                                                    <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div> */}
                                            </div>
                                            <div className="add-address-col add-remove">
                                                {/* <div className="field-add-btns" id="minusbutton">
                                                    <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* {this.state.FindAddressArray.map((val, i) => {
                                        return (
                                            <div className="form-group">
                                                <label className="">Previous Postcode</label>
                                                <div className="add-address-row">
                                                    <div className="add-address-col">
                                                        <div className="input-field">
                                                            <input type="text" className="form-control" name="zip" onChange={(e) => this.onzipChange(e)} />
                                                        </div>
                                                        <div className="input-field-btn">
                                                            <button className="btn btn-style-md btn-purple" onClick={(e) => this.getPreivous(e, i)}>Find Address</button>
                                                        </div>
                                                        <div className="input-field">
                                                            <input type="text" className="form-control" name={this.state.previous_address + i} disabled={this.state.isInputDisabled} value={this.state.previous_address + i == null ? "" : this.state.previous_address + i} onChange={(e) => this.onchange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="add-address-col add-remove">
                                                        <div className="field-add-btns" id="minusbutton">
                                                            <button className="btn-add" onClick={(e) => this.addTextfield(e)}>
                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                            <button className="btn-rem ml-5" onClick={(e) => this.removeTextField(i)}>
                                                                <i className="fa fa-minus" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                    } */}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="add-btn text-right list-item mt-20">
                                <Link to="/leadslisting" type="button" className="btn-purple btn-style-md btn-round mr-5">Cancel</Link>
                                <button type="button" className="btn-purple btn-style-md btn-round" onClick={(e) => this.submit(e)}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="extra_fields" style={{ display: "none" }}>
                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">leadip</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="leadip" value={this.state.leadip} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">xAuthentication</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="xAuthentication" value={this.state.xAuthentication} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">BankName</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="BankName" value={this.state.BankName} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1" value={this.state.previous_address1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2" value={this.state.previous_address2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3" value={this.state.previous_address3} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4" value={this.state.previous_address4} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5" value={this.state.previous_address5} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode1" value={this.state.previous_postcode1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode2" value={this.state.previous_postcode2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode3" value={this.state.previous_postcode3} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode4</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode4" value={this.state.previous_postcode4} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_postcode5</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_postcode5" value={this.state.previous_postcode5} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line1" value={this.state.previous_address1_line1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line1" value={this.state.previous_address2_line1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>


                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line1" value={this.state.previous_address3_line1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line1" value={this.state.previous_address4_line1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line1</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line1" value={this.state.previous_address5_line1} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line2" value={this.state.previous_address1_line2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line2" value={this.state.previous_address2_line2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line2" value={this.state.previous_address3_line2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line2" value={this.state.previous_address4_line2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line2</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line2" value={this.state.previous_address5_line2} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_line3" value={this.state.previous_address1_line3} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_line3" value={this.state.previous_address2_line3 || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_line3" value={this.state.previous_address3_line3 || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_line3" value={this.state.previous_address4_line3 || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_line3</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_line3" value={this.state.previous_address5_line3 || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_city" value={this.state.previous_address1_city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_city" value={this.state.previous_address2_city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_city" value={this.state.previous_address3_city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_city" value={this.state.previous_address4_city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_city" value={this.state.previous_address5_city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_province" value={this.state.previous_address1_province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_province" value={this.state.previous_address2_province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_province" value={this.state.previous_address3_province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_province" value={this.state.previous_address4_province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_province" value={this.state.previous_address5_province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address1_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address1_company" value={this.state.previous_address1_company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address2_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address2_company" value={this.state.previous_address2_company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address3_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address3_company" value={this.state.previous_address3_company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address4_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address4_company" value={this.state.previous_address4_company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">previous_address5_company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="previous_address5_company" value={this.state.previous_address5_company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">city</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="city" value={this.state.city || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">province</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="province" value={this.state.province || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">country</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="country" value={this.state.country || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">company</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="company" value={this.state.company || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_day</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_day" value={this.state.dob_day || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_month</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_month" value={this.state.dob_month || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob_year</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob_year" value={this.state.dob_year || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">dob</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="dob" value={this.state.dob || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">document_id</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="document_id" value={this.state.document_id || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">pdf_url</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="pdf_url" value={this.state.pdf_url || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">lender_id_doc_order</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="lender_id_doc_order" value={this.state.lender_id_doc_order || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">signature_src</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="signature_src" value={this.state.signature_src || ""} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">PostURL</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="PostURL" value={this.state.PostURL} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_waypoint</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_waypoint" value={this.state.is_waypoint} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_submit</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_submit" value={this.state.is_submit} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">is_signed</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="is_signed" value={this.state.is_signed} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">other</label>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
                            <input type="text" className="form-control" name="other" value={this.state.other} onChange={(e) => this.onchange(e)} />
                        </div>
                    </div>
                </div>


                <Modal className="modal modal-style" open={this.state.leadModelOpen} onClose={this.onEditLeadsModel}>
                    <div className="modal-header">
                        <h4 className="modal-title">Find out if any of these may owe you compensation. </h4>
                    </div>
                    <div className="modal-body">
                        <lable>Please select ALL the banks you had a credit card, loan, or mortgage with</lable>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Lloyds
                                        <input type="checkbox" name="Lloyds" value={'Lloyds'.toLowerCase()} checked={this.state.LeadsId.indexOf('Lloyds'.toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Barclays
                                        <input type="checkbox" name="Barclays" value={'Barclays'.toLowerCase()} checked={this.state.LeadsId.indexOf("Barclays".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Royal Bank of Scotland
                                        <input type="checkbox" name="Royal Bank of Scotland" value={'Royal Bank of Scotland'.toLowerCase()} checked={this.state.LeadsId.indexOf("Royal Bank of Scotland".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Clydesdale Bank
                                        <input type="checkbox" name="Clydesdale Bank" value={'Clydesdale Bank'.toLowerCase()} checked={this.state.LeadsId.indexOf("Clydesdale Bank".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Halifax
                                        <input type="checkbox" name="Halifax" value={"Halifax".toLowerCase()} checked={this.state.LeadsId.indexOf("Halifax".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> HSBC
                                        <input type="checkbox" name="HSBC" value={"HSBC".toLowerCase()} checked={this.state.LeadsId.indexOf("HSBC".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Natwest
                                        <input type="checkbox" name="Natwest" value={"Natwest".toLowerCase()} checked={this.state.LeadsId.indexOf("Natwest".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Barclaycard
                                        <input type="checkbox" name="Barclaycard" value={"Barclaycard".toLowerCase()} checked={this.state.LeadsId.indexOf("Barclaycard".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Woolwich
                                        <input type="checkbox" name="Woolwich" value={"Woolwich".toLowerCase()} checked={this.state.LeadsId.indexOf("Woolwich".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Egg
                                        <input type="checkbox" name="Egg" value={"Egg".toLowerCase()} checked={this.state.LeadsId.indexOf("Egg".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Morgan Stanley
                                    <input type="checkbox" name="Morgan Stanley" value={"Morgan Stanley".toLowerCase()} checked={this.state.LeadsId.indexOf("Morgan Stanley".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Goldfish Card
                                        <input type="checkbox" name="Goldfish Card" value={"Goldfish Card".toLowerCase()} checked={this.state.LeadsId.indexOf("Goldfish Card".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Yorkshire Bank
                                        <input type="checkbox" name="Yorkshire Bank" value={"Yorkshire Bank".toLowerCase()} checked={this.state.LeadsId.indexOf("Yorkshire Bank".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> First Direct
                                        <input type="checkbox" name="First Direct" value={"First Direct".toLowerCase()} checked={this.state.LeadsId.indexOf("First Direct".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Bank of Scotland
                                        <input type="checkbox" name="Bank of Scotland" value={"Bank of Scotland".toLowerCase()} checked={this.state.LeadsId.indexOf("Bank of Scotland".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> HFC Bank
                                        <input type="checkbox" name="HFC Bank" value={"HFC Bank".toLowerCase()} checked={this.state.LeadsId.indexOf("HFC Bank".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Cheltenham&Gloucester
                                    <input type="checkbox" name="Cheltenham & Gloucester" value={"Cheltenham & Gloucester".toLowerCase()} checked={this.state.LeadsId.indexOf("Cheltenham & Gloucester".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Northern Rockt
                                    <input type="checkbox" name="Northern Rockt" value={"Northern Rockt".toLowerCase()} checked={this.state.LeadsId.indexOf("Northern Rockt".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Santander
                                        <input type="checkbox" name="Santander" value={"Santander".toLowerCase()} checked={this.state.LeadsId.indexOf("Santander".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Cahoot
                                    <input type="checkbox" name="Cahoot" value={"Cahoot".toLowerCase()} checked={this.state.LeadsId.indexOf("Cahoot".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> The Cooperative Bank
                                        <input type="checkbox" name="The Cooperative Bank" value={"The Cooperative Bank".toLowerCase()} checked={this.state.LeadsId.indexOf("The Cooperative Bank".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Marks and Spencer Money
                                        <input type="checkbox" name="Marks and Spencer Money" value={"Marks and Spencer Money".toLowerCase()} checked={this.state.LeadsId.indexOf("Marks and Spencer Money".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> Sainsburys
                                        <input type="checkbox" name="Sainsburys" value={"Sainsburys".toLowerCase()} checked={this.state.LeadsId.indexOf("Sainsburys".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="modal-checkbox">
                                    <label className="custom-checkbox"> MBNA
                                    <input type="checkbox" name="MBNA" value={"MBNA".toLowerCase()} checked={this.state.LeadsId.indexOf("MBNA".toLowerCase()) > -1 ? true : false} onChange={(e) => this.onCheckBox(e)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={(e) => this.onEditLeadsModel(e)}>Save</button>
                    </div>
                </Modal>

               
               
                <Modal className="modal modal-style fade" open={this.state.sendSMSModal} onRequestClose={this.closeSMSModal}>               
					<div className="modal-header">
						<h4 className="modal-title">Warning!</h4>
					</div>
					<div className="modal-body">
						<div className="modal-termsandconditions">
							<div className="form-group">
								<p>You have not configured bitly account, Are you sure you want to send SMS without bitlly ?</p>
							</div>

						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-purple btn-style-md btn-round" onClick={(e)=>this.sendSMS(e)} >Send </button>
						<button className="btn btn-purple btn-style-md btn-round" onClick={(e)=>this.closeSMSModal(e)} >Close</button>
					</div>
				</Modal>
               
               
               
               
               
{/*                
                <Modal className="modal modal-style fade" open={this.state.sendSMSModal} onRequestClose={this.closeSMSModal}>
					<div className="modal-header">
						<h4 className="modal-title">Modal</h4>
					</div>
					<div className="modal-body">
						<div className="modal-termsandconditions">
							<div className="form-group">
								<h4>Heading...!</h4>
								<p> Helloo.. !</p>
								<p>Placae your text here...</p>
							</div>
							<div className="form-group">
								<h4>Convert String</h4>
								<p>Number(event.target.value)</p>
								<p>Thanks, but my problem is that I want a controlled number input with a null / undefined initial value.</p>
							</div>
							<div className="form-group">
								<h4>Constructor(props)</h4>
								<p>Additionally</p>
								<p>A value is passed into component X as props via Redux. Component X has a number input that uses that value. The initial value should be empty. The value should be validated as type number via propTypes </p>
							</div>
						</div>
					</div>
					

				

				</Modal> */}

            </React.Fragment>
        );
    }
}

export default withRouter(EditCSVsite);