import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import './permission.css';
class Permission extends Component {
    constructor(props) {
        super(props);
this.state={
    Setting:false,
    Setting_Status:'R',
    myProfile:false,
    myProfile_Status:'R',
    smtpSetting:false,
    smtpSetting_Status:'R',
    smsSetting:false,
    smsSetting_Status:'R',
    Template:false,
    Template_Status:'R',
    emailTemplate:false,
    emailTemplate_Status:'R',
    smsTemplate:false,
    smsTemplate_Status:'R',
    Sites:false,
    Sites_Status:'R',
    leadsListing:false,
    leadsListing_Status:'R',
    outbox:false,
    outbox_status:'R',
    logs:false,
    logs_status:'R',
    forms: false,
    forms_status:'R',
}
    }
    switchHandler = (name, e) => {
        this.setState({
            [name]: e
        })
    }

    selectHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        return (
            <div className="sms-page-wrap field-set">
                <div className="content-header">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="page-heading">
                                <h3>Role Assignment</h3>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12">
                            <div className="add-btn text-right">
                                {/* <!-- <button type="button" className="btn-style-md btn-purple btn-round">Your Button name</button> --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-box">
                    <div className="row">
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Setting</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="Setting" onChange={this.switchHandler.bind(this,'Setting')} checked={this.state.Setting} /></div>
                                <div className="drop-select">
                                    <select className="form-control" name="Setting_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>My Profile</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="myProfile" onChange={this.switchHandler.bind(this,'myProfile')} checked={this.state.myProfile} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="myProfile_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>SMTP Setting</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="smtpSetting" onChange={this.switchHandler.bind(this,'smtpSetting')} checked={this.state.smtpSetting} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="smtpSetting_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>SMS Setting</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="smsSetting" onChange={this.switchHandler.bind(this,'smsSetting')} checked={this.state.smsSetting} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="smsSetting_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Template</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="Template" onChange={this.switchHandler.bind(this,'Template')} checked={this.state.Template} /></div>
                                <div className="drop-select">
                                    <select className="form-control" name="Template_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Email Template</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="emailTemplate" onChange={this.switchHandler.bind(this,'emailTemplate')} checked={this.state.emailTemplate} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="emailTemplate_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>SMS Template</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="smsTemplate" onChange={this.switchHandler.bind(this,'smsTemplate')} checked={this.state.smsTemplate} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="smsTemplate_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Sites</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="Sites" onChange={this.switchHandler.bind(this,'Sites')} checked={this.state.Sites} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="Sites_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Leads Listing</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="leadsListing" onChange={this.switchHandler.bind(this,'leadsListing')} checked={this.state.leadsListing} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="leadsListing_Status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Out Box</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="outbox" onChange={this.switchHandler.bind(this,'outbox')} checked={this.state.outbox} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="outbox_status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Logs</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="logs" onChange={this.switchHandler.bind(this,'logs')} checked={this.state.logs} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="logs_status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-box">
                                <div className="roleSetting"><h3>Forms</h3></div>
                                <div className="custom-switch ml-15 pull-right"><Switch name="forms" onChange={this.switchHandler.bind(this,'forms')} checked={this.state.forms} /></div>
                                <div className="drop-select">
                                <select className="form-control" name="forms_status" onChange={this.selectHandler}>
                                        <option value="R">Read</option>
                                        <option value="RAW">Read & Write</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
               
                </div>




            </div>


            //  <div className="container">
            //     <div className="col-md-12 col-sm-12 col-xs-12">
            //         <div className="form-group row">
            //             <label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Active</label>
            //             <div className="col-md-9 col-sm-8 col-xs-12">
            //                 <Switch   />
            //                 <div className="">

            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            //  </div>
        );
    }
}

export default withRouter(Permission);