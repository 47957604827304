import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class GlobalSearchService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    async getAgentLead(data) {
        let token = JSON.parse(localStorage.getItem("token"));
        let url  = ENV.url + `agent/globalSearch`
  
        const data_1 = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async globalSearch(body, data) {
        let token = JSON.parse(localStorage.getItem("token"));
        let url  = ENV.url + `globalSearch?`
        if(body.filter_email !== ''){
            url= url + "email="+body.filter_email+"&"
        }
        if(body.filter_fname !== ''){
            url= url + "firstname="+body.filter_fname+"&"
        }
        if(body.filter_lname !== ''){
            url= url + "lastname="+body.filter_lname+"&"
        }
        if(body.filter_phone !== ''){
            url= url + "phone1="+body.filter_phone+"&"
        }
        if(body.zip !== ''){
            url= url + "zip="+body.zip+"&"
        }
        if(body.filter_ref !== ''){
            url= url + "Ref="+body.filter_ref+"&"
        }
        if(body.is_signed === 0){
            url= url + "is_signed=0"+"&"
        }
        if(body.leadsType !== ""){
            url= url + "leadsType="+body.leadsType
        }
  
        const data_1 = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async  getSingleCSV(id) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(ENV.url + 'get-single-csv/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,


            }
        }).then(res => res.json());
        return data_1;
    }



    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default GlobalSearchService;