import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class CSVService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    
    async getCsvData(csvid , leadId) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'csvCollection/'+csvid+'/'+leadId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateCSVdata(csvid , leadId ,data) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'csvCollection/'+csvid+'/'+leadId, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async getCsvSiteData(siteid , leadId) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'leads/'+siteid+'/'+leadId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async updateCsvSiteData(siteid , leadId , body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'leads/'+siteid+'/'+leadId, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async csvSMSsend(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'smsFromCsv/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async csvEmailsend(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'emailFromCsv/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async csvSiteSMSsend(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'sms/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async csvSiteEmailsend(body) {
        let token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'mailgun/send', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    
    
    async findAddress() {
      
    }
    




    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default CSVService;