import React, { Component } from 'react'
// let token = localStorage.getItem(s"token");
// axios.defaults.headers.common = {'Authorization': token}
import {ENV} from './../env';

class LoginService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }


    

    async Login(email, password) {
        let body = {
            email: email,
            password: password
        }
        var url = ENV.url;
        const data_1 = await fetch(url + 'auth/login', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    async forgotPassword(email) {
        let body = {
            email: email
        }
        var url = ENV.url;
        const data_1 = await fetch(url + 'forget-password', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    async resetPassword(data) {
       
        var url = ENV.url;
        const data_1 = await fetch(url + 'reset-password', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }

    




    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default LoginService;