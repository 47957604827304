import React, { Component } from 'react'
import './emailtemplate.css';
import CKEditor from "react-ckeditor-component";
import AllUserService from './../../services/alluser.service';
import AllCampaignService from '../../services/campaign.service';
import Switch from "react-switch";
import $ from 'jquery';
import EmailTemplateService from '../../services/emailtemplate.service';
import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';


const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Campaigns extends Component {
	
	initialState = {

		campaigns: [],
		action: false,
		templates: [],
		errors: '',
		editEmail: {},
		open: false,
		isloading: false,
		role: '',
		allUsers: [],
		selectedUserID: '',
		permission: '',
		pandaLoader: true,
		status: 'inactive',
		usersearch: '',
		userStatus: '',
	};
	constructor(props) {

		super(props);
		this.state = this.initialState;
		this.emailTemplateService = new EmailTemplateService();
		this.alluserService = new AllUserService();
		this.allcampaignService = new AllCampaignService();
	}

	//lifecycle
	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		}
		else {
			this.setState({ pandaLoader: false })
		}
		$('#addnewtemplate').hide();

		this.allEmailTemplateAPI();
		this.allUsersAPI();

		this.allCampaigns();

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	deleteTask(i, id){
		console.log(id);
		debugger
		let campaigns = this.state.campaigns;
		campaigns.splice(i,1);
		this.setState({
			campaigns
		})

		this.alluserService.deleteCampaign()

	}
	getPermissions() {

		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,

						pandaLoader: false

					})
					if (this.state.permission.emailTemplate === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	onchangeEmailActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			if (data.code === 200) {
				this.setState({
					allUsers: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	allCampaigns() {
		this.setState({
			isloading: true
		})
		this.allcampaignService.get().then(data => {
			if (data.code === 200) {
				this.setState({
					campaigns: data.data,
					campaignsDuplicate: data.data,
					pandaLoader: false,
					isloading: false,
				})
				// notify.show(data.message, "success", 3000)
			}
			else if (data.code === 404) {
				this.setState({
					isloading: false,
					emailTemplateDuplicate: [],
					templates: []
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}

	allEmailTemplateAPI() {
		this.setState({
			isloading: true
		})
		this.emailTemplateService.allEmailTemplate().then(data => {
			if (data.code === 200) {
				this.setState({
					templates: data.data,
					emailTemplateDuplicate: data.data,
					pandaLoader: false,
					isloading: false,
				})
				// notify.show(data.message, "success", 3000)
			}
			else if (data.code === 404) {
				this.setState({
					isloading: false,
					emailTemplateDuplicate: [],
					templates: []
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });

		$('#addnewtemplate').hide();
		$('#emailtemplatetab').show();
	};

	addmailTemplate(e) {
		e.preventDefault();

		$('#addnewtemplate').show();
		$('#emailtemplatetab').hide();
	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onChange = (evt) => {
		var newContent = evt.editor.getData();
		this.setState({
			description: newContent
		})
	}

	editEmail(id) {
		this.emailTemplateService.editEmailTemplate(id).then(data => {
			this.setState({
				editEmail: data.data[0]
			}, () => {
				this.props.history.push({
					pathname: '/editemail',
					state: { editEmail: data.data[0] }
				})
			})
		}).catch(err => {
			console.log(err)
		})

	}

	deleteItem(){

	}
	submit(e) {
		const { title, subject, description } = this.state;
		e.preventDefault();

		if (title === '') {
			this.setState({ errors: 'Title is required.' });
			return;
		}

		else if (subject === '') {
			this.setState({ errors: 'Subject is required.' });
			return;
		}
		else if (description === '') {
			this.setState({ errors: 'Description is required.' });
			return;
		}
		else {
			this.setState({
				errors: '',
			})

			let body = {
				title: this.state.title,
				description: this.state.description,
				status: this.state.status,
				subject: this.state.subject,
				action: this.state.action
			}
			this.emailTemplateService.addEmailTemplate(body).then(data => {
				if (data.code === 201) {
					this.setState({
						title: '',
						subject: '',
						description: '',
						status: 'inactive',
						action: false,
						errors: '',
						open: true
					}, () => {
						this.allEmailTemplateAPI();
					})
				}

			}).catch(err => {
				console.log(err)
			})

		}
	}


	cancel(e) {
		this.setState({
			title:"",
			subject:"",
			description:"",
			status:"inactive"
		})
		e.preventDefault();
		$('#addnewtemplate').hide();
		$('#emailtemplatetab').show();
	}

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.emailTemplateDuplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.title.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.subject.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				templates: data
			})
		})
	}
	renderEyeButton(val) {
				 
				if (typeof val != 'undefined' && val) {
		          if(val != 0){
						 let data = val[0];
					 return <td>{data.title}</td>
					 }else{
						return <td></td>
					 }
				}else{
					return <td></td>
				}
		
			}
	render() {
		var current = this;
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				< Notifications options={{ zIndex: 200, top: '20px' }} />

				<div >
					<div className="content-page template-tab" id="emailtemplatetab">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>All Campaigns</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									{this.state.role === 'subUser' && this.state.permission.emailTemplate_Status !== 'RW' ? "" :
										<div className="add-btn text-right list-item">
											<Link to="add/campaign" className="btn-style-md btn-purple btn-round">Add Campaign</Link>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="table-wrap">
							<div className="table-responsive">
								<table className="table table-hover table-striped table-bordered table-condensed">
									<thead className="thead-light">
										<tr>
											<th>#</th>
											<th>Name</th>
											<th>Description</th>
											<th>Campaign Type</th>
											<th>Email Template</th>
											<th>SMS Template</th>
										    <th >Edit</th>
										</tr>
									</thead>
									<tbody>
										{
											current.state.campaigns.length > 0 ?
												current.state.campaigns.map(function (value, i) {
                                                     let id = value._id
													return (
														<tr key={i}>
															<th title={i + 1}>{i + 1}</th>
															<td title={value.name}> {value.name} </td>
															<td title={value.description}>{value.description}</td>
															<td title={value.campaignType}>{value.campaignType}</td>
															{current.renderEyeButton(value.emailtemplate)}
															{current.renderEyeButton(value.smstemplate)}
															
															<td>
																<Link className="btn btn-table mr-5"  to={`edit/campaign/${value._id}`}>
																				<i className="fa fa-pencil-square-o" ></i> Edit
															  </Link>
															 <button className="btn btn-table mr-5" onClick={() => current.deleteTask(i,id)}>Delete</button>
															  </td>
													
														</tr>
														
													)
												}) :
												<tr >
													{
														current.state.role === 'subUser' && current.state.permission.emailTemplate_Status !== 'RW' ?
															<td colSpan="5" style={{ textAlign: "center" }}>
																{this.state.isloading ? <ClipLoader
																	css={override}
																	sizeUnit={"px"}
																	size={50}
																	color={'#123abc'}
																	loading={this.state.isloading}
																/> : "No Record Found"}
															</td>
															:
															<td colSpan="6" style={{ textAlign: "center" }}>
																{this.state.isloading ? <ClipLoader
																	css={override}
																	sizeUnit={"px"}
																	size={50}
																	color={'#123abc'}
																	loading={this.state.isloading}
																/> : "No Record Found"}
															</td>
													}
												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				
				</div>

			</React.Fragment>
		);
	}
}

export default Campaigns;