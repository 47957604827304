import React, { Component } from 'react'
import userPhoto from './../../assets/images/user-photo.jpg';
import { withRouter } from 'react-router-dom';
import ProfileService from './../../services/profile.service';
import AllUserService from './../../services/alluser.service';
import { Link } from 'react-router-dom';
import './profile.css';
import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import 'react-circular-progressbar/dist/styles.css';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { isValidPhoneNumber } from 'react-phone-number-input';

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			conpassword: '',
			phone: '',
			gender: 'Male',
			userPhoto: '',
			UserProfile: File,
			errors: '',
			path: ENV.url,
			user: '',
			role:'',
			externalLink:'',
			pkgStats:{
                totalPdfs : 0,
                usedPdfs:0,
                remainingPdfs: 0,
            }
		}
		this.profileService = new ProfileService();
		this.alluserService = new AllUserService();
	}
	//life Cycle
	componentDidMount() {
		let user = JSON.parse(localStorage.getItem('user'));

		if (user) {
			let roleStatus = user.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						user: result,
						role: user.role
					})

					if (this.state.user.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		this.userProfile();
	}

	userProfile() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.profileService.UserProfile(user.id).then(data => {
				console.log("data", data);
				this.setState({
					firstName: data.data.firstName,
					lastName: data.data.lastName,
					email: data.data.email,
					phone: data.data.phone,
					gender: data.data.gender,
					errors: '',
					userProfile: data.data.profileImage,
					externalLink: data.data.externalLink
				});

			}).catch(err => {
				console.log(err);

			})
		} else {
			this.props.history.push('/');
			localStorage.clear();

		}

	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}


	updateProfile(e) {
		const { email, password, conpassword, phone, firstName, lastName } = this.state;
		e.preventDefault();
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (firstName === '') {
			this.setState({ errors: 'First Name is required. ' });
			return;
		}
		else if (lastName === '') {
			this.setState({ errors: 'Last Name is required ' });
			return;
		}
		else if (phone === "") {
			this.setState({ errors: 'Phone number is required' });
			return;
		}

		else if (!isValidPhoneNumber(phone)) {
			this.setState({ errors: 'Phone number is invalid' });
			return;
		}

		else if (email === '') {
			this.setState({ errors: 'Email is required' });
			return;
		}
		else if (!email.match(regexp)) {
			this.setState({ errors: 'Email is invalid' });
			return;
		} else if (password !== "" && password.length < 6) {
			this.setState({ errors: 'Enter min 6 character password' });
			return;
		}
		else if (password !== "" && conpassword === '') {
			this.setState({ errors: 'Conform Password is required' });
			return;

		}
		else if (password !== conpassword) {

			this.setState({ errors: 'Conform Password does not match' });
			return;

		}
		else {
			this.setState({ errors: '' });
			let body = {
				email: this.state.email,
				password: this.state.password,
				phone: this.state.phone,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				gender: this.state.gender,
				externalLink: this.state.externalLink

			}
			let user = JSON.parse(localStorage.getItem('user'));
			if (user) {
				this.profileService.updateProfile(user.id, body).then(data => {
					document.getElementById('fnamelname').innerHTML = data.data.firstName + ' ' + data.data.lastName;
					this.setState({
						password: '',
						conpassword: ''
					})
					notify.show(data.message, "success", 2000);
				}).catch(error => {
					console.log(error)
				})
			} else {
				this.props.history.push('/');
				localStorage.clear();
			}
		}

	}

	changeImage(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.setState({
				UserProfile: file,
				userPhoto: reader.result
			}, () => {
				let user = JSON.parse(localStorage.getItem('user'));
				if (user) {
					let formData = new FormData();
					formData.append('_id', user.id);
					formData.append('profileImage', this.state.UserProfile);
					this.profileService.updateProfileImage(formData).then(data => {
						if (data.code === 201) {
							document.getElementById('headerProfileImage').src = this.state.path + data.data.profileImage;

							this.setState({
								userProfile: data.data.profileImage
							})
							notify.show(data.message, "success", 2000);
						}

					}).catch(err => {
						console.log(err)
					})
				} else {
					this.props.history.push('/');
					localStorage.clear();
				}

			});
		}
		reader.readAsDataURL(file)
	}


	cancel(e) {
		e.preventDefault();
		this.props.history.push('/dashboard');
	}
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div className="content-page Setting-tab tab-structure" id="setting01">
					<div className="setting-fields field-set">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>My Profile</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
								</div>
							</div>
						</div>
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''}
						<div className="row column-reverse">
							<div className="col-md-8 col-xs-12">
								<div className="field-form-width my-profile">
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">First Name</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Your First Name" name="firstName" value={this.state.firstName || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Last Name</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Your Last Name" name="lastName" value={this.state.lastName || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Gender</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<select type="select" className="form-control" name="gender" onChange={(e) => this.onchange(e)} value={this.state.gender || ''} >
												<option>Male</option>
												<option>Female</option>
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Phone</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<ReactPhoneInput
                                            defaultCountry={'gb'}
												inputExtraProps={{
													name: 'phone',
													required: true,
													autoFocus: true
												}}
												value={this.state.phone}
												onChange={phone => this.setState({ phone })} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Email</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="Your Email" name="email" value={this.state.email || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">External Link</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="text" className="form-control" placeholder="external link" name="externalLink" value={this.state.externalLink || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Password</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="password" className="form-control" placeholder="Your Password" name="password" value={this.state.password || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group row">
										<label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 col-form-label">Confirm Password</label>
										<div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
											<input type="password" className="form-control" placeholder="Confirm Your Password" name="conpassword" value={this.state.conpassword || ''} onChange={(e) => this.onchange(e)} />
										</div>
									</div>
									<div className="form-group-btn top-10 text-right">
										<button onClick={(e) => this.updateProfile(e)} className="btn-style-md btn-purple btn-round  mr-10">Save</button>
										<button className="btn-style-md btn-purple btn-round" onClick={(e) => this.cancel(e)}>Cancel</button>

									</div>

								</div>
							</div>
							<div className="col-md-4 col-xs-12">
								<div className="profile-picture">
									<img src={this.state.userProfile ? this.state.path + this.state.userProfile : userPhoto} alt="" />
								</div>
								<label className="custom-file-upload btn-upload">
									Update image
									<input type='file' name="profileImage" accept=".png, .jpg, .jpeg" onChange={(e) => this.changeImage(e)} ></input>
								</label>
								{/* {
								this.state.user.role === "user" ?
									<React.Fragment>
										<div className="current-packege" >
											<label>Kev SMS</label>
											{
												this.state.user.smsCredit == 0 ? <span className="package">
													<a href="javascrip:void(0);">No Credit</a>
												</span> :
													<span className="package">
														<a href="javascrip:void(0);"><strong>{this.state.user.smsCredit}  </strong></a>
													</span>
											}
										</div>
										<div className="current-packege">
											<label>Transfer By Admin</label>
											{
												this.state.user.smsCredit == 0 ? <span className="package">
													<a href="javascrip:void(0);">No Credit</a>
												</span> :
													<span className="package">
														<a href="javascrip:void(0);"><strong>{this.state.user.smsCredit}  </strong></a>
													</span>
											}
										</div>
									</React.Fragment> : ""
								} */}
								
							</div>
							{
							this.state.role === "user" ? 								
							<div className="col-md-8 col-xs-12">
								<div className="current-packege">
									<label>Package Name</label>
									{
										this.state.user.package.length == 0 ? <span className="package">
											<a href="javascrip:void(0);">No packege found</a>
										</span> :
											<span className="package">
												<a href="javascrip:void(0);">{this.state.user.package[0].packageName}  <strong>${this.state.user.package[0].price}</strong></a>
											</span>
									}
								</div>
								
								<div className="upgrade-packege text-right mt-20">
									<Link to="/get-package" className="btn btn-purple btn-round btn-style-md">Upgrade</Link>
									<Link to="/payment-history" className="btn btn-purple btn-round btn-style-md ml-10">Payment History</Link>
								</div>
							</div>:""
							}

						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(Profile)
