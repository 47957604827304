import React, { Component } from 'react';
import moment from 'moment';

import $ from 'jquery';
import LeadsListingService from '../../services/leadslisting.service';
import AllUserService from '../../services/alluser.service';
import BankService from '../../services/bank.services';
import Switch from "react-switch";
import Modal from 'react-responsive-modal';
import MailGunService from '../../services/mailgun.service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from '@emotion/core';
import { ENV } from '../../env';
import ReactPhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import CKEditor from "react-ckeditor-component";
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;


class AllCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			allUsers: [],
			selectedMailgunUserID: '',
			csvListing: [],
			csvListingDuplicate: [],
			path: ENV.url,
			permission: '',
			usersearch: '',
			editData: '',
			siteModal: false,
			pandaLoader: true,
			linkExpiryDate: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
			link: [],
			status: 'inactive',
			id: '',
			userStatus: '',
			isloading: false,
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			createUserModel: false,
			ref: "123",
			_id: "",
			RefModelOpen: false,
			TempModelOpen: false,
			bankNumber: '0',
			title: '',
			subject: '',
			description: '',
		}
		this.leadlistingService = new LeadsListingService();
		this.alluserService = new AllUserService();
		this.mailgunService = new MailGunService();
		this.bankService = new BankService();
	}

	componentDidMount() {
		debugger;
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		// if (roleStatus) {
		// 	this.logout();
		// }
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		if (localStorage.getItem('token')) {
			this.allUserCSV();
			this.allBankCount();
			this.getAllBankTemplate();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}




	getAllBankTemplate() {
		this.setState({
			isloading: true
		})
		// this.bankService.createBank(body).then(data => {
		this.bankService.getBankEmail().then(data => {
			console.log(data, "-------")
			// notify.show(data.message, 'success', 3000)
			debugger
			if (data.code === 200) {
				console.log(data.data);
				if (data.data != undefined) {
					this.setState({
						title: data.data[0].title,
						subject: data.data[0].subject,
						description: data.data[0].description,
					});
					// notify.show(data.message, "success", 3000)
					this.setState({
						isloading: false
					})
				}else{
					this.setState({
						title: '',
						subject: '',
						description: '',
					});	
					this.setState({
						isloading: false
					})
				}
			}
			if (data.code === 404) {
				debugger
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})
	}

	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}

	logout() {
		//console.log("helo")
		localStorage.clear();
		this.props.history.push('/');
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.csv === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}


	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	allUserCSV() {
		this.setState({
			isloading: true
		})
		// this.bankService.createBank(body).then(data => {
		this.bankService.getBank().then(data => {
			debugger
			console.log(data, "-------")
			// notify.show(data.message, 'success', 3000)

			if (data.code === 200) {
				debugger
				console.log(data.data);
				this.setState({
					csvListing: data.data,
					csvListingDuplicate: data.data
				});
				// notify.show(data.message, "success", 3000)
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				debugger
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})

	}



	allBankCount() {
		this.setState({
			isloading: true
		})
		// this.bankService.createBank(body).then(data => {
		this.bankService.getBankCount().then(data => {
			debugger
			console.log(data, "-------")
			// notify.show(data.message, 'success', 3000)
			if (data.code === 200) {
				debugger
				console.log(data.data);
				this.setState({
					bankNumber: data.bankCount
				});
				// notify.show(data.message, "success", 3000)
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				debugger
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})

	}













	onchangeCsvActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	submitUpdate() {
		var current = this;
		this.setState({
			isloading: true
		})
		let body = {
			status: this.state.status,
			linkExpiryDate: this.state.linkExpiryDate
		}
		this.leadlistingService.updateCSV(this.state.id, body).then(data => {
			if (data.code === 200) {
				this.oncloseSiteModal();
				let selectedCSV = JSON.parse(localStorage.getItem('bodyselectedCSV'));
				let bodyCSVID = JSON.parse(localStorage.getItem('bodyCSVID'));
				let bodysiteID = JSON.parse(localStorage.getItem('bodysiteID'));
				let formselected = JSON.parse(localStorage.getItem('FormbodyselectedCSV'));
				let formcsvid = JSON.parse(localStorage.getItem('FormbodyCSVID'));
				console.log(selectedCSV, this.state.id, "--------------------------0000")
				if (data.data.status === 'inactive' && selectedCSV === current.state.id) {
					console.log("yes it is 1")
					//localStorage.removeItem('bodysiteID');
					localStorage.removeItem('bodyselectedCSV');
				} else if (data.data.status === 'inactive' && bodyCSVID === current.state.id) {
					console.log("yes it is 2")

					localStorage.removeItem('bodyCSVID');
				} else if (data.data.status === 'inactive' && bodysiteID === current.state.id) {
					localStorage.removeItem('bodysiteID');
					console.log("yes it is 3")

				} else if (data.data.status === "inactive" && formselected === current.state.id) {
					localStorage.removeItem('FormbodyselectedCSV');
				} else if (data.data.status === "inactive" && formcsvid === current.state.id) {
					localStorage.removeItem('FormbodyCSVID');

				}
				current.setState({
					status: 'inactive',
					isloading: false
				})
				current.allUserCSV();

				setTimeout(() => {

					notify.show(data.message, "success", 2000);

				}, 1000);

			}
		}).catch(err => {
			console.log(err)
			current.setState({
				isloading: false
			})
		})
	}

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.csvListingDuplicate;

			let data = myArray.filter(x => {
				return x.name.replace(/\s+/g, '').toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				csvListing: data
			})
		})
	}



	BankCreateOpenModal = () => {
		this.setState({
			createUserModel: true,
			firstname: '',
			lastname: '',
			business: '',
			email: '',
			password: '',
			gender: 'Male',
			phone: '+92',
			conpassword: '',
			isloading: false,
			ref: '',
			status: 'inactive',
			errors: ""
		});
	}

	createUserCloseModel = () => {
		this.setState({
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			isloading: false,
			errors: "",
			ref: '',
			createUserModel: false
		});
	};


	RefCloseModel = () => {
		this.setState({
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			isloading: false,
			errors: "",
			ref: '',
			RefModelOpen: false
		}, () => {
			this.allBankCount();
		});

	};


	RefOpenModel = () => {
		this.setState({
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			isloading: false,
			errors: "",
			ref: '',
			RefModelOpen: true
		});
	};

	// EmailTemplateCreUpd
	EmailTemplateSubmit(e) {
		e.preventDefault();
		debugger
		const { title, subject, description } = this.state;
		if (title === '') {
			this.setState({ errors: 'Title is required ' });
			return;
		} else if (subject === '') {
			this.setState({ errors: 'Subject is required ' });
			return;
		} else if (description === '') {
			this.setState({ errors: 'Description is required ' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let loginUser = JSON.parse(localStorage.getItem('user'));
			let userId = loginUser.id;
			let body = {
				// Bank name, phone, email, addresss
				title: title,
				subject: subject,
				description: description
			}
			var user = JSON.parse(localStorage.getItem('user'));
			this.bankService.EmailTemplateCreUpd(body).then(data => {
				debugger
				this.setState({
					isloading: true
				});
				if (data.code === 403) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 406) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 201) {
					this.setState({
						bankName: '',
						phone: "+92",
						email: "",
						address: "",
						isloading: false,
						errors: "",
						ref: '',
						_id: '',
						createUserModel: false,
						RefModelOpen: false,
						TempModelOpen: false
					})
					this.getAllBankTemplate();
					notify.show("Email Template Updated Successfully!", "success", 1000);
					setTimeout(() => {
						// notify.show(data.message, "success", 1000);
					}, 3000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}


	addBankRefSubmit(e) {
		e.preventDefault();
		debugger
		const { bankNumber } = this.state;
		//var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (bankNumber === '') {
			this.setState({ errors: 'Bank Name is required ' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let loginUser = JSON.parse(localStorage.getItem('user'));
			let userId = loginUser.id;
			let body = {
				// Bank name, phone, email, addresss
				bankNumber: this.state.bankNumber
			}
			var user = JSON.parse(localStorage.getItem('user'));
			this.bankService.BankRefCount(body).then(data => {
				debugger
				this.setState({
					isloading: true
				});
				if (data.code === 403) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 406) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 201) {
					this.setState({
						bankName: '',
						phone: "+92",
						email: "",
						address: "",
						isloading: false,
						errors: "",
						ref: '',
						_id: '',
						createUserModel: false,
						RefModelOpen: false,
					})
					notify.show("Ref Updated Successfully!", "success", 1000);
					this.allBankCount();
					setTimeout(() => {
						notify.show("Ref has been created Successfully!", "success", 1000);
					}, 3000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	addUserSubmit(e) {
		e.preventDefault();
		debugger

		const { bankName, phone, email, address, _id, ref } = this.state;

		//var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
		const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (bankName === '') {
			this.setState({ errors: 'Bank Name is required ' });
			return;
		}
		else if (address === '') {
			this.setState({ errors: 'Address is required ' });
			return;
		}
		else if (email === '') {
			this.setState({ errors: 'Email is required' });
			return;
		}

		else if (!email.match(regexp)) {
			this.setState({ errors: 'Please enter a valid email' });
			return;
		}
		else if (phone === "") {
			this.setState({ errors: 'Phone number is required' });
			return;
		} else if (!isValidPhoneNumber(phone)) {
			this.setState({ errors: 'Invaild Phone' });
			return;
		}
		// 	else if (ref === '') {
		// 	this.setState({ errors: 'Ref is required' });
		// 	return;
		// }
		else {

			this.setState({ errors: '' });
			let loginUser = JSON.parse(localStorage.getItem('user'));
			let userId = loginUser.id;
			let body = {
				// Bank name, phone, email, addresss
				bankName: this.state.bankName,
				phone: this.state.phone,
				email: this.state.email,
				address: this.state.address,
				_id: this.state._id,
				ref: "123"

			}
			var user = JSON.parse(localStorage.getItem('user'));
			this.bankService.createBank(body).then(data => {
				debugger
				this.setState({
					isloading: true
				});
				if (data.code === 403) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 406) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 201) {
					this.createUserCloseModel();
					this.setState({
						bankName: '',
						phone: "+92",
						email: "",
						address: "",
						isloading: false,
						errors: "",
						ref: '',
						_id: '',
						createUserModel: false
					})
					this.allUserCSV();
					setTimeout(() => {
						// notify.show(data.message, "success", 1000);
					}, 3000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	BanKEditOpenModal(id) {
		debugger
		var user = JSON.parse(localStorage.getItem('user'));
		this.bankService.getSingleBank(id).then(data => {
			debugger
			this.setState({
				isloading: true
			});
			if (data.code === 403) {
				this.setState({
					errors: data.message
				})
			}
			if (data.code === 406) {
				this.setState({
					errors: data.message
				})
			}
			if (data.code === 200) {

				this.setState({
					bankName: data.data.bankName,
					phone: data.data.phone,
					email: data.data.email,
					address: data.data.address,
					isloading: false,
					errors: "",
					ref: data.data.ref,
					createUserModel: true,
					_id: data.data._id
				})
				setTimeout(() => {
					// notify.show(data.message, "success", 1000);
				}, 3000);
			}
		}).catch(err => {
			console.log(err)
		})

	}

	BankDelete(id) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this Bank?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.DeleteBankConfirm(id); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	DeleteBankConfirm(id) {
		this.bankService.deleteBank(id).then(data => {
			console.log(data);
			if (data.code === 200) {
				this.allUserCSV();
				// notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			console.log(err)
		})
	}


	TemplateCloseModel() {
		this.setState({
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			isloading: false,
			errors: "",
			ref: '',
			RefModelOpen: false,
			TempModelOpen: false
		});
		this.getAllBankTemplate();
	}


	TemplateOpenModel() {
		this.setState({
			bankName: '',
			phone: "+92",
			email: "",
			address: "",
			isloading: false,
			errors: "",
			ref: '',
			RefModelOpen: false,
			TempModelOpen: true
		});
	}


	onChangeData = (evt) => {
		var newContent = evt.editor.getData();
		this.setState({
			description: newContent
		})
	}

	render() {
		console.log(this.state.role);
		return (
			<React.Fragment>
				      <Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""}

				{this.state.role === 'admin' ?
					<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>Bank Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="head-search">
											<div className="add-btn text-right">
												{/* <!-- Add Site Modal --> */}
												<button type="button" onClick={() => this.BankCreateOpenModal()} className="btn-style-md btn-purple btn-round">Add New Bank</button>
											</div>
											{/* <div className="add-btn text-right"> */}
											{/* <!-- Add Site Modal --> */}
											{/* <button type="button" onClick={() => this.RefOpenModel()} className="btn-style-md btn-purple btn-round">Bank Ref</button> */}
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mb-20">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>User</th>
											<th>Bank Name</th>
											<th>Phone</th>
											<th>Email</th>
											<th>Address </th>
											{/* <th>ref</th> */}
											<th>Created Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													if (val.user) {
														return (
															<tr key={key}>
																<th title={key + 1}>{key + 1}</th>
																<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																<td title={val.bankName}>{val.bankName}</td>
																<td title={val.phone}>{val.phone} </td>
																<td title={val.email}>{val.email} </td>
																<td title={val.address}>{val.address} </td>
																{/* <td title={val.ref}>{val.ref} </td> */}
																<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
																<td width="230">
																	<div className="table-option-btns">
																		{/* <a type="button" href={this.state.path + val.file}   className="btn btn-table mr-5"  download  onClick={()=> this.download(val._id)} >
																	  <i className="fa fa-pencil-square-o" ></i> Download
																  </a> */}
																		<a type="button" onClick={() => this.BanKEditOpenModal(val._id)} className="btn btn-table mr-5" download  >
																			<i className="fa fa-pencil-square-o" ></i> Edit
																  </a>
																		<a type="button" onClick={() => this.BankDelete(val._id)} className="btn btn-table mr-5" download  >
																			<i className="fa fa-trash" ></i> Delete
																  </a>
																		{/* <button type="button" className="btn btn-table" onClick={() => this.conformDelete(val._id)}>
																		<i className="glyphicon glyphicon-trash"></i> Delete
																  </button> */}
																	</div>
																</td>
															</tr>
														)
													}
												})
												: <tr >
													<td colSpan="8" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}

													</td>
												</tr>
										}


									</tbody>
								</table>
							</div>
						</div>
					</div> : ''
				}


				{this.state.role === 'user' || this.state.role === 'subUser' ?
					<div className="setting-fields field-set" style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>Bank Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="add-btn text-right">
											{/* <!-- Add Site Modal --> */}
											<button type="button" onClick={() => this.BankCreateOpenModal()} className="btn-style-md btn-purple btn-round">Add New Bank</button>
										</div>
										<div className="add-btn text-right">
											{/* <!-- Add Site Modal --> */}
											<button type="button" onClick={() => this.RefOpenModel()} className="btn-style-md btn-purple btn-round">Bank Ref</button>
										</div>
										<div className="add-btn text-right">
											{/* <!-- Add Site Modal --> */}
											<button type="button" onClick={() => this.TemplateOpenModel()} className="btn-style-md btn-purple btn-round">Bank Email Template</button>
										</div>
									</div>
								</div>
							</div>




						</div>


						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>Bank Name</th>
											<th>Phone</th>
											<th>Email</th>
											<th>Address </th>
											{/* <th>ref </th> */}
											<th>Created Date</th>
											<th>Action</th>

										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													return (
														<tr key={key}>
															<th title={key + 1}>{key + 1}</th>
															<td title={val.bankName}>{val.bankName}</td>
															<td title={val.phone}>{val.phone} </td>
															<td title={val.email}>{val.email} </td>
															<td title={val.address}>{val.address} </td>
															{/* <td title={val.ref}>{val.ref} </td> */}
															<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
															<td width="230">
																<div className="table-option-btns">
																	{/* <a type="button" href={this.state.path + val.file}   className="btn btn-table mr-5"  download  onClick={()=> this.download(val._id)} >
																	  <i className="fa fa-pencil-square-o" ></i> Download
																  </a> */}
																	<a type="button" onClick={() => this.BanKEditOpenModal(val._id)} className="btn btn-table mr-5" download  >
																		<i className="fa fa-pencil-square-o" ></i> Edit
																  </a>
																	<a type="button" onClick={() => this.BankDelete(val._id)} className="btn btn-table mr-5" download  >
																		<i className="fa fa-trash" ></i> Delete
																  </a>
																	{/* <button type="button" className="btn btn-table" onClick={() => this.conformDelete(val._id)}>
																		<i className="glyphicon glyphicon-trash"></i> Delete
																  </button> */}
																</div>
															</td>
														</tr>
													)

												})
												: <tr >

													<td colSpan="7" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}
													</td>

												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>


					</div> : ''
				}

				<Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.createUserModel} onClose={this.createUserCloseModel} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.createUserCloseModel()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Add Bank </h4>
					</div>
					<div className="modal-body">

						<div className="form-group row">
							<label className="col-md-4">Bank Name</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="bankName" value={this.state.bankName || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Address</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="address" value={this.state.address || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Email</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="email" value={this.state.email || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div>

						<div className="form-group row">
							<label className="col-md-4">Phone</label>
							<div className="col-md-8">
								<ReactPhoneInput

									inputExtraProps={{
										name: 'phone',
										required: true,
										autoFocus: true
									}}
									placeholder="Enter phone number"

									value={this.state.phone}
									onChange={phone => this.setState({ phone })} />
							</div>
						</div>
						{/* <div className="form-group row">
							<label className="col-md-4">Ref</label>
							<div className="col-md-8">
								<input type="number" className="form-control" name="ref" value={this.state.ref || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div> */}
						{this.state.errors != '' ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ""}
					</div>

					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.addUserSubmit(e)}>Save</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.createUserCloseModel()}>Close</button>
					</div>
				</Modal>


				<Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.RefModelOpen} onClose={this.state.RefModelClose} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.RefCloseModel()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Bank Ref Count </h4>
					</div>

					<div className="modal-body">

						<div className="form-group row">
							<label className="col-md-4">Bank Ref</label>
							<div className="col-md-8">
								<input type="number" className="form-control" name="bankNumber" value={this.state.bankNumber || ''} onChange={(e) => this.onchange(e)} />
							</div>

							{this.state.errors != '' ?
								<div className="alert alert-danger">
									{this.state.errors}
								</div> : ""}
						</div>
					</div>

					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.addBankRefSubmit(e)}>Save</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.RefCloseModel()}>Close</button>
					</div>
				</Modal>




				<Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.TempModelOpen} onClose={this.state.TempModelOpen} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.TemplateCloseModel()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Bank Email Template </h4>
					</div>

					<div className="modal-body">

						<div className="form-group row">

							<div className="col-md-12">
								<div className="template-editor row">
									<div className="add-template-modal col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="form-group row">
											<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Title</label>
											<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
												<input type="text" className="form-control" value={this.state.title} name="title" onChange={(e) => this.onchange(e)} />
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Subject</label>
											<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
												<input type="text" className="form-control" value={this.state.subject} name="subject" onChange={(e) => this.onchange(e)} />
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Dynamic Fields</label>
											<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
												<div className="tag-wrap">
													<span className="tag tag-label">[[title]]</span>
													<span className="tag tag-label">[[firstname]]</span>
													<span className="tag tag-label">[[lastname]]</span>
													<span className="tag tag-label">[[email]]</span>
													<span className="tag tag-label">[[phone1]]</span>
													<span className="tag tag-label">[[lookupId]]</span>
													<span className="tag tag-label">[[customerName]]</span>
													<span className="tag tag-label">[[LINK0]]</span>
												</div>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Description</label>
											<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
												<CKEditor
												   config= {{'allowedContent':true}}
													activeclassName="p10"
													content={this.state.description}
													events={{
														"blur": this.onBlur,
														"afterPaste": this.afterPaste,
														"change": this.onChangeData
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							{this.state.errors != '' ?
								<div className="alert alert-danger">
									{this.state.errors}
								</div> : ""}
						</div>
					</div>

					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.EmailTemplateSubmit(e)}>Save</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.TemplateCloseModel()}>Close</button>
					</div>
				</Modal>



			</React.Fragment >
		);
	}
}

export default AllCSV;