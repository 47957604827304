module.exports = {
    OAuth: {
        developerKey:'AIzaSyCbRe90i6xrZIQ5oED4DhLc7T8rRmX4VwM',
        clientId:'596000875976-5d1r7tj3nhkap20hlum0uvspd4ppnqjt.apps.googleusercontent.com',
        secretKey:"7rbfq9O8qO-t8A_bAQDPe9EF"
    }
};



// {"web":{"client_id":"596000875976-s8cf8bsksddcng5jscnhs37t1lgn86mj.apps.googleusercontent.com","project_id":"partialleads","auth_uri":"https://accounts.google.com/o/oauth2/auth","token_uri":"https://oauth2.googleapis.com/token","auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs","client_secret":"uMnUR53DhVtknc5Qpv4f_lHw","redirect_uris":["http://localhost:3000","http://localhost:8080","https://www.esignrepack.co.uk"],"javascript_origins":["https://www.esignrepack.co.uk","http://localhost:3000","http://localhost:8080"]}}


// {"web":{"client_id":"596000875976-5d1r7tj3nhkap20hlum0uvspd4ppnqjt.apps.googleusercontent.com","project_id":"partialleads","auth_uri":"https://accounts.google.com/o/oauth2/auth","token_uri":"https://oauth2.googleapis.com/token","auth_provider_x509_cert_url":"https://www.googleapis.com/oauth2/v1/certs","client_secret":"7rbfq9O8qO-t8A_bAQDPe9EF","redirect_uris":["http://localhost:3000","http://localhost:8080"],"javascript_origins":["http://localhost:3000","http://localhost:8080"]}}