import React, { Component } from 'react'
import './smstemplate.css';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import SmSTemplateService from '../../services/smstemplate.service';
import AllUserService from './../../services/alluser.service';
import Switch from "react-switch";
import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class SmsTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			description: '',
			smstemplates: [],
			errors: '',
			open: false,
			isloading: false,
			role: '',
			allUsers: [],
			selectedUserID: '',
			permission: '',
			pandaLoader: true,
			status: 'inactive',
			usersearch: "",
			userStatus: '',
			smsTemplateDuplicate: [],
			agentLeadSearch: []

		}
		this.addSmsTemplateService = new SmSTemplateService();
		this.alluserService = new AllUserService();

	}
	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.getPageInnerPermision(result);
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		this.allSmSAPI();
		this.allUsersAPI();

		$('#smstemplatetab').show();
		$('#addnewtemplate02').hide();

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}
	}

	getPageInnerPermision(data) {
		debugger
		let role = data.role;
		let userId = data._id;
		if (role == "admin") {
			userId = data._id;
		} else if (role == "user") {
			userId = data._id;
		} else if (role == "subUser") {
			userId = data.parentId;
		}
		this.alluserService.getInnerPermission(userId).then(data => {
			console.log("editeditediteditedit", data)
			this.setState({
				permissionId: data.data._id,
				agentLeadSearch: data.data.agentLeadSearch,
				unsubscribe: data.data.unsubscribe,
				claimLink: data.data.claimLink,
				editInnerModal: true
			})
		}).catch(err => {
			console.log(err)
		})

	}



	onchangeSmsActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	getPermissions() {

		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.smsTemplate === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			if (data.code === 200) {
				this.setState({
					allUsers: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	allSmSAPI() {
		this.setState({
			isloading: true
		})
		this.addSmsTemplateService.allSmSTemplate().then(data => {
			if (data.code === 200) {

				this.setState({
					smstemplates: data.data,
					smsTemplateDuplicate: data.data,
					isloading: false
				})
				// notify.show(data.message, "success" , 3000)
			}
			else if (data.code === 404) {
				this.setState({
					isloading: false,
					smsTemplateDuplicate: [],
					smstemplates: []
				})
				// notify.show(data.message, "success" , 3000)

			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})

	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
		$('#smstemplatetab').show();
		$('#addnewtemplate02').hide();
	};


	addnewsms() {
		$('#smstemplatetab').hide();
		$('#addnewtemplate02').show();
	}

	onchange(e) {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	editSms(id) {
		this.addSmsTemplateService.getOneSmsTemplate(id).then(data => {
			if (data.code === 200) {
				this.props.history.push({
					pathname: '/editsms',
					state: { editSMS: data.data[0] }
				})
			}

		}).catch(err => {
			console.log(err)
		})
	}
	submit(e) {
		e.preventDefault();
		const { title, description } = this.state;


		if (title === '') {
			this.setState({ errors: 'Title is required.' });
			return;
		}
		else if (description === '') {
			this.setState({ errors: 'Description is required.' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let body = {
				title: this.state.title,
				description: this.state.description,
				status: this.state.status
			}
			this.addSmsTemplateService.addSmSTemplate(body).then(data => {
				if (data.code === 201) {
					this.setState({
						title: '',
						description: '',
						status: 'inactive',
						open: true
					}, () => {
						this.allSmSAPI();
					})
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	cancel(e) {
		this.setState({
			title: "",
			description: "",
			status: "inactive"
		})
		e.preventDefault();
		$('#smstemplatetab').show();
		$('#addnewtemplate02').hide();
	}

	

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.smsTemplateDuplicate;

			let data = myArray.filter(x => {
				return (x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.title.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.description.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				smstemplates: data
			})
		})
	}



	onchangeUserEditPermission(key, check) {
		debugger
		var agentLeadSearch = this.state.agentLeadSearch;
		let te = agentLeadSearch[key].smsTemplate 
		if(te != check){
			agentLeadSearch[key].smsTemplate = check ;
		}else{
			agentLeadSearch[key].smsTemplate = "" ;
		}
        
        this.setState({
            agentLeadSearch
		}, () =>{
           this.UpdateEditPermission()
		})
		console.log(agentLeadSearch);
    }
	UpdateEditPermission() {
        let data1 = {
            agentLeadSearch: this.state.agentLeadSearch,
            unsubscribe: this.state.unsubscribe,
            claimLink: this.state.claimLink
        }
        this.alluserService.editPermission(this.state.permissionId, data1).then(data => {
            console.log("editeditediteditedit", data)
            this.setState({
                editInnerModal: false
            })
        }).catch(err => {
            console.log(err)
        })
	}
	
	render() {
		var current = this;
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />


				<div style={{ display: this.state.role === "admin" ? "block" : "none" }} >

					<div className="content-page template-tab" >
						<div className="content-header">
							<div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>SMS Templates</h3>
									</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
								<div className="head-search">
										<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
									</div>
                                </div>
                            </div>
                        </div>

						</div>

						<div className="table-wrap">
							<div className="table-responsive">
								<table className="table table-hover table-striped table-bordered table-condensed">
									<thead className="thead-light">

										<tr>
											<th>#</th>
											<th>User</th>
											<th>Template name</th>
											<th>Description</th>
											<th width="230">Status</th>
										</tr>
									</thead>
									<tbody>
										{
											current.state.smstemplates.length > 0 ?
												current.state.smstemplates.map((value, i) => {
													if (value.user) {
														return (
															<tr key={i}>
																<th title={i+1}>{i+1}</th>
																<td>{value.user !== null && value.user ? value.user.firstName + " " + value.user.lastName : ""}</td>
																<td title={value.title}>{value.title}</td>
																<td title={value.description} dangerouslySetInnerHTML={{ __html: value.description }}></td>
																<td width="230">
																	<div className="table-option-btns">
																		{value.status == 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}
																		
																	</div>
																</td>
															</tr>
														)
													}
												}) :
												<tr >
													<td colSpan="5" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}

													</td>
												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>

				</div>



				<div style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? "block" : "none" }} >

					<div className="content-page template-tab" id="smstemplatetab">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>SMS Templates</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									{this.state.role === 'subUser' && this.state.permission.smsTemplate_Status !== 'RW' ? "" :
										<div className="add-btn text-right list-item">
											{/* <!-- Link to Add Email Template page --> */}
											<a url-data="#addnewtemplate02" onClick={() => this.addnewsms()} className="btn-style-md btn-purple btn-round">Add SMS Template</a>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="table-wrap">
							<div className="table-responsive">
								<table className="table table-hover table-striped table-bordered table-condensed">
									<thead className="thead-light">

										<tr>
											<th>#</th>
											<th>User</th>
											<th>Template name</th>
											<th>Description</th>
											<th>Status</th>
											{
												current.state.role === 'subUser' && current.state.permission.smsTemplate_Status !== 'RW' ?
													''
													:
													<th>Action</th>
											}
																						<th>Action</th>

										</tr>
									</thead>
									<tbody>
										{
											current.state.smstemplates.length > 0 ?
												current.state.smstemplates.map((value, i) => {
													if (value.user) {
														return (
															<tr key={i}>
																<th title={i+1}>{i+1}</th>
																<td>{value.user.firstName + " " + value.user.lastName}</td>
																<td title={value.title}>{value.title}</td>
																<td title={value.description} dangerouslySetInnerHTML={{ __html: value.description }}></td>
																<td>{value.status == 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}</td>
																{
																	current.state.role === 'subUser' && current.state.permission.smsTemplate_Status !== 'RW' ?
																		''
																		:
																		<td width="230">
																			<div className="table-option-btns">
																				<button type="button" className="btn btn-table mr-5" onClick={() => current.editSms(value._id)}>
																					<i className="fa fa-pencil-square-o" ></i> Edit
																				</button>
																			
																			</div>
																		</td>
																}
																	<td >
																{
																	current.state.agentLeadSearch.length > 0 ?
																		current.state.agentLeadSearch.map((val1, key) => {
																			return (
																				val1.value != undefined && val1.value != "" && val1.check == true ?
																				<div className="form-group row">
																					<label className="col-md-4">{val1.name}</label>
																					<div className="col-md-8">
																						<Switch name="status"
																							onChange={(e) => current.onchangeUserEditPermission(key, value._id)} checked={value._id == val1.smsTemplate ? true : false} />
																					</div>
																				</div> : null
																			)
																		}) : ""
																}
															</td>
															</tr>
														)
													}

												}) :
												<tr >

													{
														current.state.role === 'subUser' && current.state.permission.smsTemplate_Status !== 'RW' ?
															<td colSpan="5" style={{ textAlign: "center" }}>
																{this.state.isloading ? <ClipLoader
																	css={override}
																	sizeUnit={"px"}
																	size={50}
																	color={'#123abc'}
																	loading={this.state.isloading}
																/> : "No Record Found"}

															</td>
															:
															<td colSpan="6" style={{ textAlign: "center" }}>
																{this.state.isloading ? <ClipLoader
																	css={override}
																	sizeUnit={"px"}
																	size={50}
																	color={'#123abc'}
																	loading={this.state.isloading}
																/> : "No Record Found"}

															</td>
													}

												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="content-page" id="addnewtemplate02">
						<div className="content-header">
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3>Add SMS Template</h3>
									</div>
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="add-btn text-right">
										<button type="button" className="btn-style-md btn-purple btn-round mr-5" onClick={(e) => this.submit(e)}>Save Template</button>
										<button type="button" className="btn-purple btn-style-md btn-round " onClick={(e) => this.cancel(e)}>Cancel</button>

									</div>
								</div>
							</div>
						</div>
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''}
						<div className="template-editor row">
							<div className="add-template-modal col-lg-9 col-md-10 col-sm-12 col-xs-12">
								<div className="form-group row">
									<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Title</label>
									<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
										<input type="text" name="title" value={this.state.title} onChange={(e) => this.onchange(e)} className="form-control" />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Dynamic Fields</label>
									<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
										<div className="tag-wrap">
											<span className="tag tag-label">[[title]]</span>
											<span className="tag tag-label">[[firstname]]</span>
											<span className="tag tag-label">[[lastname]]</span>
											<span className="tag tag-label">[[email]]</span>
											<span className="tag tag-label">[[phone1]]</span>
											<span className="tag tag-label">[[lookupId]]</span>
											<span className="tag tag-label">[[customerName]]</span>
											<span className="tag tag-label">[[leadId]]</span>
											<span className="tag tag-label">[[csvId]]</span>
											<span class="tag tag-label">[[LINK0]]</span>
											<span class="tag tag-label">[[UNSUB]]</span>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-lg-3 col-md-3 col-sm-4 col-xs-12">Description</label>
									<div className="col-lg-9 col-md-9 col-sm-8 col-xs-12">
										<textarea className="form-control" name="description" value={this.state.description} onChange={(e) => this.onchange(e)} rows="6"></textarea>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-3 col-sm-4 col-xs-12 col-form-label">Status</label>
									<div className="col-md-9 col-sm-8 col-xs-12">
										<Switch name="status"
											onChange={(e) => this.onchangeSmsActive(e)} checked={this.state.status == 'active' ? true : false} />
									</div>
								</div>
							</div>
						</div>
					</div>


					<Modal open={this.state.open} onClose={this.onCloseModal}>
						<div className="modal-body text-center save-template">
							<img src={require("./../../assets/images/done.png")} className="img-resposive center-block mb-10" alt="" />
							<h3 className="text-purple mb-5">Successfully!</h3>
							<p>Template has been  saved successfully.</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn-purple btn-style-md btn-round" data-dismiss="onCloseModal" onClick={() => this.onCloseModal()}>Done</button>
						</div>
					</Modal>

				</div>


			</React.Fragment>
		);
	}
}

export default withRouter(SmsTemplate);