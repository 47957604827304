import React, { Component } from 'react';
import {ENV} from './../env';

// let token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class SMSSettingService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    async updateSMSTwilioWhatsapp(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
        const data_1 = await fetch(url + 'twilioWhatsApp/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    URL() {
        return ENV.url;
      

    }






    async updateSMSTwilio(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
        const data_1 = await fetch(url + 'twilio/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    async updateSMSlocal(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'smsLocal/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    async updateSeven67(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));


        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'seven67/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    async smsLocal() {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getSmsLocal', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async smsTwiliowhatsapp() {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getTwilioWhatsApp', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }


    async smsTwilio() {
        var token = JSON.parse(localStorage.getItem("token"));
        var parId = JSON.parse(localStorage.getItem('user'));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getTwilio', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async seven67() {
        var token = JSON.parse(localStorage.getItem("token"));
        var parId = JSON.parse(localStorage.getItem('user'));

        const data_1 = await fetch(this.URL() + 'getSms67', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }



    
    async genSingleUserDataTwilio(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getUserTwilio/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }

    async genSingleUserDatalocal(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())

        const data_1 = await fetch(this.URL() + 'getUserSmsLocal/'+id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());;
        return data_1;
    }





    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default SMSSettingService;