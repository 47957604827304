import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import EmailTemplateService from '../../services/emailtemplate.service';
import sms_temp from './../../assets/images/sms.svg';
import SmSTemplateService from '../../services/smstemplate.service';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AllUserService from '../../services/alluser.service';
import PackageService from '../../services/package.service';
import { ENV } from './../../env';
import moment from "moment";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(3, 'months'),
      endDate: moment().endOf('month'),
      templateId: "",
      templates: [],
      emailCount: 0,
    }
    this.emailTemplateService = new EmailTemplateService();
    this.alluserService = new AllUserService();
    this.packageService = new PackageService();
    this.addSmsTemplateService = new SmSTemplateService();
  }
  URL() {
    return ENV.url;
  }

  selectHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let role = JSON.parse(localStorage.getItem('user'));
    this.allEmailTemplateAPI();
    this.getAllEmailRecord();
    if (role) {
      let roleStatus = role.id;
      this.alluserService.editUser(roleStatus).then(data => {
        if (data.code === 200) {
          let result = data.data;
          this.setState({
            userStatus: result
          })
          if (this.state.userStatus.status === 'inactive') {
            localStorage.clear();
            this.props.history.push('/');
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }

  }


  allEmailTemplateAPI() {
    this.setState({
      isloading: true
    })	
    this.addSmsTemplateService.allSmSTemplate().then(data => {
      if (data.code === 200) {
        this.setState({
          templates: data.data,
        })

      }
      else if (data.code === 404) {
        this.setState({
          isloading: false,
          emailTemplateDuplicate: [],
          templates: []
        })


      }
    }).catch(err => {
      this.setState({
        isloading: false
      })
      console.log(err)
    })
  }


  filterData() {
    var th = this;
      this.getAllEmailRecord();
  }


  getAllEmailRecord = () => {

    const { templateId, startDate , endDate } = this.state;

    let data1 = {
      templateId, startDate , endDate
    }

    this.emailTemplateService.allSMSStatus(data1).then(data => {
      debugger
      if (data.code === 200) {
        this.setState({
          emailCount: data.theCount,
        })

      }
      else if (data.code === 404) {
        this.setState({
        })
      }
    }).catch(err => {
      this.setState({

      })
      console.log(err)
    })
  }



  reset() {
    this.setState({
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      templateId: "",
      templates: [],
    }, () => {
      this.getAllEmailRecord();
    })
  }
  handleEvent = (e, picker) => {
		this.setState({
			startDate: picker.startDate,
			endDate: picker.endDate
		})
	}

  render() {
    let th = this;
    console.log(this.state);
    return (

      <>

        <div className="content-header">
          <div className="row">
            <div className="col-md-3 col-sm-3 col-xs-12">
              <div className="page-heading">
                <h3>Email Status</h3>
              </div>
            </div>
            <div className="col-md-9 col-sm-9 col-xs-12">
              <div className="head-right-wrap">
              </div>
            </div>
          </div>
        </div>


        <div className="accordian-expand">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="listing-filter-search gobal-search">

                <div className="custom-datepicker">
                  <DateRangePicker id="selectDate" autoApply={false} locale={{ format: "DD/MM/YYYY" }} startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} >
                    <input type="button" name="daterange" value={this.state.startDate.format("MM/DD/YYYY") + " - " + this.state.endDate.format("MM/DD/YYYY")} className="date-input" />
                  </DateRangePicker>
                </div>
                <div className="form-group text-filed">
                  <select className="form-control" value={this.state.templateId} name="templateId" onChange={this.selectHandler}>
                    <option value="" >Select Template</option>
                    {
                      th.state.templates.length > 0 ?
                        th.state.templates.map(function (value, i) {
                          if (value.user) {
                            return (
                              <option value={value._id} >{value.title}</option>
                            )
                          }

                        }) : <option value=""> No Template </option>

                    }


                  </select>
                </div>
                <div className="form-group btn-filed">
                  <button type="button" className="btn-search btn-style-md mr-10" onClick={(e) => this.filterData(e)}>Filter</button>
                  <button type="button" className="btn-search btn-style-md" onClick={(e) => this.reset(e)}>Reset</button>
                </div>
              </div>
              <div className="gobal-search ">
                <div className="form-group multi-select-input">
                </div>
              </div>
            </div>
          </div>
        </div>

        <React.Fragment>
          <div className="content-page">
            <div className="dashboard-buttons">

              <React.Fragment>
                <div className="dashboard-btn-box-admin-subuser">
                  <Link className="btn btn-dashemail btn-lg col-md-12 dashboard-nav-btn sms-bg" role="button" to="/emailtemplate">
                    <div className="ribbon"><span>{this.state.emailCount}</span></div>
                    <div className="box-icon"><img src={sms_temp} className="img-resposive" alt="" /></div>
                    <h4>Email Templates</h4>
                  </Link>
                </div>
              </React.Fragment>



            </div>

          </div>
        </React.Fragment>


      </>
    )
  }
}
