import React, { Component } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker'
import InfiniteScroll from 'react-infinite-scroll-component';
import CloudPrint from '../printGmailCloudDoucmnet/CloudDocument';
import AllUserService from './../../services/alluser.service';
// import './leadslisting.css';
import $ from 'jquery';
import LeadsListingService from '../../services/leadslisting.service';
import BitlayService from './../../services/bitlay.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import Select from 'react-select';
import { ENV } from './../../env';
import { css } from '@emotion/core';

import GlobalSearchService from '../../services/globalSearch.service';
import Swal from 'sweetalert2'
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class LeadsListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			existingCSV: [],
			globalSearch: [],
			search: '',
			filter_fname: "",
			filter_lname: "",
			filter_email: "",
			filter_phone: "",
			zip: "",
			isloading: false,
			linktoCopy: '',
			csvID: '',
			searchDropdown: "",
			multiSelect: [],
			errors: ""
		}
		this.leadlistingService = new LeadsListingService();
		this.bitlayservice = new BitlayService();
		this.alluserService = new AllUserService();
		this.globalSearchService = new GlobalSearchService();
		this.alluserService = new AllUserService();
	}

	allUserCSV() {
		this.leadlistingService.allCSV().then(data => {
			if (data.code === 200) {
				let arr = [];
				for (var i = 0; i < data.data.length; i++) {
					arr.push({
						label: data.data[i].name,
						//   value: false,
						value: data.data[i]._id,
					});
				}
				this.setState({
					existingCSV: arr,
				});
			}
			if (data.code === 404) {
				this.setState({
					existingCSV: [],
				})
			}
		}).catch(err => {
			console.log(err)
		})

	}



	componentDidMount() {
		var that = this;
		window.scrollTo(0, 0);
		debugger
		if (this.props.match.params.id) {
			this.filterData(this.props.match.params.id);
		}
		let role = JSON.parse(localStorage.getItem('user'));

		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
			
		}
		else if (rolename === 'admin') {

			this.setState({
				sendTheMessages: false,
				pandaLoader: false
			});
		}
		else {
			this.setState({ pandaLoader: false })
		}


	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					let perm = this.state.permission;
					if (false) {
						this.props.history.push('*');
					} else if (this.state.permission.sendMessage === false) {
						this.setState({
							sendTheMessages: false
						})
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	filterData(id) {
		this.setState({
			isloading: true
		})
		var data = {
			userId: id
		}
		this.globalSearchService.getAgentLead(data).then(data => {
			if (data.code === 200) {
				console.log("Ahmad Testing", data.data);
				this.setState({
					globalSearch: data.data,
					isloading: false
				})
			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})
	}


	render() {
		var currentThis = this;
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}

				<Notifications options={{ zIndex: 200, top: '20px' }} />

				{this.state.csvLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<div className="content-page template-tab" id="leadstable">
					<div className="content-header">

						<div className="row">
							<div className="col-md-4 col-sm-4 col-xs-12">
								<div className="page-heading">
									<h3>Lead Submitted By Agents</h3>
								</div>
							</div>
						</div>
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div>
							: ''}

						<div className="row">
							<div className="col-xs-12">
							</div>
							<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							</div>
							<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
								<div className="add-btn pull-right">
								</div>
							</div>
						</div>
					</div>
					<div className="tab leads-tabs" role="tabpanel">
						<div className="head-right-wrap mb-10">
						</div>
						<div className="tab-content tabs">

							<div role="tabpanel" className="tab-pane fade in active" id="tab-1">



								<div className="table-wrap leads-table">
									<div className="table-responsive" style={{ textAlign: "center" }}>
										{/* <InfiniteScroll
													dataLength={this.state.leadwithphone.length}
													next={this.fetchMoreDataWithPhone}
													hasMore={this.state.hasMorephone}
													loader={
														<ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.hasMorephone}

														/>}
												> */}

										<table className="table table-hover table-striped table-bordered table-condensed" style={{}}>
											<thead className="thead-light">
												<tr>
													<th>
														<div className="table-check">
															<label className="custom-checkbox">
																<input type="checkbox" className="leadphoneall" onClick={(e) => this.selectAllwithPhone(e)} />
																<span className="checkmark"></span>
															</label>
														</div>
													</th>

													<th>Lead ID</th>
													<th>Email</th>
													<th>Title</th>
													<th>First Name</th>
													<th>Last Name</th>
													<th>Phone</th>
													<th>SMS Count</th>
													<th>Email Count</th>
													<th>Drop Of Link</th>
													<th>Ref</th>
												</tr>
											</thead>
											<tbody>
												{
													this.state.globalSearch.length > 0 ?
														this.state.globalSearch.map((val, key) => {
															return (
																<tr key={key} style={{
																	background: val.sms_send_count >
																		0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
																}}>
																	<td><div className="table-check">
																		<label className="custom-checkbox">
																			<input type="checkbox" className="leadphone" value={val.leadid} onChange={(e) => this.changePhoneCheckBox(e, val)} />
																			<span className="checkmark"></span>
																		</label>
																	</div>
																	</td>
																	<td title={val.leadid}>{val.leadid}</td>
																	<td title={val.email}>{val.email}</td>
																	<td title={val.title}>{val.title}</td>
																	<td title={val.firstname}>{val.firstname}</td>
																	<td title={val.lastname}>{val.lastname} </td>
																	<td title={val.phone1}>{val.phone1}</td>
																	<td title={val.sms_send_count}>{val.sms_send_count}</td>
																	<td title={val.email_send_count}>{val.email_send_count}</td>


																	<td title={val.directLink}>
																		{val.directLink != "" && val.directLink != null && val.directLink != undefined ? <a href={val.directLink + "?sms=21"} style={{ 'marginLeft': '5px' }} target="_blank" className="btn-round btn-edit-icon">
																			<i className="fa fa-link"></i>
																		</a> : ""}

																	</td>
																	<td title={val.CBN}>{val.CBN}</td>

																</tr>
															)
														}) :
														<tr >

															<td colSpan="16" style={{ textAlign: "center" }}>
																{this.state.isloading ? <ClipLoader
																	css={override}
																	sizeUnit={"px"}
																	size={50}
																	color={'#123abc'}
																	loading={this.state.isloading}
																/> : "No Record Found"}
															</td>

														</tr>
												}

											</tbody>
										</table>
										{/* </InfiniteScroll> */}
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>







			</React.Fragment>

		);
	}
}

export default LeadsListing;