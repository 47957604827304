import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import AllUserService from './../../services/alluser.service';
import ClipLoader from 'react-spinners/ClipLoader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AgentService from './../../services/agent.service';
import Select from 'react-select';
import { ENV } from './../../env';
import Notifications, { notify } from 'react-notify-toast';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import { css } from '@emotion/core';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			errors: '',
			path: ENV.url,
			role: '',
			externalLink: '',
			startDate: moment().startOf('month'),
			endDate: moment().endOf('month'),
			emailList: [],
			isloading: true
		}
		this.alluserService = new AllUserService();
		this.agentService = new AgentService();
	}
	componentDidMount() {
		let user = JSON.parse(localStorage.getItem('user'));

		if (user) {
			let roleStatus = user.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						isloading: false,
						user: result,
						role: user.role
					})
				}
			}).catch(err => {
				console.log(err)
			})
		}
		this.userProfile();
	}

	userProfile() {
		var th = this;
		this.setState({
			isloading: true,
		});
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.agentService.GetAllReport().then(data => {
				th.setState({
					emailList: data.agentReport,
					isloading: false,
				});

			}).catch(err => {
				console.log(err);

			})
		} else {
			this.props.history.push('/');
			localStorage.clear();

		}

	}


	updateProfile(e) {
		e.preventDefault();
		this.setState({ errors: '' });
		this.setState({
			isloading: true,
		});
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			var data = {
				startDate : this.state.startDate ,
				endDate:  this.state.endDate
			}
			this.agentService.createAgentReport(data).then(data => {
				this.userProfile();
				notify.show(data.message, "success", 2000);
			}).catch(error => {
				console.log(error)
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}
	}
  
	handleEvent = (e, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate
        })
	}
	
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div className="content-page Setting-tab tab-structure" id="setting01">
					<div className="setting-fields field-set">
						<div className="row">
							<div className="col-md-9 col-sm-6 col-xs-12">
								<div className="page-heading">
									<h3>Agent Report</h3>
								</div>
							</div>
						</div>
						<div className="content-header">
							<div className="accordian-expand">
								<div className="row">
									<div className="col-xs-12 col-sm-12">
										<div className="listing-filter-search gobal-search">
											<div className="custom-datepicker">
												<DateRangePicker id="selectDate" autoApply={false} locale={{ format: "DD/MM/YYYY" }} startDate={this.state.startDate} endDate={this.state.endDate} onEvent={this.handleEvent} >
													<input type="button" name="daterange" value={this.state.startDate.format("MM/DD/YYYY") + " - " + this.state.endDate.format("MM/DD/YYYY")} className="date-input" />
												</DateRangePicker>
											</div>
											<div className="form-group btn-filed">
												<button type="button" id="diableCreateLeadButton" className="btn-purple btn-style-md btn-round mr-5" onClick={(e) => this.updateProfile(e)}>Create Today Report</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''}

						<div className="form-group row" id="dynamic">
							{this.state.emailList.length != 0 ? this.state.emailList.map((val, i) => {
								return (
									<div className="col-md-3">
										<div className="field-add" key={i}>
											<div className="field-add-input mr-10" id="dynamic">
												<a href={val.csvName}> CSV Link  {moment(val.createdAt).format('MM/DD/YYYY')}  </a>
											</div>
										</div>
									</div>
								)
							}) : <div className="col-md-3">
									<div className="field-add">
										No Record Found !
							</div>
								</div>}
						</div>

						{this.state.isloading ? <ClipLoader
							css={override}
							sizeUnit={"px"}
							size={50}
							color={'#123abc'}
							loading={this.state.isloading}
						/> : ""}
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(Profile)
