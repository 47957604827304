import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';
import LeadsListingService from '../../services/leadslisting.service';
import AllUserService from './../../services/alluser.service';
import Switch from "react-switch";
import Modal from 'react-responsive-modal';
import MailGunService from '../../services/mailgun.service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from '@emotion/core';
import { ENV } from './../../env';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;


class AllCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			allUsers: [],
			selectedMailgunUserID: '',
			csvListing: [],
			csvListingDuplicate: [],
			path: ENV.url,
			permission: '',
			usersearch: '',
			editData: '',
			siteModal: false,
			pandaLoader: true,
			linkExpiryDate: new Date(new Date().getTime() + (2 * 24 * 60 * 60 * 1000)),
			link: [],
			status: 'inactive',
			id: '',
			userStatus: '',
			isloading: false,


		}
		this.leadlistingService = new LeadsListingService();
		this.alluserService = new AllUserService();
		this.mailgunService = new MailGunService();





	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		// if (roleStatus) {
		// 	this.logout();
		// }
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		if (localStorage.getItem('token')) {
			this.allUsersAPI();
			this.allUserCSV();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}


	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}

	logout() {
		//console.log("helo")
		localStorage.clear();
		this.props.history.push('/');
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.csv === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			console.log("all users", data)

			if (data.code === 200) {
				this.setState({
					allUsers: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	allUserCSV() {
		this.setState({
			isloading: true
		})

		this.leadlistingService.allCSV().then(data => {
			debugger
			console.log(data, "-------")
			// notify.show(data.message, 'success', 3000)

			if (data.code === 200) {
				debugger
				console.log(data.data);
				this.setState({
					csvListing: data.data,
					csvListingDuplicate: data.data
				});
				// notify.show(data.message, "success", 3000)
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				debugger
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})

	}

	search(e) {
		e.preventDefault();

		if (this.state.selectedMailgunUserID !== "") {
			this.setState({
				isloading: true
			})
			this.leadlistingService.getSingUserCSV(this.state.selectedMailgunUserID).then(data => {

				console.log(data, "-------")
				if (data.code === 200) {
					this.setState({
						csvListing: data.data,
						isloading: false
					})
					// notify.show(data.message, 'success', 3000)

				}
				else if (data.code === 404) {
					this.setState({
						csvListing: [],
						isloading: false
					})
					// notify.show(data.message, 'success', 3000)
				}
			}).catch(err => {
				console.log(err)
			})
		} else {
			this.allUserCSV();
		}

	}

	conformDelete(id) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this file?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.deleteCSV(id); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	deleteCSV(id) {
		this.leadlistingService.deleteCSV(id).then(data => {
			if (data.code === 200) {
				// notify.show(data.message, 'success', 3000);
				this.allUserCSV();

			}
		}).catch(err => {
			console.log(err)
		})
	}

	download(id) {
		this.leadlistingService.downloadCSV(id).then(data => {
			console.log(data, "csv")
		})
	}

	oncloseSiteModal = () => {
		this.setState({
			siteModal: false,
		});
	};

	reset() {
		this.allUserCSV();
		$("#selectuser").val($("#target option:first").val());
	}

	editCsv(id) {
		this.setState({
			siteModal: true
		})
		this.leadlistingService.getSingleCSV(id).then(data => {
			if (data.code === 200) {
				debugger;
				let linkExpiryDate;
				if (typeof data.data.linkExpiryDate === "undefined") {
					linkExpiryDate = new Date();
				}
				else {
					linkExpiryDate = new Date(data.data.linkExpiryDate)
				}

				this.setState({
					status: data.data.status,
					linkExpiryDate: linkExpiryDate,
					link: data.data.link,
					id: data.data._id
				})
				//	console.log('data.data.iddata.data.iddata.data.iddata.data.id', data.data._id)
			}
		}).catch(err => {
			console.log(err)
		})
	}

	onchangeCsvActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	submitUpdate() {
		var current = this;
		this.setState({
			isloading: true
		})
		let body = {
			status: this.state.status,
			linkExpiryDate: this.state.linkExpiryDate
		}
		this.leadlistingService.updateCSV(this.state.id, body).then(data => {
			if (data.code === 200) {
				this.oncloseSiteModal();
				let selectedCSV = JSON.parse(localStorage.getItem('bodyselectedCSV'));
				let bodyCSVID = JSON.parse(localStorage.getItem('bodyCSVID'));
				let bodysiteID = JSON.parse(localStorage.getItem('bodysiteID'));
				let formselected = JSON.parse(localStorage.getItem('FormbodyselectedCSV'));
				let formcsvid = JSON.parse(localStorage.getItem('FormbodyCSVID'));
				console.log(selectedCSV, this.state.id, "--------------------------0000")
				if (data.data.status === 'inactive' && selectedCSV === current.state.id) {
					console.log("yes it is 1")
					//localStorage.removeItem('bodysiteID');
					localStorage.removeItem('bodyselectedCSV');
				} else if (data.data.status === 'inactive' && bodyCSVID === current.state.id) {
					console.log("yes it is 2")

					localStorage.removeItem('bodyCSVID');
				} else if (data.data.status === 'inactive' && bodysiteID === current.state.id) {
					localStorage.removeItem('bodysiteID');
					console.log("yes it is 3")

				} else if (data.data.status === "inactive" && formselected === current.state.id) {
					localStorage.removeItem('FormbodyselectedCSV');
				} else if (data.data.status === "inactive" && formcsvid === current.state.id) {
					localStorage.removeItem('FormbodyCSVID');

				}
				current.setState({
					status: 'inactive',
					isloading: false
				})
				current.allUserCSV();

				setTimeout(() => {

					notify.show(data.message, "success", 2000);

				}, 1000);

			}
		}).catch(err => {
			console.log(err)
			current.setState({
				isloading: false
			})
		})
	}

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.csvListingDuplicate;

			let data = myArray.filter(x => {
				return x.name.replace(/\s+/g, '').toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())

			})
			current.setState({
				csvListing: data
			})
		})
	}

	render() {
		console.log(this.state.role);
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""}

				{this.state.role === 'admin' ?
					<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>CSV Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="head-search">
											<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
										</div>
										{/* <div className="add-btn text-right">
										<button type="button" className="btn-refresh btn-purple btn-round">
											<i class="fa fa-refresh fa-spin"></i>
										</button>
									</div> */}
									</div>
								</div>
							</div>
						</div>
						<div className="row mb-20">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div class="add-btn sent-btns display-flex">
									{/* <div class="form-group mr-20">
								

									{
										this.state.role == 'admin' ?

											<div class="dropdown lead-sentdrop custom-select mr-10">
												<select class="btn-dropdown dropdown-toggle btn-dropsent" id="selectuser" name="selectedMailgunUserID" onChange={(e) => this.onchange(e)} data-toggle="dropdown">
													<option value="">Select User</option>
													{
														this.state.allUsers.length > 0 ?
															this.state.allUsers.map((val, key) => {
																return (
																	<option key={key} value={val._id}>{val.firstName + " " + val.lastName}</option>
																)
															}) : <option value="">No Record Found</option>
													}
												</select>
											</div>

											: ''
									}


									<div class="sendEmail" style={{ marginRight: "5px" }}>
										<a class="btn btn-purple btn-style-md btn-send" onClick={(e) => this.search(e)}>Search</a>

									</div>
									<div class="sendEmail">
										<a class="btn btn-purple btn-style-md btn-send" onClick={(e) => this.reset(e)}>Reset</a>

									</div>
								</div> */}
								</div>
							</div>
						</div>
						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>User add</th>
											<th>Is Header</th>
											<th>CSV Name</th>
											<th>Created Date</th>
											<th>Status</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													if (val.user) {
														return (
															<tr key={key}>
																<th title={key + 1}>{key + 1}</th>
																<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>
																<td title={val.isHeader ? "True" : 'False'}>{val.isHeader ? "True" : 'False' || ""}</td>
																<td title={val.name}>{val.name || ""} </td>
																<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
																<td>{val.status === 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}</td>

																<td width="230">
																	<div className="table-option-btns">
																		{/* <a type="button" href={this.state.path + val.file}   className="btn btn-table mr-5"  download  onClick={()=> this.download(val._id)} >
																	  <i className="fa fa-pencil-square-o" ></i> Download
																  </a> */}
																		<a type="button" href={this.state.path + val.file} className="btn btn-table mr-5" download  >
																			<i className="fa fa-pencil-square-o" ></i> Download
																  </a>
																		{/* <button type="button" className="btn btn-table" onClick={() => this.conformDelete(val._id)}>
																		<i className="glyphicon glyphicon-trash"></i> Delete
																  </button> */}
																	</div>
																</td>
															</tr>
														)
													}
												})
												: <tr >
													<td colSpan="7" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}

													</td>
												</tr>
										}


									</tbody>
								</table>
							</div>
						</div>
					</div> : ''
				}


				{this.state.role === 'user' || this.state.role === 'subUser' ?
					<div className="setting-fields field-set" style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>CSV Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="head-search">
											<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
										</div>
									</div>
								</div>
							</div>




						</div>


						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed user_csv_lisitng_tbl">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>User</th>
											<th>Is Header</th>
											<th>CSV Name</th>
											<th>Created Date</th>
											<th>Status</th>
											<th width="230">Action</th>

										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													return (
														<tr key={key}>
															<th title={key + 1}>{key + 1}</th>
															<td> {val.user ? val.user.firstName + "" + val.user.lastName : "N/A"}  </td>
															{/* <td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td> */}
															<td title={val.isHeader ? "True" : 'False'}>{val.isHeader ? "True" : 'False'}</td>
															<td title={val.name}>{val.name}</td>
															<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
															<td>{val.status === 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}</td>
															{
																this.state.role === 'subUser' && this.state.permission.csv_Status !== 'RW' ?
																	<td width="230">
																		{/* href={this.state.path + val.file} */}
																		<div className="table-option-btns">
																			<a type="button" href={this.state.path + val.file} className="btn btn-table mr-5" download onClick={() => this.download(val._id)}>
																				<i className="fa fa-pencil-square-o" ></i> Download
																		</a>
																		</div>
																	</td>
																	:
																	<td width="230">
																		{/* href={this.state.path + val.file} */}
																		<div className="table-option-btns">
																			<button type="button" className="btn btn-table mr-5" onClick={(e) => this.editCsv(val._id)} >
																				<i className="fa fa-pencil-square-o" ></i> Edit
																		</button>
																			<button type="button" className="btn btn-table mr-5" onClick={() => this.conformDelete(val._id)} >
																				<i className="fa fa-pencil-square-o" ></i> Delete
																		</button>
																			<a type="button" href={this.state.path + val.file} className="btn btn-table" download onClick={() => this.download(val._id)}>
																				<i class="fa fa-download" aria-hidden="true"></i> Download
																		</a>
																		</div>
																	</td>
															}
														</tr>
													)

												})
												: <tr >

													<td colSpan="7" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}
													</td>

												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</div> : ''
				}

				<Modal open={this.state.siteModal} onClose={this.oncloseSiteModal} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.oncloseSiteModal()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Edit CSV</h4>
					</div>
					<div className="modal-body">

						<div className="form-group row">
							<label className="col-md-4">Link</label>
							<div className="col-md-8">
								<div className="tag-wrap edit-csv-tags">
									{this.state.link.length > 0 ?
										this.state.link.map((val, key) => {
											return (
												<span className="tag tag-label"><a href={val} target="_blank" key={key} >{val}</a></span>
											)
										}) : <span className="tag tag-label">No Link</span>
									}
								</div>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-md-4">Link expiry date</label>
							<div className="col-md-8">
								<DatePicker className="form-control"
									selected={this.state.linkExpiryDate}
									onChange={this.onChangedate}
								/>

							</div>
						</div>

						<div className="form-group row">
							<label className="col-md-4">Status</label>
							<div className="col-md-8">
								<Switch name="status"
									onChange={(e) => this.onchangeCsvActive(e)} checked={this.state.status === 'active' ? true : false} />
							</div>
						</div>
					</div>


					<div className="modal-footer">
						{this.state.isloading === true ?
							<React.Fragment>
								<div className="btn-process">
									<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
									<p>Processing...</p>
								</div>
							</React.Fragment>
							:
							<React.Fragment>
								<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.submitUpdate()}>Save</button>
								<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.oncloseSiteModal}>Close</button>
							</React.Fragment>
						}


					</div>
				</Modal>

			</React.Fragment >
		);
	}
}

export default AllCSV;