import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import AllUserService from '../../services/alluser.service';
import LeadsListingService from '../../services/leadslisting.service';
import Notifications, { notify } from 'react-notify-toast';
import Switch from "react-switch";
import DateTimePicker from 'react-datetime-picker';
import Select from 'react-select';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";

import Modal from 'react-responsive-modal';

import './schedular.css';
import './schedular.scss';
import SchedularService from '../../services/schedular.service';
// __ Payment Component __//
// ______________________ //
class Schedular extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: '',
			userStatus: '',
			date :new Date(),
			minDate : new Date(),
			reminder :'',
			eventname : '',
			errors : '',
			selection: 0,  //to reset selected values in multiselect

			selectedEmailID: "",
			emailtype: "",
			selectedSMSID: "",
			smstype: "",
			type:0,

			smstemplate: [],
			emailtemplate: [],
			smstypeList: [],
			emailtypeList: [],
			selectedCSV : [],
			existingCSV : [],


			activeSMS : false,
			activeEmail : false,
			eventModal: false,

			searchDropdown : "",
			event: {
				title: "",
				description:'',
				start: new Date()
			  }
		}
		this.alluserService = new AllUserService();
		this.leadlistingService = new LeadsListingService();
		this.schedularService = new SchedularService();
	}

	// ___ Lifecycle hooks ___//
	// ______________________ //

	componentDidMount() {
		window.scrollTo(0, 0);

		this.isUserActive();
		this.getAllCSV();
		this.getSMStype();
		this.getEmailtype();
		this.smsTemplate();
		this.emailTemplate();
	}

	// ___ Function call on load component ___//
	// _____________________________________ //
	isUserActive(){
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			let userId = user.id;
			this.alluserService.editUser(userId).then(data => {
				if (data.code === 200) {
					this.setState({
						userStatus: data.data,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}else{
			this.props.history.push('/');
			localStorage.clear();
		}
	}

	getAllCSV() {
		this.leadlistingService.allCSV().then(data => {
			if (data.code === 200) {
				let arr = [];
				for (var i = 0; i < data.data.length; i++) {
					arr.push({
						label: data.data[i].name,
						//   value: false,
						value: data.data[i]._id,
					});
				}
				this.setState({
					existingCSV: arr,
				});
			}
			if (data.code === 404) {
				this.setState({
					existingCSV: [],
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	getSMStype() {
		this.leadlistingService.getSMStype().then(data => {
			if (data.code === 200) {
				this.setState({
					smstypeList: data.data
				})
			}
		})
	}

	getEmailtype() {
		this.leadlistingService.getEmailtype().then(data => {
			if (data.code === 200) {
				this.setState({
					emailtypeList: data.data
				})
			}
		})
	}

	smsTemplate() {
		this.leadlistingService.allSmSTemplate().then(data => {
			if (data.code === 200) {
				this.setState({
					smstemplate: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	emailTemplate() {
		this.leadlistingService.allEmailTemplate().then(data => {
			if (data.code === 200) {
				this.setState({
					emailtemplate: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	
	//_________ Onchange Functions ________//
	// __________________________________ //
	

	onChangeCalendar = date => this.setState({ date })
 
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeSMSswitch(check) {
		this.setState({
			activeSMS: check,
		}, () =>{
			if(this.state.activeSMS && this.state.activeEmail){
				this.setState({
					type: 3
				})
			}else if(this.state.activeEmail === true && this.state.activeSMS === false){
				this.setState({
					type :1
				})
			}else if(this.state.activeEmail === false && this.state.activeSMS === true){
				this.setState({
					type:2
				})
			}
		});
	}

	onChangeEMAILswitch(check) {
		

		this.setState({
			activeEmail: check,
		}, () =>{
			if(this.state.activeSMS && this.state.activeEmail){
				this.setState({
					type: 3
				})
			}else if(this.state.activeEmail === true && this.state.activeSMS === false){
				this.setState({
					type :1
				})
			}else if(this.state.activeEmail === false && this.state.activeSMS === true){
				this.setState({
					type:2
				})
			}
		});
	}

	handleInputChange = (searchDropdown, e) => {
        if (e.action === "input-change") {
		  this.setState({ searchDropdown});
		  
        }
      };

      selectedOption(array){
          console.log(array)
          let ids = [];
          if(array){
            array.forEach(element => {
                ids.push(element.value)
            });
          }
         
         this.setState({
			 selectedCSV : ids,
			 selection: array  
         })
      }

	  reminder(e) {
		const selectedIndex = e.target.options.selectedIndex;
		//let key = e.target.options[selectedIndex].getAttribute('name');
		let val = Number(e.target.value);
		this.setState({
			reminder: val
		})
	}

	handleEventClick = ({ event, el }) => {
		console.log("kl")
		this.opneEvent();

		this.setState({ event });
	  };

	
	  closeEvent = (e) => {
		this.setState({ eventModal: false });
	};

	opneEvent = () => {
		this.setState({ eventModal: true });
	};


	
	//_________ Submit Deleted Edit functions ________//
	// _____________________________________________ //
	PostSchedular(e){
		debugger
		e.preventDefault();
		const {eventname, selectedCSV, selectedEmailID, selectedSMSID, smstype, emailtype, date, reminder} = this.state;
		if(eventname === ''){
			this.setState({
				errors: 'Please Enter Event Name'
			})
			return;
		}else if(selectedCSV.length === 0){
			this.setState({
				errors: 'Please Select CSV'
			})
			return;
		}else if(this.state.activeEmail){
			if(selectedEmailID===""){
				this.setState({
					errors: 'Please Select Email Template'
				})
				return;
			}if(emailtype===""){
				this.setState({
					errors: 'Please Select Email Type'
				})
				return;
			}
			
		}
		if(this.state.activeSMS){
			if(selectedSMSID === ''){
				this.setState({
					errors: 'Please Select SMS Template'
				})
				return;
			}else if(smstype === ''){
				this.setState({
					errors: 'Please Select SMS Type'
				})
				return;
			}
			
		}
		 if(date === '' || date === null){
			this.setState({
				errors: 'Please Select Date/Time'
			})
			return;
		}else if(reminder === '' || reminder === 0){
			this.setState({
				errors: 'Please Select Reminder'
			})
			return;
		}else if(this.state.activeEmail=== false && this.state.activeSMS===false){
			this.setState({
				errors: 'Please Active Email / SMS'
			})
			return;
		}else{
			this.setState({
				errors : ''
			})
			this.reset();
			
			let body = {
				csvId: this.state.selectedCSV,
				remainderStatus: this.state.reminder,
				date: this.state.date,
				title:this.state.eventname,
				remainderStatus:this.state.reminder,
				smsTemplate:this.state.selectedSMSID,
				emailTemplate:this.state.selectedEmailID,
				type:this.state.type,
				emailServiceType:this.state.emailtype,
				smsServiceType:this.state.smstype,
			}
			this.schedularService.addEvent(body).then(data=>{
				if(data.code === 201){
					notify.show(data.message, 'success', 3000)
					this.reset();
				}
			}).catch(err => {
				console.log(err)
			})
		}

	}
	reset(){
		this.setState({
			selectedCSV: [],
			reminder: '',
			date: null,
			eventname: '',
			selectedSMSID: '',
			selectedEmailID: '',
			type: 0,
			emailtype: '',
			smstype: '',
			activeSMS : false,
			activeEmail:false,
			selection: 0 // to reset select  value
		});
	}
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
			  
				<div className="setting-fields field-set">
					<div className="content-header">
						<div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
							<div className="page-heading">
										<h3>Schedular</h3>
									</div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                            </div>
                        </div>
					</div>
					<div className="schedular-body">
					<div className="row">
					{Object.keys(this.state.errors).length > 0 && this.state.errors ?
						<div className="alert alert-danger">
							{this.state.errors}
						</div>
						: ''}
                            <div className="col-md-6 col-sm-6 col-xs-12">
								<div className="form-group row">
									<label className="col-md-4">Event Name</label>
									<div className="col-md-8">
										<input type="text" className="form-control" placeholder="Event Name" name="eventname" value={this.state.eventname} onChange={(e) => this.handleChange(e)} />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-4">Select Date/Time</label>
									<div className="col-md-8">
										<DateTimePicker
											onChange={this.onChangeCalendar}
											value={this.state.date}
											minDate={this.state.minDate}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-md-4">Reminder Status</label>
									<div className="col-md-8">
										<div className="adv-filter">
											<ul className="outer-adv-filter">

												<li className="form-group lead-drop">
													<label>Reminder Status</label>
													<div className="dropdown lead-status custom-select">
														<select id="reminderj" value={this.state.reminder} onChange={(e) =>
															this.reminder(e)} >
															<option value="" name="">Select</option>
															<option value="1" name="reminderEmail">Sent Email</option>
															<option value="2" name="reminderEmail">No Email Sent</option>
															<option value="3" name="reminderSMS">Sent SMS</option>
															<option value="4" name="reminderSMS">No SMS Sent</option>
															<option value="5" name="noSignedLead">Unsigned Leads</option>
														</select>
													</div>
												</li>
											</ul>
										</div>

									</div>
								</div>


								<div className="btn-start mrTop-40">
								<button className="btn-search btn-style-md round-btn mr-10" onClick={(e) => this.PostSchedular(e)} >Start</button>
							</div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-12">
									<div className="form-group multi-select-input">
										<Select
											isMulti
											name="colors"
											value = {this.state.selection}
											placeholder="Select CSV..."
											options={this.state.existingCSV}
											className="basic-multi-select"
											classNamePrefix="select"
											closeMenuOnSelect={false}
											onChange={value => this.selectedOption(value)}
											onInputChange={this.handleInputChange}
											inputValue={this.state.searchDropdown}
										/>
									</div>

								<div className="add-btn sent-btns mr-20">
									{this.state.reminder === 1 || this.state.reminder === 2 || this.state.reminder===5?
										<React.Fragment>
											<div className="form-group">
												<label>Select Email Template</label>
												<div className="dropdown lead-sentdrop custom-select mr-10">
													<select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedEmailID" value={this.state.selectedEmailID} onChange={(e) =>
														this.handleChange(e)} data-toggle="dropdown">
														<option value="">Select Email Template</option>
														{
															this.state.emailtemplate.length > 0 ?
																this.state.emailtemplate.map((val, key) => {
																	return (
																		<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
																	)
																}) :
																<option value="">No Record Found</option>
														}
													</select>
												</div>
											</div>
											<div className="form-group mr-20">
												<label>Select Email Type</label>
												<div className="dropdown lead-sentdrop custom-select mr-10">
													<select className="btn-dropdown dropdown-toggle btn-dropsent" name="emailtype" value={this.state.emailtype} onChange={(e) =>
														this.handleChange(e)} data-toggle="dropdown">
														<option value="">Select Email Type</option>
														{
															this.state.emailtypeList.length > 0 ?
																this.state.emailtypeList.map((val, key) => {
																	return (
																		<option value={val.type} title={val.name} key={key}>{val.name}</option>
																	)
																}) :
																<option value="">No Record Found</option>
														}
													</select>
												</div>

												<div className="sendEmail">
													<Switch name="activeEmail"
														onChange={(e) => this.onChangeEMAILswitch(e)} checked={this.state.activeEmail} />
												</div>
											</div>

										</React.Fragment>
										: ""}
									{this.state.reminder === 3 || this.state.reminder === 4 || this.state.reminder===5 ?
										<React.Fragment>
											<div className="form-group">
												<label>Select SMS Template</label>
												<div className="dropdown lead-sentdrop custom-select mr-10">
													<select className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedSMSID" value={this.state.selectedSMSID} onChange={(e) =>
														this.handleChange(e)} data-toggle="dropdown">
														<option value="">Select SMS Template</option>
														{
															this.state.smstemplate.length > 0 ?
																this.state.smstemplate.map((val, key) => {
																	return (
																		<option value={val._id} title={val.title} key={key}>{val.title.length < 15 ? val.title : val.title.slice(0, 15) + " ..."}</option>
																	)
																}) :
																<option value="">No Record Found</option>
														}
													</select>
												</div>
											</div>
											<div className="form-group">
												<label>Select SMS Type</label>
												<div className="dropdown lead-sentdrop custom-select mr-10">
													<select className="btn-dropdown dropdown-toggle btn-dropsent" id="smstype" name="smstype" value={this.state.smstype} onChange={(e) =>
														this.handleChange(e)} data-toggle="dropdown">
														<option value="">Select SMS Type</option>
														{
															this.state.smstypeList.length > 0 ?
																this.state.smstypeList.map((val, key) => {
																	return (
																		<option value={val.type} title={val.name} key={key}>{val.name.length < 15 ? val.name : val.name.slice(0, 15) + " ..."}</option>
																	)
																}) :
																<option value="">No Record Found</option>
														}
													</select>
												</div>
												<div className="sendEmail">
													<Switch name="activeSMS"
														onChange={(e) => this.onChangeSMSswitch(e)} checked={this.state.activeSMS} />
												</div>
											</div>

										</React.Fragment>
										: ""}
								</div>
                            </div>
                        </div>

						<div className="schedular">
							<FullCalendar eventRender={this.handleEventRender}
								eventClick={this.handleEventClick}
								header={{
									center: "title",
									right: "today prev,next",
									left: "dayGridMonth, timeGridWeek"
								}}
								defaultView="dayGridMonth"
								plugins={[timeGridPlugin, dayGridPlugin]}
								events={[
									{ title: 'event 1 op', description: 'zong1', date: '2019-04-01' },
									{ title: 'event 2 pl', description: 'zong2', date: '2019-04-02' }
								]} />
						</div>

					</div>
				</div>

				<Modal className="modal modal-style fade" open={this.state.eventModal} onClose={this.closeEvent}>
					<div className="modal-header">
						<h4 className="modal-title">Warning!</h4>
					</div>
					<div className="modal-body">
						<div className="modal-termsandconditions">
							<div className="form-group">
								<p>You have not configured bitly account, Are you sure you want to send SMS without bitlly ?</p>
								{this.state.event.title}
								{this.state.event.extendedProps ? this.state.event.extendedProps.description: "N/A"
								}
								{this.state.event.start.toISOString()}
							</div>

						</div>
					</div>
				
				</Modal>

			</React.Fragment>
		);
	}
}

export default withRouter(Schedular)
