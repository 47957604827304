import React from "react";
import { Route, Switch } from "react-router-dom";
import Profile from './container/profile/profile';
import Dashboard from './container/dashboard/dashboard';
import Lead_count from './container/Lead_count/lead_sms_credit';
import SMTP from './container/smtp/smtp';
import Smssetting from './container/smssetting/smssetting';
import EmailTemplate from './container/emailtemplate/emailtemplate';
import SmsTemplate from "./container/smstemplate/smstemplate";
import Sites from './container/sites/sites';
import LeadsListing from './container/leadslisting/leadslisting';
import Login from './container/login/login';
import {PrivateRoute} from './PrivateRoute';
import EditEmailTemplate from "./container/editemailtemplate/editemailtemplate";
import EditSmsTemplate from './container/editsmstemplate/editsmstemplate';
import EditSite from './container/editsite/editsite';
import My404Component from './container/my404/my404';
import ALlUser from './container/alluser/alluser';
import EditCSV from './container/editCsv/editCsv';
import Permission from './container/permission/permission';
import EditCSVsite from './container/editCSVsite/editCSVsite';
import AllCSV from './container/allCsv/allcsv';
import Bank from './container/banks/banks';
import AllSignedCsv from './container/allCsv/allsignedcsv';
import SignatureStats from './container/signatureStats/states';
import BitlaySetting from './container/bitlaysetting/bitlaysetting'
import outBox from './container/outBox/outbox'
import home   from './staticPages/home/home'
import Logs from './container/logs/logs';
import Package from './container/package/package';
import GetPackage from './container/getpackage/getpackage';
import PaymentHistory from './container/paymentHistory/paymentHistory';
import Payment from './container/payment/payment';
import paymentSuccess from "./container/paymentSuccess/paymentSuccess";
import Schedular from "./container/schedular/schedular";
import billing from "./container/billing/billing";
import Form from "./container/form/form";
import editFormCsv from "./container/editFormCsv/editFormCsv";
import resetPassword from "./container/resetPassword/resetPassword";
import esignstats from "./container/esignstats/esignstats";
import reports from "./container/reports/reports";
import createLead from "./container/createLead/createLead";
import globalSearch from "./container/globalSearch/globalSearch";
import Unsubscribers from "./container/unsubscribers/unsubscribers";
import Templates from "./container/templates/templates";

import LeadsForCallCenter from './container/leadslisting_cc/leadslisting';
import AgentSubmit from './container/agentSubmit/agentSubmit.js';

import AgentEmail from './container/agentEmail/agentEmail';


import AgentReport from './container/csvAgentReport/agentReport';

import Campaigns from './container/campaigns/campaigns';
import AddCampaigns from './container/campaigns/addcampaigns';
import EditCampaigns from './container/campaigns/editcampaigns';


import unSubsribeAgent from './container/unsubscribers/unsubscribeAgent';

import EmailStatus from './container/emailSms/email';
import SmsStatus from './container/emailSms/sms';


export default () =>
  <Switch>
    <Route path="/" exact component={home} />
    <Route path="/login" exact component={Login} />
    <Route  path="/resetPassword" exact component={resetPassword} />
    <PrivateRoute path="/profile"  component={Profile} />
    <PrivateRoute path="/dashboard"  component={Dashboard} />
    <PrivateRoute path="/smtp"  component={SMTP} />
    <PrivateRoute path="/sms-setting"  component={Smssetting} />
    <PrivateRoute path="/emailtemplate"  component={EmailTemplate} />
    <PrivateRoute path="/smstemplate"  component={SmsTemplate} />
    <PrivateRoute path="/sites"  component={Sites} />
    <PrivateRoute path="/leadslisting"  component={LeadsListing} />
    <PrivateRoute path="/editemail"  component={EditEmailTemplate} />
    <PrivateRoute path="/editsms"  component={EditSmsTemplate} />
    <PrivateRoute path="/editsite"  component={EditSite} />
    <PrivateRoute path="/alluser"  component={ALlUser} />
    <PrivateRoute path="/roleassign"  component={Permission} />
    <PrivateRoute path="/editCsv/:leadid/:csvid" component={EditCSV}/>
    <PrivateRoute path="/editFormCsv/:leadid/:csvid" component={editFormCsv}/>
    <PrivateRoute path="/editcsvsite/:leadid/:siteid" component={EditCSVsite}/>
    <PrivateRoute path="/csvlisting" component={AllCSV}/>
    <PrivateRoute path="/banklisting" component={Bank}/> 
    <PrivateRoute path="/unsubscribers/all" component={Unsubscribers}/>
    <PrivateRoute path="/stats/csvlisting" component={AllSignedCsv}/>
    <PrivateRoute path="/stats/signature" component={SignatureStats}/>
    
    <PrivateRoute path="/templatelisting" component={Templates}/> 

    <PrivateRoute path="/shortener-setting" component={BitlaySetting}/>
    <PrivateRoute path="/payment" component = {Payment} />
    <PrivateRoute path="/payment-success" component = {paymentSuccess} />
    <PrivateRoute path="/schedular" component = {Schedular} />
    <PrivateRoute path="/billing" component = {billing} />
    <PrivateRoute path="/form" component = {Form} />
    <PrivateRoute path="/esignstats" component = {esignstats} />
    <PrivateRoute path="/reports" component = {reports} />
    <PrivateRoute path="/create-lead" component = {createLead} />
    <PrivateRoute path="/global-search" component = {globalSearch} />
    <PrivateRoute path="/global-search-leads" component = {LeadsForCallCenter} />
    
    <PrivateRoute path="/lead_count" component = {Lead_count} />
    
    
    <PrivateRoute path="/user/leadSubmit/:id" component = {AgentSubmit} />


   
    <PrivateRoute path="/user/emailStatus" component = {EmailStatus} />
    <PrivateRoute path="/user/smsStatus"   component = {SmsStatus} />

  
    
    <PrivateRoute path="/unSubsribeAgent/:id" component = {unSubsribeAgent} />

    <PrivateRoute path="/agentReport/email" component = {AgentEmail} />
  
    <PrivateRoute path="/agentReport" component = {AgentReport} />

    <PrivateRoute path="/campaigns" component = {Campaigns} />
    
    <PrivateRoute path="/add/campaign" component = {AddCampaigns} />

    <PrivateRoute path="/edit/campaign/:id" component = {EditCampaigns} />

    <PrivateRoute path="/payment-history" component = {PaymentHistory} />

    <PrivateRoute path="/outBox" component = {outBox} />
    <PrivateRoute path="/logs" component = {Logs} />
    <PrivateRoute path="/get-package" component = {GetPackage} />
    <PrivateRoute path="/package" component = {Package} />
  
   

    <Route path='*' exact={true} component={My404Component} />
  </Switch>