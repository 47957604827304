import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import './globalSearch.css';
import $ from 'jquery';
import Notifications, { notify } from 'react-notify-toast';
import GlobalSearchService from '../../services/globalSearch.service';
import ClipLoader from 'react-spinners/ClipLoader';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import { css } from '@emotion/core';
import LeadsListingService from '../../services/leadslisting.service';
import AllUserService from '../../services/alluser.service';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class GlobalSearch extends Component {
     constructor(props) {
          super(props);
          this.state={
              existingCSV:[],
              globalSearch : [],
              search:'',
              filter_fname : "",
              filter_lname : "",
              filter_email : "",
              filter_phone : "",
              zip : "",
              isloading : false,
              linktoCopy : '',
              csvID : '',
              searchDropdown : "",
              multiSelect: []
          }
        this.globalSearchService = new GlobalSearchService();
        this.leadlistingService = new LeadsListingService();
        this.alluserService = new AllUserService();
    }
    
   componentDidMount(){
		window.scrollTo(0, 0);

    let id = JSON.parse(localStorage.getItem('user'));
    if (id) {
        let userid = id.id;
        this.alluserService.editUser(userid).then(data => {
            if (data.code === 200) {
                let result = data.data;
                this.setState({
                    userStatus: result,
                })
                if (this.state.userStatus.status === 'inactive') {
                    localStorage.clear();
                    this.props.history.push('/');
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }else{
        localStorage.clear();
        this.props.history.push('/');
    }
    this.allUserCSV();
   }

   allUserCSV() {
    this.leadlistingService.allCSV().then(data => {
        if (data.code === 200) {
            let arr = [];
            for (var i = 0; i < data.data.length; i++) {
                arr.push({
                  label: data.data[i].name,
                //   value: false,
                  value: data.data[i]._id,
                });
              }
            this.setState({
                existingCSV: arr,
            });
        }
        if (data.code === 404) {
            this.setState({
                existingCSV: [],
            })
        }
    }).catch(err => {
        console.log(err)
    })
}
    onchange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    renderEyeButton(val) {
        if (typeof val != 'undefined' && val) {
            if (val.isLocked) {
                return (
                    <td>
                        <div className="table-option-btns">
                            {
                                val.pdf_url ? <a href="javascript:void(0)" onClick={(e) => this.eyslashButton(e)} className="btn-round btn-edit-icon">
                                    <i className="fa fa-eye-slash"></i>
                                </a> : ""
                            }
                        </div>
                    </td>
                )
            }
            else {
                return (
                    <td>
                        <div className="table-option-btns">
                            {
                                val.pdf_url ? <a href={val.pdf_url} target="_blank" className="btn-round btn-edit-icon">
                                    <i className="fa fa-eye"></i>
                                </a> : ""
                            }
                        </div>
                    </td>
                )
            }
        }
    }

    copyLink =async (e, lead)=> {
        let csv = await this.globalSearchService.getSingleCSV(lead.csvId);
        if (csv.code === 200) {
            this.setState({
                linktoCopy: csv.data.link.length > 0 ? csv.data.link[0] : '',
                csvID : csv.data._id
            })
        }

		let getLink = this.state.linktoCopy.split('?')[0];
		if (getLink !== "") {
			let CSVID = this.state.csvID;
			let requiredLink = getLink + '?csvId=' + CSVID + '&leadId=' + lead.leadid;
			var dummy = document.createElement("textarea");
			document.body.appendChild(dummy);
			dummy.value = requiredLink;
			dummy.select();
			document.execCommand("copy");
			document.body.removeChild(dummy);
			notify.show('Copied to clipboard', "success", 2000)
		} else {
			notify.show('Empty Link', "error", 2000)
		}
    }
    
    deleteLead(e, leadid, csvID){
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this file?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(leadid, csvID); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
    }
    
    conformDelete(id, csvID){        
		this.leadlistingService.deleteLead(csvID, id).then(data =>{
			if(data.code===200){
                let array = this.state.globalSearch;
                array.map((val, i) =>{
                    if(val.csvId === csvID && id === val.leadid){
                        array.splice(i,1)
                        this.setState({
                            globalSearch : array
                        })
                        
                    }
                })
                notify.show(data.message, "success", 3000);
			}
		})
    }
    
    editCSVrecord(e, lead) {
			this.props.history.push({
				pathname: '/editCsv/' + lead.leadid + '/' + lead.csvId,
				// state: { editEmail: data.data[0]}
			})
    }
    
    eyslashButton(e){
		e.preventDefault();
		notify.show('Please subscribe a package' , 'error', 3000)
	}

	reset(){
        this.setState({
            filter_email: '',
            filter_phone: '',
            filter_fname: '',
            filter_lname: '',
            zip: '',
            globalSearch: [],
        })
    }

    filterData(){
        if(
            this.state.filter_email === "" &&
            this.state.filter_phone === "" &&
            this.state.filter_fname === "" &&
            this.state.filter_lname === "" &&
            this.state.zip === "" 
            ){
                return;

            }

        let data ={
            "csvArray":this.state.multiSelect
        }
        let body = {
            filter_email: this.state.filter_email,
            filter_phone: this.state.filter_phone,
            filter_fname: this.state.filter_fname,
            filter_lname: this.state.filter_lname,
            zip: this.state.zip
        }
        this.setState({
            isloading : true
        })
        this.globalSearchService.globalSearch(body,data).then(data =>{
            if(data.code ===200){
                this.setState({
                    globalSearch : data.data,
                    isloading: false
                })
            }
        }).catch(err =>{
            console.log(err)
            this.setState({
                isloading : false
            })
        })
    }

    handleInputChange = (searchDropdown, e) => {
        if (e.action === "input-change") {
          this.setState({ searchDropdown });
        }
      };

      selectedOption(array){
          console.log(array)
          let ids = [];
          if(array){
            array.forEach(element => {
                ids.push(element.value)
            });
          }
         
         this.setState({
             multiSelect : ids
         })
      }
     render() { 
         return (
             
             <React.Fragment>
                 <Notifications options={{ zIndex: 200, top: '20px' }} />
                 <div className="content-header">
                     <div className="row">
                         <div className="col-md-4 col-sm-4 col-xs-12">
                             <div className="page-heading">
                                 <h3>Global Search </h3>
                             </div>
                         </div>
                     </div>
                     <div className="accordian-expand">
                         <div className="row">
                             <div className="col-xs-12 col-sm-12">
                                 <div className="listing-filter-search gobal-search">
                                    
                                     <div className="form-group text-filed">
                                         <input type="text" className="form-control" placeholder="First Name" name="filter_fname" value={this.state.filter_fname} onChange={(e) => this.onchange(e)} />
                                     </div>
                                     <div className="form-group text-filed">
                                         <input type="text" className="form-control" placeholder="Last Name" name="filter_lname" value={this.state.filter_lname} onChange={(e) => this.onchange(e)} />
                                     </div>
                                     <div className="form-group text-filed">
                                         <input type="text" className="form-control" placeholder="Email" name="filter_email" value={this.state.filter_email} onChange={(e) => this.onchange(e)} />
                                     </div>
                                     <div className="form-group text-filed">
                                         <input type="text" className="form-control" placeholder="Phone" name="filter_phone" value={this.state.filter_phone} onChange={(e) => this.onchange(e)} />
                                     </div>
                                     <div className="form-group text-filed">
                                         <input type="text" className="form-control" placeholder="Zip code" name="zip" value={this.state.zip} onChange={(e) => this.onchange(e)} />
                                     </div>
                                    <div className="form-group btn-filed">
                                        <button type="button" className="btn-search btn-style-md mr-10" onClick={(e) => this.filterData(e)}>Filter</button>
                                        <button type="button" className="btn-search btn-style-md" onClick={(e) => this.reset(e)}>Reset</button>
                                    </div>
                                 </div>
                                 <div className="gobal-search ">
                                    <div className="form-group multi-select-input">
                                         <Select
                                             isMulti
                                             name="colors"
                                             options={this.state.existingCSV}
                                             className="basic-multi-select"
                                             classNamePrefix="select"
                                             closeMenuOnSelect={false}
                                             onChange = {value => this.selectedOption(value)}
                                             onInputChange={this.handleInputChange}
                                             inputValue={this.state.searchDropdown}
                                         />
                                        </div>
                                 </div>
                             </div>
                         </div>
                     </div>

                 </div>
                 <table className="table table-hover table-striped table-bordered table-condensed">
                     <thead className="thead-light">
                         <tr>
                             <th>CSV Name</th>
                             <th>Doc Ref</th>
                             <th>Lead ID</th>
                             <th>Email</th>
                             <th>Title</th>
                             <th>First Name</th>
                             <th>Last Name</th>
                             <th>Phone</th>
                             <th>SMS Counts</th>
                             <th>Email Counts</th>
                             <th >PDF</th>
                             <th>Views</th>
                             {/* <th>Action</th> */}
                         </tr>
                     </thead>
                     <tbody>
                         {
                             this.state.globalSearch.length > 0 ?
                                 this.state.globalSearch.map((val, key) => {
                                     return (
                                         <tr key={key} style={{
                                             background: val.sms_send_count >
                                                 0 || val.email_send_count > 0 ? "#c4d0c4" : "#ffffff"
                                         }} >
                                             <td title={val.csvName} >{val.csvName}</td>
                                             <td title={val.Ref} >{val.Ref}</td>
                                             <td title={val.leadid} >{val.leadid}</td>
                                             <td title={val.email}  >{val.email}</td>
                                             <td title={val.title} >{val.title}</td>
                                             <td title={val.firstname} >{val.firstname}</td>
                                             <td title={val.lastname} >{val.lastname} </td>
                                             <td title={val.phone1} >{val.phone1}</td>
                                             <td title={val.sms_send_count} >{val.sms_send_count || '0'}</td>
                                             <td title={val.email_send_count} >{val.email_send_count || '0'}</td>
                                             {
                                                 this.renderEyeButton(val)
                                             }
                                             <td>{val.page_visited}</td>
                                             {/* <td>
                                                 <div className="table-option-btns">
                                                        <button type="button" onClick={(e) => this.editCSVrecord(e, val)} className="btn-round btn-edit-icon">
                                                            <i className="fa fa-edit"></i>
                                                        </button>
                                                        <button type="button" onClick={(e) => this.deleteLead(e, val.leadid, val.csvId)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                        <button type="button" onClick={(e) => this.copyLink(e, val)} style={{ marginLeft: "5px" }} className="btn-round btn-edit-icon">
                                                            <i className="fa fa-files-o"></i>
                                                        </button>
                                                 </div>
                                             </td> */}
                                         </tr>
                                     )
                                 }) :
                                 <tr >
                                     <td colSpan="14" style={{ textAlign: "center" }} >
                                         {this.state.isloading === true ? 
                                         <ClipLoader
                                         css={override}
                                         sizeUnit={"px"}
                                         size={50}
                                         color={'#123abc'}
                                         loading={this.state.isloading}
                                         />: 
                                         "No Record Found"
                                        }
                                     </td>
                                 </tr>
                         }
                     </tbody>
                 </table>
                 </React.Fragment>
         );
     }
}
 
export default withRouter(GlobalSearch) ;