import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Notifications, { notify } from 'react-notify-toast';
import AllPackageService from '../../services/package.service';
import AllUserService from './../../services/alluser.service';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class getPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allPackagesArray: [],
            pandaLoader: false,
            permission: '',
            pandaLoader: true,
            userStatus: '',
            userPackage: ""

        }
        this.AllPackageService = new AllPackageService();
        this.alluserService = new AllUserService();
    }
    componentDidMount() {
		window.scrollTo(0, 0);

        let role = JSON.parse(localStorage.getItem('user'));
        if(role){
            let roleStatus = role.id;
		this.alluserService.editUser(roleStatus).then(data => {
			if (data.code === 200) {
				let result = data.data;
				this.setState({
					userStatus: result,
				})
				if (this.state.userStatus.status === 'inactive') {
					localStorage.clear();
					this.props.history.push('/');
				}
			}
		}).catch(err => {
			console.log(err)
		})
        }
        let rolename = role.role;
        if (rolename === 'subUser') {
            this.getPermissions();
        } else {
            this.setState({ pandaLoader: false })
        }
        this.getallPackages();
        this.getallPackagesByUser();
    }

    getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.getPackage === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}
    getallPackages() {
        this.setState({
            pandaLoader: true
        })
        this.AllPackageService.allPackages().then(data => {

            if (data.code === 200) {
                this.setState({
                    allPackagesArray: data.data,
                    allPackagesArrayDuplicate: data.data,
                    pandaLoader: false
                })
            }
        }).catch(err => {
            this.setState({
                pandaLoader: false
            })
            console.log(err)
        })
    }

    getallPackagesByUser() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.AllPackageService.getPackageByUser(user.id).then(data => {
            if (data.code === 200) {
                debugger
                this.setState({
                    // userPackage: data.data,
                    userPackage: data.data.package[0]._id
                  
                })
            }
        }).catch(err => {
      
            console.log(err)
        })
    }

    upgradePackage(e, item){
        e.preventDefault();
        var id = item._id;
        var price = item.price;
        var signedPdf = item.signedPdf;
        var packageName = item.packageName;

        this.props.history.push({
            pathname: '/payment',
            state: { id:id, price: price, signedPdf : signedPdf,packageName : packageName }
        })
    }
    render() {
        return (
            <React.Fragment>
                {this.state.pandaLoader === true ?
                    <div className="loading">Loading&#8230;</div>
                    : ""}
                <Notifications options={{ zIndex: 200, top: '20px' }} />

                <div className="content-page template-tab" id="sitetable">
                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div className="page-heading">
                                    <h3>Select Package</h3>
                                </div>
                            </div> </div>
                    </div>
                </div>
                <div id="generic_price_table" style={{margin: '0'}}>
                    <section>
                        <div className="">
                            <div className="row">
                                {
                                    this.state.allPackagesArray.length > 0 ? this.state.allPackagesArray.map((item, key) => {

                                                return (
                                                    <div className={this.state.userPackage === item._id? "col-md-4 selected": "col-md-4"} key={key}>
                                                        <div className="generic_content active clearfix">

                                                            <div className="generic_head_price clearfix">

                                                                <div className="generic_head_content clearfix">

                                                                    <div className="head_bg"></div>
                                                                    <div className="head">
                                                                        <span>{item.packageName}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="generic_price_tag clearfix">
                                                                    <span className="price">
                                                                        <span className="sign">$</span>
                                                                        <span className="currency">{item.price}</span>
                                                                        <span className="cent"> </span>
                                                                        <span className="month"> / {item.signedPdf} <span>eSign</span></span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="generic_feature_list">
                                                                <ul>
                                                                    {item.features.length > 0 ? item.features.map((fea, key) => {
                                                                        return (
                                                                            <li key={key}>{fea}</li>
                                                                        )
                                                                    }) : 'No Feature Found.'}
                                                                </ul>
                                                            </div>
                                                            
                                                            <div className="generic_price_btn clearfix">
                                                                <a type="button" onClick={(e) => this.upgradePackage(e, item)} className={this.state.userPackage === item._id? "cursorPointer upgraded-btn": "cursorPointer"}>{this.state.userPackage === item._id? "Subscribed": "Upgrade"}</a>
                                                            </div>
                                                        </div>

                                                    </div>

                                                )


                                    }) : <span style={{ textAlign: "center", display: "block" }}>No Package Found.</span>

                                }

                            </div>
                        </div>
                    </section>
                </div>

            </React.Fragment>
        );

    }
}

export default withRouter(getPackage);