import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AllUserService from './../../services/alluser.service';
import paymentPNG from './../../assets/images/payment-success.png';

import { ENV } from './../../env';
import PaymentService from './../../services/payment.service';
import Notifications, { notify } from 'react-notify-toast';
import './paymentSuccesss.css';
import 'react-phone-input-2/lib/style.css'

// __ Payment Component __//
// ______________________ //
class PaymentSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			paypalResponse: {},
			errors: '',
			path: ENV.url,
			userStatus: '',
			pkgStats:{
                totalPdfs : 0,
                usedPdfs:0,
				remainingPdfs: 0,
				price :0
			}
				
		}
		this.alluserService = new AllUserService();
		this.paymentService = new PaymentService();
	}

	// ___ Lifecycle hooks ___//
	// ______________________ //

	componentDidMount() {

		debugger;
		let url = this.props.location.search;
		if (url) {
			var array = url.split("?");
			var responseArray = array[1].split('paymentId=');
			var paymentArray = responseArray[1].split("&", 1);

			const paymentId = paymentArray[0];
			let user = JSON.parse(localStorage.getItem('user'));
			let body = {
				paymentId: paymentId,
				userId: user.id
			}
			this.paymentService.paypalResponse(body).then(data => {
				if (data.code === 200) {
					this.setState({
						paypalResponse: data.data
					})
					this.getPackageStats();
					window.history.pushState({}, document.title, "/" + 'payment-success' );

				}
			})
		} else {
			this.props.history.push('/dashboard')
		}
		this.isUserActive();
	}

	// ___ Check if user active or not ___//
	// __________________________________ //
	isUserActive(){
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			let userId = user.id;
			this.alluserService.editUser(userId).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}


	getPackageStats() {

        let user = JSON.parse(localStorage.getItem('user'));

        this.paymentService.packageStats(user.id).then(data => {

            if (data.code === 200) {
				console.log(data, "============================================");
                this.setState({
                    pkgStats: data.data
                },() =>{
					var eachPdfPrice = 0;
					if(this.state.pkgStats){
						let totalPdfs = this.state.pkgStats.totalPdfs;
						let packagePrice;
						if(typeof this.state.pkgStats.package !== 'undefined' && this.state.pkgStats.package.length >0)
						{
							packagePrice = this.state.pkgStats.price;
							eachPdfPrice = packagePrice/totalPdfs;
						}	
						else
						{
							packagePrice = 0;
							eachPdfPrice = 0;
						}
					}
				
					document.getElementById('totalPdfs1').innerHTML = this.state.pkgStats.totalPdfs;
					document.getElementById('usedPdfs2').innerHTML = this.state.pkgStats.usedPdfs
					document.getElementById('remainingPdfs3').innerHTML = this.state.pkgStats.remainingPdfs
					document.getElementById('statsnumer4').innerHTML = '$'+ parseFloat(eachPdfPrice * this.state.pkgStats.remainingPdfs, 2).toFixed( 2 )
				})
            }
        }).catch(err => {
            console.log(err);
        })
	}
	
	render() {
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				<div className="content-page Setting-tab tab-structure" id="setting01">
					<div className="setting-fields field-set">
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
								</div>
								<div className="col-md-6 col-sm-6 col-xs-12">
									<div className="page-heading">
										<h3></h3>
									</div>
								</div>
								<div className="col-md-3 col-sm-3 col-xs-12">
								</div>
							</div>
						</div>

						<div className="row column-reverse">
							<div className="col-md-12 col-xs-12">
								<div className="payment-success" style={{display: 'block'}}>
									<div className="body">
										<h2 className="title">Payment Successful</h2>
										<img className="main-img" src={paymentPNG} alt=""/>
										<p>Your payment has been successfully processed</p>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

export default withRouter(PaymentSuccess)
