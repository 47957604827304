import React, { Component } from 'react'
import {ENV} from '../env';

class UnsubscriberService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;
    }

    async all(body) {
        var token = JSON.parse(localStorage.getItem("token"));     
        let url  = ENV.url + `unsubscribers/get?`
        if(body.filter_email !== ''){
            url= url + "email="+body.filter_email+"&"
        }
        if(body.filter_fname !== ''){
            url= url + "firstname="+body.filter_fname+"&"
        }
        if(body.filter_lname !== ''){
            url= url + "lastname="+body.filter_lname+"&"
        }
        if(body.filter_phone !== ''){
            url= url + "phone="+body.filter_phone+"&"
        }
        // if(body.zip !== ''){
        //     url= url + "zip="+body.zip
        // }
        // if(body.is_signed === 0){
        //     url= url + "is_signed=0"
        // }   
        return await fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
    }

    
    async delete(id){
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'unsubscriber/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }



    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default UnsubscriberService;