import React, { Component } from 'react';
import moment from 'moment';
import AllUserService from '../../services/alluser.service';
import TemplateService from '../../services/template.service';
import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import "react-datepicker/dist/react-datepicker.css";
import { css } from '@emotion/core';
import { ENV } from '../../env';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;


class AllCSV extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: '',
			allUsers: [],
			selectedMailgunUserID: '',
			csvListing: [],
			csvListingDuplicate: [],
			path: ENV.url,
			permission: '',
			usersearch: '',
			editData: '',
			siteModal: false,
			pandaLoader: true,
			link: [],
			status: 'inactive',
			id: '',
			userStatus: '',
			isloading: false,
			createUserModel: false,
			ref: "123",
			_id: "",
			RefModelOpen: false,
			TempModelOpen: false,

			templateId: "",
			name:""
		}
		this.alluserService = new AllUserService();
		this.templateService = new TemplateService();
	}

	componentDidMount() {
		debugger;
		window.scrollTo(0, 0);
		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		if (localStorage.getItem('token')) {
			this.alltemplateId();
		}

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	onChangedate = date => {
		this.setState({ linkExpiryDate: date })
	}

	logout() {
		//console.log("helo")
		localStorage.clear();
		this.props.history.push('/');
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					if (this.state.permission.csv === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}


	onchange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	alltemplateId() {
		this.setState({
			isloading: true
		})
		this.templateService.get().then(data => {
			debugger
			console.log(data, "-------")
			// notify.show(data.message, 'success', 3000)

			if (data.code === 200) {
				debugger
				console.log(data.data);
				this.setState({
					csvListing: data.data,
					csvListingDuplicate: data.data
				});
				// notify.show(data.message, "success", 3000)
				this.setState({
					isloading: false
				})
			}
			if (data.code === 404) {
				debugger
				this.setState({
					csvListing: [],
					csvListingDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			console.log(err)
			this.setState({
				isloading: false
			})
		})

	}
















	onchangeCsvActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}






	BankCreateOpenModal = () => {
		this.setState({
			createUserModel: true,
			templateId: '',
			status: 'inactive',
			errors: "",
			name:""
		});
	}

	createUserCloseModel = () => {
		this.setState({
			templateId: '',
			name:"",
			createUserModel: false
		});
	};






	addTemplateIdSubmit(e) {
		e.preventDefault();
		const { templateId , name  } = this.state;
		if (templateId === "") {
			this.setState({ errors: 'TemplateId is required' });
			return;
		} else if (name === "") {
			this.setState({ errors: 'Name  is required' });
			return;
		}
		else {
			this.setState({ errors: '' });
			let body = {
				templateId: templateId,
				name: name,
				ref: "123"

			}
			this.templateService.create(body).then(data => {
				this.setState({
					isloading: true
				});

				if (data.code === 400) {
					this.setState({
						errors: data.message
					})
				}

				if (data.code === 403) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 406) {
					this.setState({
						errors: data.message
					})
				}
				if (data.code === 201) {
					this.createUserCloseModel();
					this.setState({
						templateId:"",
					})
					this.alltemplateId();
					setTimeout(() => {
						// notify.show(data.message, "success", 1000);
					}, 3000);
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	BanKEditOpenModal(id) {
		debugger
		var user = JSON.parse(localStorage.getItem('user'));
		this.templateService.getSingleBank(id).then(data => {
			debugger
			this.setState({
				isloading: true
			});
			if (data.code === 403) {
				this.setState({
					errors: data.message
				})
			}
			if (data.code === 406) {
				this.setState({
					errors: data.message
				})
			}
			if (data.code === 200) {

				this.setState({
					bankName: data.data.bankName,
					phone: data.data.phone,
					email: data.data.email,
					address: data.data.address,
					isloading: false,
					errors: "",
					ref: data.data.ref,
					createUserModel: true,
					_id: data.data._id
				})
				setTimeout(() => {
					// notify.show(data.message, "success", 1000);
				}, 3000);
			}
		}).catch(err => {
			console.log(err)
		})

	}

	TemplateDelete(id) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this TemplateId?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.DeleteTemplateConfirm(id); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});
	}

	DeleteTemplateConfirm(id) {
		this.templateService.delete(id).then(data => {
			console.log(data);
			if (data.code === 200) {
				this.alltemplateId();
				// notify.show(data.message, "success", 3000)
			}
		}).catch(err => {
			console.log(err)
		})
	}





	onChangeData = (evt) => {
		var newContent = evt.editor.getData();
		this.setState({
			description: newContent
		})
	}

	render() {
		console.log(this.state.role);
		return (
			<React.Fragment>
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.pandaLoader === true ?
					<div class="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{this.state.csvLoader === true ? <div class="loading">Loading&#8230;</div> : ""}

				{this.state.role === 'admin' ?
					<div className="setting-fields field-set" style={{ display: this.state.role === 'admin' ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>Template Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="head-search">
											<div className="add-btn text-right">
												{/* <!-- Add Site Modal --> */}
												<button type="button" onClick={() => this.BankCreateOpenModal()} className="btn-style-md btn-purple btn-round">Add New Bank</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row mb-20">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							</div>
						</div>
						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
										<th>#</th>
											<th>TemplateId</th>
											<th>Name</th>
											<th>Created Date</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													if (val.user) {
														return (
															<tr key={key}>
																<th title={key + 1}>{key + 1}</th>
																<td title={val.templateId}>{val.templateId}</td>
																<td title={val.name}>{val.name}</td>
																<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
																<td width="230">
																	<div className="table-option-btns">
																		<a type="button" onClick={() => this.TemplateDelete(val._id)} className="btn btn-table mr-5" download  >
																			<i className="fa fa-trash" ></i> Delete
																  </a>
																	</div>
																</td>
															</tr>
														)
													}
												})
												: <tr >
													<td colSpan="8" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}

													</td>
												</tr>
										}


									</tbody>
								</table>
							</div>
						</div>
					</div> : ''
				}


				{this.state.role === 'user' || this.state.role === 'subUser' ?
					<div className="setting-fields field-set" style={{ display: (this.state.role === 'user' || this.state.role === 'subUser') ? 'block' : 'none' }} >
						<div className="content-header">
							<div className="row">
								<div className="col-md-3 col-sm-3 col-xs-12">
									<div className="page-heading">
										<h3>Template Listing</h3>
									</div>
								</div>
								<div className="col-md-9 col-sm-9 col-xs-12">
									<div className="head-right-wrap">
										<div className="add-btn text-right">
											<button type="button" onClick={() => this.BankCreateOpenModal()} className="btn-style-md btn-purple btn-round">Add New Template</button>
										</div>
									</div>
								</div>
							</div>




						</div>


						<div class="table-wrap">
							<div class="table-responsive">
								<table class="table table-hover table-striped table-bordered table-condensed">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>TemplateId</th>
											<th>Name</th>
											<th>Created Date</th>
											<th>Action</th>

										</tr>
									</thead>
									<tbody>
										{
											this.state.csvListing.length > 0 ?
												this.state.csvListing.map((val, key) => {
													return (
														<tr key={key}>
															<th title={key + 1}>{key + 1}</th>
															<td title={val.templateId}>{val.templateId}</td>
															<td title={val.name}>{val.name}</td>
															<td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
															<td width="230">
																<div className="table-option-btns">
																	<a type="button" onClick={() => this.TemplateDelete(val._id)} className="btn btn-table mr-5" download  >
																		<i className="fa fa-trash" ></i> Delete
																  </a>
																</div>
															</td>
														</tr>
													)

												})
												: <tr >

													<td colSpan="7" style={{ textAlign: "center" }}>
														{this.state.isloading ? <ClipLoader
															css={override}
															sizeUnit={"px"}
															size={50}
															color={'#123abc'}
															loading={this.state.isloading}
														/> : "No Record Found"}
													</td>

												</tr>
										}
									</tbody>
								</table>
							</div>
						</div>



					</div> : ''
				}

				<Modal showCloseIcon={true} className="modal modal-style fade" open={this.state.createUserModel} onClose={this.createUserCloseModel} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.createUserCloseModel()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Add Template</h4>
					</div>
					<div className="modal-body">

						<div className="form-group row">
							<label className="col-md-4">Template Id</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="templateId" value={this.state.templateId || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Template Name</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="name" value={this.state.name || ''} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						{this.state.errors != '' ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ""}
					</div>

					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={(e) => this.addTemplateIdSubmit(e)}>Save</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={() => this.createUserCloseModel()}>Close</button>
					</div>
				</Modal>

			</React.Fragment >
		);
	}
}

export default AllCSV;