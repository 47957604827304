import React, { Component } from 'react';
import {ENV} from './../env';

// let token = JSON.parse(localStorage.getItem("token"));
// axios.defaults.headers.common = {'Authorization': token}


class SiteService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    URL() {
        return ENV.url;

    }




    async postSite(body) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
        const data_1 = await fetch(url + 'sites', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }
    async getSiteHeaders(body) {
        var token = JSON.parse(localStorage.getItem("token"));

        var url = ENV.url;
        const data_1 = await fetch(url + 'siteMapping', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());
        return data_1;
    }
    async createHeaders(body) {
        let token = JSON.parse(localStorage.getItem("token"));
        console.log('header body in service',body);
        console.log(" ULR =>", this.URL())

        const data_1 = await fetch(ENV.url + 'createSiteMapping', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }
    async getAllSite() {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'get-sites', {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    async getSingleSite(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'sites/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    async editSite(id, body) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'sites/' + id, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

    async delSite(id) {
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'sites/' + id, {
            method: 'DELETE',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }


    async getUserSite(id){
        var token = JSON.parse(localStorage.getItem("token"));

        // console.log(" ULR =>", this.URL())
        const data_1 = await fetch(this.URL() + 'get-user-sites/' + id, {
            method: 'GET',
            headers: {
                'Authorization': token.tokenType + ' ' + token.accessToken,
                'Content-Type': 'application/json'
            }
        }).then(res => res.json());;
        return data_1;
    }

   

    




    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default SiteService;