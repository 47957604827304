import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import SiteService from './../../services/sites.service';
import AllUserService from './../../services/alluser.service';
import Switch from "react-switch";
import Modal from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Notifications, { notify } from 'react-notify-toast';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';

const override = css`
	margin: 0 auto;
	overflow:hidden;
`;

class Sites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			domainname: '',
			host: '',
			username: '',
			password: '',
			dbname: '',
			tablename: '',
			link: [],
			errors: '',
			usersearch : "",
			siteArray: [],
			siteArrayDuplicate: [],
			jsonCreatedDropdown: [],
			isloading: false,
			siteModal: false,
			siteHeaderModal: false,
			role: "",
			selectedUserID: "",
			siteId: '',
			status: 'inactive',
			allUsers: [],
			permission: '',
			pandaLoader: true,
			userStatus: '',
			sitesData: [

				"leadid",
				"leadip",
				"phone1",
				"email",
				"lookup_id",
				"pdf_url",
				"DateOfBirth",
				"xAuthentication",
				"title",
				"firstname",
				"lastname",
				"BankName",
				"LenderIDs",
				"sms_send_count",
				"email_send_count",
				"page_visited",
				"created",
				"address",
				"address1",
				"address2",
				"address3",
				"previous_address1",
				"previous_address2",
				"previous_address3",
				"previous_address4",
				"previous_address5",
				"previous_postcode1",
				"previous_postcode2",
				"previous_postcode3",
				"previous_postcode4",
				"previous_postcode5",
				"previous_address1_line1",
				"previous_address2_line1",
				"previous_address3_line1",
				"previous_address4_line1",
				"previous_address5_line1",
				"previous_address1_line2",
				"previous_address2_line2",
				"previous_address3_line2",
				"previous_address4_line2",
				"previous_address5_line2",
				"previous_address1_line3",
				"previous_address2_line3",
				"previous_address3_line3",
				"previous_address4_line3",
				"previous_address5_line3",
				"previous_address1_city",
				"previous_address2_city",
				"previous_address3_city",
				"previous_address4_city",
				"previous_address5_city",
				"previous_address1_province",
				"previous_address2_province",
				"previous_address3_province",
				"previous_address4_province",
				"previous_address5_province",
				"previous_address1_company",
				"previous_address2_company",
				"previous_address3_company",
				"previous_address4_company",
				"previous_address5_company",
				"city",
				"province",
				"country",
				"company",
				"zip",
				//"dob_day",
				//"dob_month",
				//"dob_year",
				//"dob",
				//"DateOfBirth",
				"document_id",
				// "pdf_url",
				"lender_id_doc_order",
				"signature_src",
				"PostURL",
				"is_waypoint",
				"is_submit",
				"is_signed",
				// "other",
			],
			siteheaderdata: [],
		}
		this.siteService = new SiteService();
		this.alluserService = new AllUserService();

	}

	componentDidMount() {
		window.scrollTo(0, 0);

		let role = JSON.parse(localStorage.getItem('user'));
		if (role) {
			let roleStatus = role.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						userStatus: result,
					})
					if (this.state.userStatus.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
		let rolename = role.role;
		if (rolename === 'subUser') {
			this.getPermissions();
		} else {
			this.setState({ pandaLoader: false })
		}
		this.getSitefromAPI();
		this.allUsersAPI();

		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.setState({
				role: user.role
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	onchangeSiteActive(check) {
		if (!check) {
			this.setState({
				status: 'inactive',
			});
		} else {
			this.setState({
				status: 'active',
			});
		}
	}

	createJson(event) {
		const selectedIndex = event.target.options.selectedIndex;
		let key = event.target.options[selectedIndex].getAttribute('id');
		let value = event.target.value;
		let body = {
			"key": key,
			"value": value
		}
		let array = this.state.jsonCreatedDropdown;
		let objIndex = array.findIndex((obj => obj.key == key));
		if (objIndex !== -1) {
			array[objIndex].key = key;
			array[objIndex].value = value
		} else {
			array.push(body);

		}
		this.setState({
			jsonCreatedDropdown: array
		});
	}

	getPermissions() {
		let user = JSON.parse(localStorage.getItem('user'));
		let id = user.id;
		let role = user.role;
		if (role === 'subUser') {
			this.alluserService.userPermission(id).then(data => {

				if (data.code === 200) {
					let result = data.data;
					this.setState({
						permission: result,
						pandaLoader: false
					})
					let perm = this.state.permission;
					if (this.state.permission.Sites === false) {
						this.props.history.push('*');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	submitHeaders = () => {
		let headers = this.state.jsonCreatedDropdown;
		headers.push({ "key": "siteId", "value": "siteId" });
		let body = {
			headers: headers,
			siteId: this.state.siteId
		}
		this.setState({
			isloading: true
		})

		this.siteService.createHeaders(body).then(data => {

			if (data.code === 201) {
				this.setState({
					siteHeaderModal: false,
					jsonCreatedDropdown: [],
					isloading: false
				})
				notify.show(data.message, "success", 3000);
				this.getSitefromAPI();
			}
		}).catch(err => {
			console.log(err);
		})


	}
	onOpenSiteModal = () => {
		this.setState({ siteModal: true });
	};

	oncloseSiteModal = () => {
		this.setState({
			siteModal: false,
			domainname: "",
			host: "",
			username: "",
			password: "",
			dbname: "",
			tablename: "",
			link: [],
			status: 'inactive',
			errors: ""
		});
	};
	oncloseSiteHeaderModal = () => {

		this.setState({
			siteHeaderModal: false,
			domainname: '',
			host: '',
			username: "",
			password: "",
			tablename: "",
			dbname: "",
			link: [],
			status: 'inactive',
			siteheaderdata: [],
			errors: ""
		})
	}

	allUsersAPI() {
		this.alluserService.allUsers().then(data => {
			if (data.code === 200) {
				this.setState({
					allUsers: data.data
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}


	getSitefromAPI() {
		this.setState({
			isloading: true
		})
		this.siteService.getAllSite().then(data => {
			if (data.code === 200) {
				this.setState({
					siteArray: data.data,
					siteArrayDuplicate: data.data,
					isloading: false
				})
				// notify.show(data.message, "success", 3000)
			}
			if (data.code === 404) {
				this.setState({
					siteArray: [],
					siteArrayDuplicate: [],
					isloading: false
				})
				// notify.show(data.message, "success", 3000)

			}
		}).catch(err => {
			this.setState({
				isloading: false
			})
			console.log(err)
		})
	}

	onchange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	changeSchema(e, index) {
		var arrayOfObjs = this.state.link;
		arrayOfObjs[index] = e.currentTarget.value;
		this.setState({ link: arrayOfObjs });
	}

	addTextfield(e) {
		e.preventDefault();
		var inputs = this.state.link;

		inputs.push('');

		this.setState({
			inputs
		})
		//  () => {
		// 	$("#afterHide").hide();
		// })
	}

	removeTextField(indexValue) {
		var array123 = [];
		array123 = this.state.link;

		array123.splice((indexValue), 1);
		// if (array123.length > 0) {
		// 	$("#afterHide").hide();

		// } else {
		// 	$("#afterHide").show();
		// }

		this.setState({
			link: array123.slice()
		});
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validatePhoneNumber(phone) {
		var re = /^\+(?:[\d]*)$/;
		return re.test(String(phone).toLowerCase())
	}





	submit() {

		const {
			domainname,
			host,
			username,
			password,
			dbname,
			tablename,
		} = this.state;
		var count = 0;

		if (domainname === '') {
			this.setState({ errors: 'Domain name is required.' });
			return;
		}
		else if (host === '') {
			this.setState({ errors: 'Host is required.' });
			return;
		}
		else if (username === '') {
			this.setState({ errors: 'Username is required.' });
			return;
		}

		else if (password === '') {
			this.setState({ errors: 'Password is required.' });
			return;
		}
		else if (dbname === '') {
			this.setState({ errors: 'DB name is required.' });
			return;
		}
		else if (tablename === '') {
			this.setState({ errors: 'Table name is required' });
			return;
		}
		else if (this.state.link.length === 0) {
			this.setState({ errors: 'Please add at least one link.' });
			return;
		}
		else if (this.state.link[0] === "") {
			this.setState({ errors: 'Please add at least one link.' });
			return;
		}
		else {
			this.setState({ errors: '' });
		}

		if (this.state.link.length > 0) {
			this.state.link.map((val, index) => {
				if (val === '') {
					this.setState({ errors: 'Remove empty link fields.' });
					count++;

				} else {
					this.setState({ errors: "" })
				}
			})
		}
		if (count === 0 && domainname !== '' && host !== '' && username !== '' && password !== '' && dbname !== '' && tablename !== '') {
			let body = {
				domainName: this.state.domainname,
				hostIp: this.state.host,
				username: this.state.username,
				password: this.state.password,
				tableName: this.state.tablename,
				dbName: this.state.dbname,
				link: this.state.link,
				status: this.state.status,
			}
			this.setState({
				isloading: true
			})
			this.siteService.postSite(body).then(data => {
				if (data.code === 201) {
					this.setState({
						siteId: data.data._id,
						isloading: false
					}, () => {
						$("#afterHide").show();
						let body = {
							siteId: this.state.siteId
						}
						this.setState({
							isloading: true
						})
						this.siteService.getSiteHeaders(body).then(data_1 => {
							if (data_1.code === 404) {
								this.setState({
									errors: data_1.message,
									siteModal: true,
									isloading: false
								})
							}
							if (data_1.code === 200) {
								this.setState({
									siteheaderdata: data_1.data,
									siteHeaderModal: true,
									siteModal: false,
									domainname: '',
									host: '',
									username: "",
									password: "",
									tablename: "",
									dbname: "",
									link: [],
									status: 'inactive',
									isloading: false
									// siteModal: false,
								})
							}

						}).catch(err => {
							console.log(err);
							this.setState({
								isloading: false
							})
						})
					})

					notify.show(data.message, "success", 2000)
					$("#addnewsite .close").click();
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}

	editSite(id) {
		this.siteService.getSingleSite(id).then(data => {
			if (data.code === 200) {
				this.props.history.push({
					pathname: '/editsite',
					state: { edit: data.data[0] }
				})
			}
		}).catch(err => {
			console.log(err)
		})
	}

	deleteSite(id) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="modal-style modal-delete">
						<div className="modal-dialog">
							<div className="modal-content">
								<div className="modal-body modal-delete-body">
									<h2>Are you sure?</h2>
									<p>You want to delete this file?</p>
								</div>
								<div className="modal-footer">
									<button className="btn-style-md btn-round btn-green" onClick={onClose}>No</button>
									<button className="btn-style-md btn-round btn-red ml-10" onClick={() => { this.conformDelete(id); onClose(); }}>Yes, Delete it!</button>
								</div>
							</div>
						</div>
					</div>
				);
			}
		});


	}

	conformDelete(id) {
		this.siteService.delSite(id).then(data => {
			if (data.code === 200) {
				this.getSitefromAPI();
			}
		}).catch(err => {
			console.log(err)
		})
	}


	search(e) {
		e.preventDefault();

		if (this.state.selectedUserID !== "") {
			this.setState({
				isloading: true
			})
			this.siteService.getUserSite(this.state.selectedUserID).then(data => {
				if (data.code === 200) {
					this.setState({
						siteArray: data.data,
						isloading: false
					})
					notify.show(data.message, 'success', 3000)
				}

				if (data.code === 404) {
					this.setState({
						siteArray: [],
						isloading: false
					})
					notify.show(data.message, 'success', 3000)
				}
			}).catch(err => {
				this.setState({
					isloading: false
				})
				console.log(err)
			})
		} else {
			this.getSitefromAPI();

		}
	}

	reset() {
		this.getSitefromAPI();
		$("#selectuser").val($("#target option:first").val());

	}

	onchangeSearchadmin(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.siteArrayDuplicate;

			let data = myArray.filter(x => {
				return (x.username.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.username.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.dbName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.domainName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.hostIp.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||
					(x.tableName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase()) ||

					(x.user.firstName.replace(/\s+/g, '') + x.user.lastName.replace(/\s+/g, '')).toLowerCase().includes(current.state.usersearch.replace(/\s+/g, '').toLowerCase())


			})
			current.setState({
				siteArray: data
			})
		})
	}

	onchangeSearch(e) {
		var current = this;
		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			let myArray = this.state.siteArrayDuplicate;

			let data = myArray.filter(x => {
				return (x.username).toLowerCase().includes(current.state.usersearch.toLowerCase()) ||
					(x.dbName).toLowerCase().includes(current.state.usersearch.toLowerCase()) ||
					(x.domainName).toLowerCase().includes(current.state.usersearch.toLowerCase()) ||
					(x.hostIp).toLowerCase().includes(current.state.usersearch.toLowerCase()) ||
					(x.tableName).toLowerCase().includes(current.state.usersearch.toLowerCase())

			})
			current.setState({
				siteArray: data
			})
		})
	}

	render() {
		var currentThis = this;
		return (
			<React.Fragment>
				{this.state.pandaLoader === true ?
					<div className="loading">Loading&#8230;</div>
					: ""}
				<Notifications options={{ zIndex: 200, top: '20px' }} />
				{
					this.state.role === "admin" ?
						<div>
							<div className="content-page template-tab" id="sitetable">
								<div className="content-header">
									<div className="row">
										<div className="col-md-3 col-sm-3 col-xs-12">
											<div className="page-heading">
												<h3>All Sites</h3>
											</div>
										</div>
										<div className="col-md-9 col-sm-9 col-xs-12">
											<div className="head-right-wrap">
												<div className="head-search">
													<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearchadmin(e)} className="form-control" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row mb-20">
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									<div className="add-btn sent-btns display-flex">
										{/* <div className="form-group mr-20">
											
											<div className="dropdown lead-sentdrop custom-select mr-10">
												<select id="selectuser" className="btn-dropdown dropdown-toggle btn-dropsent" name="selectedUserID" onChange={(e) => this.onchange(e)} data-toggle="dropdown">
													<option value="">Select User</option>
													{
														this.state.allUsers.length > 0 ?
															this.state.allUsers.map((val, key) => {
																return (
																	<option key={key} value={val._id}>{val.firstName + " " + val.lastName}</option>
																)
															}) : <option value="">No Record Found</option>
													}
												</select>
											</div>
											<div className="sendEmail" style={{ marginRight: "5px" }}>
												<a className="btn btn-purple btn-style-md btn-send" onClick={(e) => this.search(e)}>Search</a>
											</div>
											<div className="sendEmail">
												<a className="btn btn-purple btn-style-md btn-send" onClick={(e) => this.reset(e)}>Reset</a>
											</div>
										</div> */}
									</div>
								</div>
							</div>


							<div className="table-wrap">
								<div className="table-responsive">
									<table className="table table-hover table-striped table-bordered table-condensed">
										<thead className="thead-light">
											<tr>
												<th align="center">#</th>
												<th>User</th>
												<th>Username</th>
												<th>DB Name</th>
												<th>Domain Name</th>
												<th>Host IP</th>
												<th>Table Name</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											{this.state.siteArray.length > 0 ? this.state.siteArray.map((val, key) => {
												if (val.user) {
													return (
														<tr key={key}>
															<th title={key+1} align="center">{key+1}</th>
															<td title={val.user.firstName + val.user.lastName}>{val.user !== null && val.user ? val.user.firstName + " " + val.user.lastName : ""}</td>

															<td title={val.username}>{val.username}</td>
															<td title={val.dbName}>{val.dbName}</td>
															<td title={val.domainName}>{val.domainName}</td>
															<td title={val.hostIp}>{val.hostIp}</td>
															<td title={val.tableName}>{val.tableName}</td>
															<td>
																<div className="table-option-btns">
																	{val.status == 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}
																	{/* <button type="button" className="btn btn-table mr-5" onClick={() => this.editSite(val._id)}>
																		<i className="fa fa-pencil-square-o"></i> Edit </button>
																	<button type="button" className="btn btn-table" onClick={() => this.deleteSite(val._id)}>
																		<i className="glyphicon glyphicon-trash"></i> Delete </button> */}
																</div>
															</td>
														</tr>
													)
												}
											}) :
												<tr>
													<td colSpan="12" style={{ textAlign: "center" }}>
														{this.state.isloading ?
															<ClipLoader css={override} sizeUnit={"px"} size={50} color={'#123abc'} loading={this.state.isloading} /> : "No Record"}

													</td>
												</tr>
											}

										</tbody>
									</table>
								</div>
							</div>


						</div>


						:

						<div>
							<div className="content-page template-tab" id="sitetable">
								<div className="content-header">
									<div className="row">
										<div className="col-md-3 col-sm-3 col-xs-12">
											<div className="page-heading">
												<h3>All Sites</h3>
											</div>
										</div>
										<div className="col-md-9 col-sm-9 col-xs-12">
											<div className="head-right-wrap">
												<div className="head-search">
													<input type="text" name="usersearch" placeholder="Search" value={this.state.usersearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
												</div>
												{this.state.role === 'subUser' && this.state.permission.Sites_Status !== 'RW' ?
												"" :
													<div className="add-btn text-right">
														<button type="button" onClick={this.onOpenSiteModal} className="btn-style-md btn-purple btn-round">Add New site</button>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="table-wrap">
									<div className="table-responsive">
										<table className="table table-hover table-striped table-bordered table-condensed">
											<thead className="thead-light">
												<tr>
													<th align="center">#</th>
													<th>Username</th>
													<th>DB Name</th>
													<th>Domain Name</th>
													<th>Host IP</th>
													<th>Table Name</th>
													<th>Status</th>
													{this.state.role === 'subUser' && this.state.permission.Sites_Status !== 'RW' ?
														''
														:
														<th>Action</th>
													}
												</tr>
											</thead>
											<tbody>
												{this.state.siteArray.length > 0 ? this.state.siteArray.map((val, key) => {
													return (
														<tr key={key}>
															<th title={key+1} align="center">{key+1}</th>
															<td title={val.username}>{val.username}</td>
															<td title={val.dbName}>{val.dbName}</td>
															<td title={val.domainName}>{val.domainName}</td>
															<td title={val.hostIp}>{val.hostIp}</td>
															<td title={val.tableName}>{val.tableName}</td>
															<td>{val.status == 'active' ? <span className="yestick">Active</span> : <span className="noCross">In Active</span>}</td>
															{
																this.state.role === 'subUser' && this.state.permission.Sites_Status !== 'RW' ?
																	''
																	:
																	<td>
																		<div className="table-option-btns">
																			<button type="button" className="btn btn-table mr-5" onClick={() => this.editSite(val._id)}>
																				<i className="fa fa-pencil-square-o"></i>Edit</button>
																			{/* <button type="button" className="btn btn-table" onClick={() => this.deleteSite(val._id)}>
																				<i className="glyphicon glyphicon-trash"></i> Delete
																					</button> */}
																		</div>
																	</td>
															}
														</tr>
													)
												}) :
													<tr>
														{
															this.state.role === 'subUser' && this.state.permission.Sites_Status !== 'RW' ?
																<td colSpan="12" style={{ textAlign: "center" }}>
																	{this.state.isloading ?
																		<ClipLoader css={override} sizeUnit={"px"} size={50} color={'#123abc'} loading={this.state.isloading} /> : "No Record"}

																</td>
																:
																<td colSpan="12" style={{ textAlign: "center" }}>
																	{this.state.isloading ?
																		<ClipLoader css={override} sizeUnit={"px"} size={50} color={'#123abc'} loading={this.state.isloading} /> : "No Record"}

																</td>
														}
													</tr>
												}

											</tbody>
										</table>
									</div>
								</div>
							</div>


						</div>
				}
				<Modal open={this.state.siteModal} onClose={this.oncloseSiteModal} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.oncloseSiteModal()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Add new site</h4>
					</div>
					<div className="modal-body">
						{Object.keys(this.state.errors).length > 0 && this.state.errors ?
							<div className="alert alert-danger">
								{this.state.errors}
							</div> : ''} {/*
													<form className="add-template-modal"> */}
						<div className="form-group row">
							<label className="col-md-4">Domain Name</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="domainname" value={this.state.domainname} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Host</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="host" value={this.state.host} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Username</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="username" value={this.state.username} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Password</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="password" value={this.state.password} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">DB Name</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="dbname" value={this.state.dbname} onChange={(e) => this.onchange(e)} />
							</div>
						</div>
						<div className="form-group row">
							<label className="col-md-4">Table Name</label>
							<div className="col-md-8">
								<input type="text" className="form-control" name="tablename" value={this.state.tablename} onChange={(e) => this.onchange(e)} />
							</div>
						</div>

						<div className="form-group row" id="dynamic">
							<label className="col-md-4">Add Links</label>
							<div className="col-md-8">
								{this.state.link.map((val, i) => {
									return (
										<div className="field-add" key={i}>
											<div className="field-add-input mr-10" id="dynamic">
												<input type="text" className="form-control" id={i} name="link" value={this.state.link[i]} onChange={(e) => this.changeSchema(e, i)} />
											</div>
											<div className="field-add-btns" id="minusbutton">
												{/* {(i === this.state.link.length - 1 ) ? 
													<button className="btn-add" onClick={(e)=> this.addTextfield(e)}>
															<i className="fa fa-plus" aria-hidden="true"></i>
													</button>: ''
												} */}
												<button className="btn-rem ml-5" onClick={() => currentThis.removeTextField(i)}>
													<i className="fa fa-minus" aria-hidden="true"></i>
												</button>
											</div>
										</div>
									)
								})}
								<div className="field-add-btns" id="afterHide">
									<button className="btn-add" onClick={(e) => this.addTextfield(e)}>
										<i className="fa fa-plus" aria-hidden="true"></i>
									</button>
								</div>
							</div>
						</div>

						<div className="form-group row">
							<label className="col-md-4">Status</label>
							<div className="col-md-8">
								<Switch name="status"
									onChange={(e) => this.onchangeSiteActive(e)} checked={this.state.status == 'active' ? true : false} />
							</div>
						</div>

					</div>
					{
						this.state.isloading === true ?
							<React.Fragment>
								<div className="btn-process">
									<span><ClipLoader css={override} sizeUnit={"px"} size={20} color={'#123abc'} loading={true} /></span>
									<p>Processing...</p>
								</div>
							</React.Fragment>
							:
							<div className="modal-footer">
								<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.submit()}>Next</button>
								<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.oncloseSiteModal}>Close</button>

							</div>
					}

				</Modal>

				<Modal open={this.state.siteHeaderModal} onClose={this.oncloseSiteHeaderModal} closeOnOverlayClick={false}>
					<div className="modal-header">
						<button type="button" className="close" onClick={() => this.oncloseSiteHeaderModal()} data-dismiss="modal">&times;</button>
						<h4 className="modal-title">Headers Mapping</h4>
					</div>
					<div className="modal-body mapping-ul-list">
						{this.state.sitesData.length > 0 ?
							this.state.sitesData.map((val, key) => {

								return (
									<div className="form-group row" key={key}>
										<label className="col-md-6 col-sm-6 col-xs-12">{val}</label>

										<div className="col-md-6 col-sm-6 col-xs-12"  >
											<select className="form-control" onChange={(e) => this.createJson(e)}>
												<option>Select</option>
												{
													this.state.siteheaderdata.length > 0 ? this.state.siteheaderdata.map((item, key) => {
														return (
															<option key={key} id={val} value={item}>{item}</option>
														)
													}) : <option>No record found</option>
												}
											</select>
										</div>
									</div>
								)
							}) : ""
						}
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-purple btn-style-md btn-round mr-5" onClick={this.oncloseSiteHeaderModal}>Close</button>
						<button type="button" className="btn btn-purple btn-style-md btn-round" onClick={() => this.submitHeaders()}>Save</button>
					</div>
				</Modal>


			</React.Fragment>
		);
	}
}

export default withRouter(Sites);