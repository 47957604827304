import React, { Component } from 'react';
import './paymentHistory.css';
import { withRouter } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
import AllUserService from './../../services/alluser.service';

import $ from 'jquery';
import PaymentService from '../../services/payment.service';
import { CircularProgressbar,CircularProgressbarWithChildren ,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ENV } from './../../env';
import ProfileService from '../../services/profile.service';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/core';
import moment from 'moment';
const override = css`
	margin: 0 auto;
	overflow:hidden;
`;
class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            signedLeads: "",
            errors: '',
            roleIn: '',
            dashboard: [],
            loginLogs: [],
            paymentList: [],
            EData: [],
            SData: [],
            permission: '',
            pandaLoader: true,
            psites: true,
            psms: true,
            pemail: true,
            userStatus: '',
      
            leadCount: 0,
            leadPrice: 0,
            outBoxPrice: 0,
            smsCount: 0,
            userCredit: 0,
      
            leadCount_price: 0,
            smsCount_price: 0,
      
            smsCheck: false,
            smsCharge: 0,
            leadCheck: false,
            leadCharge: 0,
            par_charge: 0,
            balance_less_then: 0,
      
            isloading: false,
          }
          this.alluserService = new AllUserService();
          this.profileService = new ProfileService();
        }
        URL() {
          return ENV.url;
        }
      
        componentDidMount() {
          window.scrollTo(0, 0);
          let role = JSON.parse(localStorage.getItem('user'));
          if (role) {
            let roleStatus = role.id;
            this.alluserService.editUser(roleStatus).then(data => {
              if (data.code === 200) {
                this.userProfile();
                this.userPayment();
                this.userPaidCredit();
                this.getPageInnerPermision(role);
                let result = data.data;
                this.setState({
                  userStatus: result,
                })
                if (this.state.userStatus.status === 'inactive') {
                  localStorage.clear();
                  this.props.history.push('/');
                }
              }
            }).catch(err => {
              console.log(err)
            })
          }
          if (role) {
            this.alluserService.LeadSmsCredit().then(data => {
              if (data.code === 200) {
                let result = data;
                this.setState({
                  leadCount: result.leadCount,
                  leadPrice: result.leadPrice,
                  outBoxPrice: result.outBoxPrice,
                  smsCount: result.smsCount,
                })
      
              }
            }).catch(err => {
              console.log(err)
            })
          }
      
        }
      
        
        userPaidCredit() {
          this.alluserService.LeadSmsCredit_Paid().then(data => {
            if (data.code === 200) {
              let result = data;
              this.setState({
                leadCount_price: result.leadCount,
                smsCount_price: result.smsCount,
              })
      
            }
          }).catch(err => {
            console.log(err)
          })
        }
      
      
        userProfile() {
          let user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            debugger
            this.profileService.UserProfile(user.id).then(data => {
              debugger
              console.log("data", data);
      
            }).catch(err => {
              console.log(err);
            })
          } else {
            this.props.history.push('/');
            localStorage.clear();
              }
      
        }
      
        userPayment() {
          let user = JSON.parse(localStorage.getItem('user'));
          if (user) {
            this.setState({
              
                isloading: true
              });
            this.profileService.userPayment(user.id).then(data => {
              debugger
              console.log("data", data);
              debugger
              this.setState({
                paymentList: data.data,
                isloading: false
              });
      
            }).catch(err => {
              console.log(err);
      
            })
          } else {
            this.props.history.push('/');
            localStorage.clear();
      
          }
      
        }
      
      
        getPageInnerPermision(data) {
          debugger
          let role = data.role;
          let userId = data.id;
          if (role == "admin") {
            userId = data.id;
          } else if (role == "user") {
            userId = data.id;
          } else if (role == "subUser") {
            userId = data.parentId;
          }
          let dataArray = [];
          this.alluserService.getInnerPermission(userId).then(data => {
            debugger
            let paymentInfo = data.data.paymentInfo[0];
            if(paymentInfo.check){
              this.setState({
                smsCheck: paymentInfo.smsCheck,
                smsCharge: paymentInfo.smsCharge,
                leadCheck: paymentInfo.leadCheck,
                leadCharge: paymentInfo.leadCharge,
                par_charge: paymentInfo.par_charge,
                balance_less_then: paymentInfo.balance_less_then,
                isloading: false
              })
            }
           
          }).catch(err => {
            console.log(err)
          })
      
        }
      
 
    render() {
        return (
            <React.Fragment>
                {/* <Notifications options={{ zIndex: 200, top: '20px' }} />

                <div className="content-page template-tab" id="sitetable">
                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">
                                <div className="page-heading">
                                    <h3>Payment History</h3>
                                </div>
                            </div>
                            <div className="col-md-9 col-sm-9 col-xs-12">
                                <div className="head-right-wrap">
								<div className="head-search">
                                    <input type="text" name="paymentsearch" placeholder="Search" value={this.state.paymentsearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    





                    <div className=" row">
                        <div className="graphHeading col-sm-4 col-xs-4">
                            <div className="cards cus total">
                                <CircularProgressbarWithChildren value={(this.state.pkgStats.totalPdfs / this.state.pkgStats.totalPdfs) * 100}>
                                    Put any JSX content in here that you'd like. It'll be vertically and horizonally centered.
                                    <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon total"><i class="fa fa-file-pdf-o"></i></span>

                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="labels cus">
                                <div className="heading">
                                    {this.state.pkgStats.totalPdfs}
                                </div>
                                <label>Total Quota</label>
                            </div>
                        </div>
                        <div className="graphHeading col-sm-4 col-xs-4">
                            <div className="cards cus remain">
                                <CircularProgressbarWithChildren value={(this.state.pkgStats.remainingPdfs / this.state.pkgStats.totalPdfs) * 100}>
                                    Put any JSX content in here that you'd like. It'll be vertically and horizonally centered.
                                    <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon remain"><i class="fa fa-file-pdf-o"></i></span>

                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="labels cus">
                                <div className="heading">
                                    {this.state.pkgStats.remainingPdfs}
                                </div>
                                <label>Remaining eSign</label>
                            </div>
                        </div>
                        <div className="graphHeading col-sm-4 col-xs-4">
                            <div className="cards cus used">
                                <CircularProgressbarWithChildren value={(this.state.pkgStats.usedPdfs / this.state.pkgStats.totalPdfs) * 100}>
                                    Put any JSX content in here that you'd like. It'll be vertically and horizonally centered.
                                    <div className="file" style={{ marginTop: "100px" }}>
                                    <span className="file-icon used"><i class="fa fa-file-pdf-o"></i></span>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="labels cus">
                                <div className="heading">
                                    {this.state.pkgStats.usedPdfs}
                                </div>
                                <label>Used eSign</label>
                            </div>
                        </div>
                    </div>

                    <div className="content-header">
                        <div className="row">
                            <div className="col-md-3 col-sm-3 col-xs-12">

                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="head-search">
                                    <input type="text" name="paymentsearch" placeholder="Search" value={this.state.paymentsearch} onChange={(e) => this.onchangeSearch(e)} className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="add-btn text-right">
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="table-wrap">
                        <div className="table-responsive">
                      
                            <table className="table table-hover table-striped table-bordered table-condensed">
                                <thead className="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Package Name</th>
                                        <th>Sign Documents</th>
                                        <th>Price</th>
                                        <th>Created At</th>
                                        <th>Payment Type</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.paymentRecordList.length > 0 ?
                                            this.state.paymentRecordList.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th title={key+1}>{key+1}</th>
                                                        <td title ={val.packageName} >{val.packageName}</td>
                                                        <td title ={val.package ? val.package.signedPdf : 0} >{val.package ? val.package.signedPdf : 0}</td>
                                                        <td title ={val.price} >${val.price ? val.price : 0}</td>
                                                        <td>{moment(val.createdAt).format('MM/DD/YYYY')}</td>
                                                        <td title ={val.isExpired === true ? 'Expired' : "Not Expired"} >{val.isExpired === true ? 'Expired' : "Not Expired"} </td>
                                                        <td title ={val.payment_method} >{val.payment_method ? val.payment_method : 'N/A'}</td>
                                                        
                                                       
                                                    </tr>
                                                )
                                            }) : 
                                            <tr >
                                              <td colSpan="8" style={{ textAlign: "center" }}>
                                                {this.state.isloading ? <ClipLoader
                                                  css={override}
                                                  sizeUnit={"px"}
                                                  size={50}
                                                  color={'#123abc'}
                                                  loading={this.state.isloading}
                                                /> : "No Record Found"}

                                              </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div> */}


<div className="setting-fields field-set"  >
<div className="row mb-20">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
   <h3>Payment History</h3>
  </div>
  </div>
<div className="row mb-20">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  </div>
</div> 
<div class="table-wrap">
  <div class="table-responsive">
    <table class="table table-hover table-striped table-bordered table-condensed">
      <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>Credit Top Up</th>
          <th>price</th>
          <th>Charge Id</th>
          <th>Created Date</th>
        </tr>
      </thead>
      <tbody>
                        {
                        this.state.paymentList.length > 0 ?

                        this.state.paymentList.map((val, key) => {
                        return (
                        <tr key={key}>
                        <th title={key + 1}>{key + 1}</th>
                        <td title={val.creditTopUp}>{val.creditTopUp}</td>
                        <td title={val.price}>{val.price}</td>
                        <td title={val.chargeId}>{val.chargeId} </td>
                        <td title={moment(val.createdAt).format("YYYY/MM/DD") || ""}>{moment(val.createdAt).format("YYYY/MM/DD") || ""} </td>
                        <td colSpan="8" style={{ textAlign: "center" }}>
                        {this.state.isloading ? <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.isloading}
                        /> : " "}

                        </td>
                        </tr>
                        )
                        })
                        : 
                        <tr >
                        <td colSpan="8" style={{ textAlign: "center" }}>
                            {this.state.isloading ? <ClipLoader
                                css={override}
                                sizeUnit={"px"}
                                size={50}
                                color={'#123abc'}
                                loading={this.state.isloading}
                            /> : "No Record Found"}

                        </td>
                    </tr>
                        }
                         

                  </tbody>
    </table>
  </div>
</div>
</div>




            </React.Fragment>
        );

    }
}

export default withRouter(PaymentHistory);