import React, { Component }  from 'react'
import Highcharts from 'highcharts'

export default class HighChartStats  extends Component {
  componentDidMount() {
    this.drow(this.props)
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.chart.destroy()
    this.drow(nextProps)
  }

  shouldComponentUpdate() {
  	return false
  }

  componentWillUnmount() {
    this.chart.destroy()
  }

  drow(props) {
		this.chart = Highcharts.chart(this.container, {
      chart: {
        type: 'line'
      },
      title: {
        text: props.title
      },
      xAxis: {
        categories: props.data[0].xData,
        tickmarkPlacement: 'on',
        title: {
          enabled: false
        }
      },
      yAxis: {
        title: {
          text: 'Count'
        }
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: ({point.y:,.0f})<br/>',
        split: true
      },
      series: props.data
    })
  }

  render() {
    return <div className="chart dashboard-chart" ref={ref => this.container = ref} />
  }
}