import React, { Component } from 'react'
import {ENV} from '../env';

class DashboardService extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

   
    async getLoginLogs(to, from) {
        var token = JSON.parse(localStorage.getItem("token"));        
        var url = ENV.url;
        const data_1 = await fetch(url + 'loginLogs/'+to+'/'+from, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    async search(body, to , from) {
        var token = JSON.parse(localStorage.getItem("token"));        
       

        let url  = ENV.url + `loginLogs/${to}/${from}?`
        if(body.firstname !== ''){
            url= url + "firstname="+body.firstname+"&"
        }
        if(body.lastname !== ''){
            url= url + "lastname="+body.lastname+"&"
        }
        if(body.ip !== ''){
            url= url + "ip="+body.ip+"&"
        }
        if(body.from !== ''){
            url= url + "from="+body.from+"&"
        }
        if(body.to !== ''){
            url= url + "to="+body.to
        }

        const data_1 = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token.tokenType + ' ' + token.accessToken,

            }
        }).then(res => res.json());
        return data_1;
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default DashboardService;