import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import './my404.css';
import $ from 'jquery';
class My404Component extends Component {
     constructor(props) {
          super(props);
        
	}
	

     render() { 
         return (
             <div className="container">
                 <div className="row">
                     <div className="col-md-12">
                         <div className="error-template">
                             <h1> Oops!</h1>
                             <h2>404 Not Found</h2>
                             <div className="error-details">
                                 Sorry, an error has occured, Requested page not found!
                            </div>
                             <div className="error-actions">
                                 <Link to="/profile" className="btn btn-primary btn-lg"><span className="glyphicon glyphicon-home"></span>
                                     Take Me Home </Link>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         );
     }
}
 
export default withRouter(My404Component) ;