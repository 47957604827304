import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';

import $ from 'jquery';
class Home extends Component {
     constructor(props) {
          super(props);
         
	}
	
	//Life Cycle
     componentDidMount() {
          let token = localStorage.getItem('token');
          if (token) {
               this.props.history.push('/dashboard')
          } else {
               this.props.history.push('/');
               localStorage.clear();
          }
     }

     render() { 
          return ( 
               <section className="lead-login">
                    <div className="container-fluid">
                         <div className="row landing-bg">
                              <div className="header text-center">
                                   <div className="col-md-12 header-text">
                                        <img src={'./../../assets/images/logo-login.png'} alt="logo"/>
                                        <h1>Groom Your<span>Business</span></h1>
                                        <p className="lead">Easy-to-use interface & sharing & collaboration features</p>
                                        <Link to="/login" className="btn btn-lg btn-primary" >Sign In</Link>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
           );
     }
}
 
export default withRouter(Home) ;