import React, { Component } from 'react'
import headerPanda from '../../assets/images/logo-2.png';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ProfileService from './../../services/profile.service';
import AllUserService from './../../services/alluser.service';
import PaymentService from './../../services/payment.service';
import $ from "jquery";
import { ENV } from './../../env';


class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			userProfile: '',
			path: ENV.url,
            pkgStats:{
                totalPdfs : 0,
                usedPdfs:0,
				remainingPdfs: 0,
				price : 0
            }
		}
		this.alluserService = new AllUserService();
		this.profileService = new ProfileService();
		this.paymentService = new PaymentService();

	}

	renderStatsBar()
	{
		var eachPdfPrice = 0;
		if(this.state.pkgStats){
			let totalPdfs = this.state.pkgStats.totalPdfs;
			let packagePrice;
			
			if(typeof this.state.pkgStats.package != 'undefined')
			{
				packagePrice = this.state.pkgStats.price;
				eachPdfPrice = packagePrice/totalPdfs;
			}	
			else
			{
				packagePrice = 0;
				eachPdfPrice = 0;
			}
				
			
			
		}
		return(
			this.state.role === 'user' ? 
			<div className="pdfs">
				<div className="lidtd"><div className="title">Total Quota</div><span className="number" id="totalPdfs1">{this.state.pkgStats.totalPdfs}</span></div>
				<div className="lidtd"><div className="title">Used eSign</div><span className="number" id="usedPdfs2">{this.state.pkgStats.usedPdfs}</span></div>
				<div className="lidtd"><div className="title">Remaining eSign</div><span className="number" id="remainingPdfs3">{this.state.pkgStats.remainingPdfs}</span></div>
				<div className="lidtd"><div className="title">Wallet Balance</div><span className="number" id="statsnumer4">${ parseFloat(eachPdfPrice * this.state.pkgStats.remainingPdfs, 2).toFixed( 2 )}</span></div>
				
			</div>: ''
		)
	}

	componentDidMount() {
		
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			let roleStatus = user.id;
			this.alluserService.editUser(roleStatus).then(data => {
				if (data.code === 200) {
					let result = data.data;
					this.setState({
						user: result,
						role: user.role
					})

					if (this.state.user.status === 'inactive') {
						localStorage.clear();
						this.props.history.push('/');
					}
				}
			}).catch(err => {
				console.log(err)
			})
		}

		this.getPackageStats();
		this.userProfile();
	}

	getPackageStats() {

		this.setState({
			isloading: true
		})
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.paymentService.packageStats(user.id).then(data => {

				if (data.code === 200) {
					this.setState({
						pkgStats: data.data,
						isloading: false,
					})
				}
			}).catch(err => {
				console.log(err);
				this.setState({
					isloading: false
				})
			})
		}
	}

	userProfile() {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			this.profileService.UserProfile(user.id).then(data => {

				if (data.code === 200) {
					this.setState({
						firstName: data.data.firstName,
						email: data.data.email,
						phone: data.data.phone,
						lastName: data.data.lastName,
						errors: '',
						userProfile: data.data.profileImage
					});
				} else if (data.message === "TokenInvalid") {
					this.props.history.push('/');
					localStorage.clear();
				}


			}).catch(err => {
				console.log(err);
			})
		} else {
			this.props.history.push('/');
			localStorage.clear();
		}

	}

	logout(e) {
		e.preventDefault();
		localStorage.clear();
		this.props.history.push('/');
	}

	sideBarShowHideScript() {
		$('#sidebar').toggleClass('active');
	}

	navigateProfile(e) {
		e.preventDefault();
		this.props.history.push('/profile')
	}
	
	render() {
		return (
			<div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-2 col-xs-3 col-sm-3">
							<div className="lead-logo">
								<Link to="/dashboard">
									<img src={headerPanda} className="img-resposive" alt="" />
								</Link>
							</div>
						</div>
						<div className="col-md-10 col-xs-9 col-sm-9">
							<div className="lead-content sidebar-toggel-btn">
								<div className="navbar-header">
									<button type="button" id="sidebarCollapse" onClick={() => this.sideBarShowHideScript()} className="btn btn-info navbar-btn ">
										<i className="fa fa-align-left" aria-hidden="true"></i>
									</button>
								</div>
							</div>
							{
								this.renderStatsBar()
							}
							
			  
							<div className="top-right-btns">
								<div className="header-search-btn">
									{this.state.role === 'subUser'? 
									<Link to="/global-search-leads" data-toggle="tooltip" data-placement="left" title="Global Search"><i class="fa fa-search" aria-hidden="true"></i></Link>:
									<Link to="/global-search" data-toggle="tooltip" data-placement="left" title="Global Search"><i class="fa fa-search" aria-hidden="true"></i></Link>
									  }
								</div>
							
								<div className="login-user-patch dropdown">
									<div className="dropdown-toggle " data-toggle="dropdown">
										<div className="login-user-patch-img img-circle">
											<img src={this.state.userProfile ? this.state.path + this.state.userProfile : ""} id="headerProfileImage" className="img-resposive" alt="" />
										</div>
										<div className="login-user-patch-detail">
											<span id="fnamelname">{this.state.firstName + " " + this.state.lastName}</span>
										</div>
										<i class="fa fa-angle-down ml-10" aria-hidden="true"></i>
									</div>
									<ul className="dropdown-menu">
										<li><a href="#" onClick={(e) => this.navigateProfile(e)}>
											<i class="fa fa-user" aria-hidden="true"></i>Profile
										</a></li>
										<li>
											<a href={null} onClick={(e) => this.logout(e)}>
												<i className="fa fa-sign-out"></i>logout
											</a>
										</li>
									</ul>

									{/* <div className="login-user-patch-img img-circle" style={{ cursor: "pointer" }} onClick={(e) => this.navigateProfile(e)}>
										<img src={this.state.userProfile ? this.state.path + this.state.userProfile : ""} id="headerProfileImage" className="img-resposive" alt="" />
									</div>
									<div className="login-user-patch-detail" style={{ cursor: "pointer" }} onClick={(e) => this.navigateProfile(e)}>
										<span id="fnamelname">{this.state.firstName + " " + this.state.lastName}</span>
									</div> */}
								</div>

								{/* <div className="logout-btn">
									<a href={null} onClick={(e) => this.logout(e)}>
										<span className="hidden-xs" >logout</span><i className="fa fa-sign-out"></i>
									</a>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(Header);
